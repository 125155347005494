import { gql, useQuery } from '@apollo/client';
import { DistrictView, Modal } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';

const GET_DISTRICT = gql`
  query GetDistrict($id: ID!) {
    district: getDistrict(id: $id) {
      _id
      code
      name
      region {
        _id
        code
        name
      }
      meta {
        usersCount
        blocksCount
        roundsCount
        plotsCount
        itinerariesCount
        propertiesCount
        customersCount
      }
      createdAt
      updatedAt
    }
  }
`

export default function ViewDistrictContainer({ open, setOpen }: { open: boolean; setOpen: (val: boolean) => void }) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_DISTRICT, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="District Information"
      loading={loading}
      description="Details of district are shown below"
    >
      <DistrictView
        district={data?.district}
      />
    </Modal>
  )
}