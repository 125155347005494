import { FC } from "react";
import { Avatar } from "components/core";
import { classNames } from "utils";
import numeral from "numeral";
import moment from "moment";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";

interface MeterViewProps {
  data: any;
  loading: boolean;
}

const MeterView: FC<MeterViewProps> = ({ data, loading }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div className='pt-6'>
        <span className='text-xs font-light'>Old Meter Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Old Meter Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {data?.oldMeterNumber || data?.meter?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Old Meter Phase
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {data?.oldMeterPhase || data?.meter?.modelMeta?.phase || "N/A"}
            </div>
          </div>
       
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Old Service Type
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {data?.meter?.modelType || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>New Meter Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              New Meter Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {data?.installationMeter?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              New Meter Phase
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {data?.installationMeter?.modelMeta?.phase || "N/A"}
            </div>
          </div>
        
          <div>
            <span className='block text-sm font-light text-gray-700'>
              New Service Type
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {/* TODO: Get old service type */}
              {(data?.installationMeter?.modelType) || "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeterView;
