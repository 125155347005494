import { Outlet, Route } from "react-location";
import {
  BlocksPage,
  DashboardPage,
  DistrictsPage,
  PlotsPage,
  RegionsPage,
  ForgotPasswordPage,
  RoundsPage,
  SettingsPage,
  SigninPage,
  DisconnectionServiceOrdersPage,
  ReconnectionServiceOrdersPage,
  BillsPage,
  ReadingExceptionsPage,
  UnlistedMetersPage,
  AuthPage,
  TariffClassesPage,
  TariffInstancesPage,
  DebtDocketsPage,
  ToolsPage,
  ServicePointReadingsPage,
  ReplacementServiceOrdersPage,
  SubmittedReadingsPage,
  ShepRegularizationsPage,
  CustomersPage,
  SpotBillingPage,
  FlatRateCustomersPage,
  ServiceRegularizationsPage,
  DebtCustomersPage,
} from "pages";
import {
  ClipboardDocumentListIcon,
  CubeTransparentIcon,
  ChartBarSquareIcon,
  MapIcon,
  DocumentTextIcon,
  Square3Stack3DIcon,
  ExclamationTriangleIcon,
  ListBulletIcon,
  DocumentMinusIcon,
  BoltIcon,
  BoltSlashIcon,
  QrCodeIcon,
  ReceiptPercentIcon,
  WrenchScrewdriverIcon,
  CircleStackIcon,
  LifebuoyIcon,
  CalculatorIcon,
} from "@heroicons/react/24/outline";
import CalendarLayout from "layouts/calendar-wrapper";
import { UserPermission } from "apollo/cache/auth";
import { TariffInstancesBreadcrumb } from "containers";
import ComplaintsPage from "pages/complaints";
import { DeviceTabletIcon, UserCircleIcon } from "@heroicons/react/20/solid";

export type RouteProps = Omit<Route, "children"> & {
  withPermissions?: UserPermission[];
  navigation?: boolean;
  sidebar?: { label: string; icon: any };
  children?: RouteProps[];
};

const routes: RouteProps[] = [
  // STATISTICS
  {
    path: "/",
    element: <CalendarLayout />,
    sidebar: {
      label: "Dashboard",
      icon: ChartBarSquareIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Dashboard",
      section: "Statistics",
    },
    children: [
      {
        id: "all",
        path: "/",
        element: <DashboardPage />,
        meta: {
          layout: "App",
          section: "Statistics",
        },
      },
    ],
  },

  // CUSTOMER RELATIONS
  {
    path: "bills",
    element: <CalendarLayout />,
    sidebar: {
      label: "Bills",
      icon: DocumentTextIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Bills",
      section: "Customer Relations",
    },
    children: [
      {
        path: "/",
        element: <BillsPage />,
        meta: {
          layout: "App",
          section: "Customer Relations",
        },
      },
    ],
    withPermissions: ["*:*", "bills:*", "bills:manage"],
  },
  {
    path: "reading-plots",
    element: <PlotsPage />,
    sidebar: {
      label: "Reading Plots",
      icon: ListBulletIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Reading Plots",
      section: "Reading Administration",
    },
    withPermissions: ["*:*", "plots:*", "plots:manage"],
  },
  {
    path: "spot-billing",
    element: <SpotBillingPage />,
    sidebar: {
      label: "Spot Billing",
      icon: ClipboardDocumentListIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Spot Billing",
      section: "Reading Administration",
    },
    withPermissions: ["*:*", "readings:*", "readings:manage"],
  },
  // {
  //   path: "reading-sets",
  //   element: <Outlet />,
  //   sidebar: {
  //     label: "Reading Sets",
  //     icon: ClipboardDocumentListIcon,
  //   },
  //   meta: {
  //     layout: "App",
  //     breadcrumb: () => "Reading Sets",
  //     section: "Reading Administration",
  //   },
  //   children: [
  //     {
  //       path: "/",
  //       element: <ReadingSetsPage />,
  //       meta: {
  //         layout: "App",
  //         section: "Reading Administration",
  //       },
  //       withPermissions: ["*:*", "reading-sets:*", "reading-sets:manage"],
  //     },
  //     {
  //       path: ":readingSet",
  //       element: <Outlet />,
  //       meta: {
  //         layout: "App",
  //         breadcrumb: ({ readingSet }: { readingSet: string }) => {
  //           return <ReadingSetsBreadcrumb id={readingSet} />;
  //         },
  //         section: "Reading Administration",
  //       },
  //       children: [
  //         {
  //           path: "/readings",
  //           element: <ReadingPropertiesPage />,
  //           meta: {
  //             layout: "App",
  //             breadcrumb: () => "Readings",
  //             section: "Reading Administration",
  //           },
  //           withPermissions: ["*:*", "reading-sets:*", "reading-sets:manage"],
  //         },
  //         // {
  //         //   path: "/upload",
  //         //   element: <ReadingsUploadPage />,
  //         //   meta: {
  //         //     layout: "App",
  //         //     breadcrumb: () => "Upload",
  //         //     section: "Reading Administration",
  //         //   },
  //         //   withPermissions: ["*:*", "reading-sets:*", "reading-sets:manage"],
  //         // },
  //       ],
  //       withPermissions: ["*:*", "reading-sets:*", "reading-sets:manage"],
  //     },
  //   ],
  //   withPermissions: ["*:*", "reading-sets:*", "reading-sets:manage"],
  // },
  // {
  //   path: "readings",
  //   element: <CalendarLayout />,
  //   sidebar: {
  //     label: "Readings",
  //     icon: ListBulletIcon,
  //   },
  //   meta: {
  //     layout: "App",
  //     breadcrumb: () => "Readings",
  //     section: "Reading Administration",
  //   },
  //   children: [
  //     {
  //       path: "/",
  //       element: <ReadingsPage />,
  //       meta: {
  //         layout: "App",
  //         section: "Reading Administration",
  //       },
  //     },
  //   ],
  //   withPermissions: ["*:*", "readings:*", "readings:manage"],
  // },
  // {
  //   path: "reading-anomalies",
  //   element: <CalendarLayout />,
  //   sidebar: {
  //     label: "Reading Anomalies",
  //     icon: ExclamationCircleIcon,
  //   },
  //   meta: {
  //     layout: "App",
  //     breadcrumb: () => "Reading Anomalies",
  //     section: "Reading Administration",
  //   },
  //   children: [
  //     {
  //       path: "/",
  //       element: <ReadingAnomaliesPage />,
  //       meta: {
  //         layout: "App",
  //         section: "Reading Administration",
  //       },
  //     },
  //   ],
  //   withPermissions: ["*:*", "reading-anomalies:*", "reading-anomalies:manage"],
  // },
  {
    path: "reading-exceptions",
    element: <CalendarLayout />,
    sidebar: {
      label: "Reading Exceptions",
      icon: ExclamationTriangleIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Reading Exceptions",
      section: "Reading Administration",
    },
    children: [
      {
        path: "/",
        element: <ReadingExceptionsPage />,
        meta: {
          layout: "App",
          section: "Reading Administration",
        },
      },
    ],
    withPermissions: [
      "*:*",
      "reading-exceptions:*",
      "reading-exceptions:manage",
    ],
  },
  {
    path: "complaints",
    element: <ComplaintsPage />,
    sidebar: {
      label: "Tickets",
      icon: BoltSlashIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Tickets",
      section: "Faults And Complaints",
    },
    withPermissions: [],
  },
  {
    path: "unlisted-meters",
    element: <CalendarLayout />,
    sidebar: {
      label: "Unlisted Meters",
      icon: DocumentMinusIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Unlisted Meters",
      section: "Reading Administration",
    },
    children: [
      {
        path: "/",
        element: <UnlistedMetersPage />,
        meta: {
          layout: "App",
          section: "Reading Administration",
        },
      },
    ],
    withPermissions: ["*:*", "unlisted-meters:*", "unlisted-meters:manage"],
  },

  // {
  //   path: "reading-followups",
  //   element: <CalendarLayout />,
  //   sidebar: {
  //     label: "Reading Followups",
  //     icon: ArrowPathRoundedSquareIcon
  //   },
  //   meta: {
  //     layout: "App",
  //     breadcrumb: () => "Reading Followups",
  //     section: "Reading Administration",
  //   },
  //   children: [
  //     {
  //       path: "/",
  //       element: <ReadingFollowupsPage />,
  //       meta: {
  //         layout: "App",
  //         section: "Reading Administration",
  //       },
  //     },
  //   ],
  //   withPermissions: ["*:*", "reading-followups:*", "reading-followups:manage"]
  // },

  // STRUCTURE
  {
    path: "regions",
    element: <RegionsPage />,
    sidebar: {
      label: "Regions",
      icon: MapIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Regions Management",
      section: "Structure",
    },
    withPermissions: ["*:*", "regions:*", "regions:manage"],
  },
  {
    path: "districts",
    element: <DistrictsPage />,
    sidebar: {
      label: "Districts",
      icon: Square3Stack3DIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Districts Management",
      section: "Structure",
    },
    withPermissions: ["*:*", "districts:*", "districts:manage"],
  },
  {
    path: "blocks",
    element: <Outlet />,
    sidebar: {
      label: "Blocks",
      icon: CubeTransparentIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Blocks Management",
      section: "Structure",
    },
    children: [
      {
        path: "/",
        element: <BlocksPage />,
        meta: {
          layout: "App",
          section: "Structure",
        },
        withPermissions: ["*:*", "blocks:*", "blocks:manage"],
      },
      {
        path: ":block/rounds",
        element: <Outlet />,
        meta: {
          layout: "App",
          breadcrumb: () => "Rounds Management",
          section: "Structure",
        },
        children: [
          {
            path: "/",
            element: <RoundsPage />,
            meta: {
              layout: "App",
              section: "Structure",
            },
            withPermissions: ["*:*", "rounds:*", "rounds:manage"],
          },
          {
            path: ":round/plots",
            element: <PlotsPage />,
            meta: {
              layout: "App",
              breadcrumb: () => "Plots Management",
              section: "Structure",
            },
            withPermissions: ["*:*", "plots:*", "plots:manage"],
          },
        ],
        withPermissions: ["*:*", "rounds:*", "rounds:manage"],
      },
    ],
    withPermissions: ["*:*", "blocks:*", "blocks:manage"],
  },
  // {
  //   path: "itineraries",
  //   element: <ItinerariesPage />,
  //   sidebar: {
  //     label: "Itineraries",
  //     icon: Squares2X2Icon,
  //   },
  //   meta: {
  //     layout: "App",
  //     breadcrumb: () => "Itineraries Management",
  //     section: "Structure",
  //   },
  //   withPermissions: ["*:*", "itineraries:*", "itineraries:manage"],
  // },
  // {
  //   path: "debt-dockets",
  //   element: <Outlet />,
  //   sidebar: {
  //     label: "Debt Dockets",
  //     icon: BoltSlashIcon,
  //   },
  //   meta: {
  //     layout: "App",
  //     breadcrumb: () => "Debt Dockets",
  //     section: "Debt Management",
  //   },
  //   children: [
  //     {
  //       path: "/",
  //       element: <DebtDocketsPage />,
  //       meta: {
  //         layout: "App",
  //         section: "Debt Management",
  //       },
  //       withPermissions: [
  //         "*:*",
  //         "disconnection-service-orders:*",
  //         "disconnection-service-orders:manage",
  //       ],
  //     },
  //     {
  //       path: ":plot/debt-customers/:plotCode",
  //       element: <DebtCustomersPage />,
  //       meta: {
  //         layout: "App",
  //         breadcrumb: ({plotCode}: any) => `Debt Customers - ${plotCode}`,
  //         section: "Debt Management",
  //       },
  //       withPermissions: [
  //         "*:*",
  //         "disconnection-service-orders:*",
  //         "disconnection-service-orders:manage",
  //       ],
  //     },
  //   ],
  //   withPermissions: [
  //     "*:*",
  //     "disconnection-service-orders:*",
  //     "disconnection-service-orders:manage",
  //   ],
  // },
  {
    path: "service-points",
    element: <Outlet />,
    sidebar: {
      label: "Service Points",
      icon: QrCodeIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Service Points",
      section: "Customer Management",
    },
    children: [
      {
        path: "/",
        element: <CustomersPage />,
        meta: {
          layout: "App",
          section: "Customer Management",
        },
        withPermissions: ["*:*", "service-points:*", "service-points:manage"],
      },
      {
        path: ":servicePoint/readings",
        element: <ServicePointReadingsPage />,
        meta: {
          layout: "App",
          breadcrumb: () => "Service Point Readings",
          section: "Customer Management",
        },
        withPermissions: ["*:*", "service-points:*", "service-points:manage"],
      },
    ],
    withPermissions: ["*:*", "service-points:*", "service-points:manage"],
  },
  {
    path: "disconnections",
    element: <DisconnectionServiceOrdersPage />,
    sidebar: {
      label: "Disconnection Orders",
      icon: BoltSlashIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Disconnection Service Orders",
      section: "Customer Management",
    },
    withPermissions: [
      "*:*",
      "disconnection-service-orders:*",
      "disconnection-service-orders:manage",
    ],
  },
  {
    path: "reconnections",
    element: <ReconnectionServiceOrdersPage />,
    sidebar: {
      label: "Reconnection Orders",
      icon: BoltIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Reconnection Service Orders",
      section: "Customer Management",
    },
    withPermissions: [
      "*:*",
      "reconnection-service-orders:*",
      "reconnection-service-orders:manage",
    ],
  },

  //Debt
  {
    path: "replacements",
    element: <ReplacementServiceOrdersPage />,
    sidebar: {
      label: "Replacements",
      icon: CircleStackIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Replacements",
      section: "Debt Management",
    },
    withPermissions: [],
  },

  // CONFIGURATION
  {
    path: "tariffs",
    element: <Outlet />,
    sidebar: {
      label: "Tariffs",
      icon: ReceiptPercentIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Tariff Management",
      section: "Structure",
    },
    children: [
      {
        path: "/",
        element: <TariffClassesPage />,
        meta: {
          layout: "App",
          section: "Structure",
        },
        withPermissions: ["*:*", "tariff-classes:*", "tariff-classes:manage"],
      },
      {
        path: ":tariffClass",
        element: <TariffInstancesPage />,
        meta: {
          layout: "App",
          breadcrumb: ({ tariffClass }: { tariffClass: string }) => {
            return <TariffInstancesBreadcrumb id={tariffClass} />;
          },
          section: "Structure",
        },
        withPermissions: [
          "*:*",
          "tariff-instances:*",
          "tariff-instances:manage",
        ],
      },
    ],
    withPermissions: ["*:*", "tariff-classes:*", "tariff-classes:manage"],
  },
  {
    path: "submitted-readings",
    element: <SubmittedReadingsPage />,
    sidebar: {
      label: "Submitted Readings",
      icon: DeviceTabletIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Submitted Readings",
      section: "Reading Administration",
    },
    withPermissions: ["*:*", "readings:*", "readings:manage"],
  },

  {
    path: "shep-regularizations",
    element: <CalendarLayout />,
    sidebar: {
      label: "SHEP Captures",
      icon: LifebuoyIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "SHEP Captures",
      section: "Customer Management",
    },
    children: [
      {
        path: "/",
        element: <ShepRegularizationsPage />,
        meta: {
          layout: "App",
          section: "Customer Management",
        },
      },
    ],
    //TODO: Resolve permissions for this route
    withPermissions: ["*:*", "service-points:*", "service-points:manage"],
  },

  {
    path: "service-regularizations",
    element: <CalendarLayout />,
    sidebar: {
      label: "Regularizations",
      icon: UserCircleIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Regularizations",
      section: "Customer Management",
    },
    children: [
      {
        path: "/",
        element: <ServiceRegularizationsPage />,
        meta: {
          layout: "App",
          section: "Customer Management",
        },
      },
      // {
      //   path: ":serviceRegularization",
      //   element: <ServiceRegularizationPage />,
      //   meta: {
      //     layout: "App",
      //     breadcrumb: () => "Service Regularization",
      //     section: "Faults And Complaints",
      //   },
      // },
    ],
    //TODO: Verify and enable permisions
    withPermissions: ["*:*", "service-points:*", "service-points:manage"],
  },

  {
    path: "flat-rate-customers",
    element: <FlatRateCustomersPage />,
    sidebar: {
      label: "Flat Rate Customers",
      icon: CalculatorIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Flat Rate Customers",
      section: "Customer Management",
    },
    withPermissions: ["*:*", "service-points:*", "service-points:manage"],
  },
  // {
  //   path: "readers",
  //   element: <ReadersPage />,
  //   sidebar: {
  //     label: "Meter Readers",
  //     icon: UsersIcon
  //   },
  //   meta: {
  //     layout: "App",
  //     breadcrumb: () => "Meter Readers",
  //     section: "Configuration",
  //   },
  //   withPermissions: ["*:*", "readers:*", "readers:manage"]
  // },
  {
    path: "tools",
    element: <ToolsPage />,
    sidebar: {
      label: "Tools",
      icon: WrenchScrewdriverIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Tools",
      section: "Configuration",
    },
    withPermissions: ["*:*", "billing-tools:*", "billing-tools:manage"],
  },
  {
    path: "settings",
    element: <SettingsPage />,
    meta: {
      layout: "App",
      breadcrumb: () => "Settings",
      section: "Configuration",
    },
  },

  // AUTH
  {
    path: "forgot-password",
    element: <ForgotPasswordPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Forgot Password",
    },
  },
  {
    path: "signin",
    element: <SigninPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Signin",
    },
  },
  {
    path: "auth",
    element: <AuthPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Auth",
    },
  },
];

export default routes;
