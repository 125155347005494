import { FC } from "react";
import { Avatar } from "components/core";
import { classNames } from "utils";
import numeral from "numeral";
import moment from "moment";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import _ from "lodash";

interface ServicePointViewProps {
  servicePoint: {
    serviceClass: string;
    customer: any;
    geoCode: string;
    phoneNumber: string;
    emailAddress: string;
    code: string;
    meterCode: string;
    accountCode: string;
    meterLocation: string;
    contractedDemand: number;
    ghanaCardNumber: string;
    serviceType: string;
    servicePointType: string;
    tariffClass: {
      _id: string;
      code: string;
      name: string;
      __typename: "TariffClass";
    } | null;
    region: {
      _id: string;
      code: string;
      name: string;
      __typename: "Region";
    } | null;
    district: {
      _id: string;
      code: string;
      name: string;
      __typename: "District";
    } | null;
    block: {
      _id: string;
      code: string;
      name: string;
      __typename: "Block";
    } | null;
    round: {
      _id: string;
      code: string;
      name: string;
      __typename: "Round";
    } | null;
    plot: {
      _id: string;
      code: string;
      community: string;
      __typename: "Plot";
    } | null;
    itinerary: {
      _id: string;
      code: string;
      description: string;
      __typename: "Itinerary";
    } | null;
    property: {
      _id: string;
      code: string;
      ghanaPostAddress: string;
      __typename: "Property";
    } | null;
    propertyCode: string;
    qrCode: string;
    address: string;
    ghanaPostAddress: string;
    contractStatus: string;
    profileImageUrl: string;
    meta: {
      lastReadingDate: Date;
      lastReadingValue: number;
      lastReadingEstimated: boolean;
      lastConsumptionValue: number;
      lastBillValue: number;
      totalAmountDue: number;
      totalAmountDueUpdatedAt: Date;
    };
    accountData: {
      lastPaymentDate: Date;
      debtAmount: number;
      toBeDisconnected: number;
    };
  };
}

const ServicePointView: FC<ServicePointViewProps> = ({
  servicePoint,
}) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div>
        <span className='text-xs font-light'>Service Point Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div className='row-span-3 flex flex-col space-y-3 items-center justify-center'>
            <Avatar
              alt={
                servicePoint?.customer?.representative?.fullName ||
                servicePoint?.customer?.representative?.fullName ||
                "N A"
              }
              src={
                servicePoint?.customer?.representative?.profileImageUrl ||
                servicePoint?.customer?.representative?.profileImageUrl ||
                ""
              }
              size='xl'
            />

            <span
              className={classNames(
                servicePoint?.contractStatus === "SuperAdmin"
                  ? `bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50`
                  : "",
                servicePoint?.contractStatus === "Admin"
                  ? `bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50`
                  : "",
                servicePoint?.contractStatus === "Supervisor"
                  ? `bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50`
                  : "",
                servicePoint?.contractStatus === "Reader"
                  ? `bg-gray-100 text-[#9ACD32] dark:bg-[#9ACD32] dark:text-gray-50`
                  : "",
                servicePoint?.contractStatus === "Queued"
                  ? `bg-gray-100 text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50`
                  : "",
                servicePoint?.contractStatus === "Success"
                  ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                  : "",
                servicePoint?.contractStatus === "Reversed"
                  ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                  : "",
                !servicePoint?.contractStatus
                  ? "bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-100"
                  : "",
                "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
              )}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 10 10'
                fill='currentColor'
                className='w-1.5 h-1.5'
              >
                <circle
                  fillRule='evenodd'
                  cx='5'
                  cy='5'
                  r='5'
                  clipRule='evenodd'
                />
              </svg>
              <span>{servicePoint?.contractStatus || "Active Contract"}</span>
            </span>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              ServicePoint Code
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {servicePoint?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Ghana Card Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {servicePoint?.customer?.representative?.ghanaCardNumber ||
                servicePoint?.customer?.representative?.ghanaCardNumber ||
                "N/A"}
            </div>
          </div>
          <div className='col-span-2'>
            <span className='block text-sm font-light text-gray-700'>
              Full Name
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {servicePoint?.customer?.representative?.fullName ||
                servicePoint?.customer?.representative?.fullName ||
                "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Phone Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {servicePoint?.customer?.representative?.phoneNumber ||
                servicePoint?.customer?.representative?.phoneNumber ||
                "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Email Address
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {servicePoint?.emailAddress || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Billing Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Last Reading Date
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {servicePoint?.meta?.lastReadingDate
                ? moment(servicePoint.meta.lastReadingDate).format(dateFormat)
                : "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Last Reading Value
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {numeral(servicePoint?.meta?.lastReadingValue || 0).format("0,0")}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Last Reading Estimated?
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {servicePoint?.meta?.lastReadingEstimated ? "Yes" : "No"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Last Consumption Value
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {numeral(servicePoint?.meta?.lastConsumptionValue || 0).format(
                "0,0"
              )}{" "}
              KWh
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Last Bill Amount
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              GHS{" "}
              {numeral(servicePoint?.meta?.lastBillValue || 0).format("0,0.00")}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Total Amount Due
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              GHS{" "}
              {numeral(servicePoint?.meta?.totalAmountDue || 0).format(
                "0,0.00"
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Contract Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Service Point Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {servicePoint?.code || "N/A"}
            </div>
          </div>
          {/* <div>
            <span className='block text-sm font-light text-gray-700'>
              Meter Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {servicePoint?.meterCode || "N/A"}
            </div>
          </div> */}
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Account Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {servicePoint?.accountCode || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Contracted Demand
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {servicePoint?.contractedDemand || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Service Class
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {_.startCase(servicePoint?.serviceClass) || "N/A"}
            </div>
          </div>
          {/* <div>
            <span className='block text-sm font-light text-gray-700'>
              Service Type
            </span>
            <div className='mt-1 block w-full sm:tpext-sm'>
              {servicePoint?.serviceType || "N/A"}
            </div>
          </div> */}
          {/* <div>
            <span className='block text-sm font-light text-gray-700'>
              Tariff Class
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {servicePoint?.tariffClass?.name || "N/A"}(
              {servicePoint?.tariffClass?.code || "N/A"})
            </div>
          </div> */}
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Property Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Region
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {servicePoint?.region?.name || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              District
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {servicePoint?.district?.name || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Block
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {servicePoint?.block?.name || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Round
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {servicePoint?.round?.name || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>Plot</span>
            <div className='mt-1 block w-full sm:text-sm'>
              {servicePoint?.plot?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Itinerary
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {servicePoint?.itinerary?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Property
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {servicePoint?.propertyCode || "N/A"}
            </div>
          </div>
          <div className='col-start-1'>
            <span className='block text-sm font-light text-gray-700'>
              Geo Code
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {servicePoint?.geoCode || "N/A"}
            </div>
          </div>
          <div className='col-span-2'>
            <span className='block text-sm font-light text-gray-700'>
              Meter Location
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {servicePoint?.meterLocation || "N/A"}
            </div>
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default ServicePointView;
