import { gql, useQuery } from '@apollo/client';
import { TariffClassView, Modal } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';

const GET_TARIFF_CLASS = gql`
  query GetTariffClass($id: ID!) {
    tariffClass: getTariffClass(id: $id) {
      _id
      code
      name
      description
      serviceClass
      # currentInstance {
      #   _id
      #   code
      #   name
      #   tariffClass {
      #     _id
      #   }
      #   energyCharge {
      #     type
      #     value
      #     steps {
      #       name
      #       displayName
      #       minimumConsumption
      #       maximumConsumption
      #       value
      #       exclusive
      #     }
      #   }
      #   serviceCharge {
      #     type
      #     value
      #     steps {
      #       name
      #       displayName
      #       minimumConsumption
      #       maximumConsumption
      #       value
      #       exclusive
      #     }
      #   }
      #   hasExtraItems
      #   extraItems {
      #     name
      #     displayName
      #     category
      #     type
      #     appliedTo
      #     value
      #   }
      #   startDate
      #   endDate
      #   createdAt
      #   updatedAt
      # }
      createdAt
      updatedAt
    }
  }
`

export default function ViewTariffClassContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_TARIFF_CLASS, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Tariff Class Information"
      loading={loading}
      description="Details of tariff class are shown below"
    >
      <TariffClassView
        tariffClass={data?.tariffClass}
      />
    </Modal>
  )
}