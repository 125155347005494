import { gql, useQuery } from "@apollo/client";

const GET_TARIFF_CLASSES = gql`
  query GetTariffClasses(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
  ) {
    rows: getTariffClasses(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
    ) {
      _id
      code
      name
      description
      serviceClass
      createdAt
      updatedAt
    }
    count: getTariffClassesCount(search: $search, searchFields: $searchFields)
  }
`;

export const useTariffClasses = (filter?: any) => {
  const { data, ...rest } = useQuery(GET_TARIFF_CLASSES, {
    variables: filter,
    notifyOnNetworkStatusChange: false,
  });

  return {
    tariffClasses: data?.rows ?? [],
    count: data?.count ?? 0,
    ...rest,
  };
};
