import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Header } from 'components';
import moment from 'moment';
import lodash from 'lodash';
import { FC, useEffect, useRef } from 'react'
import { useMatch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';
import { wrapClick, classNames, withPermissions } from 'utils';
import numeral from 'numeral';
import { useReactToPrint } from 'react-to-print';
import { PrinterIcon } from '@heroicons/react/24/outline';
import ls from 'localstorage-slim';
import { useFormik } from 'formik';
import * as Yup from "yup";
import toast from 'react-hot-toast';

const GET_READING_SHEET = gql`
  query GetReadingSheet($readingSet: ID!) {
    readingSheet: getReadingSheet(readingSet: $readingSet) {
      readings {
        _id
        qrCode
        address
        ghanaPostAddress
        propertyCode
        customerCode
        emailAddress
        phoneNumber
        geoCode
        servicePointNumber
        meterNumber
        accountNumber
        fullName
        meterLocation
        contractedDemand
        serviceType
        customerType
        tariffClass {
          _id
        }
        previousReading {
          readingDate
          readingValue
          readingEstimated
          consumptionValue
          billValue
          totalAmountDue
        }
        currentReading {
          readingDate
          readingValue
          readingEstimated
          consumptionValue
          billValue
          totalAmountDue
        }
        status
        meta {
          readingRecordedAt
          readingSyncedAt
        }
      }
      readingSet {
        _id
        code
        name
        itinerary {
          _id
        }
        itineraryCode
        itineraryDescription
        region {
          _id
        }
        regionCode
        regionName
        district {
          _id
        }
        districtCode
        districtName
        block {
          _id
        }
        blockCode
        blockName
        round {
          _id
        }
        roundCode
        roundName
        plot {
          _id
        }
        plotCode
        community
        meta {
          cycleStartDate
          cycleEndDate
          propertiesCount
          customersCount
        }
        reader {
          _id
          lastName
          firstName
          phoneNumber
        }
        month
        year
        status
        createdAt
        updatedAt
      }
    }
  }
`;

const READ_READING_SET = gql`
  mutation ReadReadingSet($id: ID!, $readings: [ReadReadingSetReadingInput!]!) {
    readReadingSet(id: $id, readings: $readings) {
      _id
    }
  }
`;

const ReadingsPage: FC = () => {
  const { params } = useMatch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();

  const [getReadingSheet, { data, loading: loadingReadingSheet, refetch }] = useLazyQuery(GET_READING_SHEET);
  const [readReadingSet, { loading }] = useMutation(READ_READING_SET);

  const form = useFormik({
    initialValues: {
      readings: [] as {
        _id: string;
        result: object;
      }[]
    },
    validationSchema: Yup.object().shape({
      readings: Yup.array().of(Yup.object().shape({
        _id: Yup.string().required(),
        result: Yup.object().shape({
          readingValue: Yup.number().when(["readingAnomaly", "readingNoTrace"], {
            is: (readingAnomaly: boolean, readingNoTrace: boolean) => readingAnomaly === false && readingNoTrace === false,
            then: (schema) => schema.min(0).required(),
            otherwise: (schema) => schema.notRequired(),
          }),
          readingDate: Yup.date().max(moment().toDate()).required(),
          readingAnomaly: Yup.boolean().required(),
          readingNoTrace: Yup.boolean().required(),
          readingNotes: Yup.string().when("readingAnomaly", {
            is: true,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired(),
          }),
        })
      }))
    }),
    onSubmit: async (values) => {
      console.log("submit", values)
      await readReadingSet({
        variables: {
          id: params.readingSet,
          readings: values.readings.map(reading => ({
            _id: reading._id,
            result: reading.result
          }))
        }
      }).then(({data}) => {
        if (data?.readReadingSet?._id) {
          toast(JSON.stringify({ type: "success", title: "Reading set uploaded successfully" }))
          refetch?.();
          navigate({to: "/reading-sets", replace: true})
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not upload reading set" }))
        }
      })
    }
  })

  const readingSheetRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => readingSheetRef.current,
    documentTitle: data?.bill?.code,
    bodyClass: "w-[1600px]",
  });

  const resetReadings = () => {
    form.setFieldValue("readings", form.values.readings.map((reading: any) => ({ ...reading, result: { readingDate: "", readingValue: "", readingAnomaly: false, readingNoTrace: false, readingNotes: "" } })));
  }

  useEffect(() => {
    getReadingSheet({
      variables: {
        readingSet: params.readingSet,
      }
    }).then(({ data }) => {
      if (data?.readingSheet) {
        //combine reading sheet data with local data if any
        const localReadingSheet = ls.get<{ readings: any[], timestamp: string }>(`subs:readingsheet:${params.readingSet}`, { decrypt: true });
        if (localReadingSheet?.readings.length && moment(localReadingSheet.timestamp).isAfter(moment(data?.readingSheet?.readingSet?.updatedAt))) {
          form.setFieldValue("readings", localReadingSheet.readings);
        } else {
          form.setFieldValue("readings", data?.readingSheet?.readings?.map((reading: any) => ({ ...reading, result: { readingDate: "", readingValue: "", readingAnomaly: false, readingNoTrace: false, readingNotes: "" } })));
        }
      }
    })
  }, [params.readingSet])

  useEffect(() => {
    if (form.values.readings.length) {
      ls.set(`subs:readingsheet:${params.readingSet}`, { readings: form.values.readings, timestamp: new Date().getTime() }, { encrypt: true, ttl: 60 * 60 * 12 });
    }
  }, [form.values.readings])

  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-gray-50">
      <Header
        renderActions={() => (
          <>
            {withPermissions(["*:*", "reading-sets:*", "reading-sets:create"])(
              <button
                type="button"
                onClick={wrapClick(handlePrint)}
                className="ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                <PrinterIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                Print
              </button>
            )}
          </>
        )}
      />
      <div className="flex-1 flex flex-col py-4 sm:py-6 px-4 sm:px-6 overflow-y-auto light text-sm">
        <table ref={readingSheetRef} className="w-full divide-y divide-gray-300 border border-gray-300 rounded-md table-fixed">
          <thead className="bg-gray-50">
            <tr className="">
              <th scope="col" className="w-10 h-0 p-0" />
              <th scope="col" className="w-[20%] h-0 p-0" />
              <th scope="col" className="w-[20%] h-0 p-0" />
              <th scope="col" className='h-0 p-0' />
              <th scope="col" className='h-0 p-0' />
              <th scope="col" className='h-0 p-0' />
              <th scope="col" className='h-0 p-0' />
              <th scope="col" className='h-0 p-0' />
            </tr>
            <tr>
              <th colSpan={8} scope="col" className='border-b border-gray-300 text-sm'>
                <div className='flex p-4 items-center print:space-x-4'>
                  <div className='bg-primary-800 hidden print:flex items-center justify-center h-12 w-12 p-0.5'>
                    <img
                      className=" w-auto bg-white p-0.5"
                      src={require("../../assets/logo.png")}
                      alt="SUBS"
                    />
                  </div>
                  <div className='grid grid-cols-16 gap-3 text-left'>
                    <dt className='font-semibold text-gray-900'>District:</dt>
                    <dd className='col-span-3 font-light'>{data?.readingSheet?.readingSet?.districtName} ({data?.readingSheet?.readingSet?.districtCode})</dd>
                    <dt className='font-semibold text-gray-900'>Block:</dt>
                    <dd className='col-span-3 font-light text-ellipses overflow-hidden line-clamp-1'>{data?.readingSheet?.readingSet?.blockName}</dd>
                    <dt className='font-semibold text-gray-900'>Itinerary:</dt>
                    <dd className='col-span-3 font-light'>{data?.readingSheet?.readingSet?.itineraryCode}</dd>
                    <dt className='font-semibold text-gray-900'>Reader:</dt>
                    <dd className='col-span-3 font-light'>{[data?.readingSheet?.readingSet?.reader?.lastName, data?.readingSheet?.readingSet?.reader?.firstName]?.join(" ")}</dd>
                    <dt className='font-semibold text-gray-900'>Cycle:</dt>
                    <dd className='col-span-3 font-light'>{moment({ month: (data?.readingSheet?.readingSet?.month - 1), year: data?.readingSheet?.readingSet?.year, day: 1 }).format("MMMM, YYYY")}</dd>
                    <dt className='font-semibold text-gray-900'>Route:</dt>
                    <dd className='col-span-3 font-light'>{data?.readingSheet?.readingSet?.blockCode}</dd>
                    <dt className='font-semibold text-gray-900'>Customers:</dt>
                    <dd className='col-span-3 font-light'>{data?.readingSheet?.readingSet?.meta?.customersCount}</dd>
                    <dt className='font-semibold text-gray-900'>Created At:</dt>
                    <dd className='col-span-3 font-light'>{moment(data?.readingSheet?.readingSet?.createdAt).format("DD/MM/YYYY")}</dd>
                  </div>
                </div>
              </th>
            </tr>
            <tr className="bg-white">
              <th colSpan={8} scope="col" className='h-4 border-b border-gray-300' />
            </tr>
            <tr className="">
              <th
                scope="col"
                className="whitespace-nowrap px-1 pt-2 pb-0.5 text-center text-sm font-semibold text-gray-900 w-10"
              >
                No
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-1 pt-2 pb-0.5 text-left text-sm font-semibold text-gray-900 w-[20%]"
              >
                Building
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-1 pt-2 pb-0.5 text-left text-sm font-semibold text-gray-900 w-[20%]"
              >
                Access Description - Building
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-1 pt-2 pb-0.5 text-left text-sm font-semibold text-gray-900"
              >
                Geo Code
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-1 pt-2 pb-0.5 text-left text-sm font-semibold text-gray-900"
              >
                Meter No
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-1 pt-2 pb-0.5 text-left text-sm font-semibold text-gray-900"
              >
                Reading Date
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-1 pt-2 pb-0.5 text-left text-sm font-semibold text-gray-900"
              >
                Reading
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-1 pt-2 pb-0.5 text-left text-sm font-semibold text-gray-900"
              >
                Anomaly | No Trace
              </th>
            </tr>
            <tr className="">
              <th
                scope="col"
                className="whitespace-nowrap px-1 pt-0.5 pb-2 text-left text-sm font-semibold text-gray-900"
              >
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-1 pt-0.5 pb-2 text-left text-sm font-semibold text-gray-900"
              >
                Customer
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-1 pt-0.5 pb-2 text-left text-sm font-semibold text-gray-900"
              >
                Access Description - Meter Chamber
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-1 pt-0.5 pb-2 text-left text-sm font-semibold text-gray-900"
              >
                Energy Type
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-1 pt-0.5 pb-2 text-left text-sm font-semibold text-gray-900"
              >
                Meter Digits
              </th>
              <th
                scope="col"
                colSpan={3}
                className="whitespace-nowrap px-1 pt-0.5 pb-2 text-left text-sm font-semibold text-gray-900"
              >
                Note
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {lodash.chain(form.values.readings)?.map?.((step: any, idx: string) => (
              <>
                <tr key={idx + "a"} className="">
                  <td className="whitespace-nowrap px-1 py-0 text-sm h-[26px] text-gray-70 text-center">{numeral(idx + 1).format("0,0")}</td>
                  <td className="whitespace-nowrap px-1 py-0 text-sm h-[26px] text-gray-500 overflow-hidden text-ellipsis">{step?.address || "N/A"}</td>
                  <td className="whitespace-nowrap px-1 py-0 text-sm h-[26px] text-gray-500 overflow-hidden text-ellipsis">{step?.address || "N/A"}</td>
                  <td className="whitespace-nowrap px-1 py-0 text-sm h-[26px] text-gray-500 overflow-hidden text-ellipsis">{step?.geoCode?.split?.("-")?.join?.("") || "N/A"}</td>
                  <td className="whitespace-nowrap px-1 py-0 text-sm h-[26px] text-gray-500 overflow-hidden text-ellipsis">{step?.meterNumber || "N/A"}</td>
                  <td className="whitespace-nowrap px-1 py-0 pt-0.5 text-sm h-[26px] text-gray-500">
                    <input
                      key={idx}
                      type={"date"}
                      id={`readings.[${idx}].result.readingDate`}
                      name={`readings.[${idx}].result.readingDate`}
                      value={lodash.get(form.values.readings, `[${idx}].result.readingDate`, "")}
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                      max={moment().format("YYYY-MM-DD")}
                      className={classNames(
                        lodash.get(form.errors, `readings.[${idx}].result.readingDate`) && lodash.get(form.touched, `readings.[${idx}].result.readingDate`)  ? "focus:ring-red-500 focus:border-red-500 border-red-600 border" : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                        "block w-full sm:text-sm placeholder:font-light p-0 px-1 placeholder:text-sm h-[24px] border border-gray-300 focus:border print:hidden"
                      )}
                    />
                    <input
                      className={classNames(
                        "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                        "hidden w-full sm:text-sm placeholder:font-light placeholder:text-sm h-[24px] border border-gray-300 focus:border print:block"
                      )}
                    />
                  </td>
                  <td className="whitespace-nowrap px-1 py-0 pt-0.5 text-sm h-[26px] text-gray-500">
                    <input
                      key={idx}
                      type={"number"}
                      step={1}
                      id={`readings.[${idx}].result.readingValue`}
                      name={`readings.[${idx}].result.readingValue`}
                      value={lodash.get(form.values.readings, `[${idx}].result.readingValue`, 0)}
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                      className={classNames(
                        lodash.get(form.errors, `readings.[${idx}].result.readingValue`) && lodash.get(form.touched, `readings.[${idx}].result.readingValue`)  ? "focus:ring-red-500 focus:border-red-500 border-red-600 border" : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                        "block w-full sm:text-sm placeholder:font-light p-0 px-1 placeholder:text-sm h-[24px] border border-gray-300 focus:border print:hidden"
                      )}
                    />
                    <input
                      className={classNames(
                        "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                        "hidden w-full sm:text-sm placeholder:font-light placeholder:text-sm h-[24px] border border-gray-300 focus:border print:block"
                      )}
                    />
                  </td>
                  <td className="whitespace-nowrap px-1 py-0 pt-0.5 text-sm h-[26px] text-gray-500">
                    <div className='flex space-x-1'>
                      <div className='flex-1 flex space-x-1 items-center'>
                        <input
                          key={idx}
                          type={"checkbox"}
                          id={`readings.[${idx}].result.readingAnomaly`}
                          name={`readings.[${idx}].result.readingAnomaly`}
                          checked={lodash.get(form.values.readings, `[${idx}].result.readingAnomaly`, false)}
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          className={classNames(
                            lodash.get(form.errors, `readings.[${idx}].result.readingAnomaly`) && lodash.get(form.touched, `readings.[${idx}].result.readingAnomaly`) ? "focus:ring-red-500 focus:border-red-500 border-red-600 border" : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                            "block sm:text-sm placeholder:font-light placeholder:text-sm h-[20px] w-[20px] border border-gray-300 focus:border print:hidden"
                          )}
                        />
                        <input
                          className={classNames(
                            "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                            "hidden w-full sm:text-sm placeholder:font-light placeholder:text-sm h-[24px] w-[24px] border border-gray-300 focus:border print:block"
                          )}
                        />
                        <label htmlFor={`[${idx}].result.readingAnomaly`} className='text-xs'>Anomaly</label>
                      </div>
                      <div className='flex-1 flex space-x-1 items-center'>
                        <input
                          key={idx}
                          type={"checkbox"}
                          id={`readings.[${idx}].result.readingNoTrace`}
                          name={`readings.[${idx}].result.readingNoTrace`}
                          checked={lodash.get(form.values.readings, `[${idx}].result.readingNoTrace`, false)}
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          className={classNames(
                            lodash.get(form.errors, `readings.[${idx}].result.readingNoTrace`) && lodash.get(form.touched, `readings.[${idx}].result.readingNoTrace`)  ? "focus:ring-red-500 focus:border-red-500 border-red-600 border" : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                            "block sm:text-sm placeholder:font-light placeholder:text-sm h-[20px] w-[20px] border border-gray-300 focus:border print:hidden"
                          )}
                        />
                        <input
                          className={classNames(
                            "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                            "hidden w-full sm:text-sm placeholder:font-light placeholder:text-sm h-[24px] w-[24px] border border-gray-300 focus:border print:block"
                          )}
                        />
                        <label htmlFor={`[${idx}].result.readingNoTrace`} className='text-xs'>No Trace</label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr key={idx + "b"} className="">
                  <td className="whitespace-nowrap px-1 py-0 text-sm h-[26px] text-gray-700"></td>
                  <td className="whitespace-nowrap px-1 py-0 text-sm h-[26px] text-gray-500 overflow-hidden text-ellipsis">{step?.fullName || "N/A"}</td>
                  <td className="whitespace-nowrap px-1 py-0 text-sm h-[26px] text-gray-500 overflow-hidden text-ellipsis">{step?.meterLocation || "N/A"}</td>
                  <td className="whitespace-nowrap px-1 py-0 text-sm h-[26px] text-gray-500 overflow-hidden text-ellipsis">{step?.appliedTo || "N/A"}</td>
                  <td className="whitespace-nowrap px-1 py-0 text-sm h-[26px] text-gray-500 overflow-hidden text-ellipsis">{step?.meterDigits || "N/A"}</td>
                  <td colSpan={3} className="whitespace-nowrap px-1 py-0.5 text-sm h-[26px] text-gray-500">
                    <input
                      key={idx}
                      type={"text"}
                      id={`readings.[${idx}].result.readingNotes`}
                      name={`readings.[${idx}].result.readingNotes`}
                      value={lodash.get(form.values.readings, `[${idx}].result.readingNotes`, "")}
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                      className={classNames(
                        lodash.get(form.errors, `readings.[${idx}].result.readingNotes`) && lodash.get(form.touched, `readings.[${idx}].result.readingNotes`) ? "focus:ring-red-500 focus:border-red-500 border-red-600 border" : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                        "block w-full sm:text-sm placeholder:font-light placeholder:text-sm h-[24px] border border-gray-300 focus:border print:hidden p-0 px-1"
                      )}
                    />
                    <input
                      className={classNames(
                        "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                        "hidden w-full sm:text-sm placeholder:font-light placeholder:text-sm h-[24px] border border-gray-300 focus:border print:block"
                      )}
                    />
                  </td>
                </tr>
                <tr key={idx + "c"} className="">
                  <td className='h-[0.5px] p-0 bg-gray-300' colSpan={8} />
                </tr>
              </>
            )).value()}
          </tbody>
          <tfoot className="bg-gray-50">
            <tr className="hidden print:table-row">
              <th scope="col" className="" />
              <th scope="col" className="" />
              <th scope="col" className="" />
              <th scope="col" className='' />
              <th scope="col" colSpan={2} className="whitespace-nowrap px-1 py-2 pt-10 text-sm font-semibold text-gray-900">
                <div className=' inline-flex w-full'>Reading Date:
                  <div className='ml-2 flex-1 border-b border-gray-300 text-transparent'>date</div>
                </div>
              </th>
              <th scope="col" colSpan={2} className="whitespace-nowrap px-1 py-2 pt-10 text-sm font-semibold text-gray-900">
                <div className=' inline-flex w-full'>Reader Signature:
                  <div className='ml-2 flex-1 border-b border-gray-300 text-transparent'>signature</div>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
        <div className="bg-white mt-3 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border border-gray-300">
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Submitting Readings..." : "Submit Readings"}
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(resetReadings)}
          >
            Clear All
          </button>
        </div>
      </div>
    </main>
  )
}

export default ReadingsPage;
