import { gql, useQuery } from '@apollo/client';
import { Header, Shimmers, TableComponent } from 'components';
import { FC, useEffect, useMemo } from 'react'
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { wrapClick, useUrlState } from 'utils';
import config from 'config';
import ViewReaderContainer from './view';
import { DistrictPicker, ExportDataContainer, RegionPicker } from 'containers';
import { Avatar } from 'components/core';
import ActionButton, { Action } from 'components/buttons/action-button';

const GET_READERS = gql`
  query GetReaders(
    $page: Int $pageSize: Int
    $sort: String
    $search: String $searchFields: [String!]
    $region: ID $district: ID
  ) {
    getUsers(
      page: $page pageSize: $pageSize
      sort: $sort
      search: $search searchFields: $searchFields
      region: $region district: $district
      role: READER
    ) {
      rows {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        gender
        profileImageUrl
        region {
          _id
        }
        regionCode
        regionName
        district {
          _id
        }
        districtCode
        districtName
        meta {
          isPasswordSet
          isBlocked
          isDeleted
          lastLoginAt
        }
        role
        createdAt
        updatedAt
      }
      total
      totalPages
      page
      pageSize
    }
  }
`;

const ReadersPage: FC = () => {

  const dispatchAction = (id: string, action: Exclude<Action, ("expand" | "goto" | "clone")>) => () => {
    navigate({
      search: (old) => ({
        ...old,
        id,
        modal: action
      })
    })
  }

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [region, setRegion] = useUrlState("region");
  const [district, setDistrict] = useUrlState("district");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const filter = useMemo(() => ({
    page: searchParams.page || 1,
    pageSize: searchParams.pageSize || 12,
    search: (searchParams.search || "").toString(),
    searchFields: ["code", "lastName", "firstName", "emailAddress", "ghanaCardNumber"],
    sort: searchParams.sort || "",
    region: searchParams.region || undefined,
    district: searchParams.district || undefined,
  }), [searchParams])

  const { data, loading, networkStatus, refetch } = useQuery(GET_READERS, {
    variables: filter,
    notifyOnNetworkStatusChange: false
  });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
      })
    })
  }, [navigate])

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <Header />
      <div className='flex flex-1 overflow-y-auto'>
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={'readers'}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={data?.getUsers}
            hasSearch={true}
            // renderExport={({ exportOpen, setExportOpen }) => (
            //   <ExportDataContainer
            //     open={exportOpen}
            //     setOpen={setExportOpen}
            //     entityType="User"
            //     filter={filter}
            //   />
            // )}
            renderHeaderItems={() => (
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex space-x-2">
                <RegionPicker
                  id="region"
                  labelHidden={true}
                  setFieldValue={(_field: string, value: string) => {
                    setRegion(value);
                    setDistrict(undefined);
                  }}
                  values={{ region }}
                  rawId={true}
                />
                {region && (
                  <DistrictPicker
                    id="district"
                    labelHidden={true}
                    setFieldValue={(_field: string, value: string) => setDistrict(value)}
                    values={{ district }}
                    filter={{ region }}
                    rawId={true}
                  />
                )}
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Staff
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Contact
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Ghana Card | Gender
                </th>
                {!region && (
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Region
                  </th>
                )}
                {!district && (
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    District
                  </th>
                )}
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                </th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.AvatarShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                {!region && (
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                )}
                {!district && (
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                )}
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={1} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr key={item._id} className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer" onClick={wrapClick(dispatchAction(item._id, "view"))}>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <Avatar
                        disabled={true}
                        alt={[(item?.lastName || "")?.trim(), (item?.firstName || "")?.trim()].join(" ").trim() || "N A"}
                        src={item?.profileImageUrl || ""}
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-gray-900 dark:text-gray-100">{[(item?.lastName || "")?.trim(), (item?.firstName || "")?.trim()].join(" ").trim() || "N A"}</div>
                      <div className="text-gray-500 dark:text-gray-400">{item?.code || "N/A"}</div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">{item?.phoneNumber || "N/A"}</div>
                  <div className="text-gray-500 dark:text-gray-400">{item?.emailAddress || "N/A"}</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">{item?.ghanaCardNumber || "N/A"}</div>
                  <div className="text-gray-500 dark:text-gray-400">{item?.gender || "N/A"}</div>
                </td>
                {!region && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                    <div className="font-medium text-gray-900 dark:text-gray-100">{item?.regionName || "N/A"}</div>
                    <div className="text-gray-500 dark:text-gray-400">{item?.regionCode || "N/A"}</div>
                  </td>
                )}
                {!district && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                    <div className="font-medium text-gray-900 dark:text-gray-100">{item?.districtName || "N/A"}</div>
                    <div className="text-gray-500 dark:text-gray-400">{item?.districtCode || "N/A"}</div>
                  </td>
                )}
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>

      </div>
      {!!searchParams.id?.length && (
        <>
          <ViewReaderContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  )
}

export default ReadersPage;
