import { TextInput } from "components/core"
import { useFormik } from "formik"
import { FC, useMemo } from "react"
import { classNames, wrapClick } from "utils";
import { generateBill } from "./generate-bill";
import moment from "moment";
import numeral from "numeral";

interface BillingPreviewFormProps {
  handleNext?: (values: any) => void
  handlePrevious?: () => void;
  values: any;
  handleCancel?: () => void;
  hideSubmitButtons?: boolean
}

const BillingPreviewForm: FC<BillingPreviewFormProps> = ({ handleNext, handlePrevious, values, hideSubmitButtons }) => {

  const form = useFormik({
    initialValues: {
      previousReadingDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
      previousReadingValue: 0,
      currentReadingDate: moment().format("YYYY-MM-DD"),
      currentReadingValue: 0,
    },
    onSubmit: (values) => {
      handleNext?.(values)
    }
  })

  const previewBill = useMemo(() => generateBill({
    ...form.values,
    previousReadingDate: moment(form.values.previousReadingDate).toDate(),
    currentReadingDate: moment(form.values.currentReadingDate).toDate(),
    tariffInstance: {
      ...values.tariffInstanceInfo,
      ...values.chargeInfo,
      ...values.extraItemsInfo,
    }
  }), [form.values, values.chargeInfo, values.extraItemsInfo, values.tariffInstanceInfo])

  return (
    <form onSubmit={form.handleSubmit} className="flex-1 flex flex-col overflow-hidden">
      <div className={classNames(
        hideSubmitButtons ? "" : "p-6",
        "space-y-6 divide-y divide-gray-200 overflow-y-auto"
      )}>
        <div>
          <span className="text-xs font-light">Billing Information</span>
          <div className="grid grid-cols-4 gap-6 mt-2">

            <div className="">
              <TextInput
                id="previousReadingDate"
                label="Last Reading Date"
                type="date"
                placeholder="User Code"
                required={true}
                {...form}
              />
            </div>

            <div className="">
              <TextInput
                id="currentReadingDate"
                label="Current Reading Date"
                type="date"
                placeholder="User Code"
                required={true}
                {...form}
              />
            </div>

            <div>
              <TextInput
                id="previousReadingValue"
                label="Last Reading (KWh)"
                type="number"
                step={1}
                placeholder="eg. 1000"
                required={true}
                {...form}
              />
            </div>

            <div>
              <TextInput
                id="currentReadingValue"
                label="Current Reading (KWh)"
                type="number"
                step={1}
                placeholder="eg. 1000"
                required={true}
                {...form}
              />
            </div>
          </div>
        </div>


        <div className="pt-6">
          <span className="text-xs font-light">Bill Preview</span>
          <div className="mt-2 border-gray-300 border p-4 border-dashed bg-gray-50">
            <div className="grid grid-cols-4 gap-6">

              <div>
                <span className="block text-sm font-light text-gray-700">
                  Previous Reading
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {numeral(form?.values?.previousReadingValue || 0).format("0,0")} KWh
                </div>
              </div>

              <div>
                <span className="block text-sm font-light text-gray-700">
                  Current Reading
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {numeral(form?.values?.currentReadingValue || 0).format("0,0")} KWh
                </div>
              </div>

              <div>
                <span className="block text-sm font-light text-gray-700">
                  Consumption
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {numeral(previewBill?.consumption || 0).format("0,0")} KWh
                </div>
              </div>

              <div>
                <span className="block text-sm font-light text-gray-700">
                  Consumption Period
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {previewBill?.numberOfDays || 0} Days
                </div>
              </div>


            </div>
            <table className="min-w-full divide-y divide-gray-300 mt-4">
              <thead>
                <tr className="bg-primary-600">
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-4"
                  >
                    Item
                  </th>
                  <th
                    scope="col"
                    className="hidden py-3.5 px-3 text-right text-sm font-semibold text-white sm:table-cell"
                  >
                    Unit
                  </th>
                  <th
                    scope="col"
                    className="hidden py-3.5 px-3 text-right text-sm font-semibold text-white sm:table-cell"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-white sm:pr-4"
                  >
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {previewBill.items.map((item, idx) => (
                  <tr key={idx} className="border-b border-gray-200">
                    <td className="py-4 pl-4 pr-3 text-sm sm:pl-4">
                      <div className="font-medium text-gray-900">{item.title}</div>
                      <div className="mt-0.5 text-gray-500 sm:hidden">
                        {item.title}
                      </div>
                    </td>
                    <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">{item.unit}</td>
                    <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">{numeral(item.unitCost).format("0,0.0000")}</td>
                    <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-4">{numeral(item.cost).format("0,0.00")}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th />
                  <th />
                  <th
                    scope="row"
                    // colSpan={3}
                    className="hidden pl-6 pr-3 pt-6 pb-2.5 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
                  >
                    Current Bill
                  </th>
                  <th scope="row" className="pl-4 pr-3 pt-6 pb-2.5 text-left text-sm font-normal text-gray-500 sm:hidden">
                    Current Bill
                  </th>
                  <td className="pl-3 pr-4 pt-6 pb-2.5 text-right text-sm text-gray-500 sm:pr-4">GHS {numeral(previewBill.total).format("0,0.00")}</td>
                </tr>
                <tr>
                  <th />
                  <th />
                  <th
                    scope="row"
                    // colSpan={3}
                    className="hidden pl-6 pr-3 py-2.5 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
                  >
                    Previous Balance
                  </th>
                  <th scope="row" className="pl-4 pr-3 py-2.5 text-left text-sm font-normal text-gray-500 sm:hidden">
                    Previous Balance
                  </th>
                  <td className="pl-3 pr-4 py-2.5 text-right text-sm text-gray-500 sm:pr-4">GHS {numeral(0).format("0,0.00")}</td>
                </tr>
                <tr>
                  <th />
                  <th />
                  <th
                    scope="row"
                    // colSpan={3}
                    className="bg-primary-600 hidden pl-6 pr-3 py-3 text-right text-sm font-semibold text-white sm:table-cell md:pl-0"
                  >
                    Amount Payable
                  </th>
                  <th scope="row" className="bg-primary-600 pl-4 pr-3 py-3 text-left text-sm font-semibold text-white sm:hidden">
                    Amount Payable
                  </th>
                  <td className="bg-primary-600 pl-3 pr-4 py-3 text-right text-sm font-semibold text-white sm:pr-4">
                    GHS {numeral(previewBill.total).format("0,0.00")}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      {!hideSubmitButtons && (
      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="submit"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious as () => void)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
      )}
    </form>
  )
}

export default BillingPreviewForm