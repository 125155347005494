import { FC } from "react";
import { Avatar } from "components/core";
import { classNames, wrapImage } from "utils";
import numeral from "numeral";
import moment from "moment";
import lodash from "lodash";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { PhotoIcon } from "@heroicons/react/24/outline";

interface CustomerReadingViewProps {
  reading: any;
}

const CustomerReadingView: FC<CustomerReadingViewProps> = ({ reading }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div>
        <span className='text-xs font-light'>Customer Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Service Point Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {reading?.servicePointCode || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Account Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {reading?.accountCode || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Service Class
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {reading?.servicePoint?.serviceClass || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Contract Status
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              <span
                className={classNames(
                  "bg-gray-100 text-gray-800",
                  reading?.customer?.status === "SuperAdmin"
                    ? `bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50`
                    : "",
                  reading?.customer?.status === "Admin"
                    ? `bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50`
                    : "",
                  reading?.customer?.status === "Supervisor"
                    ? `bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50`
                    : "",
                  reading?.customer?.status === "Reader"
                    ? `bg-gray-100 text-[#9ACD32] dark:bg-[#9ACD32] dark:text-gray-50`
                    : "",
                  reading?.customer?.status === "Queued"
                    ? `bg-gray-100 text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50`
                    : "",
                  reading?.customer?.status === "Success"
                    ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                    : "",
                  reading?.customer?.status === "Reversed"
                    ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                    : "",
                  !reading?.customer?.status
                    ? "bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-100"
                    : "",
                  "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                )}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 10 10'
                  fill='currentColor'
                  className='w-1.5 h-1.5'
                >
                  <circle
                    fillRule='evenodd'
                    cx='5'
                    cy='5'
                    r='5'
                    clipRule='evenodd'
                  />
                </svg>
                <span>{reading?.customer?.status || "Active Contract"}</span>
              </span>
            </div>
          </div>
          <div className='col-span-2'>
            <span className='block text-sm font-light text-gray-700'>
              Full Name
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {reading?.customer?.representative?.fullName ||
                reading?.customer?.organization?.name ||
                "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Phone Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {reading?.customer?.representative?.phoneNumber ||
                reading?.customer?.representative?.phoneNumber ||
                "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Email Address
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {reading?.customer?.representative?.emailAddress ||
                reading?.customer?.representative?.emailAddress ||
                "N/A"}
            </div>
          </div>
        </div>
      </div>
      {reading?.contactPerson && (
        <div>
          <span className='text-xs font-light'>Contact Person Information</span>
          <div className='grid grid-cols-4 gap-6 mt-2'>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Full Name
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {reading?.contactPerson?.fullName || "N/A"}
              </div>
            </div>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Phone Number
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {reading?.contactPerson?.phoneNumber || "N/A"}
              </div>
            </div>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Email Address
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {reading?.contactPerson?.emailAddress || "N/A"}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='pt-6'>
        <span className='text-xs font-light'>Meter Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Meter Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {reading?.meter?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Meter Brand
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {reading?.meter?.modelMeta?.brandCode || "N/A"} -{" "}
              {reading?.meter?.modelMeta?.brandName || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Meter Model
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {reading?.meter?.modelMeta?.modelCode || "N/A"} -{" "}
              {reading?.meter?.modelMeta?.modelName || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Meter Phase
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {reading?.meter?.modelMeta?.phase || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Reading Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          {/* <div>
            <span className="block text-sm font-light text-gray-700">
              Last Reading Date
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.previousReading?.readingDate
                ? moment(reading.previousReading.readingDate).format(dateFormat)
                : "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Reading Value
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {numeral(reading?.previousReading?.readingValue || 0).format(
                "0,0"
              )}{" "}
              KWh
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Reading Estimated?
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.previousReading?.readingEstimated ? "Yes" : "No"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Outstanding Amount
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              GHS{" "}
              {numeral(reading?.previousReading?.totalAmountDue || 0).format(
                "0,0.00"
              )}
            </div>
          </div> */}
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Reading Date
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {reading?.currentReading?.readingDate
                ? moment(reading.currentReading.readingDate).format(dateFormat)
                : "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Reading Value
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {reading?.currentReading?.readingValue
                ? `${numeral(reading.currentReading.readingValue || 0).format(
                    "0,0"
                  )} KWh`
                : "N/A"}
            </div>
          </div>
          <div className='col-span-4'>
            <span className='block text-sm font-light text-gray-700'>
              Reading Image
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {reading?.meta?.readingImageUrl ? (
                wrapImage(
                  <img
                    src={reading?.meta?.readingImageUrl}
                    alt='reading'
                    className='h-64 w-full object-cover object-center'
                  />
                )
              ) : (
                <div className='flex  mt-2 w-full items-center justify-center'>
                  <div className='relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-6 text-center  focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'>
                    <PhotoIcon
                      className='mx-auto h-10 w-10 text-gray-400'
                      stroke='currentColor'
                      strokeWidth={1}
                      aria-hidden='true'
                    />
                    <h3 className='mt-2 text-sm font-medium text-gray-900'>
                      No Image
                    </h3>
                    <p className='mt-1 text-sm text-gray-500'></p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerReadingView;
