import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Week 1",
    AMRservice: 4070,
    PrepaidService: 1400,
    PostPaidService: 2400,
    TemporalService: 1400,
    amt: 2400,
    total: 2400,
  },
  {
    name: "Week 2",
    AMRservice: 3340,
    PrepaidService: 2600,
    PostPaidService: 2001,
    TemporalService: 1400,
    amt: 1928,
    total: 2400,
  },
  {
    name: "Week 3",
    AMRservice: 2030,
    PrepaidService: 4900,
    PostPaidService: 1980,
    TemporalService: 1400,
    amt: 2301,
    total: 2400,
  },
  {
    name: "Week 4",
    AMRservice: 3002,
    PrepaidService: 2300,
    PostPaidService: 2103,
    TemporalService: 1400,
    amt: 3201,
    total: 2400,
  },
  {
    name: "Week 5",
    AMRservice: 2900,
    PrepaidService: 3290,
    PostPaidService: 3800,
    TemporalService: 1400,
    amt: 2390,
    total: 2400,
  },
  {
    name: "Week 6",
    AMRservice: 3100,
    rPrepaidServicet: 2900,
    PostPaidService: 3190,
    TemporalService: 1400,
    amt: 1284,
    total: 1902,
  },
  {
    name: "Week 7",
    AMRservice: 3100,
    PrepaidService: 2900,
    PostPaidService: 4000,
    TemporalService: 1400,
    amt: 1284,
    total: 1902,
  },
];

export default class Example extends PureComponent {
  static jsfiddleUrl = "https://jsfiddle.net/alidingling/30763kr7/";

  render() {
    return (
      <ResponsiveContainer width="100%" height="80%">
        <BarChart
          width={1050}
          height={550}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 15,
            bottom: 5,
          }}
          className="text-sm mt-6 font-medium hover:gray-50"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="PostPaidService" fill="#FFB200" />
          <Bar dataKey="PrepaidService" fill="#4339F2" />
          <Bar dataKey="AMRservice" fill="#02A0FC" />
          <Bar dataKey="TemporalService" fill="#FF3A29" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}