import { useFormik } from "formik";
import { FC, useMemo, useRef } from "react";
import { wrapClick } from "utils";
import { generateBill } from "./generate-bill";
import moment from "moment";
import numeral from "numeral";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import _ from "lodash";
import Loader from "components/layouts/loader";
import lodash from "lodash";
import { QRCodeSVG } from "qrcode.react";
import { useReactToPrint } from "react-to-print";

interface BillingPreviewFormProps {
  handleNext?: (values: any) => void;
  handlePrevious?: () => void;
  values: any;
  handleCancel?: () => void;
}

const GET_TARIFF_INSTANCES = gql`
  query GetTariffInstances($tariffClass: ID!) {
    tariffInstances: getTariffInstances(
      tariffClass: $tariffClass
      page: 0
      pageSize: 0
    ) {
      _id
      code
      name
      energyCharge {
        type
        value
        steps {
          name
          minimumConsumption
          maximumConsumption
          value
          exclusive
        }
      }
      serviceCharge {
        type
        value
        steps {
          name
          minimumConsumption
          maximumConsumption
          value
          exclusive
        }
      }
      hasExtraItems
      extraItems {
        name
        category
        type
        appliedTo
        value
      }
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;

const BillingPreviewForm: FC<BillingPreviewFormProps> = ({
  handlePrevious,
  values,
}) => {
  const existingServicePoint = useMemo(
    () => values?.customerInfo?.existingServicePoint,
    [values]
  );
  const { data, loading } = useQuery(GET_TARIFF_INSTANCES, {
    variables: {
      tariffClass: existingServicePoint?.tariffClass?._id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const { dateFormat } = useReactiveVar(currentConfigVar);
  const form = useFormik({
    initialValues: {},
    onSubmit: () => {},
  });
  const ref = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: existingServicePoint?.code,
    bodyClass: "w-[1000px] justify-center",
  });
  const bill = useMemo(
    () =>
      generateBill({
        previousReadingDate:
          existingServicePoint?.meta?.lastCmsActualReadingDate,
        previousReadingValue:
          existingServicePoint?.meta?.lastCmsActualReadingValue,
        currentReadingDate: values?.readingInfo?.currentReadingDate,
        currentReadingValue: values?.readingInfo?.currentReadingValue,
        tariffInstances: data?.tariffInstances,
      }),
    [
      existingServicePoint?.meta?.lastCmsActualReadingDate,
      existingServicePoint?.meta?.lastCmsActualReadingValue,
      values?.readingInfo?.currentReadingDate,
      values?.readingInfo?.currentReadingValue,
      data?.tariffInstances,
    ]
  );

  return (
    <div className='flex-1 flex flex-col overflow-hidden'>
      <div className='flex-1 overflow-y-auto p-6'>
        {loading ? (
          <Loader />
        ) : (
          <div
            ref={ref}
            className='flex aspect-[210/290] w-full flex-col space-y-5 overflow-hidden'
          >
            <div className='grid grid-cols-7 gap-2 text-sm'>
              <div className='col-span-4'>
                <div className='grid grid-cols-4 gap-1'>
                  <div>
                    <img
                      className='bg-indigo-800 p-2 h-32 w-32'
                      src='https://commercial.test.subs.subsenterprise.com/static/media/logo.fb9cf839e5865243d3ca.png'
                      alt='logo'
                    />
                  </div>
                  <div className='col-span-3 font-bold'>
                    <dl>
                      <dd>ELECTRICITY COMPANY OF GHANA LIMITED</dd>
                      <dd>VAT REG: 714 V 000395</dd>
                      <dd>P.O. Box GP 521</dd>
                      <dd>Accra, Ghana</dd>
                      <dd>PHONE: 0302-611611</dd>
                      <dd>WEB: https://www.ecg.com.gh</dd>
                      <dd>EMAIL: info@ecg.com.gh</dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div className='col-span-3'>
                <dl>
                  <dd>
                    <span className='font-bold'>Electricity bill</span>
                    <span className='text-xs'>
                      Period:{" "}
                      {numeral(
                        bill?.billPeriod || bill?.consumptionPeriod
                      ).format("0,0")}{" "}
                      Days
                    </span>
                  </dd>
                  <dd>
                    <span className='font-bold'>Bill Number: </span>
                    {existingServicePoint?.code || "N/A"}
                  </dd>
                  <dd>
                    <span className='font-bold'>Bill Date: </span>
                    {moment().format(dateFormat)}{" "}
                    <span className='font-bold ml-3'>Month: </span>
                    {moment().format("MMM YYYY")}
                  </dd>
                  <dd>
                    <span className='font-bold'>Due Date: </span>
                    {moment().add(15, "days").format(dateFormat)}{" "}
                    <span className='text-xs'>
                      (Pay by this date to avoid disconnection)
                    </span>
                  </dd>
                  <dd>
                    <span className='font-bold'>Tariff: </span>
                    {existingServicePoint?.tariffClass?.code}{" "}
                    {existingServicePoint?.tariffClass?.name || "N/A"} (
                    {existingServicePoint?.serviceType})
                  </dd>
                  <dd>
                    <span className='font-bold'>Contracted Demand(KVA): </span>
                    {existingServicePoint?.contractedDemand ??
                      (existingServicePoint?.tariffClass?.code === "E01"
                        ? "0.5"
                        : "0.7")}
                  </dd>
                </dl>
              </div>
            </div>
            <div className='flex space-x-12'>
              <div className='text-sm flex-1'>
                <h1 className='bg-gray-300 p-2 text-center font-semibold'>
                  Customer Information
                </h1>
                <div className='flex flex-col justify-between'>
                  <div className='grid grid-cols-3 p-3'>
                    <span className='font-semibold'>Recipient Name: </span>
                    <span className='col-span-2'>
                      {existingServicePoint?.customer?.representative
                        ?.fullName ||
                        existingServicePoint?.customer?.organization
                          ?.fullName ||
                        existingServicePoint?.customer?.representative
                          ?.fullName ||
                        "N/A"}
                    </span>
                    <span className='font-semibold'>Digital Address: </span>
                    <span className='col-span-2'>
                      {existingServicePoint?.property?.ghanaPostAddress ||
                        "N/A"}
                    </span>
                    <span className='font-semibold'>Delivery Address: </span>
                    <span className='col-span-2'>
                      {existingServicePoint?.property?.address || "N/A"}
                    </span>
                  </div>
                  <div className='grid grid-cols-3 p-3'>
                    <span className='font-semibold'>Meter Number:</span>
                    <span className='col-span-2'>
                      {existingServicePoint?.meterCode || "N/A"}
                    </span>
                    <span className='font-semibold'>Account Number:</span>
                    <span className='col-span-2'>
                      {existingServicePoint?.accountCode || "N/A"}
                    </span>
                    <span className='font-semibold'>Customer Name: </span>
                    <span className='col-span-2'>
                      {existingServicePoint?.customer?.representative
                        ?.fullName ||
                        existingServicePoint?.customer?.organization
                          ?.fullName ||
                        existingServicePoint?.customer?.representative
                          ?.fullName ||
                        "N/A"}
                    </span>
                    <span className='font-semibold'>Service Address: </span>
                    <span className='col-span-2'>
                      {existingServicePoint?.property?.address || "N/A"}
                    </span>
                  </div>
                </div>
              </div>
              <div className='text-sm flex-1 flex flex-col'>
                <h1 className='bg-gray-300 p-2 text-center font-semibold'>
                  Consumption History (12 months)
                </h1>
                <div className='grid grid-cols-3 p-3'>
                  <span className='font-semibold'>Geographical Code:</span>
                  <span className='col-span-2'>
                    {existingServicePoint?.geoCode || "N/A"}
                  </span>
                  <span className='font-semibold'>Issuing Center:</span>
                  <span className='col-span-2'>
                    {existingServicePoint?.district?.name || "N/A"}
                  </span>
                </div>
                <div className='w-full flex-1 flex flex-row justify-evenly relative'>
                  <div className='flex flex-col'>
                    <div className='flex-1 flex flex-col-reverse justify-between'>
                      {lodash.map(
                        [0, 100, 200, 300, 400, 500, 600, 700],
                        (val) => (
                          <div className='flex justify-end'>
                            <span className='text-xs text-gray-700'>
                              {val} -
                            </span>
                            <div className='absolute h-0 mt-2 right-2 left-16 border-t border-gray-300' />
                          </div>
                        )
                      )}
                    </div>
                    <div className='h-6' />
                  </div>
                  {lodash.map(
                    [
                      "01",
                      "02",
                      "03",
                      "04",
                      "05",
                      "06",
                      "07",
                      "08",
                      "09",
                      "10",
                      "11",
                      "12",
                    ],
                    (date) => (
                      <div className='flex flex-col items-center z-10'>
                        <div className='w-4 flex-1 flex items-end'>
                          <div className='rounded-xs bg-gray-800 w-full h-0' />
                        </div>
                        <div className='h-6 flex items-end justify-center'>
                          <span className='text-xs text-gray-700'>{date}</span>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>

            <div className='pb-8'>
              <h1 className='text-sm text-center font-bold'>
                Meter Information
              </h1>
              <table className='w-full table-auto border-separate'>
                <thead>
                  <tr className='text-sm text-left font-bold'>
                    <th className='bg-gray-300 px-3 py-2'>Meter No.</th>
                    <th className='bg-gray-300 px-3 py-2'>Energy type</th>
                    <th className='bg-gray-300 px-3 py-2'>From</th>
                    <th className='bg-gray-300 px-3 py-2'>To</th>
                    <th className='bg-gray-300 px-3 py-2'>Previous Reading</th>
                    <th className='bg-gray-300 px-3 py-2'>Current Reading</th>
                    <th className='bg-gray-300 px-3 py-2'>Diff.</th>
                    <th className='bg-gray-300 px-3 py-2'>Multiplier</th>
                    <th className='bg-gray-300 px-3 py-2'>Consumption</th>
                    <th className='bg-gray-300 px-3 py-2'>E/A</th>
                  </tr>
                </thead>
                <tbody className='text-xs'>
                  <tr>
                    <td className='px-3 py-2'>
                      {existingServicePoint?.meterCode || "N/A"}
                    </td>
                    <td className='px-3 py-2'>Active energy (kWh)</td>
                    <td className='px-3 py-2'>
                      {moment(bill?.previousReadingDate).format(dateFormat)}
                    </td>
                    <td className='px-3 py-2'>
                      {moment(bill?.currentReadingDate).format(dateFormat)}
                    </td>
                    <td className='px-3 py-2'>
                      {numeral(bill?.previousReadingValue || 0).format("0,0")}
                    </td>
                    <td className='px-3 py-2'>
                      {numeral(bill?.currentReadingValue || 0).format("0,0")}
                    </td>
                    <td className='px-3 py-2'>
                      {numeral(bill?.totalConsumption || 0).format("0,0")}
                    </td>
                    <td className='px-3 py-2 text-center'>1.00</td>
                    <td className='px-3 py-2 text-center'>
                      {numeral(bill?.totalConsumption || 0).format("0,0")}
                    </td>
                    <td className='px-3 py-2 text-center'>{"A"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='flex flex-1 flex-col'>
              <h1 className='text-sm text-center font-bold'>
                Calculation Information
              </h1>
              <div className='relative flex-1'>
                <table className='w-full table-fixed border-separate'>
                  <thead>
                    <tr className='text-sm text-left font-bold'>
                      <th className='w-60 bg-gray-300 px-3 py-2'>
                        Additional Information
                      </th>
                      <th className='w-20 bg-gray-300 px-3 py-2'>From</th>
                      <th className='w-20 bg-gray-300 px-3 py-2'>To</th>
                      <th className='bg-gray-300 px-3 py-2'>Item</th>
                      <th className='w-28 bg-gray-300 px-3 py-2'>Unit</th>
                      <th className='w-20 bg-gray-300 px-3 py-2'>Price</th>
                      <th className='w-28 bg-gray-300 px-3 py-2 text-right'>
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody className='text-xs'>
                    {lodash.map(bill?.bills, (billX: any) => (
                      <>
                        {lodash.map(billX?.billItems, (item, idx) => (
                          <tr>
                            <td className=''></td>
                            <td className='px-3'>
                              {moment(billX?.previousDate).format(dateFormat)}
                            </td>
                            <td className='px-3'>
                              {moment(billX?.currentDate).format(dateFormat)}
                            </td>
                            <td className='px-3'>{item.title}</td>
                            <td className='px-3 text-right'>{item.unit}</td>
                            <td className='px-3 text-right'>
                              {numeral(item.unitCost).format("0,0.0000")}
                            </td>
                            <td className='px-3 text-right'>
                              {numeral(item.cost).format("0,0.00")}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td className='h-4' />
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
                <div className='absolute inset-0 flex flex-col'>
                  <div className='flex flex-1'>
                    <div className='w-60'></div>
                    <div className='flex-1 border-2 border-black'></div>
                  </div>
                  <div className='flex'>
                    <div className='flex-1'></div>
                    <div className='text-sm w-1/3 divide-y-2 divide-black border-2 border-t-0 border-black font-bold'>
                      <div className='grid grid-cols-5 divide-x-2 divide-black'>
                        <span className='col-span-3 px-3 py-0.5'>
                          Current Bill:
                        </span>
                        <span className='col-span-2 px-3 py-0.5 text-right'>
                          GHS {numeral(bill?.totalBillAmount).format("0,0.00")}
                        </span>
                      </div>
                      <div className='grid grid-cols-5 divide-x-2 divide-black'>
                        <span className='col-span-3 px-3 py-0.5'>
                          Previous Balance:
                        </span>
                        <span className='col-span-2 px-3 py-0.5 text-right'>
                          GHS {numeral(bill?.totalBillAmount).format("0,0.00")}
                        </span>
                      </div>
                      <div className='grid grid-cols-5 divide-x-2 divide-black'>
                        <span className='col-span-3 px-3 py-0.5'>
                          Total Paid:
                        </span>
                        <span className='col-span-2 px-3 py-0.5 text-right'>
                          GHS {numeral(0).format("0,0.00")}
                        </span>
                      </div>
                      <div className='grid grid-cols-5 divide-x-2 divide-black'>
                        <span className='col-span-3 px-3 py-0.5'>
                          Amount Payable:
                        </span>
                        <span className='col-span-2 px-3 py-0.5 text-right'>
                          GHS {numeral(bill?.totalBillAmount).format("0,0.00")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='flex flex-col pb-8'>
              <span className='text-sm font-bold'>
                Payment Information. Last payment date: 17/03/2023 and Payment
                Amount: 1,800.00
              </span>
              <span className='px-6 text-end'>Please See Over</span>
            </div>
            <div className='grid grid-cols-3'>
              <div className='col-span-1 grid grid-cols-5 gap-x-3 gap-y-1 border-2 border-black p-2 text-xs'>
                <span className='col-span-2 text-right font-bold'>
                  Customer Name:{" "}
                </span>
                <span className='col-span-3'>
                  {existingServicePoint?.customer?.representative?.fullName ||
                    existingServicePoint?.customer?.organization?.fullName ||
                    existingServicePoint?.customer?.representative?.fullName ||
                    "N/A"}
                </span>
                <span className='col-span-2 text-right font-bold'>
                  Bill Date:{" "}
                </span>
                <span className='col-span-3'>
                  {moment().format(dateFormat)}
                </span>
                <span className='col-span-2 text-right font-bold'>
                  Account No:
                </span>
                <span className='col-span-3'>
                  {existingServicePoint?.accountCode || "N/A"}
                </span>
                <span className='col-span-2 text-right font-bold'>
                  Bill No:
                </span>
                <span className='col-span-3'>
                  {" "}
                  {existingServicePoint?.code || "N/A"}{" "}
                </span>
                <span className='col-span-2 text-right font-bold'>
                  Amount Payable:{" "}
                </span>
                <span className='col-span-3'>
                  GHS {numeral(bill?.totalBillAmount).format("0,0.00")}
                </span>
              </div>
              <div className='col-span-1'>
                <div className='flex inset-0 items-center justify-center'>
                  <QRCodeSVG
                    value={existingServicePoint?.code}
                    size={120}
                    imageSettings={{
                      src: require("assets/logo.png"),
                      height: 20,
                      width: 20,
                      excavate: true,
                    }}
                  />
                </div>
              </div>
              <div className='col-span-1'></div>
            </div>
          </div>
        )}
      </div>

      <div className='bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200'>
        <button
          type='button'
          onClick={handlePrint}
          className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
        >
          Print Bill
        </button>
        <button
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
          onClick={wrapClick(handlePrevious as () => void)}
        >
          Previous
        </button>
        <button
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default BillingPreviewForm;
