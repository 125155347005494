import { wrapClick } from 'utils';
import { gql, useQuery } from '@apollo/client';
import { ReaderView, Modal } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';

const GET_UNCAPTURED_METER = gql`
  query GetUncapturedMeter($id: ID!) {
    readingAnomaly: getUncapturedMeter(id: $id) {
      _id
      code
      meterNumber
      customerName
      customerPhoneNumber
      nearestMeterNumber
      imageUrl
      reader {
        _id
        lastName
        firstName
        profileImageUrl
        phoneNumber
      }
      property {
        _id
        code
        ghanaPostAddress
        regionCode
        regionName
        districtCode
        districtName
        blockCode
        blockName
        roundCode
        roundName
        plotCode
        community
        itineraryCode
        itineraryDescription
      }
      itinerary {
        _id
        code
        description
        regionCode
        regionName
        districtCode
        districtName
        blockCode
        blockName
        roundCode
        roundName
        plotCode
        community
      }
      region {
        _id
      }
      regionCode
      regionName
      district {
        _id
      }
      districtCode
      districtName
      meta {
        anomalyLocation {
          coordinates
        }
        anomalyImageLocation {
          coordinates
        }
      }
      createdAt
      updatedAt
    }
  }
`

export default function ViewUncapturedMeterContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {

  const navigate = useNavigate<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const { data } = useQuery(GET_UNCAPTURED_METER, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });
  const updateItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "update"
      })
    })
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Reader Information"
      description="Details of agent are shown below"
      renderActions={() => (
        <>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(updateItem)}
          >
            Edit
          </button>
        </>
      )}
    >
      <ReaderView
        reader={data?.reader}
      />
    </Modal>
  )
}