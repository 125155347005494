import { FC } from 'react'
import numeral from 'numeral';

interface BlockViewProps {
  block: {
    code: string;
    name: string;
    region: {
      code: string;
      name: string;
    };
    district: {
      code: string;
      name: string;
    };
    meta: {
      roundsCount: number;
      plotsCount: number;
      itinerariesCount: number;
      propertiesCount: number;
      customersCount: number;
    }
  };
}

const BlockView: FC<BlockViewProps> = ({ block }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className='text-xs font-light'>Block Information</span>
        <div className='grid grid-cols-2 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Block Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {block?.code || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Block Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {block?.name || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Region Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {block?.region?.code || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Region Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {block?.region?.name || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              District Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {block?.district?.code || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              District Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {block?.district?.name || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Block Statistics</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Rounds Count
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {numeral(block?.meta?.roundsCount || 0).format("0,0")}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Plots Count
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {numeral(block?.meta?.plotsCount || 0).format("0,0")}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Itineraries Count
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {numeral(block?.meta?.itinerariesCount || 0).format("0,0")}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Properties Count
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {numeral(block?.meta?.propertiesCount || 0).format("0,0")}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Customers Count
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {numeral(block?.meta?.customersCount || 0).format("0,0")}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlockView