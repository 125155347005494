import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Shimmers, TableComponent } from "components";
import moment from "moment";
import lodash from "lodash";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick, useUrlState, classNames, useTableData } from "utils";
import config from "config";
import ViewShepRegularizationContainer from "./view";
import { Avatar, SearchSelectInput, SelectInput } from "components/core";
import numeral from "numeral";
import { currentConfigVar } from "apollo/cache/config";
import ActionButton, { Action } from "components/buttons/action-button";
import ReadingsFilterContainer from "./filter";
import { useShepRegularizations } from "./hooks";
const DATE_FORMAT = "YYYY-MM-DD";


const searchOptions = [
  {
    label: "Code",
    value: "code",
    min: 4,
  },
  {
    label: "Service Point Number",
    value: "servicePointCode",
    min: 5,
  },
  {
    label: "Customer Number",
    value: "customerCode",
    min: 4,
  },
  {
    label: "Account Number",
    value: "accountCode",
    min: 5,
  },
];
const ShepRegularizationsPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [region, setRegion] = useUrlState("region");
  const [district, setDistrict] = useUrlState("district");
  const [searchBy, setSearchParameter] = useUrlState("search_field");
  const [searchValue, setSearch] = useUrlState("search");
  const [minSearch, setMinSearch] = useUrlState("minSearch");

  const updateSearchBy = (key: string) => {
    setSearchParameter(key);
    if (key) {
      setMinSearch(searchOptions.find((option) => option.value === key)?.min);
    }
  };

  const filter = useMemo(
  () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search:
      searchParams.search && searchBy
        ? (searchParams.search || "").toString()
        : undefined,
    searchFields: searchBy && searchValue ? [searchBy] : undefined,
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
      sort: searchParams.sort || "",
      region: region || "",
      district: district || "",
    }),
    [searchParams]
  );

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "" } : {}),

        ...(!old?.sort ? { sort: "-meta.readingRecordedAt" } : {}),
      }),
    });
  }, [navigate]);

  const { rows, count, loading, networkStatus, refetch } =
    useShepRegularizations(filter);
  const records = useTableData({ rows, count });

  return (
    <main className='flex-1 flex flex-col overflow-hidden bg-gray-50'>
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={"SHEP captures"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            onSearchClicked={setSearch}
            disableSearch={!searchBy}
            hasSearch={true}
            renderSearchOption={() => (
              <SelectInput
                id={"searchBy"}
                label={""}
                values={{ searchBy }}
                handleChange={(e: any) => updateSearchBy(e.target.value)}
                handleBlur={undefined}
                options={[
                  {
                    label: "Search By",
                    value: "",
                  },
                  ...searchOptions,
                ]}
              />
            )}               // renderFilter={({ filterOpen, setFilterOpen }) => (
            //   <ReadingsFilterContainer
            //     open={filterOpen}
            //     setOpen={setFilterOpen}
            //   />
            // )}
            // renderExport={({ exportOpen, setExportOpen }) => (
            //   <ExportDataContainer
            //     open={exportOpen}
            //     setOpen={setExportOpen}
            //     entityType="Reading"
            //     filter={filter}
            //   />
            // )}

            renderColumns={() => (
              <tr>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Code | Date
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Customer
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  SPN | Account No
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Service Class | Meter No
                </th>
                {!region && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Region
                  </th>
                )}
                {!district && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    District
                  </th>
                )}

                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <>
                <tr>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.AvatarShimmer />
                  </td>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                  {!region && (
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.SingleShimmer />
                    </td>
                  )}
                  {!district && (
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.SingleShimmer />
                    </td>
                  )}
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.ActionsShimmer actionsCount={2} />
                  </td>
                </tr>
              </>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.code || "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {moment(item?.createdAt).format(dateFormat)}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='flex items-center'>
                    <div className='h-10 w-10 flex-shrink-0'>
                      <Avatar
                        disabled={true}
                        alt={
                          item?.customerData?.representative?.fullName?.trim() ||
                          item?.customerData?.organization?.name?.trim() ||
                          "N A"
                        }
                        src={
                          item?.customerData?.representative?.profileImageUrl ||
                          item?.customerData?.representative?.profileImageUrl ||
                          ""
                        }
                      />
                    </div>
                    <div className='ml-4'>
                      <div className='text-gray-900 dark:text-gray-100'>
                        {item?.customerData?.representative?.fullName?.trim() ||
                          item?.customerData?.organization?.name?.trim() ||
                          "N A"}
                      </div>
                      <div className='text-gray-500 dark:text-gray-400'>
                        {item?.customerCode || "N/A"}
                      </div>
                    </div>
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.servicePointCode || "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.accountCode || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-500 dark:text-gray-400'>
                    <div className='text-gray-500 dark:text-gray-400'>
                      {item?.service?.serviceClass || "N/A"}
                    </div>
                  </div>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.service?.meterNumber || "N/A"}
                  </div>
                </td>
                {!region && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                    <div className='font-medium text-gray-900 dark:text-gray-100'>
                      {item?.region?.name || "N/A"}
                    </div>
                    <div className='text-gray-500 dark:text-gray-400'>
                      {item?.region?.code || "N/A"}
                    </div>
                  </td>
                )}
                {!district && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                    <div className='font-medium text-gray-900 dark:text-gray-100'>
                      {item?.district?.name || "N/A"}
                    </div>
                    <div className='text-gray-500 dark:text-gray-400'>
                      {item?.district?.code || "N/A"}
                    </div>
                  </td>
                )}
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {!!searchParams.id?.length && (
        <>
          <ViewShepRegularizationContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default ShepRegularizationsPage;
