import { useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import ReadingInfoSummary from 'components/forms/resolve-reconnection-service-order/reading-info-summary';
import { FC } from 'react'
                       
interface InstallationServiceOrderResolutionViewProps {
  resolution: {
    property: any;
    service: any;
    reading: any;
    notes: string;
    supportingImageUrls: string[];

  };
}

const InstallationServiceOrderResolutionView: FC<InstallationServiceOrderResolutionViewProps> = ({ resolution }) => {
  const currentConfig = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
     
      <div className=' p-0'>
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">Reading Infomation</h3>
            <p className="mt-1 text-xs text-gray-500">Details about property</p>
          </div>
          <div />
        </div>
        <div className="mt-6">
        <ReadingInfoSummary
            data={{
              readingDate: resolution?.reading?.readingDate,
              readingValue: resolution?.reading?.readingValue,
              readingImageUrl: resolution?.reading?.readingImageUrl,
              supportingImageUrls: resolution?.supportingImageUrls,  
              notes: resolution?.notes
            }}
            config={currentConfig}
            />
        </div>
      </div>
    </div>
  )
}

export default InstallationServiceOrderResolutionView





