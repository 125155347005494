import moment from "moment";

const CustomerInfoSummary = ({ data, config: { dateFormat } }: any) => (
  <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3'>
    <div className='divide-y-2 divide-gray-300 divide-dashed space-y-6'>
      <div className='grid grid-cols-4 gap-3'>
        <div className=''>
          <span className='block text-sm font-light text-gray-700'>Type</span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.existingServicePoint?.customer?.customerType || "N/A"}
          </div>
        </div>
        <div className=''>
          <span className='block text-sm font-light text-gray-700'>Title</span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.existingServicePoint?.customer?.representative?.title ||
              data?.existingServicePoint?.customer?.representative?.title ||
              "N/A"}
          </div>
        </div>
        <div className='col-span-2'>
          <span className='block text-sm font-light text-gray-700'>
            Full Name
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.existingServicePoint?.customer?.representative?.fullName ||
              data?.existingServicePoint?.customer?.representative?.fullName ||
              "N/A"}
          </div>
        </div>
        <div className=''>
          <span className='block text-sm font-light text-gray-700'>
            Nationality
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.existingServicePoint?.customer?.representative
              ?.nationality ||
              data?.existingServicePoint?.customer?.representative
                ?.nationality ||
              "N/A"}
          </div>
        </div>
        <div className=''>
          <span className='block text-sm font-light text-gray-700'>
            Date of Birth
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {moment(
              data?.existingServicePoint?.customer?.representative
                ?.dateOfBirth ||
                data?.existingServicePoint?.customer?.representative
                  ?.dateOfBirth
            ).format(dateFormat)}
          </div>
        </div>
        <div className=''>
          <span className='block text-sm font-light text-gray-700'>Gender</span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.existingServicePoint?.customer?.representative?.gender ||
              data?.existingServicePoint?.customer?.representative?.gender ||
              "N/A"}
          </div>
        </div>
      </div>
      <div className='grid grid-cols-4 gap-3 pt-6'>
        <div className=''>
          <span className='block text-sm font-light text-gray-700'>
            Account Number
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.existingServicePoint?.account?.code || "N/A"}
          </div>
        </div>
        <div>
          <span className='block text-sm font-light text-gray-700'>
            Customer Number
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.existingServicePoint?.customer?.code || "N/A"}
          </div>
        </div>
        <div>
          <span className='block text-sm font-light text-gray-700'>
            Property Number
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.existingServicePoint?.property?.code || "N/A"}
          </div>
        </div>
        <div>
          <span className='block text-sm font-light text-gray-700'>
            Service Point Number
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.existingServicePoint?.code || "N/A"}
          </div>
        </div>
        <div>
          <span className='block text-sm font-light text-gray-700'>
            Geographical Code
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.existingServicePoint?.geoCode || "N/A"}
          </div>
        </div>
        <div className='col-span-2'>
          <span className='block text-sm font-light text-gray-700'>
            Property Address
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.existingServicePoint?.property?.ghanaPostAddress || "N/A"}
          </div>
        </div>
      </div>
      <div className='grid grid-cols-4 gap-3 pt-6'>
        <div>
          <span className='block text-sm font-light text-gray-700'>
            Meter Number
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.existingServicePoint?.meter?.code || "N/A"}
          </div>
        </div>
        <div>
          <span className='block text-sm font-light text-gray-700'>
            Meter Make
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.existingServicePoint?.meter?.modelMeta?.brandCode || "N/A"} -{" "}
            {data?.existingServicePoint?.meter?.modelMeta?.brandName || "N/A"}
          </div>
        </div>
        <div>
          <span className='block text-sm font-light text-gray-700'>
            Meter Model
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.existingServicePoint?.meter?.code || "N/A"} -{" "}
            {data?.existingServicePoint?.meter?.name || "N/A"}
          </div>
        </div>
        <div className=''>
          <span className='block text-sm font-light text-gray-700'>
            Meter Type
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.existingServicePoint?.meter?.modelType || "N/A"}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default CustomerInfoSummary;
