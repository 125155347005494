import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import _ from "lodash";
import moment from "moment";
import { wrapImage } from "utils";

const ContractInfoSummary = ({ data }: any) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3'>
      <div className=''>
        <div className='grid grid-cols-2 gap-6'>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Reading Value
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {_.startCase(data?.readingValue || "n/A")}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Reading Date
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {moment(data?.readingDate).format(dateFormat)}
            </div>
          </div>
          {/* <div className="col-span-2">
          <span className="block text-sm font-light text-gray-700 ">
            Reading Location
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.readingLocationName || "N/A"}
          </div>
        </div> */}
          <div className='col-span-2'>
            <span className='block text-sm font-light text-gray-700 col-span-2'>
              Reading Image
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {wrapImage(
                <img src={data?.readingImageUrl} className='h-36 w-full' />
              ) || "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractInfoSummary;
