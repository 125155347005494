import { useEffect } from "react";
import { wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, InitiateDisconnectionForm } from "components";
import { useMatch, useSearch } from "react-location";
import { LocationGenerics } from "router/location";

const INITIATE_DISCONNECTION = gql`
  mutation InitiateDisconnection(
    $plot: ID
    $region: ID
    $district: ID
    $minimumAge: Int
    $maximumAge: Int
    $minimumAmount: Float
    $maximumAmount: Float
  ) {
    flagServicePointsForDisconnection(
      plot: $plot
      region: $region
      district: $district
      minimumAge: $minimumAge
      maximumAge: $maximumAge
      maximumAmount: $maximumAmount
      minimumAmount: $minimumAmount
    ) {
      _id
    }
  }
`;

export default function InitiateDisconnectionContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();

  const [initiateDisconnection] = useMutation(INITIATE_DISCONNECTION);

  const form = useFormik({
    initialValues: {
      minimumAge: searchParams.minAge || 0,
      maximumAge: searchParams.maxAge || null,
      maximumAmount: searchParams.maxDebtAmount || null,
      minimumAmount: searchParams.minDebtAmount || 0,
    },
    onSubmit: async (values) => {
      await initiateDisconnection({
        variables: {
          id: searchParams.id,
          plot: undefined,
          region: searchParams.region || undefined,
          district: searchParams.district || undefined,
          ...values,
        },
      }).then(({ data }) => {
        if (!!data.flagServicePointsForDisconnection) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Disconnections prioritized Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not prioritize disconnection",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      minimumAge: searchParams.minAge || 0,
      maximumAge: searchParams.maxAge || null,
      maximumAmount: searchParams.maxDebtAmount || null,
      minimumAmount: searchParams.minDebtAmount || 0,
    });
  }, [searchParams]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title='Prioritize Disconnections'
      description='Provide details to prioritize disconnections'
      renderActions={() => (
        <>
          <button
            type='button'
            disabled={form.isSubmitting}
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {form.isSubmitting
              ? "Prioritizing Disconnections..."
              : "Prioritize Disconnections"}
          </button>
        </>
      )}
    >
      <InitiateDisconnectionForm form={form} />
    </Modal>
  );
}
