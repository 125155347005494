import { FC, forwardRef } from "react";
import numeral from "numeral";
import moment from "moment";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import {
  EnvelopeIcon,
  GlobeAltIcon,
  PhoneIcon,
} from "@heroicons/react/20/solid";
import { QRCodeSVG } from "qrcode.react";
import _ from "lodash";

interface BillViewProps {
  ref: any;
  bill: {
    code: string;
    emailAddress: string;
    phoneNumber: string;
    fullName: string;
    profileImageUrl: string;
    ghanaCardNumber: string;
    payer: {
      fullName: string;
      emailAddress: string;
    };
    servicePointNumber: string;
    accountNumber: string;
    geoCode: string;
    contractedDemand: string;
    contractStatus: string;
    meterNumber: string;
    serviceClass: string;
    serviceType: string;
    tariffClassCode: string;
    tariffClassName: string;
    propertyCode: string;
    qrCode: string;
    address: string;
    ghanaPostAddress: string;
    customerCode: string;
    readingSetCode: string;
    readingSetName: string;
    month: number;
    year: number;
    billPeriod: number;
    billStartDate: Date;
    billEndDate: Date;
    consumptionPeriod: string;
    previousBill: {
      readingDate: Date;
      readingValue: number;
      readingEstimated: boolean;
      consumptionValue: number;
      billValue: number;
      totalAmountDue: number;
    };
    currentBill: {
      readingDate: Date;
      readingValue: number;
      readingEstimated: boolean;
      consumptionValue: number;
      billValue: number;
      totalAmountDue: number;
    };
    currentBillAmount: number;
    outstandingAmount: number;
    totalAmountDue: number;
    totalAmountDueAt: Date;
    bills: {
      currentDate: Date;
      previousDate: Date;
      numberOfDays: number;
      consumption: number;
      billItems: {
        title: string;
        unit: string;
        quantity: string;
        unitCost: string;
        cost: string;
      }[];
      billAmount: number;
    }[];
    createdAt: string;
    updatedAt: string;
  };
}

const BillView: FC<BillViewProps> = forwardRef<HTMLDivElement, BillViewProps>(
  ({ bill }, ref) => {
    const { dateFormat } = useReactiveVar(currentConfigVar);

    return (
      <div
        ref={ref}
        className="flex aspect-[210/297] w-full flex-col space-y-5 overflow-hidden"
      >
        <div className="grid grid-cols-7 gap-2 text-sm">
          <div className="col-span-4">
            <div className="grid grid-cols-4 gap-1">
              <div>
                <img
                  className="bg-indigo-800 p-2 h-32 w-32"
                  src="https://commercial.test.subs.subsenterprise.com/static/media/logo.fb9cf839e5865243d3ca.png"
                />
              </div>
              <div className="col-span-3 font-bold">
                <dl>
                  <dd>ELECTRICITY COMPANY OF GHANA LIMITED</dd>
                  <dd>VAT REG: 714 V 000395</dd>
                  <dd>P.O. Box GP 521</dd>
                  <dd>Accra, Ghana</dd>
                  <dd>PHONE: 0302-611611</dd>
                  <dd>WEB: https://www.ecg.com.gh</dd>
                  <dd>EMAIL: info@ecg.com.gh</dd>
                </dl>
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <dl>
              <dd>
                <span className="font-bold">Electricity bill</span>
                <span className="text-xs">
                  Period:{" "}
                  {numeral(bill?.billPeriod || bill?.consumptionPeriod).format(
                    "0,0"
                  )}{" "}
                  Days
                </span>
              </dd>
              <dd>
                <span className="font-bold">Bill Number: </span>
                {bill?.code || "N/A"}
              </dd>
              <dd>
                <span className="font-bold">Bill Date: </span>
                {moment(bill?.createdAt).format(dateFormat)}{" "}
                <span className="font-bold ml-3">Month: </span>
                {moment(bill?.createdAt).format("MMM YYYY")}
              </dd>
              <dd>
                <span className="font-bold">Due Date: </span>
                {moment(bill?.totalAmountDueAt).format(dateFormat)}{" "}
                <span className="text-xs">
                  (Pay by this date to avoid disconnection)
                </span>
              </dd>
              <dd>
                <span className="font-bold">Tariff: </span>
                {bill?.tariffClassCode} {bill?.tariffClassName || "N/A"} (
                {bill?.serviceType || "N/A"})
              </dd>
              <dd>
                <span className="font-bold">Contracted Demand(KVA): </span>
                {bill?.contractedDemand}
              </dd>
            </dl>
          </div>
        </div>
        <div className="flex space-x-12">
          <div className="text-sm flex-1">
            <h1 className="bg-gray-300 p-2 text-center font-semibold">
              Customer Information
            </h1>
            <div className="flex flex-col justify-between">
              <div className="grid grid-cols-3 p-3">
                <span className="font-semibold">Recipient Name: </span>
                <span className="col-span-2">{bill?.fullName || "N/A"}</span>
                <span className="font-semibold">Digital Address: </span>
                <span className="col-span-2">
                  {bill?.ghanaPostAddress || "N/A"}
                </span>
                <span className="font-semibold">Delivery Address: </span>
                <span className="col-span-2">{bill?.address || "N/A"}</span>
              </div>
              <div className="grid grid-cols-3 p-3">
                <span className="font-semibold">Meter Number:</span>
                <span className="col-span-2">{bill?.meterNumber || "N/A"}</span>
                <span className="font-semibold">Account Number:</span>
                <span className="col-span-2">
                  {bill?.accountNumber || "N/A"}
                </span>
                <span className="font-semibold">Customer Name: </span>
                <span className="col-span-2">{bill?.fullName || "N/A"}</span>
                <span className="font-semibold">Service Address: </span>
                <span className="col-span-2">{bill?.address || "N/A"}</span>
              </div>
            </div>
          </div>
          <div className="text-sm flex-1 flex flex-col">
            <h1 className="bg-gray-300 p-2 text-center font-semibold">
              Consumption History (12 months)
            </h1>
            <div className="grid grid-cols-3 p-3">
              <span className="font-semibold">Geographical Code:</span>
              <span className="col-span-2">{bill?.geoCode || "N/A"}</span>
              <span className="font-semibold">Issuing Center:</span>
              <span className="col-span-2">Kwabenya District</span>
            </div>
            <div className="w-full flex-1 flex flex-row justify-evenly relative">
              <div className="flex flex-col">
                <div className="flex-1 flex flex-col-reverse justify-between">
                  {_.map([0, 100, 200, 300, 400, 500, 600, 700], (val) => (
                    <div className="flex justify-end">
                      <span className="text-xs text-gray-700">{val} -</span>
                      <div className="absolute h-0 mt-2 right-2 left-16 border-t border-gray-300" />
                    </div>
                  ))}
                </div>
                <div className="h-6" />
              </div>
              {_.map(
                [
                  "01",
                  "02",
                  "03",
                  "04",
                  "05",
                  "06",
                  "07",
                  "08",
                  "09",
                  "10",
                  "11",
                  "12",
                ],
                (date) => (
                  <div className="flex flex-col items-center z-10">
                    <div className="w-4 flex-1 flex items-end">
                      <div className="rounded-xs bg-gray-800 w-full h-16" />
                    </div>
                    <div className="h-6 flex items-end justify-center">
                      <span className="text-xs text-gray-700">{date}</span>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>

        <div className="pb-8">
          <h1 className="text-sm text-center font-bold">Meter Information</h1>
          <table className="w-full table-auto border-separate">
            <thead>
              <tr className="text-sm text-left font-bold">
                <th className="bg-gray-300 px-3 py-2">Meter No.</th>
                <th className="bg-gray-300 px-3 py-2">Energy type</th>
                <th className="bg-gray-300 px-3 py-2">From</th>
                <th className="bg-gray-300 px-3 py-2">To</th>
                <th className="bg-gray-300 px-3 py-2">Previous Reading</th>
                <th className="bg-gray-300 px-3 py-2">Current Reading</th>
                <th className="bg-gray-300 px-3 py-2">Diff.</th>
                <th className="bg-gray-300 px-3 py-2">Multiplier</th>
                <th className="bg-gray-300 px-3 py-2">Consumption</th>
                <th className="bg-gray-300 px-3 py-2">E/A</th>
              </tr>
            </thead>
            <tbody className="text-xs">
              <tr>
                <td className="px-3 py-2">{bill?.meterNumber || "N/A"}</td>
                <td className="px-3 py-2">Active energy (kWh)</td>
                <td className="px-3 py-2">
                  {moment( bill?.previousBill?.readingDate
                  ).format(dateFormat)}
                </td>
                <td className="px-3 py-2">
                  {moment(
                    bill?.billEndDate || bill?.currentBill?.readingDate
                  ).format(dateFormat)}
                </td>
                <td className="px-3 py-2">
                  {numeral(bill?.previousBill?.readingValue || 0).format("0,0")}
                </td>
                <td className="px-3 py-2">
                  {numeral(bill?.currentBill?.readingValue || 0).format("0,0")}
                </td>
                <td className="px-3 py-2">
                  {numeral(bill?.currentBill?.consumptionValue || 0).format(
                    "0,0"
                  )}
                </td>
                <td className="px-3 py-2 text-center">1.00</td>
                <td className="px-3 py-2 text-center">
                  {numeral(bill?.currentBill?.consumptionValue || 0).format(
                    "0,0"
                  )}
                </td>
                <td className="px-3 py-2 text-center">
                  {bill?.currentBill?.readingEstimated ? "E" : "A"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex flex-1 flex-col">
          <h1 className="text-sm text-center font-bold">
            Calculation Information
          </h1>
          <div className="relative flex-1">
            <table className="w-full table-fixed border-separate">
              <thead>
                <tr className="text-sm text-left font-bold">
                  <th className="w-60 bg-gray-300 px-3 py-2">
                    Additional Information
                  </th>
                  <th className="w-20 bg-gray-300 px-3 py-2">From</th>
                  <th className="w-20 bg-gray-300 px-3 py-2">To</th>
                  <th className="bg-gray-300 px-3 py-2">Item</th>
                  <th className="w-28 bg-gray-300 px-3 py-2">Unit</th>
                  <th className="w-20 bg-gray-300 px-3 py-2">Price</th>
                  <th className="w-28 bg-gray-300 px-3 py-2 text-right">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody className="text-xs">
                {bill?.bills?.map?.((billX) => (
                  <>
                    {billX?.billItems?.map((item, idx) => (
                      <tr>
                        <td className=""></td>
                        <td className="px-3">
                          {moment(billX?.previousDate).format(dateFormat)}
                        </td>
                        <td className="px-3">
                          {moment(billX?.currentDate).format(dateFormat)}
                        </td>
                        <td className="px-3">{item.title}</td>
                        <td className="px-3 text-right">{item.unit}</td>
                        <td className="px-3 text-right">
                          {numeral(item.unitCost).format("0,0.0000")}
                        </td>
                        <td className="px-3 text-right">
                          {numeral(item.cost).format("0,0.00")}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td className="h-4" />
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
            <div className="absolute inset-0 flex flex-col">
              <div className="flex flex-1">
                <div className="w-60"></div>
                <div className="flex-1 border-2 border-black"></div>
              </div>
              <div className="flex">
                <div className="flex-1"></div>
                <div className="text-sm w-1/3 divide-y-2 divide-black border-2 border-t-0 border-black font-bold">
                  <div className="grid grid-cols-5 divide-x-2 divide-black">
                    <span className="col-span-3 px-3 py-0.5">
                      Current Bill:
                    </span>
                    <span className="col-span-2 px-3 py-0.5 text-right">
                      GHS {numeral(bill?.currentBillAmount).format("0,0.00")}
                    </span>
                  </div>
                  <div className="grid grid-cols-5 divide-x-2 divide-black">
                    <span className="col-span-3 px-3 py-0.5">
                      Previous Balance:
                    </span>
                    <span className="col-span-2 px-3 py-0.5 text-right">
                      GHS {numeral(bill?.outstandingAmount).format("0,0.00")}
                    </span>
                  </div>
                  <div className="grid grid-cols-5 divide-x-2 divide-black">
                    <span className="col-span-3 px-3 py-0.5">Total Paid:</span>
                    <span className="col-span-2 px-3 py-0.5 text-right">
                      GHS {numeral(0).format("0,0.00")}
                    </span>
                  </div>
                  <div className="grid grid-cols-5 divide-x-2 divide-black">
                    <span className="col-span-3 px-3 py-0.5">
                      Amount Payable:
                    </span>
                    <span className="col-span-2 px-3 py-0.5 text-right">
                      GHS {numeral(bill?.totalAmountDue).format("0,0.00")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col pb-8">
          <span className="text-sm font-bold">
            Payment Information. Last payment date: 17/03/2023 and Payment
            Amount: 1,800.00
          </span>
          <span className="px-6 text-end">Please See Over</span>
        </div>
        <div className="grid grid-cols-3">
          <div className="col-span-1 grid grid-cols-5 gap-x-3 gap-y-1 border-2 border-black p-2 text-xs">
            <span className="col-span-2 text-right font-bold">
              Customer Name:{" "}
            </span>
            <span className="col-span-3">{bill?.fullName || "N/A"}</span>
            <span className="col-span-2 text-right font-bold">Bill Date: </span>
            <span className="col-span-3">
              {moment(bill?.createdAt).format(dateFormat)}
            </span>
            <span className="col-span-2 text-right font-bold">Account No:</span>
            <span className="col-span-3">{bill?.accountNumber || "N/A"}</span>
            <span className="col-span-2 text-right font-bold">Bill No:</span>
            <span className="col-span-3"> {bill?.code || "N/A"} </span>
            <span className="col-span-2 text-right font-bold">
              Amount Payable:{" "}
            </span>
            <span className="col-span-3">
              GHS {numeral(bill?.totalAmountDue).format("0,0.00")}
            </span>
          </div>
          <div className="col-span-1">
            <div className="flex inset-0 items-center justify-center">
              <QRCodeSVG
                value={bill?.code}
                size={120}
                imageSettings={{
                  src: require("assets/logo.png"),
                  height: 20,
                  width: 20,
                  excavate: true,
                }}
              />
            </div>
          </div>
          <div className="col-span-1"></div>
        </div>
      </div>
    );

    return (
      <div
        ref={ref}
        className="space-y-6  border-gray-300 border p-4 border-dashed bg-gray-50"
      >
        <div className="flex flex-row items-center space-x-4 justify-center border-b border-gray-300 py-6">
          <div>
            <img
              alt="ECG Logo"
              src={require("assets/logo.png")}
              className="h-32 w-32"
            />
          </div>
          <div className="space-y-1 text-gray-900">
            <h1 className="text-xl text-primary-600 font-bold">
              ELECTRICITY COMPANY OF GHANA LIMITED
            </h1>
            <div className="flex items-center space-x-6">
              <div className="flex items-center space-x-2">
                <EnvelopeIcon className="h-4 w-4" />
                <span>P.O BOX GP 521, ACCRA</span>
              </div>
              <div className="flex items-center space-x-2">
                <PhoneIcon className="h-4 w-4" />
                <span>0302-611-611</span>
              </div>
              <div className="flex items-center space-x-2">
                <GlobeAltIcon className="h-4 w-4" />
                <span>ecg@ecggh.com</span>
              </div>
            </div>
            <div className="flex items-center space-x-6">
              <div className="flex items-center space-x-2">
                <span className="font-semibold">VAT REG:</span>
                <span>714V000395</span>
              </div>
              <div className="flex items-center space-x-2">
                <span className="font-semibold">Website:</span>
                <span>https://www.ecggh.com/</span>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-5 border border-gray-300 divide-x divide-gray-300">
          <div className="col-span-2 grid grid-cols-2 gap-6 p-4">
            <div className="col-span-2">
              <span className="block text-sm">Customer Name</span>
              <div className="mt-1 block w-full text-sm font-light text-gray-700">
                {bill?.fullName || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm">Meter Number</span>
              <div className="mt-1 block w-full text-sm font-light text-gray-700">
                {bill?.meterNumber || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm">Account Number</span>
              <div className="mt-1 block w-full text-sm font-light text-gray-700">
                {bill?.accountNumber || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm">Digital Address</span>
              <div className="mt-1 block w-full text-sm font-light text-gray-700">
                {bill?.ghanaPostAddress || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm">Geo Code</span>
              <div className="mt-1 block w-full text-sm font-light text-gray-700">
                {bill?.geoCode || "N/A"}
              </div>
            </div>
          </div>
          <div className="col-span-3 grid grid-cols-2 gap-6 p-4">
            <div>
              <span className="block text-sm">Bill Period</span>
              <div className="mt-1 block w-full text-sm font-light text-gray-700">
                {moment(
                  bill?.billStartDate || bill?.previousBill?.readingDate
                ).format(dateFormat)}{" "}
                -{" "}
                {moment(
                  bill?.billEndDate || bill?.currentBill?.readingDate
                ).format(dateFormat)}{" "}
                (
                {numeral(bill?.billPeriod || bill?.consumptionPeriod).format(
                  "0,0"
                )}{" "}
                days)
              </div>
            </div>
            <div>
              <span className="block text-sm">Bill Number</span>
              <div className="mt-1 block w-full text-sm font-light text-gray-700">
                {bill?.code || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm">Bill Date</span>
              <div className="mt-1 block w-full text-sm font-light text-gray-700">
                {moment(bill?.createdAt).format(dateFormat)}
              </div>
            </div>
            <div>
              <span className="block text-sm">Due Date</span>
              <div className="mt-1 block w-full text-sm font-light text-gray-700">
                {moment(bill?.totalAmountDueAt).format(dateFormat)}
              </div>
            </div>
            <div>
              <span className="block text-sm">Tariff</span>
              <div className="mt-1 block w-full text-sm font-light text-gray-700">
                {bill?.tariffClassCode} {bill?.tariffClassName || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm">Contracted Demand</span>
              <div className="mt-1 block w-full text-sm font-light text-gray-700">
                {bill?.contractedDemand} KVA
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-5">
          <div className="col-span-2 grid grid-cols-2 gap-6 p-4music">
            <div className="col-span-2">
              <span className="block text-sm">Delivery Address</span>
              <div className="mt-1 block w-full text-sm font-light text-gray-700">
                {bill?.address || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm">Energy Type</span>
              <div className="mt-1 block w-full text-sm font-light text-gray-700">
                Active Energy (KWh)
              </div>
            </div>

            <div className="col-start-1">
              <span className="block text-sm">Previous Reading</span>
              <div className="mt-1 block w-full text-sm font-light text-gray-700">
                {numeral(bill?.previousBill?.readingValue || 0).format("0,0")}{" "}
                KWh
              </div>
            </div>

            <div>
              <span className="block text-sm">Current Reading</span>
              <div className="mt-1 block w-full text-sm font-light text-gray-700">
                {numeral(bill?.currentBill?.readingValue || 0).format("0,0")}{" "}
                KWh
              </div>
            </div>

            <div>
              <span className="block text-sm">Consumption</span>
              <div className="mt-1 block w-full text-sm font-light text-gray-700">
                {numeral(bill?.currentBill?.consumptionValue || 0).format(
                  "0,0"
                )}{" "}
                KWh
              </div>
            </div>

            <div className="">
              <span className="block text-sm">Reading Type</span>
              <div className="mt-1 block w-full text-sm font-light text-gray-700">
                {bill?.currentBill?.readingEstimated ? "Estimated" : "Actual"}
              </div>
            </div>
          </div>
          <div className="col-span-3 flex">
            <div className="h-full w-full flex-1 flex flex-row justify-evenly">
              <div className="flex flex-col">
                <div className="flex-1 flex flex-col-reverse">
                  {_.map([0, 100, 200, 300, 400, 500], (val) => (
                    <div className="flex-1 flex items-end justify-end">
                      <span className="text-xs text-gray-700">{val} -</span>
                    </div>
                  ))}
                </div>
                <div className="h-6" />
              </div>
              {_.map(
                [
                  "01",
                  "02",
                  "03",
                  "04",
                  "05",
                  "06",
                  "07",
                  "08",
                  "09",
                  "10",
                  "11",
                  "12",
                ],
                (date) => (
                  <div className="flex flex-col items-center">
                    <div className="rounded-full bg-primary-100 w-4 flex-1 flex items-end">
                      <div className="rounded-full bg-primary-600 w-full h-16" />
                    </div>
                    <div className="h-6 flex items-end justify-center">
                      <span className="text-xs text-gray-700">{date}</span>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <div className="pt-6">
          <div className="flex items-center justify-center">
            <span className="text-sm font-bold text-center">
              Calculation Information
            </span>
          </div>
          <div className="mt-2 ">
            <table className="min-w-full divide-y divide-gray-300 mt-4 border-collapse ">
              <thead>
                <tr className="bg-primary-600">
                  <th
                    scope="col"
                    colSpan={2}
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-4 w-[40%]"
                  >
                    Items
                  </th>
                  <th
                    scope="col"
                    className="hidden py-3.5 px-3 text-right text-sm font-semibold text-white sm:table-cell  w-[20%]"
                  >
                    Units
                  </th>
                  <th
                    scope="col"
                    className="hidden py-3.5 px-3 text-right text-sm font-semibold text-white sm:table-cell w-[20%]"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-white sm:pr-4 w-[20%]"
                  >
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {bill?.bills?.map?.((billX) => (
                  <>
                    <tr className="border-b border-gray-200 bg-yellow-50">
                      <td
                        colSpan={5}
                        className="py-3 pl-4 pr-3 text-sm text-gray-600 sm:pl-4"
                      >
                        {moment(billX?.previousDate).format(dateFormat)} -{" "}
                        {moment(billX?.currentDate).format(dateFormat)} (
                        {numeral(billX?.numberOfDays).format("0,0")} days)
                      </td>
                    </tr>
                    {billX?.billItems?.map((item, idx) => (
                      <tr key={idx} className="border-b border-gray-200">
                        <td
                          colSpan={2}
                          className="py-4 pl-4 pr-3 text-sm sm:pl-4"
                        >
                          <div className="font-medium text-gray-900">
                            {item.title}
                          </div>
                        </td>
                        <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                          {item.unit}
                        </td>
                        <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                          {numeral(item.unitCost).format("0,0.0000")}
                        </td>
                        <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-4">
                          {numeral(item.cost).format("0,0.00")}
                        </td>
                      </tr>
                    ))}
                  </>
                ))}
                <tr className="border-b border-transparent">
                  <td colSpan={5} className="h-6" />
                </tr>
              </tbody>
              <tfoot>
                <tr className="">
                  <th className="relative" rowSpan={3} colSpan={2}>
                    <div className="border border-gray-300 inset-0 absolute grid gap-1 p-4 py-3">
                      <div className="inline-flex space-x-2">
                        <span className="text-sm">Customer Name:</span>
                        <div className="text-sm font-light text-gray-700">
                          {bill?.fullName || "N/A"}
                        </div>
                      </div>
                      <div className="inline-flex space-x-2">
                        <span className="text-sm">Bill Date:</span>
                        <div className="text-sm font-light text-gray-700">
                          {moment(bill?.createdAt).format(dateFormat)}
                        </div>
                      </div>
                      <div className="inline-flex space-x-2">
                        <span className="text-sm">Account No:</span>
                        <div className="text-sm font-light text-gray-700">
                          {bill?.accountNumber || "N/A"}
                        </div>
                      </div>
                      <div className="inline-flex space-x-2">
                        <span className="text-sm">Bill Number:</span>
                        <div className="text-sm font-light text-gray-700">
                          {bill?.code || "N/A"}
                        </div>
                      </div>
                      <div className="inline-flex space-x-2">
                        <span className="text-sm">Amount Payable:</span>
                        <div className="text-sm font-light text-gray-700">
                          GHS {numeral(bill?.totalAmountDue).format("0,0.00")}
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="relative" rowSpan={3}>
                    <div className="flex inset-0 absolute items-center justify-center">
                      <QRCodeSVG
                        value={bill?.code}
                        size={120}
                        imageSettings={{
                          src: require("assets/logo.png"),
                          height: 20,
                          width: 20,
                          excavate: true,
                        }}
                      />
                    </div>
                  </th>
                  <th
                    scope="row"
                    className="pl-4 pr-3  py-3 text-left text-sm font-medium text-gray-700"
                  >
                    Current Bill
                  </th>
                  <td className="pl-3 pr-4 py-3 text-right text-sm text-gray-500">
                    GHS {numeral(bill?.currentBillAmount).format("0,0.00")}
                  </td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    className="pl-4 pr-3 py-3 text-left text-sm font-medium text-gray-700"
                  >
                    Previous Balance
                  </th>
                  <td className="pl-3 pr-4 py-3 text-right text-sm text-gray-500">
                    GHS {numeral(bill?.outstandingAmount).format("0,0.00")}
                  </td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    className="bg-primary-600 pl-4 pr-3 py-5 text-left text-sm font-semibold text-white"
                  >
                    Amount Payable
                  </th>
                  <td className="bg-primary-600 pl-3 pr-4 py-5 text-right text-sm font-semibold text-white sm:pr-4">
                    GHS {numeral(bill?.totalAmountDue).format("0,0.00")}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    );
  }
);

export default BillView;
