import { useQuery, gql } from "@apollo/client"

const GET_READING_SET = gql`
  query GetReadingSet($id: ID!) {
    readingSet: getReadingSet(id: $id) {
      _id
      code
      name
    }
  }
`
const ReadingSetsBreadcrumb = ({ id }: { id: string }) => {
  const { data } = useQuery(GET_READING_SET, {
    variables: {
      id
    },
    notifyOnNetworkStatusChange: false
  })
  return (
    <>
      {data?.readingSet?.code || "Reading Set"}
    </>
  )
}

export default ReadingSetsBreadcrumb;