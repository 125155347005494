import { useEffect } from "react";
import { wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, DisapproveDisconnectionServiceOrderForm } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_SERVICE_ORDER } from "./view";

const DISAPPROVE_SERVICE_ORDER = gql`
  mutation DisapproveDisconnectionServiceOrder($id: ID!, $reason: String!) {
    disapproveDisconnectionServiceOrder(id: $id, reason: $reason) {
      _id
    }
  }
`;

export default function DisapproveDisconnectionServiceOrderContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading: fetchLoading } = useQuery(GET_SERVICE_ORDER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [disapproveDisconnectionServiceOrder, { loading }] =
    useMutation(DISAPPROVE_SERVICE_ORDER);

  const form = useFormik({
    initialValues: {
      reason: "",
    },
    onSubmit: async (values) => {
      await disapproveDisconnectionServiceOrder({
        variables: {
          id: searchParams.id,
          ...values,
        },
      }).then(({ data }) => {
        if (data.disapproveDisconnectionServiceOrder._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Service Order Disapproved Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not  disapprove Service Order",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      reason: data?.readingSet?.reason || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.readingSet, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={fetchLoading}
      title='Disapprove Service Order'
      size='4xl'
      description='Provide reasons for disapproving service order'

      renderActions={() => (
        <>
          <button
            type='button'
            disabled={loading}
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading
              ? "Disapproving Service Order..."
              : "Disapprove Service Order"}
          </button>
        </>
      )}
    >
      {data?.serviceOrder?._id && (
        <DisapproveDisconnectionServiceOrderForm form={form} />
      )}
    </Modal>
  );
}
