import { FC } from "react";
import { Avatar } from "components/core";
import { wrapImage } from "utils";
import moment from "moment";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import _ from "lodash";
import { MapProvider, Marker } from "react-map-gl";
import Map from "react-map-gl";
import config from "config";



const CustomerView: FC<any> = ({ customer }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div className=''>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-6 font-normal text-gray-900 dark:text-gray-100'>
              Customer Information
            </h3>
            <p className='mt-1 text-xs text-gray-500'>Details about customer</p>
          </div>
          <div />
        </div>
        <div className='mt-6'>
          <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
            {customer?.customerType ===
              "Individual" && (
                <div className=''>
                  <span className='text-xs font-light'>Customer Information</span>
                  <div className='grid grid-cols-4 gap-6 mt-2'>
                    <div className='row-span-2 items-center justify-center flex flex-col'>
                      <Avatar
                        alt={
                          customer?.customerRepresentative
                            .fullName || "N A"
                        }
                        src={
                          customer?.customerRepresentative
                            .profileImageUrl || ""
                        }
                        size='lg'
                      />

                    </div>
                    <div className=''>
                      <span className='block text-sm font-light text-gray-700'>
                        Title
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {customer?.customerRepresentative
                          ?.title || "N/A"}
                      </div>
                    </div>
                    <div className='col-span-2'>
                      <span className='block text-sm font-light text-gray-700'>
                        Full Name
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {customer?.customerRepresentative
                          ?.fullName || "N/A"}
                      </div>
                    </div>
                    <div className=''>
                      <span className='block text-sm font-light text-gray-700'>
                        Nationality
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {customer?.customerRepresentative
                          ?.nationality || "N/A"}
                      </div>
                    </div>
                    <div className=''>
                      <span className='block text-sm font-light text-gray-700'>
                        Date of Birth
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {moment(
                          customer?.customerRepresentative
                            ?.dateOfBirth
                        ).format(dateFormat)}
                      </div>
                    </div>
                    <div className=''>
                      <span className='block text-sm font-light text-gray-700'>
                        Gender
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {customer?.customerRepresentative
                          ?.gender || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {customer?.customerType ===
              "Organization" && (
                <div className=''>
                  <span className='text-xs font-light'>
                    Organization Information
                  </span>
                  <div className='grid grid-cols-4 gap-6 mt-2'>
                    <div className='col-span-2'>
                      <span className='block text-sm font-light text-gray-700'>
                        Name
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {customer?.customerOrganization?.name ||
                          "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {customer?.customer?.customerType ===
              "Individual" && (
                <div className='pt-6'>
                  <span className='text-xs font-light'>Contact Information</span>
                  <div className='grid grid-cols-4 gap-6 mt-2'>
                    <div className=''>
                      <span className='block text-sm font-light text-gray-700'>
                        Phone Number
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {customer?.customerRepresentative
                          ?.phoneNumber || "N/A"}
                      </div>
                    </div>
                    <div className='col-span-2'>
                      <span className='block text-sm font-light text-gray-700'>
                        Email Address
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {customer?.customerRepresentative
                          ?.emailAddress || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {customer?.customerType ===
              "Organization" && (
                <div className='pt-6'>
                  <span className='text-xs font-light'>
                    Representative Information
                  </span>
                  <div className='grid grid-cols-4 gap-6 mt-2'>
                    <div className='col-start-1'>
                      <span className='block text-sm font-light text-gray-700'>
                        Title
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {customer?.customerRepresentative
                          ?.title || "N/A"}
                      </div>
                    </div>
                    <div className='col-span-2'>
                      <span className='block text-sm font-light text-gray-700'>
                        Full Name
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {customer?.customerRepresentative
                          ?.fullName || "N/A"}
                      </div>
                    </div>
                    <div className=''>
                      <span className='block text-sm font-light text-gray-700'>
                        Nationality
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {customer?.customerRepresentative
                          ?.nationality || "N/A"}
                      </div>
                    </div>
                    <div className=''>
                      <span className='block text-sm font-light text-gray-700'>
                        Phone Number
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {customer?.customerRepresentative
                          ?.phoneNumber || "N/A"}
                      </div>
                    </div>
                    <div className=''>
                      <span className='block text-sm font-light text-gray-700'>
                        Email Address
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {customer?.customerRepresentative
                          ?.emailAddress || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
      {/* {JSON.stringify(customer, null, 2)} */}
      {/* Service Information for Meter */}
      <div className='pt-4'>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-6 font-normal text-gray-900 dark:text-gray-100'>
              Service Information
            </h3>
            <p className='mt-1 text-xs text-gray-500'>Details about service</p>
          </div>
          <div />
        </div>
        <div className='mt-6'>
          <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
            <div className=''>
              <span className='text-xs font-light'>Service Information</span>
              <div className='grid grid-cols-4 gap-6 mt-2'>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Account Number
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {customer?.accountCode || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Service Point Number (SPN)
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {customer?.code || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Meter Number
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {customer?.meterCode || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Tariff Class
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {customer?.tariffClass?.name || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Customer Type
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {customer?.customerType || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Contract Status
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {customer?.status || "N/A"}
                  </div>
                </div>

              </div>
            </div>
            <div className='pt-6'>
              <span className='text-xs font-light'>Meter Information</span>
              <div className='grid grid-cols-4 gap-6 mt-2'>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Brand
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {customer?.meter?.brand?.name || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Model
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {customer?.meter?.model?.name || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Model Type
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {customer?.meter?.modelType || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Meter Phase
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {customer?.meter?.modelMeta?.phase || "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Customer Identity Secr=tion */}
      <div className='pt-4'>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-6 font-normal text-gray-900 dark:text-gray-100'>
              Customer Identity Information
            </h3>
            <p className='mt-1 text-xs text-gray-500'>
              Details about identity of customer
            </p>
          </div>
          <div />
        </div>
        <div className='mt-6'>
          <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
            <>
              {customer?.customerRepresentative
                ?.hasGhanaCard ? (
                <div className=''>
                  <span className='text-xs font-light'>
                    Representative Identity Information
                  </span>
                  <div className='grid grid-cols-8 gap-6 mt-2'>
                    <div className='col-span-2'>
                      <span className='block text-sm font-light text-gray-700'>
                        Ghana Card Number
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {customer?.customerRepresentative
                          ?.ghanaCardNumber || "N/A"}
                      </div>
                    </div>
                    <div className='col-span-2'>
                      <span className='block text-sm font-light text-gray-700'>
                        Ghana Card Issue Date
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {moment(
                          customer?.customerRepresentative
                            ?.ghanaCardIssueDate
                        ).format(dateFormat)}
                      </div>
                    </div>
                    <div className='col-span-2'>
                      <span className='block text-sm font-light text-gray-700'>
                        Ghana Card Expiry Date
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {moment(
                          customer?.customerRepresentative
                            ?.ghanaCardExpiryDate
                        ).format(dateFormat)}
                      </div>
                    </div>
                    <div className='col-span-4'>
                      <span className='block text-sm font-light text-gray-700'>
                        Ghana Card Front Image
                      </span>
                      <div className='mt-2'>
                        {wrapImage(
                          <img
                            src={
                              customer?.customerRepresentative?.ghanaCardFrontImageUrl
                            }
                            alt={"front"}
                            className='w-full h-64 text-xs'
                          />
                        )}
                      </div>
                    </div>
                    <div className='col-span-4'>
                      <span className='block text-sm font-light text-gray-700'>
                        Ghana Card Back Image
                      </span>
                      <div className='mt-2'>
                        {wrapImage(
                          <img
                            src={
                              customer?.customerRepresentative?.ghanaCardBackImageUrl
                            }
                            alt={"back"}
                            className='w-full h-64 text-xs'
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className=''>
                  <span className='text-xs font-light'>
                    Representative Identity Information
                  </span>
                  <div className='grid grid-cols-8 gap-6 mt-2'>
                    <div className='col-span-2'>
                      <span className='block text-sm font-light text-gray-700'>
                        ID Number
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {customer?.customerRepresentative
                          ?.identityCardNumber || "N/A"}
                      </div>
                    </div>
                    <div className='col-span-2'>
                      <span className='block text-sm font-light text-gray-700'>
                        ID Type
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {_.startCase(
                          customer?.customerRepresentative
                            ?.identityCardType
                        ) || "N/A"}
                      </div>
                    </div>
                    <div className='col-span-2'>
                      <span className='block text-sm font-light text-gray-700'>
                        ID Issue Date
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {moment(
                          customer?.customerRepresentative
                            ?.identityCardIssueDate
                        ).format(dateFormat)}
                      </div>
                    </div>
                    {customer?.customerRepresentative
                      ?.identityCardType !== "VotersIdentificationCard" && (
                        <div className='col-span-2'>
                          <span className='block text-sm font-light text-gray-700'>
                            ID Expiry Date
                          </span>
                          <div className='mt-1 block w-full sm:text-sm'>
                            {moment(
                              customer?.customerRepresentative
                                ?.identityCardExpiryDate
                            ).format(dateFormat)}
                          </div>
                        </div>
                      )}
                    <div className='col-span-4'>
                      <span className='block text-sm font-light text-gray-700'>
                        ID Front Image
                      </span>
                      <div className='mt-2'>
                        {wrapImage(
                          <img
                            src={
                              customer?.customerRepresentative?.identityCardFrontImageUrl
                            }
                            alt={"front"}
                            className='w-full h-64 text-xs'
                          />
                        )}
                      </div>
                    </div>
                    {customer?.customerRepresentative
                      ?.identityCardType === "DriversLicense" && (
                        <div className='col-span-4'>
                          <span className='block text-sm font-light text-gray-700'>
                            ID Back Image
                          </span>
                          <div className='mt-2'>
                            {wrapImage(
                              <img
                                src={
                                  customer?.customer
                                    ?.representative?.identityCardBackImageUrl
                                }
                                alt={"back"}
                                className='w-full h-64 text-xs'
                              />
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              )}
            </>
            {customer?.customer?.customerType ===
              "Organization" && (
                <div className='pt-6'>
                  <span className='text-xs font-light'>
                    Organization Identity Information
                  </span>
                  <div className='grid grid-cols-6 gap-6 mt-2'>
                    <div className='col-span-2'>
                      <span className='block text-sm font-light text-gray-700'>
                        Tax Identification Number
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {customer?.customer?.organization
                          ?.taxIdentificationNumber || "N/A"}
                      </div>
                    </div>
                    <div className='col-span-2'>
                      <span className='block text-sm font-light text-gray-700'>
                        Organization Registration Number
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {customer?.customer?.organization
                          ?.organizationRegistrationNumber || "N/A"}
                      </div>
                    </div>
                    <div className='col-span-2'>
                      <span className='block text-sm font-light text-gray-700'>
                        Organization Registration Date
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {moment(
                          customer?.customer?.organization
                            ?.organizationRegistrationDate
                        ).format(dateFormat)}
                      </div>
                    </div>
                    <div className='col-span-3'>
                      <span className='block text-sm font-light text-gray-700'>
                        Certificate of Incorporation
                      </span>
                      <div className='mt-2'>
                        {wrapImage(
                          <img
                            src={
                              customer?.customer?.organization
                                ?.certificateOfIncorporationDocumentUrl
                            }
                            alt={"Certificate of Incorporation"}
                            className='w-full h-64 text-xs'
                          />
                        )}
                      </div>
                    </div>
                    <div className='col-span-3'>
                      <span className='block text-sm font-light text-gray-700'>
                        Business Regsitration Document
                      </span>
                      <div className='mt-2'>
                        {wrapImage(
                          <img
                            src={
                              customer?.customer?.organization
                                ?.organizationRegistrationDocumentUrl
                            }
                            alt={"Business Registration Document"}
                            className='w-full h-64 text-xs'
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>

      {/* Property Information */}

      <div className='pt-4'>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-6 font-normal text-gray-900 dark:text-gray-100'>
              Property Information
            </h3>
            <p className='mt-1 text-xs text-gray-500'>Details about property</p>
          </div>
          <div />
        </div>
        <div className='mt-6'>
          <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
            <div className=''>
              <span className='text-xs font-light'>Owner Information</span>
              <div className='grid grid-cols-4 gap-6 mt-2'>
                <div className='col-span-2'>
                  <span className='block text-sm font-light text-gray-700'>
                    Full name
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {customer?.property?.owner?.fullName ||
                      "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Phone Number
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {customer?.property?.owner?.phoneNumber ||
                      "N/A"}
                  </div>
                </div>
              </div>
            </div>
            <div className='pt-6'>
              <span className='text-xs font-light'>Property Information</span>
              <div className='grid grid-cols-2 gap-6 mt-2'>

                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Property Code
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {customer?.propertyCode || "N/A"}
                  </div>
                </div>


                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Ghana Post Address
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {customer?.property?.ghanaPostAddress ||
                      "N/A"}
                  </div>
                </div>
                <div className="">
                  <span className='block text-sm font-light text-gray-700'>
                    Address
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {customer?.property?.streetName || "N/A"}
                  </div>
                </div>
                <div className=''>
                  <span className='block text-sm font-light text-gray-700'>
                    Closest Landmark
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {customer?.property?.closestLandmark || "N/A"}
                  </div>
                </div>


              </div>
            </div>

            <div className='pt-6'>
              <span className='text-xs font-light'>Premise Information</span>
              <div className='grid grid-cols-4 gap-6 mt-2'>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Premise Type
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {customer?.property?.premiseType?.name ||
                      "N/A"}
                  </div>
                </div>
                <div className=''>
                  <span className='block text-sm font-light text-gray-700'>
                    Premise Category
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {customer?.property?.premiseCategory?.name ||
                      "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Activity
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {customer?.activity?.name || "N/A"}
                  </div>
                </div>
                <div className=''>
                  <span className='block text-sm font-light text-gray-700'>
                    Sub Activity
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {customer?.subActivity?.name ||
                      "N/A"}
                  </div>
                </div>
              </div>
            </div>
            <div className='pt-6 grid grid-cols-2 gap-6'>
              <div>
                <span className='text-xs font-light'>
                  Site Plan Information
                </span>
                {customer?.property?.sitePlanDocumentUrl ? (
                  <div className='mt-2'>
                    {wrapImage(
                      <img
                        src={
                          customer?.property?.sitePlanDocumentUrl
                        }
                        alt={customer?.property?.structureNumber}
                        className='w-full h-64 text-xs'
                      />
                    )}
                  </div>
                ) : (
                  <div className='border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600'>
                    Site plan not provided
                  </div>
                )}
              </div>
              <div>
                <span className='text-xs font-light'>Map Information</span>
                <div className='mt-2 w-full h-64 rounded-md overflow-hidden'>
                  <MapProvider>
                    <Map
                      id='service-location'
                      initialViewState={{
                        longitude:
                          customer?.property?.geoLocation
                            ?.coordinates?.[0] || -0.234361,
                        latitude:
                          customer?.property?.geoLocation
                            ?.coordinates?.[1] || 5.667032,
                        zoom: 15,
                      }}
                      mapStyle='mapbox://styles/mapbox/streets-v12'
                      mapboxAccessToken={config.mapbox.token}
                    >
                      <Marker
                        longitude={
                          customer?.property?.geoLocation
                            ?.coordinates?.[0] || -0.234361
                        }
                        latitude={
                          customer?.property?.geoLocation
                            ?.coordinates?.[1] || 5.667032
                        }
                      >
                        <img
                          className='w-6 h-6'
                          src={require("assets/logo.png")}
                          alt={"pin"}
                        />
                      </Marker>
                    </Map>
                  </MapProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='pt-4'>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-6 font-normal text-gray-900 dark:text-gray-100'>
              Geospatial Information
            </h3>
            <p className='mt-1 text-xs text-gray-500'>Geospatioal Details about property</p>
          </div>
          <div />
        </div>
        <div className='mt-6'>
          <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>

            <div className=''>
              <span className='text-xs font-light'>Geospatial Information</span>
              <div className='grid grid-cols-4 gap-6 mt-2'>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Region
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {customer?.regionName || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    District
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {customer?.districtName || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Block
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {customer?.blockName || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Round
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {customer?.roundName || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Plot
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {customer?.plotCode || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Structure Number
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {customer?.structureNumber || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Northing (Y)
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {customer?.cartesianLocation?.northing || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Easting (X)
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {customer?.cartesianLocation?.northing || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Zone
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {customer?.property?.zone || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Geo Code
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {customer?.geoCode || "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Service Information */}
      <div className='pt-4'>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-6 font-normal text-gray-900 dark:text-gray-100'>
              Service Information
            </h3>
            <p className='mt-1 text-xs text-gray-500'>
              Details about service being requested for
            </p>
          </div>
          <div />
        </div>
        <div className='mt-6'>
          <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
            <div className="grid grid-cols-3 gap-6 mt-2">
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Energy Certification Issuer Name
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {customer?.energyCertificateNumber || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Energy Certification Phone Number
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {customer?.energyCertificateNumber || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Energy Certification Issuer ID
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {customer?.energyCertificateNumber || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Energy Certificate Number
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {customer?.energyCertificateNumber || "N/A"}
                </div>
              </div>
              <div className="col-span-2">
                <span className="block text-sm font-light text-gray-700">
                  Energy Certificate Document
                </span>
                <div className="mt-1 block sm:text-sm">
                  {customer?.energyCertificateDocumentUrl ? (
                    <>
                      {wrapImage(
                        <img
                          src={customer?.energyCertificateDocumentUrl}
                          alt={customer?.energyCertificateNumber}
                          className="w-full  h-64 object-cover object-top text-xs"
                        />
                      )}
                    </>
                  ) : (
                    <div className="border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600">
                      Energy certificate document not provided
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerView;
