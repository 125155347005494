import { useEffect } from "react";
import { classNames, wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { ConfigureItineraryForm, Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";

const GET_ITINERARY = gql`
  query GetItinerary($id: ID!) {
    itinerary: getItinerary(id: $id) {
      _id
      meta {
        cycleStartDate
        cycleEndDate
        defaultReader {
          _id
          lastName
          firstName
          profileImageUrl
          phoneNumber
          emailAddress
          ghanaCardNumber
        }
      }
    }
  }
`;

const CONFIGURE_ITINERARY = gql`
  mutation ConfigureItinerary(
    $id: ID!
    $defaultReader: ID!
    $cycleStartDate: PositiveInt!
    $cycleEndDate: PositiveInt!
  ) {
    configureItinerary(
      id: $id
      defaultReader: $defaultReader
      cycleStartDate: $cycleStartDate
      cycleEndDate: $cycleEndDate
    ) {
      _id
    }
  }
`;

export default function ConfigureItineraryContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_ITINERARY, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [configureItinerary, { loading: loadingConfigure }] = useMutation(CONFIGURE_ITINERARY);

  const form = useFormik({
    initialValues: {
      reader: null,
      cycleStartDate: 1,
      cycleEndDate: 20,
    },
    onSubmit: async (values) => {
      await configureItinerary({
        variables: {
          id: searchParams.id,
          ...values,
          defaultReader: (values.reader as any)?._id,
        },
      }).then(({ data }) => {
        if (data.configureItinerary._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Itinerary Configured Successfully",
            })
          );
          refetch?.();
          form.resetForm()
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not configure Itinerary",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      reader: data?.itinerary?.meta?.defaultReader || null,
      cycleEndDate: data?.itinerary?.meta?.cycleEndDate || null,
      cycleStartDate: data?.itinerary?.meta?.cycleStartDate || null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.itinerary, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Configure Itinerary"
      loading={loading}
      description="Provide the details to configure reading set"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loadingConfigure || !form.values.reader}
            className={classNames(
              loadingConfigure || !form.values.reader
                ? "cursor-not-allowed"
                : "cursor-pointer",
              "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            )}
            onClick={wrapClick(form.handleSubmit)}
          >
            {loadingConfigure ? "Configuring Itinerary..." : "Configure Itinerary"}
          </button>
        </>
      )}
    >
      {data?.itinerary?._id && <ConfigureItineraryForm form={form} />}
    </Modal>
  );
}
