import { useEffect } from 'react'
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, ScheduleReadingSetForm } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import moment from 'moment-business-days';
import lodash from "lodash";

const GET_READING_SET = gql`
  query GetReadingSet($id: ID!) {
    readingSet: getReadingSet(id: $id) {
      _id
      meta {
        cycleStartDate
        cycleEndDate
      }
    }
  }
`

const UPDATE_READING_SET = gql`
  mutation ScheduleReadingSet(
    $id: ID!
    $cycleStartDate: Date
    $cycleEndDate: Date
  ) {
    scheduleReadingSet(
      id: $id
      startDate: $cycleStartDate
      endDate: $cycleEndDate
    ) {
      _id
    }
  }
`

export default function ScheduleReadingSetContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_READING_SET, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });
  const [scheduleReadingSet] = useMutation(UPDATE_READING_SET)

  const form = useFormik({
    initialValues: {
      cycleStartDate: moment().format("YYYY-MM-DD"),
      cycleEndDate: moment().format("YYYY-MM-DD"),
      numberOfDays: 0,
    },
    onSubmit: async (values) => {
      await scheduleReadingSet({
        variables: {
          id: searchParams.id,
          ...values
        }
      }).then(({ data }) => {
        if (data.scheduleReadingSet._id) {
          toast(JSON.stringify({ type: "success", title: "Schedule Edited Successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not edit Schedule" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  useEffect(() => {
    form.setValues({
      cycleStartDate: data?.readingSet?.meta?.cycleStartDate ? moment(data.readingSet.meta.cycleStartDate).format("YYYY-MM-DD") : "",
      cycleEndDate: data?.readingSet?.meta?.cycleEndDate ? moment(data.readingSet.meta.cycleEndDate).format("YYYY-MM-DD") : "",
      numberOfDays: (data?.readingSet?.meta?.cycleStartDate && data?.readingSet?.meta?.cycleEndDate) ? moment(data.readingSet.meta.cycleEndDate).endOf("day").businessDiff(moment(data.readingSet.meta.cycleStartDate).startOf("day")) : 0,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.readingSet, searchParams.id, open])

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Schedule Reading Set"
      description="Provide start and end dates to update schedule of the reading set."
      loading={loading}
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={form.isSubmitting}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {form.isSubmitting ? "Editing Schedule..." : "Edit Schedule"}
          </button>
        </>
      )}>
      {data?.readingSet?._id && (
        <ScheduleReadingSetForm
          form={form}
          year={searchParams.year || moment().year()}
          month={lodash.isNumber(searchParams.month) ? searchParams.month : moment().month()}
        />
      )}
    </Modal>
  )
}