import BillingPreviewForm from "./billing-preview";
import CustomerInfoForm from "./customer-info-form";
import CustomerInfoSummary from "./customer-info-summary";
import ReadingInfoForm from "./reading-info-form";
import ReadingInfoSummary from "./reading-info-summary";
import SummaryPage from "./summary";

const formSteps = [
  {
    name: "Customer Info",
    description: "Information about agent to attend to service order.",
    accessor: "customerInfo",
    FormComponent: CustomerInfoForm,
    SummaryComponent: CustomerInfoSummary,
    href: 0,
  },
  {
    name: "Reading Info",
    description: "Information about user to approve to service order.",
    accessor: "readingInfo",
    FormComponent: ReadingInfoForm,
    SummaryComponent: ReadingInfoSummary,
    href: 1,
  },
  {
    name: "Preview",
    description: "Information about user to approve to service order.",
    accessor: "previewInfo",
    FormComponent: SummaryPage,
    SummaryComponent: ReadingInfoSummary,
    href: 2,
  },
  {
    name: "Regularization Bill",
    description: "Information about user to approve to service order.",
    accessor: "billingInfo",
    FormComponent: BillingPreviewForm,
    SummaryComponent: ReadingInfoSummary,
    href: 3,
  },
];

export default formSteps;
