import { wrapClick, withPermissions } from "utils";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { ReadingSetView, Modal } from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";

const GET_ITINERARY = gql`
  query GetReadingSet($id: ID!) {
    readingSet: getReadingSet(id: $id) {
      _id
      code
      name
      itinerary {
        _id
      }
      itineraryCode
      itineraryDescription
      region {
        _id
      }
      regionCode
      regionName
      district {
        _id
      }
      districtCode
      districtName
      block {
        _id
      }
      blockCode
      blockName
      round {
        _id
      }
      roundCode
      roundName
      plot {
        _id
      }
      plotCode
      community
      meta {
        cycleStartDate
        cycleEndDate
        propertiesCount
        customersCount
        propertiesReadCount
        customersReadCount
        successCustomerReadCount
        anomalyCustomerReadCount
        exceptionCustomerReadCount
        noTraceCustomerReadCount
        totalBilled
        totalConsumption
      }
      reader {
        _id
        code
        lastName
        firstName
        profileImageUrl
        phoneNumber
        emailAddress
        ghanaCardNumber
      }
      month
      year
      status
      createdAt
      updatedAt
    }
  }
`;

export default function ViewReadingSetContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  const { pollInterval } = useReactiveVar(currentConfigVar);

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_ITINERARY, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
    pollInterval,
  });
  const scheduleItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "schedule",
      }),
    });
  };
  const assignItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "assign",
      }),
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Reading Set Information"
      description="Details of reading set are shown below"
      size="4xl"
      loading={loading}
      renderActions={() => (
        <>
          {data?.readingSet?.status !== "Completed" && (
            <>
              {withPermissions([
                "*:*",
                "reading-sets:*",
                "reading-sets:schedule",
              ])(
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={wrapClick(scheduleItem)}
                >
                  Reschedule
                </button>
              )}
              {withPermissions([
                "*:*",
                "reading-sets:*",
                "reading-sets:assign",
              ])(
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={wrapClick(assignItem)}
                >
                  {data?.readingSet?.reader ? "Reassign" : "Assign"}
                </button>
              )}
            </>
          )}
        </>
      )}
    >
      <ReadingSetView readingSet={data?.readingSet} />
    </Modal>
  );
}
