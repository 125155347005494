import { gql, useQuery } from '@apollo/client';
import { BlockView, Modal } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { FC } from 'react';

const GET_BLOCK = gql`
  query GetBlock($id: ID!) {
    block: getBlock(id: $id) {
      _id
      code
      name
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      meta {
        roundsCount
        plotsCount
        itinerariesCount
        propertiesCount
        customersCount
      }
      createdAt
      updatedAt
    }
  }
`

interface ViewBlockContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
}

const ViewBlockContainer: FC<ViewBlockContainerProps> = ({ open, setOpen }) => {
  
  const searchParams = useSearch<LocationGenerics>();
  const { data,  loading } = useQuery(GET_BLOCK, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Block Information"
      loading={loading}
      description="Details of block are shown below"
    >
      <BlockView
        block={data?.block}
      />
    </Modal>
  )
}

export default ViewBlockContainer;