import { FC } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { Disclosure } from "@headlessui/react";
import moment from "moment";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import numeral from "numeral";
import { QRCodeSVG } from "qrcode.react";
import lodash from "lodash";

interface BillInstallmentViewProps {
  bills: any[];
}

const BillInstallmentView: FC<BillInstallmentViewProps> = ({ bills }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className='flex-1 flex flex-col space-y-6 divide-y divide-gray-200 p-6 overflow-y-auto'>
      {bills?.map((bill, idx) => (
        <Disclosure
          as='div'
          defaultOpen={true}
          key={idx}
          className={idx === 0 ? " py-4" : " py-4"}
        >
          {({ open }) => (
            <>
              <Disclosure.Button
                as='div'
                className='flex justify-between items-center cursor-pointer'
              >
                <div>
                  <h3 className='text-md leading-6 font-normal text-gray-900 dark:text-gray-100'>
                    {moment(bill.previousReadingDate).format(dateFormat)} -{" "}
                    {moment(bill.currentReadingDate).format(dateFormat)}
                  </h3>
                  <p className='mt-1 text-xs text-gray-500'>
                    {bill.previousReadingValue} - {bill.currentReadingValue}
                  </p>
                </div>
                <div>
                  {open ? (
                    <ChevronUpIcon className='h-6 w-6 text-gray-400 dark:text-gray-500' />
                  ) : (
                    <ChevronDownIcon className='h-6 w-6 text-gray-400 dark:text-gray-500' />
                  )}
                </div>
              </Disclosure.Button>
              <Disclosure.Panel as='div' className='mt-6'>
                <div className='border-gray-300 border p-4 border-dashed bg-gray-50'>
                  <div className='grid grid-cols-4 gap-6'>
                    <div>
                      <span className='block text-sm font-light text-gray-700'>
                        Previous Reading Date
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {moment(bill?.previousReadingDate).format(dateFormat)}
                      </div>
                    </div>

                    <div>
                      <span className='block text-sm font-light text-gray-700'>
                        Previous Reading Value
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {numeral(bill?.previousReadingValue || 0).format("0,0")}{" "}
                        KWh
                      </div>
                    </div>

                    <div>
                      <span className='block text-sm font-light text-gray-700'>
                        Current Reading Date
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {moment(bill?.currentReadingDate).format(dateFormat)}
                      </div>
                    </div>

                    <div>
                      <span className='block text-sm font-light text-gray-700'>
                        Current Reading Value
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {numeral(bill?.currentReadingValue || 0).format("0,0")}{" "}
                        KWh
                      </div>
                    </div>

                    <div>
                      <span className='block text-sm font-light text-gray-700'>
                        Previous Reading Type
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {bill?.previousReadingType}
                      </div>
                    </div>

                    <div>
                      <span className='block text-sm font-light text-gray-700'>
                        Current Reading Type
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {bill?.currentReadingType}
                      </div>
                    </div>

                    <div>
                      <span className='block text-sm font-light text-gray-700'>
                        Consumption
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {numeral(bill?.totalConsumption || 0).format("0,0")} KWh
                      </div>
                    </div>

                    <div>
                      <span className='block text-sm font-light text-gray-700'>
                        Bill Period
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {bill?.billPeriod || 0} Days
                      </div>
                    </div>
                  </div>
                  <table className='min-w-full divide-y divide-gray-300 mt-4 border-collapse '>
                    <thead>
                      <tr className='bg-primary-600'>
                        <th
                          scope='col'
                          colSpan={2}
                          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-4 w-[40%]'
                        >
                          Items
                        </th>
                        <th
                          scope='col'
                          className='hidden py-3.5 px-3 text-right text-sm font-semibold text-white sm:table-cell  w-[20%]'
                        >
                          Units
                        </th>
                        <th
                          scope='col'
                          className='hidden py-3.5 px-3 text-right text-sm font-semibold text-white sm:table-cell w-[20%]'
                        >
                          Price
                        </th>
                        <th
                          scope='col'
                          className='py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-white sm:pr-4 w-[20%]'
                        >
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {bill?.bills?.map?.((billX: any) => (
                        <>
                          <tr className='border-b border-gray-200 bg-yellow-50'>
                            <td
                              colSpan={5}
                              className='py-3 pl-4 pr-3 text-sm text-gray-600 sm:pl-4'
                            >
                              {moment(billX?.previousDate).format(dateFormat)} -{" "}
                              {moment(billX?.currentDate).format(dateFormat)} (
                              {numeral(billX?.numberOfDays).format("0,0")} days)
                            </td>
                          </tr>
                          {billX?.billItems?.map(
                            (
                              item: {
                                title: string;
                                unit: string;
                                unitCost: any;
                                cost: any;
                              },
                              idx: string
                            ) => (
                              <tr
                                key={idx}
                                className='border-b border-gray-200'
                              >
                                <td
                                  colSpan={2}
                                  className='py-4 pl-4 pr-3 text-sm sm:pl-4'
                                >
                                  <div className='font-medium text-gray-900'>
                                    {item.title}
                                  </div>
                                </td>
                                <td className='hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell'>
                                  {item.unit}
                                </td>
                                <td className='hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell'>
                                  {numeral(item.unitCost).format("0,0.0000")}
                                </td>
                                <td className='py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-4'>
                                  {numeral(item.cost).format("0,0.00")}
                                </td>
                              </tr>
                            )
                          )}
                        </>
                      ))}
                      <tr className='border-b border-transparent'>
                        <td colSpan={5} className='h-6' />
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr className=''>
                        <th className='relative' rowSpan={3} colSpan={2}></th>
                        <th className='relative' rowSpan={3}></th>
                        <th
                          scope='row'
                          className='pl-4 pr-3  py-3 text-left text-sm font-medium text-gray-700'
                        >
                          Current Bill
                        </th>
                        <td className='pl-3 pr-4 py-3 text-right text-sm text-gray-500'>
                          GHS {numeral(bill?.totalBillAmount).format("0,0.00")}
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope='row'
                          className='bg-primary-600 pl-4 pr-3 py-5 text-left text-sm font-semibold text-white'
                        >
                          Amount Payable
                        </th>
                        <td className='bg-primary-600 pl-3 pr-4 py-5 text-right text-sm font-semibold text-white sm:pr-4'>
                          GHS {numeral(bill?.totalBillAmount).format("0,0.00")}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>

                <div
                  // ref={ref}
                  className='hidden print:flex aspect-[210/297] w-full flex-col space-y-5 overflow-hidden'
                >
                  <div className='grid grid-cols-7 gap-2 text-sm'>
                    <div className='col-span-4'>
                      <div className='grid grid-cols-4 gap-1'>
                        <div>
                          <img
                            className='bg-indigo-800 p-2 h-32 w-32'
                            src='https://commercial.test.subs.subsenterprise.com/static/media/logo.fb9cf839e5865243d3ca.png'
                            alt='logo'
                          />
                        </div>
                        <div className='col-span-3 font-bold'>
                          <dl>
                            <dd>ELECTRICITY COMPANY OF GHANA LIMITED</dd>
                            <dd>VAT REG: 714 V 000395</dd>
                            <dd>P.O. Box GP 521</dd>
                            <dd>Accra, Ghana</dd>
                            <dd>PHONE: 0302-611611</dd>
                            <dd>WEB: https://www.ecg.com.gh</dd>
                            <dd>EMAIL: info@ecg.com.gh</dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                    <div className='col-span-3'>
                      <dl>
                        <dd>
                          <span className='font-bold'>Electricity bill</span>
                          <span className='text-xs'>
                            Period:{" "}
                            {numeral(
                              bill?.billPeriod || bill?.consumptionPeriod
                            ).format("0,0")}{" "}
                            Days
                          </span>
                        </dd>
                        <dd>
                          <span className='font-bold'>Bill Number: </span>
                          {bill?.code || "N/A"}
                        </dd>
                        <dd>
                          <span className='font-bold'>Bill Date: </span>
                          {moment(bill?.createdAt).format(dateFormat)}{" "}
                          <span className='font-bold ml-3'>Month: </span>
                          {moment(bill?.createdAt).format("MMM YYYY")}
                        </dd>
                        <dd>
                          <span className='font-bold'>Due Date: </span>
                          {moment(bill?.totalAmountDueAt).format(
                            dateFormat
                          )}{" "}
                          <span className='text-xs'>
                            (Pay by this date to avoid disconnection)
                          </span>
                        </dd>
                        <dd>
                          <span className='font-bold'>Tariff: </span>
                          {bill?.tariffClass?.code}{" "}
                          {bill?.tariffClass?.name || "N/A"} (
                        </dd>
                        <dd>
                          <span className='font-bold'>
                            Contracted Demand(KVA):{" "}
                          </span>
                          {bill?.contractedDemand ??
                            (bill?.tariffClass?.code === "E01" ? "0.5" : "0.7")}
                        </dd>
                      </dl>
                    </div>
                  </div>
                  <div className='flex space-x-12'>
                    <div className='text-sm flex-1'>
                      <h1 className='bg-gray-300 p-2 text-center font-semibold'>
                        Customer Information
                      </h1>
                      <div className='flex flex-col justify-between'>
                        <div className='grid grid-cols-3 p-3'>
                          <span className='font-semibold'>
                            Recipient Name:{" "}
                          </span>
                          <span className='col-span-2'>
                            {bill?.customer?.representative?.fullName ||
                              bill?.customer?.organization?.fullName ||
                              bill?.customer?.representative?.fullName ||
                              "N/A"}
                          </span>
                          <span className='font-semibold'>
                            Digital Address:{" "}
                          </span>
                          <span className='col-span-2'>
                            {bill?.ghanaPostAddress || "N/A"}
                          </span>
                          <span className='font-semibold'>
                            Delivery Address:{" "}
                          </span>
                          <span className='col-span-2'>
                            {bill?.address || "N/A"}
                          </span>
                        </div>
                        <div className='grid grid-cols-3 p-3'>
                          <span className='font-semibold'>Meter Number:</span>
                          <span className='col-span-2'>
                            {bill?.meterCode || "N/A"}
                          </span>
                          <span className='font-semibold'>Account Number:</span>
                          <span className='col-span-2'>
                            {bill?.accountCode || "N/A"}
                          </span>
                          <span className='font-semibold'>Customer Name: </span>
                          <span className='col-span-2'>
                            {bill?.customer?.representative?.fullName ||
                              bill?.customer?.organization?.fullName ||
                              bill?.customer?.representative?.fullName ||
                              "N/A"}
                          </span>
                          <span className='font-semibold'>
                            Service Address:{" "}
                          </span>
                          <span className='col-span-2'>
                            {bill?.address || "N/A"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='text-sm flex-1 flex flex-col'>
                      <h1 className='bg-gray-300 p-2 text-center font-semibold'>
                        Consumption History (12 months)
                      </h1>
                      <div className='grid grid-cols-3 p-3'>
                        <span className='font-semibold'>
                          Geographical Code:
                        </span>
                        <span className='col-span-2'>
                          {bill?.geoCode || "N/A"}
                        </span>
                        <span className='font-semibold'>Issuing Center:</span>
                        <span className='col-span-2'>Kwabenya District</span>
                      </div>
                      <div className='w-full flex-1 flex flex-row justify-evenly relative'>
                        <div className='flex flex-col'>
                          <div className='flex-1 flex flex-col-reverse justify-between'>
                            {lodash.map(
                              [0, 100, 200, 300, 400, 500, 600, 700],
                              (val) => (
                                <div className='flex justify-end'>
                                  <span className='text-xs text-gray-700'>
                                    {val} -
                                  </span>
                                  <div className='absolute h-0 mt-2 right-2 left-16 border-t border-gray-300' />
                                </div>
                              )
                            )}
                          </div>
                          <div className='h-6' />
                        </div>
                        {lodash.map(
                          [
                            "01",
                            "02",
                            "03",
                            "04",
                            "05",
                            "06",
                            "07",
                            "08",
                            "09",
                            "10",
                            "11",
                            "12",
                          ],
                          (date) => (
                            <div className='flex flex-col items-center z-10'>
                              <div className='w-4 flex-1 flex items-end'>
                                <div className='rounded-xs bg-gray-800 w-full h-0' />
                              </div>
                              <div className='h-6 flex items-end justify-center'>
                                <span className='text-xs text-gray-700'>
                                  {date}
                                </span>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='pb-8'>
                    <h1 className='text-sm text-center font-bold'>
                      Meter Information
                    </h1>
                    <table className='w-full table-auto border-separate'>
                      <thead>
                        <tr className='text-sm text-left font-bold'>
                          <th className='bg-gray-300 px-3 py-2'>Meter No.</th>
                          <th className='bg-gray-300 px-3 py-2'>Energy type</th>
                          <th className='bg-gray-300 px-3 py-2'>From</th>
                          <th className='bg-gray-300 px-3 py-2'>To</th>
                          <th className='bg-gray-300 px-3 py-2'>
                            Previous Reading
                          </th>
                          <th className='bg-gray-300 px-3 py-2'>
                            Current Reading
                          </th>
                          <th className='bg-gray-300 px-3 py-2'>Diff.</th>
                          <th className='bg-gray-300 px-3 py-2'>Multiplier</th>
                          <th className='bg-gray-300 px-3 py-2'>Consumption</th>
                          <th className='bg-gray-300 px-3 py-2'>E/A</th>
                        </tr>
                      </thead>
                      <tbody className='text-xs'>
                        <tr>
                          <td className='px-3 py-2'>
                            {bill?.meterCode || "N/A"}
                          </td>
                          <td className='px-3 py-2'>Active energy (kWh)</td>
                          <td className='px-3 py-2'>
                            {moment(bill?.previousBill?.readingDate).format(
                              dateFormat
                            )}
                          </td>
                          <td className='px-3 py-2'>
                            {moment(
                              bill?.billEndDate ||
                                bill?.currentBill?.readingDate
                            ).format(dateFormat)}
                          </td>
                          <td className='px-3 py-2'>
                            {numeral(
                              bill?.previousBill?.readingValue || 0
                            ).format("0,0")}
                          </td>
                          <td className='px-3 py-2'>
                            {numeral(
                              bill?.currentBill?.readingValue || 0
                            ).format("0,0")}
                          </td>
                          <td className='px-3 py-2'>
                            {numeral(
                              bill?.currentBill?.consumptionValue || 0
                            ).format("0,0")}
                          </td>
                          <td className='px-3 py-2 text-center'>1.00</td>
                          <td className='px-3 py-2 text-center'>
                            {numeral(
                              bill?.currentBill?.consumptionValue || 0
                            ).format("0,0")}
                          </td>
                          <td className='px-3 py-2 text-center'>
                            {bill?.currentBill?.readingEstimated ? "E" : "A"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='flex flex-1 flex-col'>
                    <h1 className='text-sm text-center font-bold'>
                      Calculation Information
                    </h1>
                    <div className='relative flex-1'>
                      <table className='w-full table-fixed border-separate'>
                        <thead>
                          <tr className='text-sm text-left font-bold'>
                            <th className='w-60 bg-gray-300 px-3 py-2'>
                              Additional Information
                            </th>
                            <th className='w-20 bg-gray-300 px-3 py-2'>From</th>
                            <th className='w-20 bg-gray-300 px-3 py-2'>To</th>
                            <th className='bg-gray-300 px-3 py-2'>Item</th>
                            <th className='w-28 bg-gray-300 px-3 py-2'>Unit</th>
                            <th className='w-20 bg-gray-300 px-3 py-2'>
                              Price
                            </th>
                            <th className='w-28 bg-gray-300 px-3 py-2 text-right'>
                              Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody className='text-xs'>
                          {lodash.map(bill?.bills, (billX: any) => (
                            <>
                              {lodash.map(billX?.billItems, (item, idx) => (
                                <tr>
                                  <td className=''></td>
                                  <td className='px-3'>
                                    {moment(billX?.previousDate).format(
                                      dateFormat
                                    )}
                                  </td>
                                  <td className='px-3'>
                                    {moment(billX?.currentDate).format(
                                      dateFormat
                                    )}
                                  </td>
                                  <td className='px-3'>{item.title}</td>
                                  <td className='px-3 text-right'>
                                    {item.unit}
                                  </td>
                                  <td className='px-3 text-right'>
                                    {numeral(item.unitCost).format("0,0.0000")}
                                  </td>
                                  <td className='px-3 text-right'>
                                    {numeral(item.cost).format("0,0.00")}
                                  </td>
                                </tr>
                              ))}
                              <tr>
                                <td className='h-4' />
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                      <div className='absolute inset-0 flex flex-col'>
                        <div className='flex flex-1'>
                          <div className='w-60'></div>
                          <div className='flex-1 border-2 border-black'></div>
                        </div>
                        <div className='flex'>
                          <div className='flex-1'></div>
                          <div className='text-sm w-1/3 divide-y-2 divide-black border-2 border-t-0 border-black font-bold'>
                            <div className='grid grid-cols-5 divide-x-2 divide-black'>
                              <span className='col-span-3 px-3 py-0.5'>
                                Current Bill:
                              </span>
                              <span className='col-span-2 px-3 py-0.5 text-right'>
                                GHS{" "}
                                {numeral(bill?.currentBillAmount).format(
                                  "0,0.00"
                                )}
                              </span>
                            </div>
                            <div className='grid grid-cols-5 divide-x-2 divide-black'>
                              <span className='col-span-3 px-3 py-0.5'>
                                Previous Balance:
                              </span>
                              <span className='col-span-2 px-3 py-0.5 text-right'>
                                GHS{" "}
                                {numeral(bill?.outstandingAmount).format(
                                  "0,0.00"
                                )}
                              </span>
                            </div>
                            <div className='grid grid-cols-5 divide-x-2 divide-black'>
                              <span className='col-span-3 px-3 py-0.5'>
                                Total Paid:
                              </span>
                              <span className='col-span-2 px-3 py-0.5 text-right'>
                                GHS {numeral(0).format("0,0.00")}
                              </span>
                            </div>
                            <div className='grid grid-cols-5 divide-x-2 divide-black'>
                              <span className='col-span-3 px-3 py-0.5'>
                                Amount Payable:
                              </span>
                              <span className='col-span-2 px-3 py-0.5 text-right'>
                                GHS{" "}
                                {numeral(bill?.totalAmountDue).format("0,0.00")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='flex flex-col pb-8'>
                    <span className='text-sm font-bold'>
                      Payment Information. Last payment date: 17/03/2023 and
                      Payment Amount: 1,800.00
                    </span>
                    <span className='px-6 text-end'>Please See Over</span>
                  </div>
                  <div className='grid grid-cols-3'>
                    <div className='col-span-1 grid grid-cols-5 gap-x-3 gap-y-1 border-2 border-black p-2 text-xs'>
                      <span className='col-span-2 text-right font-bold'>
                        Customer Name:{" "}
                      </span>
                      <span className='col-span-3'>
                        {bill?.customer?.representative?.fullName ||
                          bill?.customer?.organization?.fullName ||
                          bill?.customer?.representative?.fullName ||
                          "N/A"}
                      </span>
                      <span className='col-span-2 text-right font-bold'>
                        Bill Date:{" "}
                      </span>
                      <span className='col-span-3'>
                        {moment(bill?.createdAt).format(dateFormat)}
                      </span>
                      <span className='col-span-2 text-right font-bold'>
                        Account No:
                      </span>
                      <span className='col-span-3'>
                        {bill?.accountCode || "N/A"}
                      </span>
                      <span className='col-span-2 text-right font-bold'>
                        Bill No:
                      </span>
                      <span className='col-span-3'>
                        {" "}
                        {bill?.code || "N/A"}{" "}
                      </span>
                      <span className='col-span-2 text-right font-bold'>
                        Amount Payable:{" "}
                      </span>
                      <span className='col-span-3'>
                        GHS {numeral(bill?.totalAmountDue).format("0,0.00")}
                      </span>
                    </div>
                    <div className='col-span-1'>
                      <div className='flex inset-0 items-center justify-center'>
                        <QRCodeSVG
                          value={bill?.code}
                          size={120}
                          imageSettings={{
                            src: require("assets/logo.png"),
                            height: 20,
                            width: 20,
                            excavate: true,
                          }}
                        />
                      </div>
                    </div>
                    <div className='col-span-1'></div>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
};

export default BillInstallmentView;
