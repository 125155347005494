import { gql, useLazyQuery, useReactiveVar } from "@apollo/client";
import { SelectInput, TextArea, TextInput, UploadArea } from "components/core";
import { useFormik } from "formik";
import moment from "moment";
import numeral from "numeral";
import { FC, useEffect } from "react";
import { validators, wrapClick, wrapImage } from "utils";
import * as yup from "yup";
import lodash from "lodash";
import { currentConfigVar } from "apollo/cache/config";

const RESOLVE_ADDRESS_INFO = gql`
  query ResolveGhanaPostAddress($address: String!) {
    address: resolveGhanaPostAddress(address: $address) {
      _id
      postCode
      district
      region
      area
      address
      street
      coordinates {
        longitude
        latitude
      }
    }
  }
`;

interface ContractInfoFormProps {
  handleNext: (values: any) => void;
  handlePrevious: () => void;
  initialValues: any;
  values: any;
  handleCancel: () => void;
}

const ContractInfoForm: FC<ContractInfoFormProps> = ({
  initialValues,
  handleNext,
  handlePrevious,
  handleCancel,
}) => {
  const [resolveGhanaPostAddress] = useLazyQuery(RESOLVE_ADDRESS_INFO);
  const { dateFormat } = useReactiveVar(currentConfigVar);

  const form = useFormik({
    initialValues,
    validationSchema: yup.object().shape({
      readingValue: yup.number().required("Reading value is required"),
      // readingImageUrl: yup.string().required("Reading image is required"),
      readingDate: yup.date().required("Reading date is required"),
    }),
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  return (
    <form
      onSubmit={form.handleSubmit}
      className='flex-1 flex flex-col overflow-hidden'
    >
      <div className='space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto'>
        <div>
          <span className='text-xs font-light'>Contract Information</span>
          <div className='grid grid-cols-2 gap-6 mt-2'>
            <div className='space-y-1'>
              <TextInput
                id='readingValue'
                type="number"
                placeholder='e.g. 1000'
                label='Reading Value'
                postText='kwH'
                {...form}
              />
            </div>
            <div className='space-y-1'>
              <TextInput
                id='readingDate'
                placeholder='e.g. 1000'
                type='date'
                label='Reading Date'
                // postText="kwH"
                {...form}
              />
            </div>

            <div className='col-span-2 '>
              <UploadArea
                id='readingImageUrl'
                label='Proof of Reading'
                {...form}
              />
            </div>
          </div>
        </div>
      <div className="py-6">
      <span className='text-xs font-light'>Reading History</span>

        {form.values?.readingHistory?.length > 0 ? (
          <>
            {form.values?.readingHistory?.map((reading: any) => (
              <div>
                <div className='grid grid-cols-4 gap-6 mt-2'>
                  <div>
                    <span className='block text-sm font-light text-gray-700'>
                      Last Reading Date
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {reading?.previousReading?.readingDate
                        ? moment(reading.previousReading.readingDate).format(
                            dateFormat
                          )
                        : "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className='block text-sm font-light text-gray-700'>
                      Last Reading Value
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {numeral(
                        reading?.previousReading?.readingValue || 0
                      ).format("0,0")}{" "}
                      KWh
                    </div>
                  </div>
                  <div>
                    <span className='block text-sm font-light text-gray-700'>
                      Last Reading Estimated?
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {reading?.previousReading?.readingEstimated
                        ? "Yes"
                        : "No"}
                    </div>
                  </div>
                  <div>
                    <span className='block text-sm font-light text-gray-700'>
                      Outstanding Amount
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      GHS{" "}
                      {numeral(
                        reading?.previousReading?.totalAmountDue || 0
                      ).format("0,0.00")}
                    </div>
                  </div>
                  <div>
                    <span className='block text-sm font-light text-gray-700'>
                      Current Reading Date
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {reading?.currentReading?.readingDate
                        ? moment(reading.currentReading.readingDate).format(
                            dateFormat
                          )
                        : "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className='block text-sm font-light text-gray-700'>
                      Current Reading Value
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {reading?.currentReading?.readingValue
                        ? `${numeral(
                            reading.currentReading.readingValue || 0
                          ).format("0,0")} KWh`
                        : "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className='block text-sm font-light text-gray-700'>
                      Current Reading Estimated?
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {lodash.isBoolean(
                        reading?.currentReading?.readingEstimated
                      )
                        ? reading?.currentReading?.readingEstimated
                          ? "Yes"
                          : "No"
                        : "N/A"}
                    </div>
                  </div>
                  <div className='col-span-4'>
                    <span className='block text-sm font-light text-gray-700'>
                      Reading Image
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {wrapImage(
                        <img
                          src={reading?.meta?.readingImageUrl}
                          alt='reading'
                          className='h-64 w-full object-cover object-center'
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className='flex min-h-[400px] items-center justify-center'>
            <div className='text-center'>
              <svg
                className='mx-auto h-12 w-12 text-gray-400 dark:text-gray-300'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                aria-hidden='true'
              >
                <path
                  vectorEffect='non-scaling-stroke'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z'
                />
              </svg>
              <h3 className='mt-2 text-sm font-medium text-gray-900 dark:text-gray-100'>
                No Reading History
              </h3>
              <p className='mt-1 text-sm text-gray-500 dark:text-gray-400'>
                Kindly Refer To CMS For Reading History.
              </p>
            </div>
          </div>
        )}
        </div>
      </div>

      <div className='bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200'>
        <button
          type='submit'
          className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
        >
          Next
        </button>
        <button
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default ContractInfoForm;
