import { gql, useQuery } from '@apollo/client';
import { RegionView, Modal } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';

const GET_REGION = gql`
  query GetRegion($id: ID!) {
    region: getRegion(id: $id) {
      _id
      code
      name
      meta {
        usersCount
        districtsCount
        blocksCount
        roundsCount
        plotsCount
        itinerariesCount
        propertiesCount
        customersCount
      }
      createdAt
      updatedAt
    }
  }
`

export default function ViewRegionContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_REGION, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Region Information"
      loading={loading}
      description="Details of region are shown below"
    >
      <RegionView
        region={data?.region}
      />
    </Modal>
  )
}