import { FC } from "react";
import numeral from "numeral";
import { classNames } from "utils";
import { Avatar } from "components/core";

interface PlotViewProps {
  plot: {
    code: string;
    name: string;
    regionCode: string;
    regionName: string;
    districtCode: string;
    districtName: string;
    blockCode: string;
    blockName: string;
    roundCode: string;
    roundName: string;
    meta: {
      itinerariesCount: number;
      propertiesCount: number;
      customersCount: number;
    };
    debtMeta?: {
      totalDebtors: number;
      totalDebtAmount: number;
      lowestDebtAmount: number;
      highestDebtAmount: number;
    };
    disconnector?: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
  };
  from?: string;
}

function Owner({
  owner,
  type,
}: {
  owner: any;
  type: "field-agents" | "staff-accounts";
}) {
  return (
    <div
      className={classNames(
        "border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 hover:border-gray-400 dark:hover:border-gray-500",
        "focus:outline-none relative rounded-lg border px-3 py-3 shadow-sm flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
      )}
    >
      <div className='flex-shrink-0'>
        <Avatar
          alt={
            [(owner?.lastName || "")?.trim(), (owner?.firstName || "")?.trim()]
              .join(" ")
              .trim() || "N A"
          }
          src={owner?.profileImageUrl || ""}
        />
      </div>
      <div className='flex-1 min-w-0 text-left'>
        <p className='text-sm font-medium text-gray-900 dark:text-white truncate'>
          {[(owner?.lastName || "")?.trim(), (owner?.firstName || "")?.trim()]
            .join(" ")
            .trim() || "N A"}
        </p>
        <p className='text-sm text-gray-500 dark:text-gray-400 truncate'>
          {owner?.phoneNumber || "N/A"}
        </p>
      </div>
    </div>
  );
}

const PlotView: FC<PlotViewProps> = ({ plot, from }) => {
  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div>
        <span className='text-xs font-light'>Plot Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Plot Code
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {plot?.code || "N/A"}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              Plot Name
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {plot?.name || "N/A"}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              Region Code
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {plot?.regionCode || "N/A"}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              Region Name
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {plot?.regionName || "N/A"}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              District Code
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {plot?.districtCode || "N/A"}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              District Name
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {plot?.districtName || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Block Code
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {plot?.blockCode || "N/A"}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              Block Name
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {plot?.blockName || "N/A"}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              Round Code
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {plot?.roundCode || "N/A"}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              Round Name
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {plot?.roundName || "N/A"}
            </div>
          </div>
        </div>
      </div>
      {/* <div className='pt-6'>
        <span className='text-xs font-light'>Plot Statistics</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Itineraries Count
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {numeral(plot?.meta?.itinerariesCount || 0).format("0,0")}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              Properties Count
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {numeral(plot?.meta?.propertiesCount || 0).format("0,0")}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              Customers Count
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {numeral(plot?.meta?.customersCount || 0).format("0,0")}
            </div>
          </div>
        </div>
      </div> */}
      {from === "debt" && (
        <div className='pt-6'>
          <span className='text-xs font-light'>Plot Debt Statistics</span>
          <div className='grid grid-cols-4 gap-6 mt-2'>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Debtors Count
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {numeral(plot?.debtMeta?.totalDebtors || 0).format("0,0")}
              </div>
            </div>

            <div>
              <span className='block text-sm font-light text-gray-700'>
                Minimum Debt Amount
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                GHS{" "}
                {numeral(plot?.debtMeta?.lowestDebtAmount || 0).format(
                  "0,0.00"
                )}{" "}
              </div>
            </div>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Maximum Debt Amount
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                GHS{" "}
                {numeral(plot?.debtMeta?.highestDebtAmount || 0).format(
                  "0,0.00"
                )}{" "}
              </div>
            </div>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Total Debt Amount{" "}
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                GHS{" "}
                {numeral(plot?.debtMeta?.totalDebtAmount || 0).format("0,0.00")}{" "}
              </div>
            </div>
            <div className='col-span-2'>
              <span className='block text-sm font-light text-gray-700'>
                Disconnector
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                <Owner owner={plot?.disconnector} type='staff-accounts' />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlotView;
