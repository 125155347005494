import { FC, forwardRef } from "react";
import { Avatar } from "components/core";
import { classNames, wrapImage } from "utils";
import numeral from "numeral";
import moment from "moment";
import lodash from "lodash";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import Map from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MapProvider, Marker } from "react-map-gl";
import config from "config";
import {
  EnvelopeIcon,
  GlobeAltIcon,
  PhoneIcon,
  PhotoIcon,
} from "@heroicons/react/20/solid";
import _ from "lodash";

interface SpotBillViewProps {
  ref: any;
  spotBill: any;
}

const SpotBillBillView: FC<SpotBillViewProps> = forwardRef<
  HTMLDivElement,
  SpotBillViewProps
>(({ spotBill }, ref) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);
  return (
    <div ref={ref} className='flex-1 flex flex-col overflow-hidden'>
      {" "}
      <div className='hidden print:flex flex-row items-center space-x-4 justify-center py-6 '>
        <div>
          <img
            alt='ECG Logo'
            src={require("assets/logo.png")}
            className='h-32 w-32'
          />
        </div>
        <div className='space-y-1 text-gray-900'>
          <h1 className='text-xl text-primary-600 font-bold'>
            ELECTRICITY COMPANY OF GHANA LIMITED
          </h1>
          <div className='flex items-center space-x-6'>
            <div className='flex items-center space-x-2'>
              <EnvelopeIcon className='h-4 w-4' />
              <span>P.O BOX GP 521, ACCRA</span>
            </div>
            <div className='flex items-center space-x-2'>
              <PhoneIcon className='h-4 w-4' />
              <span>0302-611-611</span>
            </div>
            <div className='flex items-center space-x-2'>
              <GlobeAltIcon className='h-4 w-4' />
              <span>ecg@ecggh.com</span>
            </div>
          </div>
          <div className='flex items-center space-x-6'>
            <div className='flex items-center space-x-2'>
              <span className='font-semibold'>VAT REG:</span>
              <span>714V000395</span>
            </div>
            <div className='flex items-center space-x-2'>
              <span className='font-semibold'>Website:</span>
              <span>https://www.ecggh.com/</span>
            </div>
          </div>
        </div>
      </div>
      <div className='space-y-6 divide-y divide-gray-200'>
        <div className='border-2 print:flex hidden  my-0 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3'>
          <div className=''>
            <div className='grid grid-cols-4 gap-6'>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Meter Number
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {spotBill?.meterCode || "N/A"}
                </div>
              </div>
              <div className='col-span-3'>
                <span className='block text-sm font-light text-gray-700'>
                  Full Name
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {spotBill?.servicePoint?.customerRepresentative?.fullName?.trim() ||
                    spotBill?.servicePoint?.customerOrganization?.name?.trim() ||
                    "N A"}{" "}
                </div>
              </div>

              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Date
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {spotBill?.createdAt
                    ? moment(spotBill.createdAt).format(dateFormat)
                    : "N/A"}
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Tariff Class
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {spotBill?.tariffClass?.name || "N/A"}
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Digital Address
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {spotBill?.ghanaPostAddress || "N/A"}
                </div>
              </div>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  GeoCode
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {spotBill?.geoCode || "N/A"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='print:pt-6 print:!my-6  !my-0 border-none print:!border-t print:border-solid'>
          <div className='grid grid-cols-4 gap-6 '>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Prev. Reading
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {numeral(spotBill?.bill?.previousReading || 0).format("0,0")}{" "}
                KWh{" "}
              </div>
            </div>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Curr. Reading
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {numeral(spotBill?.bill?.currentReading || 0).format("0,0")} KWh{" "}
              </div>
            </div>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Consumption
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {numeral(spotBill?.bill?.dailyConsumption || 0).format("0,0")}{" "}
                KWh{" "}
              </div>
            </div>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Account No.
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {spotBill?.accountCode || "N/A"}
              </div>
            </div>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Prev. Reading Date
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {spotBill?.bill?.from
                  ? moment(spotBill?.bill?.from).format(dateFormat)
                  : "N/A"}
              </div>
            </div>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Curr. Reading Date
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {spotBill?.bill?.from
                  ? moment(spotBill?.bill?.from).format(dateFormat)
                  : "N/A"}
              </div>
            </div>
          </div>
        </div>

        <div className='pt-6'>
          <div className='grid grid-cols-3 gap-6'>
            <div className='col-span-3'>
              <div className='mt-1 w-full '>
                <table className='min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md mr-4'>
                  <thead className='bg-gray-50'>
                    <tr className='divide-x divide-gray-200'>
                      <th
                        scope='col'
                        className='whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 '
                      >
                        From
                      </th>
                      <th
                        scope='col'
                        className='whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 '
                      >
                        To
                      </th>
                      <th
                        scope='col'
                        className='whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 '
                      >
                        Item
                      </th>
                      <th
                        scope='col'
                        className='whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 '
                      >
                        Unit
                      </th>
                      <th
                        scope='col'
                        className='whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900  text-right'
                      >
                        Unit Price(GHS)
                      </th>
                      <th
                        scope='col'
                        className='whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900  text-right'
                      >
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {_.map(spotBill?.bill?.items, (item, idx) => {
                      return (
                        <tr key={idx} className='divide-x divide-gray-200'>
                          <td className='py-2 whitespace-nowrap text-sm text-gray-800 font-medium dark:text-gray-400  px-3'>
                            {item?.from
                              ? moment(item?.from).format(dateFormat)
                              : "N/A"}{" "}
                          </td>
                          <td className='px-3 text-sm'>
                            {" "}
                            {item?.to
                              ? moment(item?.to).format(dateFormat)
                              : "N/A"}
                          </td>
                          <td className='px-3 text-sm'>
                            {item?.item || "N/A"}
                          </td>
                          <td className='px-3 text-sm'>
                            {item?.unit || "N/A"}
                          </td>
                          <td className='px-3 text-sm text-right'>
                            {numeral(item?.unitPrice || 0).format("0,0.00") ||
                              "N/A"}
                          </td>
                          <td className='px-3 text-sm text-right'>
                            {numeral(item?.amount || 0).format("0,0.00") ||
                              "N/A"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className='pt-6'>
          <div className='grid grid-cols-3 gap-6'>
            <div className='col-span-3'>
              <span className='block text-sm font-medium text-gray-700'>
                Recent Payments Made
              </span>
              <div className='mt-1 w-full '>
                <table className='min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md mr-4'>
                  <thead className='bg-gray-50'>
                    <tr className='divide-x divide-gray-200'>
                      <th
                        scope='col'
                        className='whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 '
                      >
                        Date
                      </th>

                      <th
                        scope='col'
                        className='whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900  text-right'
                      >
                        Amount (GHS)
                      </th>
                      <th
                        scope='col'
                        className='whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900  text-center'
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {_.map(spotBill?.bill?.payments, (item, idx) => {
                      return (
                        <tr key={idx} className='divide-x divide-gray-200'>
                          <td className='py-2 whitespace-nowrap text-sm text-gray-800 font-medium dark:text-gray-400  px-3'>
                            {item?.transactionDate
                              ? moment(item?.transactionDate).format(dateFormat)
                              : "N/A"}{" "}
                          </td>

                          <td className='px-3 text-sm text-right'>
                            {numeral(item?.amount || 0).format("0,0.00") ||
                              "N/A"}
                          </td>
                          <td
                            className={classNames(
                              "px-3 text-sm text-center",
                              !item?.status
                                ? "text-gray-400"
                                : item?.status == "Successful"
                                ? "text-green-500"
                                : "text-red-500"
                            )}
                          >
                            {item?.status || "N/A"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className='pt-6'>
          <div className='grid grid-cols-4  gap-y-4  divide-y divide-gray-500'>
            <div className='col-span-4 flex justify-between items-center'>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Current Bill
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  GHS{" "}
                  {numeral(spotBill?.bill?.currentBillAmount || 0).format(
                    "0,0.00"
                  ) || "N/A"}
                </div>
              </div>
              <span className='text-3xl'> +</span>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Previous Balance
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  GHS{" "}
                  {numeral(spotBill?.bill?.previousBalance || 0).format(
                    "0,0.00"
                  ) || "N/A"}{" "}
                </div>
              </div>
              <span className='text-3xl'> +</span>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Adjustments
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  GHS{" "}
                  {numeral(spotBill?.bill?.adjustments || 0).format("0,0.00") ||
                    "N/A"}{" "}
                </div>
              </div>
              <span className='text-3xl'>-</span>
              <div>
                <span className='block text-sm font-light text-gray-700'>
                  Current Month Payments
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  GHS{" "}
                  {numeral(spotBill?.bill?.currentMonthPayments || 0).format(
                    "0,0.00"
                  ) || "N/A"}{" "}
                </div>
              </div>
              <span className='text-3xl'>=</span>
            </div>
            <div className='col-span-2 py-3'></div>
            <div className='col-start-3 col-span-2 py-3 flex items-center justify-between'>
              <span className='block  font-semibold text-gray-900 text-lg   '>
                Total Amount Payable:
              </span>
              <div className='mt-1 block  font-semibold text-gray-900 text-lg'>
                GHS{" "}
                {numeral(spotBill?.bill?.totalAmountPayable || 0).format(
                  "0,0.00"
                ) || "N/A"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SpotBillBillView;
