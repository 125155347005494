import { gql, useQuery } from "@apollo/client";

const GET_PLOTS = gql`
  query GetDebtors(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $block: ID
    $round: ID
  ) {
    rows: getPlots(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      block: $block
      round: $round
      debtors: true
    ) {
      _id
      code
      name
      region {
        _id
      }
      district {
        _id
      }
      block {
        _id
      }
      round {
        _id
      }
      meta {
        itinerariesCount
        propertiesCount
        customersCount
      }
      debtMeta {
        status
        totalDebtors
        totalDebtAmount
        lowestDebtAmount
        highestDebtAmount
      }
      disconnector {
        _id
        lastName
        firstName
        phoneNumber
      }
      createdAt
      updatedAt
    }
    count: getPlotsCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      block: $block
      round: $round
      debtors: true
    )
  }
`;

export const usePlots = (variables: any) => {
  const { data, ...rest } = useQuery(GET_PLOTS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const plotsData = data?.rows || [];
  const count = data?.count || 0;
  return { plotsData, count, ...rest };
};
