import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { CustomerReadingForm, Modal } from "components";

const CREATE_COMPLAINT = gql`
  mutation CreateCustomerReading(
    $servicePoint: ID!
    $readingValue: NonNegativeInt
    $readingImageUrl: String
    $readingLocation: GeoLocationInput
    $readingLocationName: String
    $readingRecordedAt: Date
  ) {
    createCustomerReading(
      servicePoint: $servicePoint
      readingValue: $readingValue
      readingImageUrl: $readingImageUrl
      readingLocation: $readingLocation
      readingLocationName: $readingLocationName
      readingRecordedAt: $readingRecordedAt
    ) {
      _id
    }
  }
`;

export default function CreateCustomerReadingContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [createComplaint] = useMutation(CREATE_COMPLAINT);

  const form = useFormik<any>({
    initialValues: {
      customerInfo: {
        existingServicePoint: "",
        servicePoint: "",
      },
      readingInfo: {
        readingValue: "",
        readingImageUrl: "",
        readingLocationName: "",
        readingRecordedAt: "",
        readingLocation: null,
      },
    },
    onSubmit: async (values) => {
      await createComplaint({
        variables: {
          ...values.readingInfo,
          servicePoint: values.customerInfo.existingServicePoint?._id,
        },
      }).then(({ data }) => {
        if (data.createCustomerReading._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Customer Reading Created Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create Customer Reading",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      hideActions={true}
      size={"4xl"}
      title="Add New Customer Reading"
      description="Provide the details to add a new customer reading"
    >
      <CustomerReadingForm form={form} />
    </Modal>
  );
}
