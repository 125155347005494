import { gql, useQuery } from "@apollo/client";
import {
  Modal,
  SpotBillingBillView,
  SpotBillingCustomerView,
} from "components";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSearch } from "react-location";
import { useReactToPrint } from "react-to-print";
import { LocationGenerics } from "router/location";
import { wrapClick, classNames, withPermissions } from "utils";

const GET_SPOT_BILL = gql`
  query GetSpotBill($id: ID!) {
    spotBill: getSpotBill(id: $id) {
      _id
      code
      accountCode
      tariffClassCode
      servicePointCode
      createdAt
      updatedAt
      meterCode
      status
      bill {
        accountNumber
        adjustments
        billDueDate
        currentBillAmount
        currentMonthPayments
        currentReading
        daysBetweenReadings
        dailyConsumption
        from
        items {
          amount
          from
          item
          to
          unit
          unitPrice
        }
        payments {
          amount
          status
          transactionDate
        }
        meterNumber
        phoneNumber
        previousBalance
        previousReading
        servicePointNumber
        to
        totalAmountPayable
      }
      region {
        name
        code
      }
      district {
        code
        name
      }
      tariffClass {
        name
        serviceClass
      }
      servicePoint {
        _id
        ghanaPostAddress
        geoCode
        customerRepresentative {
          fullName
          profileImageUrl
          phoneNumber
        }
        customerOrganization {
          name
        }
      }
      meter {
        model {
          name
        }
        brand {
          name
        }
      }

      readingDate
      readingImageUrl
      readingValue
    }
  }
`;

export default function ViewSpotBillingContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [tab, setTab] = useState("Customer");
  const { data, loading } = useQuery(GET_SPOT_BILL, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  const tabs = useMemo(
    () => [
      { name: "Customer Details", href: "Customer" },
      { name: "Bill Details", href: "Bill" },
    ],
    [data?.reading]
  );

  const spotBillRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => spotBillRef.current,
    documentTitle: data?.spotBill?.code,
    bodyClass: "w-[1000px]",
    
  });

  const __setTab = (tab: string) => () => {
    setTab(tab);
  };

  useEffect(() => {
    setTab("Customer");
  }, [searchParams.id]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      hidePadding={true}
      title='Spot bill Information'
      description='Details of spot bill are shown below'
      size='3xl'
      renderActions={() => (
        <>
          {tab == "Bill" && withPermissions(["bills:*", "bills:print"])(
            <button
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={wrapClick(handlePrint)}
            >
              Print
            </button>
          )}
        </>
      )}
    >
      <>
        <div className='block'>
          <div className='border-b border-gray-200 bg-white px-6'>
            <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
              {tabs.map((_tab) => (
                <button
                  key={_tab.name}
                  onClick={wrapClick(__setTab(_tab.href))}
                  disabled={
                    loading || (_tab.href == "Bill" && !data?.spotBill?.bill)
                  }
                  className={classNames(
                    tab === _tab.href
                      ? "border-primary-500 text-primary-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200 ",
                    "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm disabled:cursor-not-allowed disabled:text-gray-400 disabled:border-transparent"
                  )}
                  aria-current={tab === _tab.href ? "page" : undefined}
                >
                  {_tab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
        <div className='flex-1 w-full max-h-[65vh] overflow-y-auto  sm:p-6'>
          {tab === "Customer" && (
            <SpotBillingCustomerView spotBill={data?.spotBill} />
          )}
          {tab === "Bill" && <SpotBillingBillView spotBill={data?.spotBill} ref={spotBillRef}/>}
        </div>
      </>
    </Modal>
  );
}
