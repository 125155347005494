import ReadingInfoForm from "./reading-info-form";
import CustomerInfoForm from "./customer-info-form";
import ReadingInfoSummary from "./reading-info-summary";
import CustomerInfoSummary from "./customer-info-summary";

const formSteps = [
  {
    name: "Customer Info",
    description: "Information about the customer making the request.",
    accessor: "customerInfo",
    FormComponent: CustomerInfoForm,
    SummaryComponent: CustomerInfoSummary,
  },
  {
    name: "Reading Info",
    description: "Information about reading with the customer.",
    accessor: "readingInfo",
    FormComponent: ReadingInfoForm,
    SummaryComponent: ReadingInfoSummary,
  },
];

export default formSteps;
