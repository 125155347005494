import { gql, useQuery } from "@apollo/client";
import {
  ReadingView,
  Modal,
  ReadingDetialsView,
  AnomalyDetailsView,
  ExceptionDetailsView,
} from "components";
import { useEffect, useMemo, useState } from "react";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick, classNames } from "utils";

const GET_READING = gql`
  query GetReading($id: ID!) {
    reading: getReading(id: $id) {
      _id
      code
      customerCode
      customer {
        _id
      }
      emailAddress
      phoneNumber
      servicePointNumber
      meterNumber
      accountNumber
      fullName
      meterLocation
      geoCode
      contractedDemand
      ghanaCardNumber
      profileImageUrl
      serviceType
      customerType
      qrCode
      address
      ghanaPostAddress
      tariffClass {
        _id
        code
        name
      }
      tariffClassName
      tariffClassCode
      region {
        _id
      }
      regionCode
      regionName
      district {
        _id
      }
      districtCode
      districtName
      block {
        _id
      }
      blockCode
      blockName
      round {
        _id
      }
      roundCode
      roundName
      plot {
        _id
      }
      plotCode
      community
      itinerary {
        _id
      }
      itineraryCode
      itineraryDescription
      property {
        _id
      }
      propertyCode
      previousReading {
        readingDate
        readingValue
        readingEstimated
        consumptionValue
        billValue
        totalAmountDue
      }
      currentReading {
        readingDate
        readingValue
        readingEstimated
        consumptionValue
        billValue
        totalAmountDue
      }
      meta {
        readingImageUrl
        readingLocation {
          coordinates
        }
        readingImageLocation {
          coordinates
        }
        anomalyImageUrl
        anomalyLocation {
          coordinates
        }
        anomalyImageLocation {
          coordinates
        }
        exceptionImageUrl
        exceptionLocation {
          coordinates
        }
        exceptionImageLocation {
          coordinates
        }
        noTraceLocation {
          coordinates
        }
        readingRecordedAt
        readingSyncedAt
      }
      bill {
        _id
        code
        emailAddress
        phoneNumber
        fullName
        profileImageUrl
        ghanaCardNumber
        payer {
          fullName
          emailAddress
        }
        servicePointNumber
        accountNumber
        geoCode
        contractedDemand
        contractStatus
        meterNumber
        serviceClass
        serviceType
        tariffClassCode
        tariffClassName
        propertyCode
        qrCode
        address
        ghanaPostAddress
        customerCode
        readingSetCode
        readingSetName
        month
        year
        consumptionPeriod
        previousBill {
          readingDate
          readingValue
          readingEstimated
          consumptionValue
          billValue
          totalAmountDue
        }
        currentBill {
          readingDate
          readingValue
          readingEstimated
          consumptionValue
          billValue
          totalAmountDue
        }
        currentBillAmount
        outstandingAmount
        totalAmountDue
        totalAmountDueAt
        bills {
          currentDate
          previousDate
          tariffInstance {
            _id
          }
          numberOfDays
          consumption
          billItems {
            title
            unit
            quantity
            unitCost
            cost
          }
          billAmount
        }
        createdAt
        updatedAt
      }
      status
      anomaly {
        _id
        reports {
          _id
          anomalies
          description
          imageUrl
          reader {
            _id
            code
            lastName
            firstName
            phoneNumber
            emailAddress
            profileImageUrl
          }
          meta {
            anomalyLocation {
              type
              coordinates
            }
            anomalyImageLocation {
              type
              coordinates
            }
          }
          createdAt
        }
      }
      exception {
        _id
        type
        description
        value
        imageUrl
        meta {
          exceptionImageLocation {
            coordinates
          }
          exceptionLocation {
            coordinates
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export default function ViewReadingContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [tab, setTab] = useState("Customer");
  const { data, loading } = useQuery(GET_READING, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  const tabs = useMemo(
    () => [
      { name: "Customer Details", href: "Customer" },
      ...(data?.reading?.status === "MeterRead"
        ? [{ name: "Reading Details", href: "Reading" }]
        : []),
      ...(data?.reading?.status === "AnomalyDetected"
        ? [{ name: "Anomaly Details", href: "Anomaly" }]
        : []),
      ...(data?.reading?.status === "ExceptionDetected"
        ? [{ name: "Exception Details", href: "Exception" }]
        : []),
    ],
    [data?.reading]
  );

  const __setTab = (tab: string) => () => {
    setTab(tab);
  };

  useEffect(() => {
    setTab("Customer");
  }, [searchParams.id]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      hidePadding={true}
      title="Reading Information"
      description="Details of reading are shown below"
      size="4xl"
    >
      <>
        <div className="block">
          <div className="border-b border-gray-200 bg-white px-6">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((_tab) => (
                <button
                  key={_tab.name}
                  onClick={wrapClick(__setTab(_tab.href))}
                  className={classNames(
                    tab === _tab.href
                      ? "border-primary-500 text-primary-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                    "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={tab === _tab.href ? "page" : undefined}
                >
                  {_tab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
        <div className="flex-1 w-full max-h-[65vh] overflow-y-auto  sm:p-6">
          {tab === "Customer" && <ReadingView reading={data?.reading} />}
          {tab === "Reading" && <ReadingDetialsView reading={data?.reading} />}
          {tab === "Anomaly" && (
            <AnomalyDetailsView
              anomaly={data?.reading?.anomaly}
            />
          )}
          {tab === "Exception" && (
            <ExceptionDetailsView
              exception={data?.reading?.exception}
              reading={data?.reading}
            />
          )}
        </div>
      </>
    </Modal>
  );
}
