import { gql, useLazyQuery } from "@apollo/client";
import {
  ArrowTopRightOnSquareIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import { Avatar, SelectInput, TextInput } from "components/core";
import { FormikProps, useFormik } from "formik";
import { FC, useState } from "react";
import { Link } from "react-location";
import { classNames, wrapClick } from "utils";

interface AssignItineraryFormProps {
  form: FormikProps<{
    reader: any;
    cycleStartDate: number;
    cycleEndDate: number;
  }>;
}

function Owner({
  owner,
  onClick,
  isActive,
}: {
  owner: any;
  onClick: any;
  isActive: boolean;
}) {
  return (
    <button
      onClick={onClick}
      className={classNames(
        isActive
          ? "border-primary-500 dark:border-primary-600 bg-white dark:bg-primary-800 hover:border-primary-700 dark:hover:border-primary-500"
          : "border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 hover:border-gray-400 dark:hover:border-gray-500",
        "focus:outline-none relative rounded-lg border px-3 py-3 shadow-sm flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
      )}
    >
      <div className="flex-shrink-0">
        <Avatar
          alt={
            [(owner?.lastName || "")?.trim(), (owner?.firstName || "")?.trim()]
              .join(" ")
              .trim() || "N A"
          }
          src={owner?.profileImageUrl || ""}
        />
      </div>
      <div className="flex-1 min-w-0 text-left">
        <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
          {[(owner?.lastName || "")?.trim(), (owner?.firstName || "")?.trim()]
            .join(" ")
            .trim() || "N A"}
        </p>
        <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
          {owner?.phoneNumber || "N/A"}
        </p>
      </div>
      <div className="flex-shrink-0">
        <Link
          type="button"
          to={"/field-agents/"}
          search={{ id: owner?._id }}
          target={"_blank"}
          className="w-8 h-8 bg-white hover:bg-gray-100 dark:bg-gray-900 dark:hover:bg-gray-900 inline-flex items-center justify-center text-gray-400 dark:text-gray-400 rounded-full bg-transparent hover:text-gray-500 dark:hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          <span className="sr-only">View reader details</span>
          <ArrowTopRightOnSquareIcon className="w-5 h-5" aria-hidden="true" />
        </Link>
      </div>
    </button>
  );
}

const GET_USERS = gql`
  query GetUsers(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
  ) {
    rows: getUsers(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
    ) {
      _id
      code
      lastName
      firstName
      ghanaCardNumber
      phoneNumber
      emailAddress
      gender
      profileImageUrl
      role {
        _id
        code
        name
      }
      createdAt
      updatedAt
    }
  }
`;

const AssignItineraryForm: FC<AssignItineraryFormProps> = ({ form }) => {
  const [page] = useState(1);
  const [getReaders, { loading, data }] = useLazyQuery(GET_USERS);

  const readerSearchForm = useFormik({
    initialValues: {
      userIdentifier: "fullName",
      emailAddress: "",
      fullName: "",
      ghanaCardNumber: "",
      phoneNumber: "",
      code: "",
    },
    onSubmit: (values) => {
      getReaders({
        variables: {
          page,
          pageSize: 9,
          search: (values as any)[values.userIdentifier],
          searchFields:
            values.userIdentifier === "fullName"
              ? ["lastName", "firstName"]
              : [values.userIdentifier],
        },
      });
    },
  });

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">District Information</span>
        <div className="grid grid-cols-2 gap-6 mt-2">
          <div>
            <TextInput
              id="cycleStartDate"
              label="Default Cycle Start Day"
              type="number"
              placeholder="e.g. 24"
              required={true}
              max={31}
              min={1}
              step={1}
              {...form}
              postText="day of month"
            />
          </div>

          <div className="space-y-1">
            <TextInput
              id="cycleEndDate"
              label="Default Cycle End Day"
              type="number"
              placeholder="e.g. 28"
              required={true}
              max={31}
              min={1}
              step={1}
              {...form}
              postText="day of month"
            />
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Current Assignment</span>
        {form?.values?.reader ? (
          <div className="grid grid-cols-4 gap-6 mt-2">
            <div className="row-span-2 flex items-center justify-center">
              <Avatar
                alt={
                  [
                    (form?.values?.reader?.lastName || "")?.trim(),
                    (form?.values?.reader?.firstName || "")?.trim(),
                  ]
                    .join(" ")
                    .trim() || "N A"
                }
                src={form?.values?.reader?.profileImageUrl || ""}
                size="xl"
              />
            </div>

            <div>
              <span className="block text-sm font-light text-gray-700">
                Reader Code
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {form?.values?.reader?.code || "N/A"}
              </div>
            </div>

            <div>
              <span className="block text-sm font-light text-gray-700">
                Last Name
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {form?.values?.reader?.lastName || "N/A"}
              </div>
            </div>

            <div>
              <span className="block text-sm font-light text-gray-700">
                Other Names
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {form?.values?.reader?.firstName || "N/A"}
              </div>
            </div>

            <div>
              <span className="block text-sm font-light text-gray-700">
                Ghana Card
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {form?.values?.reader?.ghanaCardNumber || "N/A"}
              </div>
            </div>

            <div>
              <span className="block text-sm font-light text-gray-700">
                Email Address
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {form?.values?.reader?.emailAddress || "N/A"}
              </div>
            </div>

            <div>
              <span className="block text-sm font-light text-gray-700">
                Phone Number
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {form?.values?.reader?.phoneNumber || "N/A"}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex  mt-2 w-full items-center justify-center">
            <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-6 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
              <UserPlusIcon
                className="mx-auto h-10 w-10 text-gray-400"
                stroke="currentColor"
                strokeWidth={1}
                aria-hidden="true"
              />
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No reader assigned
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Get started by searching for a reader below.
              </p>
            </div>
          </div>
        )}
      </div>
      <div>
        <span className="text-xs font-light">Select Meter Reader</span>
        <form
          onSubmit={readerSearchForm.handleSubmit}
          className="grid grid-cols-6 gap-6 mt-2"
        >
          <div className="col-span-3 sm:col-span-2">
            <SelectInput
              id="userIdentifier"
              label="Find Reader By"
              options={[
                { label: "Email Address", value: "emailAddress" },
                { label: "Full Name", value: "fullName" },
                { label: "Ghana Card Number", value: "ghanaCardNumber" },
                { label: "Phone Number", value: "phoneNumber" },
                { label: "Reader Code", value: "code" },
              ]}
              placeholder="e.g. Mensah"
              {...readerSearchForm}
            />
          </div>

          {readerSearchForm.values?.userIdentifier === "fullName" && (
            <div className="col-span-6 sm:col-span-3">
              <TextInput
                id="fullName"
                label="Full Name (Last Name, Other Names)"
                type="text"
                placeholder="e.g. Mensah John"
                {...readerSearchForm}
              />
            </div>
          )}

          {readerSearchForm.values?.userIdentifier === "emailAddress" && (
            <div className="col-span-6 sm:col-span-3">
              <TextInput
                id="emailAddress"
                label="Email Address"
                type="email"
                placeholder="e.g. reader@ecg.com.gh"
                {...readerSearchForm}
              />
            </div>
          )}

          {readerSearchForm.values?.userIdentifier === "phoneNumber" && (
            <div className="col-span-6 sm:col-span-3">
              <TextInput
                id="phoneNumber"
                label="Phone Number"
                type="text"
                placeholder="e.g. 0200000000"
                {...readerSearchForm}
              />
            </div>
          )}

          {readerSearchForm.values?.userIdentifier === "ghanaCardNumber" && (
            <div className="col-span-6 sm:col-span-3">
              <TextInput
                id="ghanaCardNumber"
                label="Ghana Card Number"
                type="text"
                placeholder="e.g. GHA-725667795-8"
                {...readerSearchForm}
              />
            </div>
          )}

          {readerSearchForm.values?.userIdentifier === "code" && (
            <div className="col-span-6 sm:col-span-3">
              <TextInput
                id="code"
                label="Reader Code"
                type="text"
                placeholder="e.g. MR12345678"
                {...readerSearchForm}
              />
            </div>
          )}
          <div className="col-span-6 sm:col-span-1 items-end justify-end flex">
            <button
              type="submit"
              className="w-full inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
            >
              {loading ? "Searching..." : "Search"}
            </button>
          </div>
        </form>
        <div className="grid grid-cols-3 gap-6 mt-6 pt-6 border-t border-gray-200">
          {data?.rows?.map?.((reader: any) => (
            <Owner
              key={reader._id}
              isActive={reader._id === form.values.reader?._id}
              onClick={wrapClick(() => form.setFieldValue("reader", reader))}
              owner={reader}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AssignItineraryForm;
