import { gql, useQuery } from "@apollo/client";
import { FC } from "react";
import { SearchSelectInput } from "components/core";

interface RoundPickerContainerProps {
  filter?: {
    region?: string;
    district?: string;
    block?: string;
    status?: ("Active" | "InActive");
  };
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  setFieldTouched?: any;
  setFieldError?: any;
  rawId?: boolean;
}

const GET_ROUNDS = gql`
  query GetRoundsSelect(
    $region: ID
    $district: ID
    $block: ID
    $status: RoundStatus
  ) {
    rounds: getRounds(
      region: $region
      district: $district
      block: $block
      status: $status
      sort: "code"
      page: 0
      pageSize: 0
    ) {
      _id
      code
      name
    }
  }
`;

const RoundPickerContainer: FC<RoundPickerContainerProps> = ({
  filter,
  id,
  label,
  rawId,
  ...form
}) => {
  const { loading, data } = useQuery(GET_ROUNDS, {
    variables: {
      ...(filter ? filter : {}),
    },
    notifyOnNetworkStatusChange: false,
  });

  return (
    <SearchSelectInput
      id={id ?? "round"}
      label={label ?? "Round"}
      placeholder="Select Round"
      optionsLoading={loading}
      options={(data?.rounds ?? [])?.map((round: any) => ({
        label: {
          title: round.code as string,
        },
        value: rawId ? round?._id : round,
      }))}
      {...form}
    />
  );
};

export default RoundPickerContainer;
