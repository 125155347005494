import moment from "moment";

const CustomerInfoSummary = ({ data, config: { dateFormat } }: any) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3">
    <div>
      <div className="grid grid-cols-3 gap-6">
        <div>
          <span className="block text-sm font-light text-gray-700">
            Priority
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.priority || "N/A"}
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Estimated Resolution Date
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.estimatedResolutionDate ? moment(data.estimatedResolutionDate).format(dateFormat) : "N/A"}
          </div>
        </div>
        <div className="col-span-3">
          <span className="block text-sm font-light text-gray-700">
            Extra Notes
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.notes || "N/A"}
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default CustomerInfoSummary;