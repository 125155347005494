import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Shimmers, TableComponent } from "components";
import moment from "moment";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  wrapClick,
  useUrlState,
  classNames,
  useTableData,
  wrapOnchange,
} from "utils";
import config from "config";
import ViewReadingExceptionContainer from "./view";
import { ExportDataContainer } from "containers";
import { currentConfigVar } from "apollo/cache/config";
import { Avatar, SelectInput } from "components/core";
import ActionButton, { Action } from "components/buttons/action-button";
import ApproveReadingExceptionContainer from "./approve";
import ResetReadingExceptionContainer from "./reset";
import InvestigateReadingExceptionContainer from "./investigate";
import CorrectReadingExceptionContainer from "./correct";
import lodash from "lodash";
import _ from "lodash";

const ReadingExceptionStatues = [
  "Pending",
  "Approved",
  "Reset",
  "Corrected",
  "InvestigationInitiated",
  "InvestigationInProgress",
  "InvestigationCompleted",
];

const GET_READING_EXCEPTIONS = gql`
  query GetReadingExceptions(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $fromDate: Date
    $toDate: Date
    $region: ID
    $district: ID
    $status: ReadingExceptionStatus
  ) {
    rows: getReadingExceptions(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      fromDate: $fromDate
      toDate: $toDate
      region: $region
      district: $district
      status: $status
    ) {
      _id
      code
      description
      value
      imageUrl
      type
      reader {
        _id
        lastName
        firstName
        profileImageUrl
        phoneNumber
        __typename
      }
      reading {
        _id
        code
        customerCode
        emailAddress
        phoneNumber
        servicePointNumber
        meterNumber
        accountNumber
        fullName
        meterLocation
        geoCode
        contractedDemand
        ghanaCardNumber
        profileImageUrl
        serviceType
        customerType
        qrCode
        address
        ghanaPostAddress
        tariffClass {
          _id
          code
          name
          __typename
        }
        tariffClassCode
        tariffClassName
        regionCode
        regionName
        districtCode
        districtName
        blockCode
        blockName
        roundCode
        roundName
        plotCode
        community
        itineraryCode
        itineraryDescription
        propertyCode
        __typename
      }
      status
      createdAt
      updatedAt
      __typename
    }
    count: getReadingExceptionsCount(
      search: $search
      searchFields: $searchFields
      fromDate: $fromDate
      toDate: $toDate
      region: $region
      district: $district
      status: $status
    )
  }
`;

const searchOptions = [
  {
    label: "Code",
    value: "code",
    min: 11,
  },
];

const ReadingExceptionsPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [exceptionStatus, setExcceptionStatus] = useUrlState("exceptionStatus");

  const [searchBy, setSearchParameter] = useUrlState("search_field");
  const [searchValue, setSearch] = useUrlState("search");
  const [minSearch, setMinSearch] = useUrlState("minSearch");

  const updateSearchBy = (key: string) => {
    setSearchParameter(key);
    if (key) {
      setMinSearch(searchOptions.find((option) => option.value === key)?.min);
    }
  };

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search:
        searchParams.search && searchBy
          ? (searchParams.search || "").toString()
          : undefined,
      searchFields: searchBy && searchValue ? [searchBy] : undefined,
      sort: searchParams.sort || "",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      fromDate: searchParams.fromDate ? searchParams.fromDate : undefined,
      toDate: searchParams.toDate ? searchParams.toDate : undefined,
      status: searchParams?.exceptionStatus || undefined,
    }),
    [searchParams]
  );

  const { data, loading, networkStatus, refetch } = useQuery(
    GET_READING_EXCEPTIONS,
    {
      variables: filter,
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
      }),
    });
  }, [navigate]);

  const records = useTableData(data || {});

  return (
    <main className='flex-1 flex flex-col overflow-hidden bg-gray-50'>
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={"exceptions"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            onSearchClicked={setSearch}
            disableSearch={!searchBy}
            hasSearch={true}
            renderSearchOption={() => (
              <SelectInput
                id={"searchBy"}
                label={""}
                values={{ searchBy }}
                handleChange={(e: any) => updateSearchBy(e.target.value)}
                handleBlur={undefined}
                options={[
                  {
                    label: "Search By",
                    value: "",
                  },
                  ...searchOptions,
                ]}
              />
            )}
            renderHeaderItems={() => (
              <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex space-x-2'>
                <SelectInput
                  id='exceptionStatus'
                  label=''
                  handleBlur={() => {}}
                  handleChange={wrapOnchange(setExcceptionStatus)}
                  options={[
                    { label: "--- Select Exception Status ---", value: "" },
                    ...ReadingExceptionStatues.map((exceptionStatus) => ({
                      value: exceptionStatus,
                      label: _.startCase(exceptionStatus),
                    })),
                  ]}
                  placeholder='Select Exception Status'
                  values={{ exceptionStatus }}
                />
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Code | Date
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Type | Description
                </th>
                {/* {!region && (
                  <th
                    scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Region
                  </th>
                )}
                {!district && (
                  <th
                    scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    District
                  </th>
                )} */}
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Customer
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Acc No | SPN
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Property
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Reported By
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Status
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>
                {/* {!region && (
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                )}
                {!district && (
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                )} */}
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.AvatarShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.ActionsShimmer actionsCount={1} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='font-medium text-gray-900 dark:text-gray-100'>
                    {item?.code || "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {moment(item?.createdAt).format(dateFormat + " hh:mm A")}
                  </div>
                </td>
                <td className=' px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='text-gray-900 dark:text-gray-100 truncate max-w-[400px]'>
                    {lodash.startCase(item?.type || "Unknown")}
                  </div>
                  <div className='text-gray-500 dark:text-gray-400 truncate max-w-[400px]'>
                    {_.startCase(item?.description) || "Unknown"}
                  </div>
                </td>
                {/* {!region && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                    <div className="font-medium text-gray-900 dark:text-gray-100">{item?.regionName || item?.customer?.regionName || "N/A"}</div>
                    <div className="text-gray-500 dark:text-gray-400">{item?.regionCode || item?.customer?.regionCode || "N/A"}</div>
                  </td>
                )}
                {!district && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                    <div className="font-medium text-gray-900 dark:text-gray-100">{item?.districtName || item?.customer?.districtName || "N/A"}</div>
                    <div className="text-gray-500 dark:text-gray-400">{item?.districtCode || item?.customer?.districtCode || "N/A"}</div>
                  </td>
                )} */}
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='flex items-center'>
                    <div className='h-10 w-10 flex-shrink-0'>
                      <Avatar
                        disabled={true}
                        alt={item?.reading?.fullName?.trim() || "N A"}
                        src={item?.reading?.profileImageUrl || ""}
                      />
                    </div>
                    <div className='ml-4'>
                      <div className='text-gray-900 dark:text-gray-100'>
                        {item?.reading?.fullName?.trim() || "N A"}
                      </div>
                      <div className='text-gray-500 dark:text-gray-400'>
                        {item?.reading?.phoneNumber || "N/A"}
                      </div>
                    </div>
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.reading?.accountNumber || "N A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.reading?.servicePointNumber || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.property?.code ||
                      item?.reading?.propertyCode ||
                      "N A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.property?.ghanaPostAddress ||
                      item?.reading?.ghanaPostAddress ||
                      "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='flex items-center'>
                    <div className='h-10 w-10 flex-shrink-0'>
                      <Avatar
                        disabled={true}
                        alt={
                          [
                            (item?.reader?.lastName || "")?.trim(),
                            (item?.reader?.firstName || "")?.trim(),
                          ]
                            .join(" ")
                            .trim() || "N A"
                        }
                        src={item?.reader?.profileImageUrl || ""}
                      />
                    </div>
                    <div className='ml-4'>
                      <div className='text-gray-900 dark:text-gray-100'>
                        {[
                          (item?.reader?.lastName || "")?.trim(),
                          (item?.reader?.firstName || "")?.trim(),
                        ]
                          .join(" ")
                          .trim() || "N A"}
                      </div>
                      <div className='text-gray-500 dark:text-gray-400'>
                        {item?.reader?.phoneNumber || "N/A"}
                      </div>
                    </div>
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <span
                    className={classNames(
                      item?.status === "InvestigationInitiated"
                        ? `bg-red-50 text-red-800`
                        : "",
                      item?.status === "Pending"
                        ? `bg-amber-100 text-amber-800`
                        : "",
                      item?.status === "Approved"
                        ? `bg-green-100 text-green-800`
                        : "",
                      item?.status === "Reset"
                        ? `bg-green-100 text-green-800`
                        : "",
                      item?.status === "Corrected"
                        ? `bg-green-100 text-green-800`
                        : "",
                      !item?.status ? "bg-gray-200 text-gray-800" : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 10 10'
                      fill='currentColor'
                      className='w-1.5 h-1.5'
                    >
                      <circle
                        fillRule='evenodd'
                        cx='5'
                        cy='5'
                        r='5'
                        clipRule='evenodd'
                      />
                    </svg>
                    <span>{_.startCase(item?.status) || "Unknown"}</span>
                  </span>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    <ActionButton
                      action='approve'
                      disabled={item?.status !== "Pending"}
                      onClick={dispatchAction(item?._id, "approve")}
                    />
                    <ActionButton
                      action='correct'
                      disabled={item?.status !== "Pending"}
                      onClick={dispatchAction(item?._id, "correct")}
                    />
                    <ActionButton
                      action='reset'
                      disabled={item?.status !== "Pending"}
                      onClick={dispatchAction(item?._id, "reset")}
                    />
                    <ActionButton
                      action='investigate'
                      disabled={item?.status !== "Pending"}
                      onClick={dispatchAction(item?._id, "investigate")}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>
      {!!searchParams.id?.length && (
        <>
          <ApproveReadingExceptionContainer
            open={modal === "approve"}
            refetch={refetch}
            setOpen={(val: boolean) => setModal(val ? "approve" : undefined)}
          />
          <CorrectReadingExceptionContainer
            open={modal === "correct"}
            refetch={refetch}
            setOpen={(val: boolean) => setModal(val ? "correct" : undefined)}
          />
          <ResetReadingExceptionContainer
            open={modal === "reset"}
            refetch={refetch}
            setOpen={(val: boolean) => setModal(val ? "reset" : undefined)}
          />
          <InvestigateReadingExceptionContainer
            open={modal === "investigate"}
            refetch={refetch}
            setOpen={(val: boolean) =>
              setModal(val ? "investigate" : undefined)
            }
          />
          <ViewReadingExceptionContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default ReadingExceptionsPage;
