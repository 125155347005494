import { gql, useQuery } from "@apollo/client";

export const GET_COMPLAINTS = gql`
  query GetComplaints(
    $page: Int
    $pageSize: Int
    $search: String
    $searchFields: [String!]
    $sort: String
    $userRole: ID
    $type: ID
    $status: ComplaintStatus
    $region: ID
    $district: ID
    $servicePoint: ID

  ) {
    rows: getComplaints(
      page: $page
      pageSize: $pageSize
      search: $search
      searchFields: $searchFields
      sort: $sort
      userRole: $userRole
      type: $type
      status: $status
      region: $region
      district: $district
      servicePoint: $servicePoint
    ) {
      _id
      code
      description
      createdAt
      updatedAt
      createdBy {
        code
        firstName
        lastName
        profileImageUrl
      }
      meter {
        code
        status
      }
      customer {
        code
        representative {
          fullName
          phoneNumber
        }
        organization {
          name
        }
      }
      contactPerson {
        emailAddress
        fullName
        phoneNumber
      }
      customerCode
      type {
        code
        name
      }
      status
      servicePointCode
      userRole {
        _id
      }
    }
    count: getComplaintsCount(
      search: $search
      searchFields: $searchFields
      type: $type
      status: $status
      userRole: $userRole
      region: $region
      district: $district
      servicePoint: $servicePoint

    )
  }
`;

const GET_SERVICE_ORDER_SUMMARY = gql`
  query GetComplaintSummary($region: ID, $district: ID, $userRole: ID) {
    getComplaintSummary(
      region: $region
      district: $district
      userRole: $userRole
    ) {
      Open
      InProgress
      Closed
      Escalated
    }
  }
`;

export const useComplaints = (variables: any, searchParams?: any) => {
  const { data: dataSummary, refetch } = useQuery(GET_SERVICE_ORDER_SUMMARY, {
    variables: {
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      userRole: variables.userRole || undefined,
    },
    notifyOnNetworkStatusChange: false,
  });
  const { data, ...rest } = useQuery(GET_COMPLAINTS, {
    variables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  return {
    complaints: data?.rows || [],
    count: data?.count || 0,
    summary: dataSummary?.getComplaintSummary || {},
    ...rest,
    refetch: (variables?: any) => [rest?.refetch({ variables }), refetch()],
  };
};
