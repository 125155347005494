import { FC, useRef } from "react"
import { wrapClick } from "utils";
import {
  gql,
  useMutation,
  useQuery,
} from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, ResolveReconnectionServiceOrderForm } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_SERVICE_ORDER } from "./view";
import * as yup from "yup";

export interface ResolveReconnectionServiceOrderInput {
  result: string
  resolution: Resolution
}

export interface Resolution {
  notes: string
  reading: Reading
  supportingImageUrls: any[]
}

export interface Reading {
  readingDate: string
  readingImageUrl: string
  readingValue: string
}
interface IResolveReconnectionServiceOrderProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}

const RESOLVE_SERVICE_ORDER = gql`
 mutation ResolveReconnectionServiceOrder(
  $id: ID!
  $resolution: ReconnectionServiceOrderResolutionInput!
  $result: ReconnectionServiceOrderResult!
) {
  resolveReconnectionServiceOrder(
    id: $id
    resolution: $resolution
    result: $result
  ) {
    _id
  }
}

`





const ResolveReconnectionServiceOrderContainer: FC<IResolveReconnectionServiceOrderProps> = ({ open, setOpen, refetch }) => {

  const searchParams = useSearch<LocationGenerics>();
  const { data } = useQuery(GET_SERVICE_ORDER, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });
  const cancelButtonRef = useRef(null);
  const [resolveReconnectionServiceOrder, { loading }] = useMutation(RESOLVE_SERVICE_ORDER)

  const form = useFormik<ResolveReconnectionServiceOrderInput>({
    initialValues: {
      result: "",
      resolution: {
        notes: "",
        reading: {
          readingDate: "",
          readingImageUrl: "",
          readingValue: ""
        },
        supportingImageUrls: []
      }
    },
    validationSchema: yup.object().shape({
      result: yup.string().required("Result is required"),
      resolution: yup.object().shape({
        notes: yup.string().required("Notes is required"),
        reading: yup.object().shape({
          readingDate: yup.string().required("Reading Date is required"),
          readingImageUrl: yup.string().required("Reading Image is required"),
          readingValue: yup.string().required("Reading Value is required")
        }),
        supportingImageUrls: yup.array().of(yup.string().required("Supporting Image is required"))
      })
    }),
    onSubmit: async (values) => {
      await resolveReconnectionServiceOrder({
        variables: {
          id: data?.serviceOrder?._id,
          ...values
        }
      }).then(({ data }) => {
        if (data.resolveReconnectionServiceOrder._id) {
          toast(JSON.stringify({ type: "success", title: "Service Order Resolved Successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not resolve Service Order" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Resolve Service Order"
      description="Provide reasons for resolving service order"
      renderActions={() => (
        <button
          type="button"
          disabled={loading}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.handleSubmit)}
        >
          {loading ? "Resolving Service Order..." : "Resolve Service Order"}
        </button>
      )}
    >
      {data?.serviceOrder?._id && (
        <ResolveReconnectionServiceOrderForm
          form={form}
        />
      )}
    </Modal>
  )
}

export default ResolveReconnectionServiceOrderContainer