export default [
  { region: "Ashanti Region", district: "Adansi North", code: "A2" },
  { region: "Ashanti Region", district: "Ahafo Ano South", code: "AY" },
  { region: "Ashanti Region", district: "Asante Akim North", code: "AN" },
  { region: "Ashanti Region", district: "Atwima Mponua", code: "AI" },
  { region: "Ashanti Region", district: "Bosome Freho Ext", code: "A9" },
  { region: "Ashanti Region", district: "Kumasi", code: "AK" },
  { region: "Ashanti Region", district: "Obuasi", code: "AO" },
  { region: "Ashanti Region", district: "Sekyere Central", code: "AQ" },
  { region: "Ashanti Region", district: "Adansi South", code: "A3" },
  { region: "Ashanti Region", district: "Amansie Central", code: "AV" },
  { region: "Ashanti Region", district: "Asante Akim South", code: "AA" },
  { region: "Ashanti Region", district: "Atwima Nwabiagya", code: "AH" },
  { region: "Ashanti Region", district: "Bosomtwe", code: "AT" },
  { region: "Ashanti Region", district: "Kumawu", code: "AU" },
  { region: "Ashanti Region", district: "Offinso North", code: "A6" },
  { region: "Ashanti Region", district: "Sekyere East", code: "AR" },
  { region: "Ashanti Region", district: "Afigya Kwabre", code: "AF" },
  { region: "Ashanti Region", district: "Amansie West", code: "AW" },
  { region: "Ashanti Region", district: "Asokore Mampong", code: "AS" },
  { region: "Ashanti Region", district: "Bekwai", code: "AB" },
  { region: "Ashanti Region", district: "Ejisu Juaben", code: "AE" },
  { region: "Ashanti Region", district: "Kwabre East", code: "AD" },
  { region: "Ashanti Region", district: "Offinso South", code: "A7" },
  { region: "Ashanti Region", district: "Sekyere South", code: "AZ" },
  { region: "Ashanti Region", district: "Ahafo Ano North", code: "AX" },
  { region: "Ashanti Region", district: "Asante Akim Central", code: "AC" },
  { region: "Ashanti Region", district: "Atwima Kwanwoma", code: "AG" },
  { region: "Ashanti Region", district: "Bosome Freho", code: "A4" },
  { region: "Ashanti Region", district: "Ejura-Sekyedumase", code: "AJ" },
  { region: "Ashanti Region", district: "Mampong", code: "AM" },
  { region: "Ashanti Region", district: "Sekyere Afram Plains", code: "AP" },
  { region: "Brong Ahafo Region", district: "Asunafo North", code: "Bu" },
  { region: "Brong Ahafo Region", district: "Atebubu-Amantin", code: "BA" },
  { region: "Brong Ahafo Region", district: "Dormaa East", code: "BE" },
  { region: "Brong Ahafo Region", district: "Kintampo North", code: "BK" },
  { region: "Brong Ahafo Region", district: "Pru", code: "BP" },
  { region: "Brong Ahafo Region", district: "Sunyani West", code: "BY" },
  { region: "Brong Ahafo Region", district: "Techiman", code: "BT" },
  { region: "Brong Ahafo Region", district: "Asunafo South", code: "BV" },
  { region: "Brong Ahafo Region", district: "Banda", code: "BC" },
  { region: "Brong Ahafo Region", district: "Dormaa West", code: "BF" },
  { region: "Brong Ahafo Region", district: "Kintampo South", code: "BL" },
  { region: "Brong Ahafo Region", district: "Sene East", code: "BG" },
  { region: "Brong Ahafo Region", district: "Tain", code: "BZ" },
  { region: "Brong Ahafo Region", district: "Techiman North", code: "BX" },
  { region: "Brong Ahafo Region", district: "Asutifi North", code: "BQ" },
  { region: "Brong Ahafo Region", district: "Berekum", code: "BB" },
  { region: "Brong Ahafo Region", district: "Jaman North  ", code: "BJ" },
  { region: "Brong Ahafo Region", district: "Nkoranza North", code: "BN" },
  { region: "Brong Ahafo Region", district: "Sene West", code: "BH" },
  { region: "Brong Ahafo Region", district: "Tano North", code: "B2" },
  { region: "Brong Ahafo Region", district: "Wenchi", code: "BW" },
  { region: "Brong Ahafo Region", district: "Asutifi South", code: "BR" },
  { region: "Brong Ahafo Region", district: "Dormaa", code: "BD" },
  { region: "Brong Ahafo Region", district: "Jaman South", code: "BI" },
  { region: "Brong Ahafo Region", district: "Nkoranza South", code: "BO" },
  { region: "Brong Ahafo Region", district: "Sunyani", code: "BS" },
  { region: "Brong Ahafo Region", district: "Tano South", code: "B3" },
  { region: "Central Region", district: "Abura Asebu Kwamankese", code: "CA" },
  { region: "Central Region", district: "Asikuma / Odoben / Brakwa", code: "CB" },
  { region: "Central Region", district: "Awutu Senya East", code: "CX" },
  { region: "Central Region", district: "Gomoa East", code: "CG" },
  { region: "Central Region", district: "Twifo Ati-Morkwa", code: "CT" },
  { region: "Central Region", district: "Gomoa East", code: "CG" },
  { region: "Central Region", district: "Agona East", code: "CP" },
  { region: "Central Region", district: "Assin North", code: "CR" },
  { region: "Central Region", district: "Cape Coast", code: "CC" },
  { region: "Central Region", district: "Twifo/Heman/Lower Denkyira", code: "CH" },
  { region: "Central Region", district: "Agona West ", code: "CO" },
  { region: "Central Region", district: "Assin South", code: "CS" },
  { region: "Central Region", district: "Effutu", code: "CE" },
  { region: "Central Region", district: "Komenda Edina Eguafo", code: "CK" },
  { region: "Central Region", district: "Upper Denkyira East", code: "CU" },
  { region: "Central Region", district: "Ajumako Enyan Esiam", code: "CJ" },
  { region: "Central Region", district: "Awutu Senya", code: "CW" },
  { region: "Central Region", district: "Ekumfi", code: "CF" },
  { region: "Central Region", district: "Mfantseman", code: "CM" },
  { region: "Central Region", district: "Upper Denkyira West", code: "CV" },
  { region: "Eastern Region", district: "Akuapem North", code: "E2" },
  { region: "Eastern Region", district: "Atiwa", code: "ET" },
  { region: "Eastern Region", district: "Birim South", code: "EZ" },
  { region: "Eastern Region", district: "Kwaebibirem", code: "EK" },
  { region: "Eastern Region", district: "Kwahu South", code: "EI" },
  { region: "Eastern Region", district: "Nsawam Adoagyiri", code: "EG" },
  { region: "Eastern Region", district: "West Akim", code: "EW" },
  { region: "Eastern Region", district: "Akuapem South", code: "E3" },
  { region: "Eastern Region", district: "Ayensuano", code: "EO" },
  { region: "Eastern Region", district: "Denkyembour", code: "ED" },
  { region: "Eastern Region", district: "Kwahu Afram Plains North", code: "EP" },
  { region: "Eastern Region", district: "Kwahu West", code: "EJ" },
  { region: "Eastern Region", district: "Suhum", code: "ES" },
  { region: "Eastern Region", district: "Akyemansa", code: "EM" },
  { region: "Eastern Region", district: "Birim Central", code: "EB" },
  { region: "Eastern Region", district: "East Akim", code: "EE" },
  { region: "Eastern Region", district: "Kwahu Afram Plains South", code: "EQ" },
  { region: "Eastern Region", district: "Lower Manya Krobo", code: "EL" },
  { region: "Eastern Region", district: "Upper Manya Krobo", code: "EU" },
  { region: "Eastern Region", district: "Asuogyaman", code: "EA" },
  { region: "Eastern Region", district: "Birim North", code: "EX" },
  { region: "Eastern Region", district: "Fanteakwa", code: "EF" },
  { region: "Eastern Region", district: "Kwahu East", code: "EH" },
  { region: "Eastern Region", district: "New Juaben", code: "EN" },
  { region: "Eastern Region", district: "Upper West Akim", code: "EV" },
  { region: "Eastern Region", district: "Yilo Krobo", code: "EY" },
  { region: "Greater Accra Region", district: "Accra", code: "GA" },
  { region: "Greater Accra Region", district: "Ashaiman", code: "GB" },
  { region: "Greater Accra Region", district: "Ga West", code: "GW" },
  { region: "Greater Accra Region", district: "Ledzokuku Krowor", code: "GZ" },
  { region: "Greater Accra Region", district: "Ada East", code: "GY" },
  { region: "Greater Accra Region", district: "Ga Central", code: "GC" },
  { region: "Greater Accra Region", district: "Kpone Katamanso", code: "GK" },
  { region: "Greater Accra Region", district: "Ningo Prampram", code: "GN" },
  { region: "Greater Accra Region", district: "Ada West ", code: "GX" },
  { region: "Greater Accra Region", district: "Ga East", code: "GE" },
  { region: "Greater Accra Region", district: "La Dade-Kotopon", code: "GL" },
  { region: "Greater Accra Region", district: "Shai-Osudoku", code: "GO" },
  { region: "Greater Accra Region", district: "Adentann", code: "GD" },
  { region: "Greater Accra Region", district: "Ga South", code: "GS" },
  { region: "Greater Accra Region", district: "La Nkwantanang-Madina", code: "GM" },
  { region: "Greater Accra Region", district: "Tema", code: "GT" },
  { region: "Northern Region", district: "Bole", code: "NB" },
  { region: "Northern Region", district: "East Gonja", code: "N4" },
  { region: "Northern Region", district: "Kpandai", code: "NA" },
  { region: "Northern Region", district: "Nanumba North", code: "NN" },
  { region: "Northern Region", district: "Sagnerigu", code: "NS" },
  { region: "Northern Region", district: "Tatale Sangule", code: "NF" },
  { region: "Northern Region", district: "Yendi", code: "NY" },
  { region: "Northern Region", district: "Bunkpurugu-Yunyoo", code: "NP" },
  { region: "Northern Region", district: "East Mamprusi", code: "NE" },
  { region: "Northern Region", district: "Kumbungu", code: "NK" },
  { region: "Northern Region", district: "Nanumba South", code: "NO" },
  { region: "Northern Region", district: "Savelugu-Nanton", code: "NU" },
  { region: "Northern Region", district: "Tolon", code: "NL" },
  { region: "Northern Region", district: "Central Gonja", code: "N3" },
  { region: "Northern Region", district: "Gushiegu", code: "NG" },
  { region: "Northern Region", district: "Mamprugu Moaduri", code: "NM" },
  { region: "Northern Region", district: "North Gonja", code: "N2" },
  { region: "Northern Region", district: "Sawla Tuna Kalba", code: "NW" },
  { region: "Northern Region", district: "West Gonja", code: "N5" },
  { region: "Northern Region", district: "Chereponi", code: "NC" },
  { region: "Northern Region", district: "Karaga", code: "NR" },
  { region: "Northern Region", district: "Mion", code: "NI" },
  { region: "Northern Region", district: "Saboba", code: "NX" },
  { region: "Northern Region", district: "Tamale", code: "NT" },
  { region: "Northern Region", district: "West Mamprusi", code: "ND" },
  { region: "Northern Region", district: "Zabzugu", code: "NZ" },
  { region: "Upper East Region", district: "Bawku", code: "UA" },
  { region: "Upper East Region", district: "Bongo", code: "UO" },
  { region: "Upper East Region", district: "Kassena Nankana East", code: "UK" },
  { region: "Upper East Region", district: "Talensi", code: "UT" },
  { region: "Upper East Region", district: "Bawku West", code: "UW" },
  { region: "Upper East Region", district: "Builsa North", code: "UR" },
  { region: "Upper East Region", district: "Kassena Nankana West", code: "UL" },
  { region: "Upper East Region", district: "Binduri", code: "UU" },
  { region: "Upper East Region", district: "Builsa South", code: "US" },
  { region: "Upper East Region", district: "Nabdam  ", code: "UN" },
  { region: "Upper East Region", district: "Bolgatanga", code: "UB" },
  { region: "Upper East Region", district: "Garu-Tempane", code: "UG" },
  { region: "Upper East Region", district: "Pusiga", code: "UP" },
  { region: "Upper West Region", district: "Daffiama Bussie Issa", code: "XD" },
  { region: "Upper West Region", district: "Nadowli Kaleo", code: "XO" },
  { region: "Upper West Region", district: "Wa", code: "XW" },
  { region: "Upper West Region", district: "Jirapa", code: "XJ" },
  { region: "Upper West Region", district: "Nandom", code: "XN" },
  { region: "Upper West Region", district: "Wa East", code: "XX" },
  { region: "Upper West Region", district: "Lambussie Karni", code: "XK" },
  { region: "Upper West Region", district: "Sissala East", code: "XS" },
  { region: "Upper West Region", district: "Wa West", code: "XY" },
  { region: "Upper West Region", district: "Lawra", code: "XL" },
  { region: "Upper West Region", district: "Sissala West", code: "XT" },
  { region: "Volta Region", district: "Adaklu", code: "VA" },
  { region: "Volta Region", district: "Akatsi South", code: "VX" },
  { region: "Volta Region", district: "Ho West", code: "VI" },
  { region: "Volta Region", district: "Keta", code: "VK" },
  { region: "Volta Region", district: "Krachi EasT", code: "VR" },
  { region: "Volta Region", district: "Nkwanta South", code: "VO" },
  { region: "Volta Region", district: "South Tongu", code: "VE" },
  { region: "Volta Region", district: "Afadjato South", code: "VF" },
  { region: "Volta Region", district: "Biakoye", code: "VB" },
  { region: "Volta Region", district: "Hohoe", code: "VC" },
  { region: "Volta Region", district: "Ketu North", code: "VY" },
  { region: "Volta Region", district: "Krachi Nchumuru", code: "VQ" },
  { region: "Volta Region", district: "North Dayi", code: "VD" },
  { region: "Volta Region", district: "Agotime Ziope", code: "VG" },
  { region: "Volta Region", district: "Central Tongu", code: "VV" },
  { region: "Volta Region", district: "Jasikan", code: "VJ" },
  { region: "Volta Region", district: "Ketu South", code: "VZ" },
  { region: "Volta Region", district: "Krachi West", code: "VS" },
  { region: "Volta Region", district: "North Tongu", code: "VT" },
  { region: "Volta Region", district: "Akatsi North", code: "VW" },
  { region: "Volta Region", district: "Ho", code: "VH" },
  { region: "Volta Region", district: "Kadjebi", code: "VM" },
  { region: "Volta Region", district: "Kpando", code: "VP" },
  { region: "Volta Region", district: "Nkwanta North", code: "VN" },
  { region: "Volta Region", district: "South Dayi", code: "VE" },
  { region: "Western Region", district: "Ahanta West", code: "WH" },
  { region: "Western Region", district: "Bibiani/Anhwiaso/Bekwai", code: "WB" },
  { region: "Western Region", district: "Juaboso", code: "WQ" },
  { region: "Western Region", district: "Sefwi Akontombra", code: "WF" },
  { region: "Western Region", district: "Suaman", code: "WU" },
  { region: "Western Region", district: "Wassa Amenfi West", code: "WY" },
  { region: "Western Region", district: "Aowin", code: "WA" },
  { region: "Western Region", district: "Bodi", code: "WO" },
  { region: "Western Region", district: "Mpohor", code: "WM" },
  { region: "Western Region", district: "Sefwi Wiaso", code: "WG" },
  { region: "Western Region", district: "Tarkwa Nsuaem", code: "WT" },
  { region: "Western Region", district: "Wassa East", code: "WZ" },
  { region: "Western Region", district: "Bia East", code: "WC" },
  { region: "Western Region", district: "Ellembelle", code: "WE" },
  { region: "Western Region", district: "Nzema East", code: "WN" },
  { region: "Western Region", district: "Sekondi-Takoradi", code: "WS" },
  { region: "Western Region", district: "Wassa Amenfi Central", code: "WW" },
  { region: "Western Region", district: "Bia West", code: "WD" },
  { region: "Western Region", district: "Jomoro", code: "WJ" },
  { region: "Western Region", district: "Prestea Huni Valley", code: "WP" },
  { region: "Western Region", district: "Shama", code: "WR" },
  { region: "Western Region", district: "Wassa Amenfi East", code: "WX" },
] as const;