import { gql, useQuery } from "@apollo/client";
import { Header, SelectInput, Shimmers, TableComponent } from "components";
import _ from "lodash";
import numeral from "numeral";
import { FC, useEffect, useMemo } from "react";
import { useMatch, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick, useUrlState, withPermissions, useTableData } from "utils";
import config from "config";
import ViewPlotContainer from "./view";
import ActionButton, { Action } from "components/buttons/action-button";

const GET_PLOTS = gql`
  query GetPlots(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $block: ID
    $round: ID
  ) {
    rows: getPlots(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      block: $block
      round: $round
    ) {
      _id
      code
      name
      region {
        _id
      }
      district {
        _id
      }
      block {
        _id
      }
      round {
        _id
      }
      meta {
        itinerariesCount
        propertiesCount
        customersCount
      }
      createdAt
      updatedAt
    }
    count: getPlotsCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      block: $block
      round: $round
    )
  }
`;

const searchOptions = [
  {
    label: "Code",
    value: "code",
    min: 11,
  },
];
const PlotsPage: FC = () => {
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };
  const navigateItem = (item: any) => () => {
    navigate({
      to: `/itineraries`,
      search: () => ({
        plot: item?._id,
        region: item?.region?._id,
        district: item?.district?._id,
        round: item?.round?._id,
        block: item?.block?._id,
      }),
    });
  };

  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");

  const [searchBy, setSearchParameter] = useUrlState("search_field");
  const [searchValue, setSearch] = useUrlState("search");
  const [minSearch, setMinSearch] = useUrlState("minSearch");

  const updateSearchBy = (key: string) => {
    setSearchParameter(key);
    if (key) {
      setMinSearch(searchOptions.find((option) => option.value === key)?.min);
    }
  };

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search:
        searchParams.search && searchBy
          ? (searchParams.search || "").toString()
          : undefined,
      searchFields: searchBy && searchValue ? [searchBy] : undefined,
      sort: searchParams.sort || "",
      block: params.block || undefined,
      round: params.round || undefined,
    }),
    [searchParams, params]
  );

  const { data, loading, networkStatus, refetch } = useQuery(GET_PLOTS, {
    variables: filter,
    notifyOnNetworkStatusChange: false,
  });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
      }),
    });
  }, [navigate]);

  const records = useTableData(data || {});

  return (
    <main className='flex-1 flex flex-col overflow-hidden h-screen bg-gray-50'>
      <Header />
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={"plots"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            onSearchClicked={setSearch}
            disableSearch={!searchBy}
            hasSearch={true}
            renderSearchOption={() => (
              <SelectInput
                id={"searchBy"}
                label={""}
                values={{ searchBy }}
                handleChange={(e: any) => updateSearchBy(e.target.value)}
                handleBlur={undefined}
                options={[
                  {
                    label: "Search By",
                    value: "",
                  },
                  ...searchOptions,
                ]}
              />
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Code
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Community
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Itineraries
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Properties
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Customers
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.ActionsShimmer actionsCount={3} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='font-medium text-gray-900 dark:text-gray-100'>
                    {item?.code || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.name || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {numeral(item?.meta?.itinerariesCount || 0).format("0,0")}{" "}
                    itineraries
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {numeral(item?.meta?.propertiesCount || 0).format("0,0")}{" "}
                    properties
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {numeral(item?.meta?.customersCount || 0).format("0,0")}{" "}
                    customers
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    {withPermissions([
                      "*:*",
                      "itineraries:*",
                      "itineraries:manage",
                    ])(
                      <ActionButton
                        action='goto'
                        onClick={navigateItem(item)}
                      />
                    )}
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {!!searchParams.id?.length && (
        <>
          <ViewPlotContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default PlotsPage;
