import { gql, useQuery } from "@apollo/client";

const GET_REPLACEMENTS = gql`
query GetReplacementServiceOrders(
  $page: Int
  $pageSize: Int
  $search: String
  $status: ReplacementServiceOrderStatus
  $searchFields: [String!]
  $sort: String
  $region: ID
  $district: ID
  $category: ReplacementServiceOrderCategory
  $priority: ReplacementServiceOrderPriority
  $replacementType: ReplacementServiceOrderType
  $adoraSyncStatus: ReplacementAdoraSyncStatus

  $replacementTypes: [ReplacementServiceOrderType]
  $fromDate: Date
  $toDate: Date
) {
  rows: getReplacementServiceOrders(
    page: $page
    pageSize: $pageSize
    search: $search
    searchFields: $searchFields
    sort: $sort
    region: $region
    district: $district
    category: $category
    status: $status
    priority: $priority
    replacementType: $replacementType
    replacementTypes: $replacementTypes
    adoraSyncStatus: $adoraSyncStatus

    fromDate: $fromDate
    toDate: $toDate
  ) {
    _id
    debtStatus
    customer {
      _id
      representative {
        fullName
        phoneNumber
        profileImageUrl
      }
      organization {
        name
      }
    }
    customerCode
    servicePointCode
    accountCode
    servicePoint {
      _id
      geoCode
      serviceClass
    }
    resolution {
      previousReading {
        readingDate
        readingImageUrl
        readingValue
      }
    }
    meter {
      _id
      code
      modelMeta {
        modelCode
        modelName
        brandCode
        brandName
        systemCode
        systemName
        phase
        digits
      }
    }
    completedAt
    code
    oldMeterNumber
    oldMeterPhase
    replacementReason
    replacementType
  }
  count: getReplacementServiceOrdersCount(
    search: $search
    searchFields: $searchFields
    region: $region
    district: $district
    status: $status
    category: $category
    priority: $priority
    replacementType: $replacementType
    replacementTypes: $replacementTypes
    adoraSyncStatus: $adoraSyncStatus

    fromDate: $fromDate
    toDate: $toDate
  )
}
`;

export const useReplacements = (variables?: any) => {
  const { data, ...rest } = useQuery(GET_REPLACEMENTS, {
    variables: {
      ...variables,
      status: "Completed",
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
  });

  return {
    rows: data?.rows,
    count: data?.count,
    ...rest,
  };
};
