import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { Shimmers, TableComponent } from 'components';
import moment from 'moment';
import { FC, useEffect, useMemo } from 'react'
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import {  useUrlState } from 'utils';
import config from 'config';
import { ExportDataContainer } from 'containers';
import { currentConfigVar } from 'apollo/cache/config';
import { Avatar } from 'components/core';

const GET_READING_ANOMALIES = gql`
  query GetReadingFollowups(
    $page: Int $pageSize: Int
    $sort: String
    $search: String $searchFields: [String!]
    $fromDate: DateTime $toDate: DateTime 
    $region: ID $district: ID
  ) {
    getReadingFollowups(
      page: $page pageSize: $pageSize
      sort: $sort
      search: $search searchFields: $searchFields
      fromDate: $fromDate toDate: $toDate
      region: $region district: $district
    ) {
      rows {
        _id
        code
        name
        phoneNumber
        followUpDate
        reader {
          _id
          lastName
          firstName
          profileImageUrl
          phoneNumber
        }
        reading {
          _id
        }
        customer {
          _id
          fullName
          profileImageUrl
          phoneNumber
          ghanaPostAddress
          propertyCode
          regionCode
          regionName
          districtCode
          districtName
        }
        createdAt
        updatedAt
      }
      total
      totalPages
      page
      pageSize
    }
  }
`

const ReadingFollowupsPage: FC = () => {
  const { pollInterval, dateFormat } = useReactiveVar(currentConfigVar)
  const viewItem = (id: string) => () => {
    navigate({
      search: (old) => ({
        ...old,
        id,
        modal: "view"
      })
    })
  }

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();

  const filter = useMemo(() => ({
    page: searchParams.page || 1,
    pageSize: searchParams.pageSize || 12,
    search: (searchParams.search || "").toString(),
    searchFields: ["code", "name"],
    sort: searchParams.sort || "",
    region: searchParams.region || undefined,
    district: searchParams.district || undefined,
    fromDate: searchParams.fromDate || undefined,
    toDate: searchParams.toDate || undefined,
  }), [searchParams])

  const { data, loading, networkStatus, refetch } = useQuery(GET_READING_ANOMALIES, {
    variables: filter,
    notifyOnNetworkStatusChange: false
  });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
      })
    })
  }, [navigate])

  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-gray-50">
      <div className='flex flex-1 overflow-y-auto'>
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={'followups'}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={data?.getReadingFollowups}
            hasSearch={true}
            // renderExport={({ exportOpen, setExportOpen }) => (
            //   <ExportDataContainer
            //     open={exportOpen}
            //     setOpen={setExportOpen}
            //     entityType="ReadingFollowup"
            //     filter={filter}
            //   />
            // )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Code | Date
                </th>
                {/* {!region && (
                  <th
                    scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Region
                  </th>
                )}
                {!district && (
                  <th
                    scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    District
                  </th>
                )} */}
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Customer
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Contact Info
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Follow Up Date
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Property
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Recorded By
                </th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                {/* {!region && (
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                )}
                {!district && (
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                )} */}
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.AvatarShimmer />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
              // onClick={wrapClick(viewItem(item._id))}
              >
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100">{item?.code || "N/A"}</div>
                  <div className="text-gray-500 dark:text-gray-400">{moment(item?.createdAt).format(dateFormat + " hh:mm A")}</div>
                </td>
                {/* {!region && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                    <div className="font-medium text-gray-900 dark:text-gray-100">{item?.regionName || item?.customer?.regionName || "N/A"}</div>
                    <div className="text-gray-500 dark:text-gray-400">{item?.regionCode || item?.customer?.regionCode || "N/A"}</div>
                  </td>
                )}
                {!district && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                    <div className="font-medium text-gray-900 dark:text-gray-100">{item?.districtName || item?.customer?.districtName || "N/A"}</div>
                    <div className="text-gray-500 dark:text-gray-400">{item?.districtCode || item?.customer?.districtCode || "N/A"}</div>
                  </td>
                )} */}
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <Avatar
                        disabled={true}
                        alt={item?.customer?.fullName?.trim() || "N A"}
                        src={item?.customer?.profileImageUrl || ""}
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-gray-900 dark:text-gray-100">{item?.customer?.fullName?.trim() || "N A"}</div>
                      <div className="text-gray-500 dark:text-gray-400">{item?.customer?.phoneNumber || "N/A"}</div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">{item?.name || "N A"}</div>
                  <div className="text-gray-500 dark:text-gray-400">{item?.phoneNumber || "N/A"}</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100 line-clamp-2">{moment(item?.followUpDate).format(dateFormat)}</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">{item?.property?.code || item?.customer?.propertyCode || "N A"}</div>
                  <div className="text-gray-500 dark:text-gray-400">{item?.property?.ghanaPostAddress || item?.customer?.ghanaPostAddress || "N/A"}</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <Avatar
                        disabled={true}
                        alt={[(item?.reader?.lastName || "")?.trim(), (item?.reader?.firstName || "")?.trim()].join(" ").trim() || "N A"}
                        src={item?.reader?.profileImageUrl || ""}
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-gray-900 dark:text-gray-100">{[(item?.reader?.lastName || "")?.trim(), (item?.reader?.firstName || "")?.trim()].join(" ").trim() || "N A"}</div>
                      <div className="text-gray-500 dark:text-gray-400">{item?.reader?.phoneNumber || "N/A"}</div>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          />
        </div>

      </div>
    </main>
  )
}

export default ReadingFollowupsPage;
