import moment from "moment";
import numeral from "numeral";

const FraudInfoSummary = ({ data, values }: any) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3">
    <div>
      <div className="grid grid-cols-3 gap-6">
        <div>
          <span className="block text-sm font-light text-gray-700">
            Previous Reading Value
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {numeral(values?.customerInfo?.existingServicePoint?.meta?.lastCmsActualReadingValue).format("0,0") || "N/A"} KWh
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Previous Reading Date
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {moment(values?.customerInfo?.existingServicePoint?.meta?.lastCmsActualReadingDate).format("DD/MM/YYYY") || "N/A"}
          </div>
        </div>
        <div className="col-start-1">
          <span className="block text-sm font-light text-gray-700">
            Current Reading Value
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {numeral(data?.currentReadingValue).format("0,0") || "N/A"} KWh
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Current Reading Date
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {moment(data?.currentReadingDate).format("DD/MM/YYYY") || "N/A"}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default FraudInfoSummary;
