import { FC } from "react";
import { Avatar } from "components/core";
import { classNames, wrapImage } from "utils";
import numeral from "numeral";
import moment from "moment";
import lodash from "lodash";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import TableEmptyComponent from "components/tables/table-empty";
import { PhotoIcon } from "@heroicons/react/24/outline";

interface CustomerReadingViewProps {
  readings: any[];
  loading: boolean;
}

const CustomerReadingView: FC<CustomerReadingViewProps> = ({ readings }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      {readings?.length > 0 ? (
        <>
          {readings?.map((reading) => (
            <div>
              <span className='text-xs font-light'>Reading Information</span>
              <div className='grid grid-cols-4 gap-6 mt-2'>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Outstanding Amount
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    GHS{" "}
                    {numeral(
                      reading?.previousReading?.totalAmountDue || 0
                    ).format("0,0.00")}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Reading Date
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {reading?.currentReading?.readingDate
                      ? moment(reading.currentReading.readingDate).format(
                          dateFormat
                        )
                      : "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Reading Value
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {reading?.currentReading?.readingValue
                      ? `${numeral(
                          reading.currentReading.readingValue || 0
                        ).format("0,0")} KWh`
                      : "N/A"}
                  </div>
                </div>

                <div className='col-span-4'>
                  <span className='block text-sm font-light text-gray-700'>
                    Reading Image
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {reading?.meta?.readingImageUrl ? (
                      wrapImage(
                        <img
                          src={reading?.meta?.readingImageUrl}
                          alt='reading'
                          className='h-64 w-full object-cover object-center'
                        />
                      )
                    ) : (
                      <div className='flex  mt-2 w-full items-center justify-center'>
                        <div className='relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-6 text-center  focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'>
                          <PhotoIcon
                            className='mx-auto h-10 w-10 text-gray-400'
                            stroke='currentColor'
                            strokeWidth={1}
                            aria-hidden='true'
                          />
                          <h3 className='mt-2 text-sm font-medium text-gray-900'>
                            No Image
                          </h3>
                          <p className='mt-1 text-sm text-gray-500'></p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className='flex min-h-[600px] items-center justify-center'>
          <div className='text-center'>
            <svg
              className='mx-auto h-12 w-12 text-gray-400 dark:text-gray-300'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
              aria-hidden='true'
            >
              <path
                vectorEffect='non-scaling-stroke'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z'
              />
            </svg>
            <h3 className='mt-2 text-sm font-medium text-gray-900 dark:text-gray-100'>
              No Reading History
            </h3>
            <p className='mt-1 text-sm text-gray-500 dark:text-gray-400'>
              Kindly Refer To CMS For Reading History.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerReadingView;
