import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Header, OfficeHeader, Shimmers, TableComponent } from "components";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  wrapClick,
  useUrlState,
  classNames,
  withPermissions,
  useTableData,
} from "utils";
import config from "config";
import AssignReadingSetContainer from "./assign";
import ScheduleReadingSetContainer from "./schedule";
import ViewReadingSetContainer from "./view";
import ReadingSetsFilterContainer from "./filter";
import {
  Avatar,
  MonthPicker,
  SearchSelectInput,
  SelectInput,
} from "components/core";
import { currentConfigVar } from "apollo/cache/config";
import ActionButton, { Action } from "components/buttons/action-button";
import { BlockPicker, ExportDataContainer, RoundPicker } from "containers";

const GET_READING_SETS = gql`
  query GetReadingSets(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $block: ID
    $round: ID
    $month: PositiveInt
    $year: PositiveInt
    $status: ReadingSetStatus
  ) {
    rows: getReadingSets(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      block: $block
      round: $round
      month: $month
      year: $year
      status: $status
    ) {
      _id
      code
      name
      region {
        _id
      }
      regionCode
      regionName
      district {
        _id
      }
      districtCode
      districtName
      block {
        _id
      }
      blockCode
      blockName
      round {
        _id
      }
      roundCode
      roundName
      plot {
        _id
      }
      plotCode
      community
      meta {
        cycleStartDate
        cycleEndDate
        propertiesCount
        customersCount
      }
      reader {
        _id
        lastName
        firstName
        phoneNumber
      }
      status
      createdAt
      updatedAt
    }
    count: getReadingSetsCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      block: $block
      round: $round
      month: $month
      year: $year
      status: $status
    )
  }
`;

const ReadingSetsPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };
  const navigateItem = (id: string, action: "readings" | "upload") => () => {
    navigate({
      to: `./${id}/${action}`,
    });
  };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [block, setBlock] = useUrlState("block");
  const [round, setRound] = useUrlState("round");
  const [status, setStatus] = useUrlState("status");

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: (searchParams.search || "").toString(),
      searchFields: ["code"],
      sort: searchParams.sort || "",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      block: searchParams.block || undefined,
      round: searchParams.round || undefined,
      status: searchParams.status || undefined,
      year: searchParams.year || moment().year(),
      month: _.isNumber(searchParams.month)
        ? searchParams.month + 1
        : moment().month() + 1,
    }),
    [searchParams]
  );

  const { data, loading, networkStatus, refetch } = useQuery(GET_READING_SETS, {
    variables: filter,
    notifyOnNetworkStatusChange: false,
  });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.sort ? { sort: "code" } : {}),
        ...(!_.isNumber(old?.month) ? { month: moment().month() } : {}),
        ...(!old?.year ? { year: moment().year() } : {}),
      }),
    });
  }, [navigate]);

  const records = useTableData(data || {});

  useEffect(() => {
    setBlock(undefined);
    setRound(undefined);
  }, [searchParams.region, searchParams.district]);

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <OfficeHeader />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"reading sets"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            renderHeaderItems={() => (
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex space-x-2">
                <MonthPicker />

                {searchParams.district && (
                  <div className="w-40">
                    <BlockPicker
                      id="block"
                      label="Block"
                      labelHidden
                      placeholder="Block Code"
                      required={true}
                      filter={{
                        region: searchParams.region,
                        district: searchParams.district,
                      }}
                      setFieldValue={(field: string, value: string) => {
                        setBlock(value);
                        setRound(undefined);
                      }}
                      rawId={true}
                      values={{ block }}
                    />
                  </div>
                )}

                {searchParams.district && searchParams.block && (
                  <div className="w-40">
                    <RoundPicker
                      id="round"
                      label="Round"
                      labelHidden
                      placeholder="Round Code"
                      required={true}
                      filter={{
                        region: searchParams.region,
                        district: searchParams.district,
                        block: searchParams.block,
                      }}
                      setFieldValue={(field: string, value: string) => {
                        setRound(value);
                      }}
                      rawId={true}
                      values={{ round }}
                    />
                  </div>
                )}
                <div className="w-40">
                  <SearchSelectInput
                    id="status"
                    label="Status"
                    labelHidden
                    placeholder="Status"
                    required={true}
                    values={{ status }}
                    options={[
                      { label: { title: "Unassigned" }, value: "Unassigned" },
                      { label: { title: "Pending" }, value: "Pending" },
                      { label: { title: "In Progress" }, value: "InProgress" },
                      { label: { title: "Completed" }, value: "Completed" },
                    ]}
                    setFieldValue={(field: string, value: string) => {
                      setStatus(value);
                    }}
                  />
                </div>
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Reading Set
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Itinerary
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Statistics
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Reading Period
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Reader
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.AvatarShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={4} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100">
                    {item?.code || "N/A"}
                  </div>
                  <div className="font-medium text-gray-500 dark:text-gray-400">
                    {item?.name || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.plotCode || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-500">
                    {item?.community || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {numeral(item?.meta?.propertiesCount || 0).format("0,0")}{" "}
                    properties
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {numeral(item?.meta?.customersCount || 0).format("0,0")}{" "}
                    customers
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className="text-gray-900 dark:text-gray-100">
                    <span className="text-xs mr-2 font-light">from</span>
                    {item?.meta?.cycleStartDate
                      ? moment(item?.meta?.cycleStartDate).format(dateFormat)
                      : "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    <span className="text-xs mr-2 font-light">to</span>
                    {item?.meta?.cycleEndDate
                      ? moment(item?.meta?.cycleEndDate).format(dateFormat)
                      : "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <Avatar
                        disabled={true}
                        alt={
                          [
                            (item?.reader?.lastName || "")?.trim(),
                            (item?.reader?.firstName || "")?.trim(),
                          ]
                            .join(" ")
                            .trim() || "N A"
                        }
                        src={item?.reader?.profileImageUrl || ""}
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-gray-900 dark:text-gray-100">
                        {[
                          (item?.reader?.lastName || "")?.trim(),
                          (item?.reader?.firstName || "")?.trim(),
                        ]
                          .join(" ")
                          .trim() || "N A"}
                      </div>
                      <div className="text-gray-500 dark:text-gray-400">
                        {item?.reader?.phoneNumber || "N/A"}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <span
                    className={classNames(
                      item?.status === "InProgress"
                        ? `bg-amber-100 text-amber-800`
                        : "",
                      item?.status === "Unassigned"
                        ? `bg-red-100 text-red-800`
                        : "",
                      item?.status === "Pending"
                        ? `bg-yellow-100 text-yellow-800`
                        : "",
                      item?.status === "Completed"
                        ? `bg-green-100 text-green-800`
                        : "",
                      !item?.status ? "bg-gray-200 text-gray-800" : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 10"
                      fill="currentColor"
                      className="w-1.5 h-1.5"
                    >
                      <circle
                        fillRule="evenodd"
                        cx="5"
                        cy="5"
                        r="5"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>{item?.status || "Unknown"}</span>
                  </span>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className="space-x-1">
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    {withPermissions([
                      "*:*",
                      "reading-sets:*",
                      "reading-sets:assign",
                    ])(
                      <ActionButton
                        action={
                          item?.status === "Unassigned" ? "assign" : "reassign"
                        }
                        onClick={dispatchAction(item?._id, "assign")}
                        disabled={item?.status === "Completed"}
                      />
                    )}
                    {withPermissions([
                      "*:*",
                      "reading-sets:*",
                      "reading-sets:schedule",
                    ])(
                      <ActionButton
                        action="schedule"
                        onClick={dispatchAction(item?._id, "schedule")}
                        disabled={item?.status === "Completed"}
                      />
                    )}
                    {/* {withPermissions(["*:*", "readings:*", "readings:upload"])(
                      <ActionButton
                        action='upload'
                        onClick={navigateItem(item?._id, "upload")}
                        disabled={item?.status !== "Pending"}
                      />
                    )} */}
                    <ActionButton
                      action="expand"
                      onClick={navigateItem(item?._id, "readings")}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {!!searchParams.id?.length && (
        <>
          {withPermissions(["*:*", "reading-sets:*", "reading-sets:assign"])(
            <AssignReadingSetContainer
              open={modal === "assign"}
              setOpen={(val: boolean) => setModal(val ? "assign" : undefined)}
              refetch={refetch}
            />
          )}
          {withPermissions(["*:*", "reading-sets:*", "reading-sets:schedule"])(
            <ScheduleReadingSetContainer
              open={modal === "schedule"}
              setOpen={(val: boolean) => setModal(val ? "schedule" : undefined)}
              refetch={refetch}
            />
          )}
          <ViewReadingSetContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default ReadingSetsPage;
