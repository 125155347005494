import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { TextArea, TextInput } from "components/core"
import { FormikProps } from "formik"
import moment from "moment";
import { FC } from "react"
import { wrapImage } from "utils";

interface ApproveReadingExceptionFormProps {
  readingException: {
    code: string;
    description: string;
    imageUrl?: string;
    value: number;
    reading: any;
  }
  form: FormikProps<{
    notes: string;
  }>;
}

const ApproveReadingExceptionForm: FC<ApproveReadingExceptionFormProps> = ({ form, readingException }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Approval Information</span>
        <div className="grid grid-cols-4 gap-6 mt-2">
          <div className="mt-1 block w-full col-span-4">
            {wrapImage(
              <img
                src={readingException?.imageUrl}
                alt={readingException?.code}
                className="h-64 w-full object-cover object-center"
              />
            )}
          </div>
          <div className="col-span-1">
            <TextInput
              id="value"
              label="Previous Reading Date"
              type="text"
              disabled={true}
              values={{ value: readingException?.reading?.previousReading?.readingDate ? moment(readingException?.reading.previousReading.readingDate).format(dateFormat) : "N/A" }}
              handleChange={undefined}
              handleBlur={undefined}
            />
          </div>

          <div className="col-span-1">
            <TextInput
              id="reading.previousReading.readingValue"
              label="Previous Reading Value"
              type="number"
              disabled={true}
              values={readingException}
              handleChange={undefined}
              handleBlur={undefined}
              postText={"KWh"}
            />
          </div>

          <div className="col-span-1">
            <TextInput
              id="value"
              label="Current Reading Date"
              type="text"
              disabled={true}
              values={{ value: readingException?.reading?.meta?.readingRecordedAt ? moment(readingException?.reading.meta?.readingRecordedAt).format(dateFormat) : "N/A" }}
              handleChange={undefined}
              handleBlur={undefined}
            />
          </div>

          <div className="col-span-1">
            <TextInput
              id="value"
              label="Current Reading Value"
              type="number"
              placeholder="e.g. Mensah Enoch Nana Nyankah"
              disabled={true}
              values={readingException}
              handleChange={undefined}
              handleBlur={undefined}
              postText={"KWh"}
            />
          </div>

          <div className="col-span-1">
            <TextInput
              id="type"
              label="Type"
              type="text"
              placeholder="e.g. Customer not at home"
              disabled={true}
              values={readingException}
              handleChange={undefined}
              handleBlur={undefined}
            />
          </div>

          <div className="col-span-3">
            <TextInput
              id="description"
              label="Description"
              type="text"
              placeholder="e.g. Customer not at home"
              disabled={true}
              values={readingException}
              handleChange={undefined}
              handleBlur={undefined}
            />
          </div>
          <div className="col-span-4">
            <TextArea
              id="notes"
              label="Notes"
              placeholder="e.g. Details captured are conclusive"
              required={true}
              rows={10}
              {...form}
            />
          </div>

        </div>
      </div>
    </div>
  )
}

export default ApproveReadingExceptionForm