import { classNames, withPermissions, wrapClick } from "utils";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  CustomerReadingHistoryView,
  CustomerReadingView,
  Modal,
} from "components";
import { useSearch, useNavigate, Link } from "react-location";
import { LocationGenerics } from "router/location";
import { useEffect, useState } from "react";
import { Action } from "components/buttons/action-button";

export const GET_CUSTOMER = gql`
  query GetCustomerReading($getCustomerReadingId: ID!) {
    reading: getCustomerReading(id: $getCustomerReadingId) {
      _id
      code
      servicePointCode
      servicePoint {
        _id
        serviceClass
      }
      accountCode
      geoCode
      contractStatus
      meterCode
      meter {
        code
        modelMeta {
          brandCode
          brandName
          modelCode
          modelName
          phase
        }
      }
      plotCode
      customerCode
      month
      year
      status
      origin
      createdAt
      updatedAt
      customer {
        code
        customerType

        organization {
          name
        }
        representative {
          fullName
          profileImageUrl
          phoneNumber
          emailAddress
        }
        status
      }
      recordedBy {
        firstName
        lastName
        profileImageUrl
      }
      region {
        code
        name
      }
      district {
        code
        name
      }
      currentReading {
        consumptionPeriod
        consumptionValue
        readingDate
        readingValue
      }
      meter {
        code
      }
      meta {
        readingRecordedAt
        readingImageUrl
      }
      contactPerson {
        emailAddress
        fullName
        phoneNumber
      }
    }
  }
`;

const GET_READING_HISTORY = gql`
  query GetCustomerReadingHistory($servicePoint: ID) {
    readings: getReadings(
      statuses: [MeterRead, ExceptionDetected]
      servicePoint: $servicePoint
      sort: "year month"
    ) {
      _id
      currentReading {
        readingDate
        readingValue
        readingEstimated
        consumptionValue
        billValue
        totalAmountDue
      }
      status
      meta {
        readingImageUrl
        readingLocation {
          coordinates
          type
        }
      }
    }
  }
`;

const tabs = [
  { name: "Reading Details", href: "details" },
  {
    name: "Reading History",
    href: "history",
  },
];

export default function ViewCustomerReadingContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [orderTab, setOrderTab] = useState("details");
  const [getReadingHistory, { data: dataHistory, loading: loadingHistory }] =
    useLazyQuery(GET_READING_HISTORY, {
      notifyOnNetworkStatusChange: false,
    });
  const { data, loading } = useQuery(GET_CUSTOMER, {
    variables: {
      getCustomerReadingId: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
    onCompleted(data) {
      if (data?.reading?.servicePoint?._id) {
        getReadingHistory({
          variables: {
            servicePoint: data?.reading?.servicePoint?._id,
          },
        });
      }
    },
  });

  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };
  const dispatchAction =
    (action: Exclude<Action, "expand" | "goto" | "clone">) => () => {
      navigate({
        search: (old) => ({
          ...old,
          id: searchParams.id,
          modal: action,
        }),
      });
    };

  return (
    <Modal
      open={open}
      loading={loading}
      setOpen={setOpen}
      hidePadding
      title='Customer Reading Information'
      description='Details of reading are shown below'
      size='4xl'
      // renderActions={() =>
      //   data?.reading?.status === "Pending" ? (
      //     <>
      //       <button
      //         type='button'
      //         className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm'
      //         onClick={wrapClick(dispatchAction("approve"))}
      //       >
      //         Approve
      //       </button>
      //       <button
      //         type='button'
      //         className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
      //         onClick={wrapClick(dispatchAction("reject"))}
      //       >
      //         Reject
      //       </button>
      //     </>
      //   ) : (
      //     <></>
      //   )
      // }
    >
      <div className='block'>
        <div className='border-b border-gray-200 bg-white px-6'>
          <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
            {tabs?.map((_status) => (
              <button
                key={_status.name}
                onClick={wrapClick(__setOrderTab(_status.href))}
                className={classNames(
                  orderTab === _status.href
                    ? "border-primary-500 text-primary-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={orderTab === _status.href ? "page" : undefined}
              >
                {_status.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div className='flex-1 w-full min-h-[65vh] max-h-[65vh] overflow-y-auto  sm:p-6'>
        {orderTab === "details" && (
          <CustomerReadingView reading={data?.reading} />
        )}
        {orderTab === "history" && (
          <CustomerReadingHistoryView
            readings={dataHistory?.readings}
            loading={loadingHistory}
          />
        )}
      </div>
    </Modal>
  );
}
