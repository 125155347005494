import numeral from 'numeral';
import { FC } from 'react'

interface DistrictViewProps {
  district: {
    code: string;
    name: string;
    region:{
      code: string;
      name: string;
    };
    meta: {
      blocksCount: number;
      roundsCount: number;
      plotsCount: number;
      itinerariesCount: number;
      propertiesCount: number;
      customersCount: number;
      usersCount: number;
    };
  };
}

const DistrictView: FC<DistrictViewProps> = ({ district }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className='text-xs font-light'>District Information</span>
        <div className='grid grid-cols-2 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              District Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {district?.code || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              District Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {district?.name || "N/A"}
            </div>
          </div>


          <div>
            <span className="block text-sm font-light text-gray-700">
              Region Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {district?.region?.code || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Region Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {district?.region?.name || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>District Statistics</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Blocks Count
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {numeral(district?.meta?.blocksCount || 0).format("0,0")}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Rounds Count
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {numeral(district?.meta?.roundsCount || 0).format("0,0")}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Plots Count
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {numeral(district?.meta?.plotsCount || 0).format("0,0")}
            </div>
          </div>
          
          <div>
            <span className="block text-sm font-light text-gray-700">
              Itineraries Count
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {numeral(district?.meta?.itinerariesCount || 0).format("0,0")}
            </div>
          </div>
          
          <div>
            <span className="block text-sm font-light text-gray-700">
              Properties Count
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {numeral(district?.meta?.propertiesCount || 0).format("0,0")}
            </div>
          </div>
          
          <div>
            <span className="block text-sm font-light text-gray-700">
              Customers Count
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {numeral(district?.meta?.customersCount || 0).format("0,0")}
            </div>
          </div>
          
          <div>
            <span className="block text-sm font-light text-gray-700">
              Readers Count
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {numeral(district?.meta?.usersCount || 0).format("0,0")}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DistrictView