import { FC } from "react";
import { Avatar, SelectInput, TextArea } from "components/core";
import { FormikProps } from "formik";
import { classNames } from "utils";
import _ from "lodash";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { LocationGenerics } from "router/location";
import { useNavigate, useSearch } from "react-location";

type Props = {
  servicePoint: any;
  form: FormikProps<any>;
};

const RestoreServicePointForm: FC<Props> = ({ servicePoint, form }) => {
  const navigate = useNavigate<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const inCompleteCustomer =
    !servicePoint?.customerRepresentative?.fullName ||
    !servicePoint?.customerRepresentative?.phoneNumber;
  return (
    <div className="space-y-6 divide-y divide-gray-200 ">
      {inCompleteCustomer && (
        <div className="rounded-md bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">
                Attention needed
              </h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>
                  Ensure customer information is updated before proceeding, as
                  it is necessary to restore a customer's account.
                </p>
              </div>
              <div className="mt-3">
                <button
                  onClick={() =>
                    navigate({
                      search(prev) {
                        return {
                          ...prev,
                          modal: "update",
                          id: searchParams.id,
                        };
                      },
                    })
                  }
                  type="button"
                  className="rounded-md  bg-yellow-100 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50"
                >
                  Click to Update customer
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        <div className="">
          <span className="text-xs font-light">Customer Information</span>
        </div>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div className="row-span-3 flex flex-col space-y-3 items-center justify-center">
            <Avatar
              alt={servicePoint?.customerRepresentative?.fullName || "N A"}
              src={servicePoint?.customerRepresentative?.profileImageUrl || ""}
              size="xl"
            />

            <span
              className={classNames(
                "bg-gray-100 text-gray-800",
                servicePoint?.status === "SuperAdmin"
                  ? `bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50`
                  : "",
                servicePoint?.status === "Admin"
                  ? `bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50`
                  : "",
                servicePoint?.status === "Supervisor"
                  ? `bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50`
                  : "",
                servicePoint?.status === "Reader"
                  ? `bg-gray-100 text-[#9ACD32] dark:bg-[#9ACD32] dark:text-gray-50`
                  : "",
                servicePoint?.status === "Queued"
                  ? `bg-gray-100 text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50`
                  : "",
                servicePoint?.status === "Success"
                  ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                  : "",
                servicePoint?.status === "Reversed"
                  ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                  : "",
                !servicePoint?.status
                  ? "bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-100"
                  : "",
                "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
              )}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 10 10"
                fill="currentColor"
                className="w-1.5 h-1.5"
              >
                <circle
                  fillRule="evenodd"
                  cx="5"
                  cy="5"
                  r="5"
                  clipRule="evenodd"
                />
              </svg>
              <span>
                {_.startCase(servicePoint?.status) || "Active Contract"}
              </span>
            </span>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Customer Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {servicePoint?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Ghana Card Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {servicePoint?.customerRepresentative?.ghanaCardNumber ||
                servicePoint?.customerOrganization
                  ?.organizationRegistrationNumber ||
                "N/A"}
            </div>
          </div>
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Full Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {servicePoint?.customerOrganization?.name ||
                servicePoint?.customerRepresentative?.fullName ||
                "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Phone Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {servicePoint?.customerRepresentative?.phoneNumber || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Email Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {servicePoint?.customerRepresentative?.emailAddress || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className="space-y-6 divide-y divide-gray-200 pt-6 flex-1">
        <div>
          <span className="text-xs font-light">Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="col-span-3">
              <TextArea
                id="reason"
                label="Reason"
                placeholder="e.g. Changed Phone Number"
                {...form}
              />
            </div>
            {/*
            <div className="col-span-1">
                <SelectInput
                   label="Does this require a reconnection fee?"
                   id="requiresInvoice"
                   options={[
                    {
                      label: 'Yes',
                      value: 1
                    },
                    {
                      label: 'No',
                      value: 0 
                    }
                   ]}
                   {...form}
                />
            </div>
                  */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestoreServicePointForm;
