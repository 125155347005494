import { SelectInput, TextInput } from "components/core";
import { FormikFormProps, FormikProps, useFormik } from "formik";
import moment from "moment";
import { FC, useEffect } from "react";
import { classNames, wrapClick } from "utils";
import { CustomerInfoFormSchema } from "./schema";
import { gql, useLazyQuery, useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import toast from "react-hot-toast";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { DistrictPicker, RegionPicker } from "containers";
import { GET_READING_HISTORY } from "pages/customers/view";

const SEARCH_SERVICE_POINT = gql`
  query SearchServicePoints(
    $search: String!
    $searchField: String!
    $limit: NonNegativeInt
  ) {
    servicePoints: searchServicePoints(
      search: $search
      searchField: $searchField
      limit: $limit
    ) {
      _id
      code
      qrCode
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      block {
        _id
        code
        name
      }
      round {
        _id
        code
        name
      }
      plot {
        _id
        code
        name
      }
      itinerary {
        _id
        code
        description
      }
      property {
        _id
        code
        qrCode
        address
        ghanaPostAddress
        streetName
        houseNumber
        houseNumberSlateUrl
        community
        itineraryCode
        itineraryDescription
        owner {
          fullName
          phoneNumber
        }
        region {
          _id
          code
          name
        }
        district {
          _id
          code
          name
        }
      }
      propertyCode
      customer {
        _id
        code
        customerType

        organization {
          name
          taxIdentificationNumber
          organizationRegistrationNumber
          organizationRegistrationDate
          organizationRegistrationDocumentUrl
          certificateOfIncorporationDocumentUrl
        }
        representative {
          title
          fullName
          nationality
          dateOfBirth
          gender
          phoneNumber
          emailAddress
          profileImageUrl
          hasGhanaCard
          ghanaCardNumber
          ghanaCardIssueDate
          ghanaCardExpiryDate
          ghanaCardFrontImageUrl
          ghanaCardBackImageUrl
          identityCardType
          identityCardNumber
          identityCardIssueDate
          identityCardExpiryDate
          identityCardFrontImageUrl
          identityCardBackImageUrl
        }
      }
      customerCode
      account {
        _id
        code
        status
      }
      accountCode
      meter {
        _id
        code
        modelMeta {
          modelCode
          modelName
          brandCode
          brandName
          phase
        }
        modelType
        status
      }
      meterCode
      serviceType
      serviceClass
      tariffClass {
        _id
        code
        name
        description
        serviceClass
      }
      geoCode
      transformerPhase
      meterLocation
      meterHeight
      transformerNumber
      currentTransformerRatio
      deliveryPointNumber
      contractedDemand
      poleNumber
      energyCertificateNumber
      energyCertificateDocumentUrl
      status
      createdAt
      updatedAt
    }
  }
`;

interface CustomerInfoFormProps {
  handleNext: (values: any) => void;
  handlePrevious: () => void;
  initialValues: any;
  values: any;
  handleCancel: () => void;
  parentForm: FormikProps<any>;
}

const CustomerInfoForm: FC<CustomerInfoFormProps> = ({
  initialValues,
  handleNext,
  handleCancel,
  handlePrevious,
  parentForm,
}) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);
  const form = useFormik({
    initialValues,
    validationSchema: CustomerInfoFormSchema,
    onSubmit: (values) => {
      handleNext(values);
      getReadingHistory({
        variables: {
          servicePoint: data?.customer?._id,
        },
      });
    },

    onReset: () => {
      handleCancel?.();
    },
  });

  const [searchServicePoints, { loading, data }] = useLazyQuery(
    SEARCH_SERVICE_POINT,
    {
      onCompleted: (data) => {
        if (data?.servicePoints?.length) {
          getReadingHistory({
            variables: {
              servicePoint: data.servicePoints?.[0]?._id,
            },
          });
        }
      },
    }
  );

  const [getReadingHistory, { data: dataHistory, loading: loadingHistory }] =
    useLazyQuery(GET_READING_HISTORY, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      onCompleted: (data) => {

      }
    });

  const servicePointSearchForm = useFormik({
    initialValues: {
      search: "",
      customerIdentifier: "code",
      code: "",
    },
    onSubmit: (values) => {
      searchServicePoints({
        variables: {
          search: (values as any)[values.customerIdentifier],
          searchField: values?.customerIdentifier,
          limit: 3,
        },
      }).then(({ data }) => {
        if (data?.servicePoints?.length) {
          form.setFieldValue("existingServicePoint", data.servicePoints?.[0]);
          parentForm.setFieldValue(
            "extraInfo.oldMeterNumberTemp",
            data.servicePoints?.[0]?.meter?.code
          );
          parentForm.setFieldValue(
            "extraInfo.oldMeterPhaseTemp",
            data.servicePoints?.[0]?.meter?.modelMeta?.phase
          );
          parentForm.setFieldValue(
            "extraInfo.oldMeterNumber",
            data.servicePoints?.[0]?.meter?.code
          );
          parentForm.setFieldValue(
            "extraInfo.oldMeterPhase",
            data.servicePoints?.[0]?.meter?.modelMeta?.phase
          );
        } else {
          toast(JSON.stringify({ type: "error", title: "Customer Not Found" }));
        }
      });
    },
  });

  useEffect(() => {
    if (dataHistory?.readings) {
      parentForm.setFieldValue("readingInfo.readingHistory", dataHistory?.readings);
    }
  }, [dataHistory]);

  return (
    <div className='flex-1 flex flex-col overflow-hidden'>
      <div className='space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto'>
        <div className=''>
          <span className='text-xs font-light'>Search for Service Point</span>
          <form
            onSubmit={servicePointSearchForm.handleSubmit}
            className='grid grid-cols-8 gap-6 mt-2'
          >
            {servicePointSearchForm.values?.customerIdentifier === "code" && (
              <div className='col-span-6 sm:col-span-3'>
                <TextInput
                  id='code'
                  label='Service Point Number'
                  type='text'
                  placeholder='e.g. 20XXXXXXX'
                  {...servicePointSearchForm}
                />
              </div>
            )}
            <div className='col-span-6 sm:col-span-2 items-end justify-end flex'>
              <button
                type='submit'
                className='w-full inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
              >
                {loading ? "Searching Customer..." : "Search Customer"}
              </button>
            </div>
          </form>
        </div>
        <div className='pt-6'>
          <span className='text-xs font-light'>Selected Service Point</span>
          {form?.values?.existingServicePoint ? (
            <div className='border mt-2 border-gray-300 border-dashed bg-gray-100 divide-y divide-dashed divide-gray-300 rounded-md'>
              <div className='grid grid-cols-4 gap-x-4 gap-y-6  p-4'>
                <div className=''>
                  <span className='block text-sm font-light text-gray-700'>
                    Type
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.customer
                      ?.customerType || "N/A"}
                  </div>
                </div>
                <div className=''>
                  <span className='block text-sm font-light text-gray-700'>
                    Title
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.customer
                      ?.representative?.title ||
                      form?.values?.existingServicePoint?.customer
                        ?.representative?.title ||
                      "N/A"}
                  </div>
                </div>
                <div className='col-span-2'>
                  <span className='block text-sm font-light text-gray-700'>
                    Full Name
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.customer
                      ?.representative?.fullName ||
                      form?.values?.existingServicePoint?.customer
                        ?.representative?.fullName ||
                      "N/A"}
                  </div>
                </div>
                <div className=''>
                  <span className='block text-sm font-light text-gray-700'>
                    Nationality
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.customer
                      ?.representative?.nationality ||
                      form?.values?.existingServicePoint?.customer
                        ?.representative?.nationality ||
                      "N/A"}
                  </div>
                </div>
                <div className=''>
                  <span className='block text-sm font-light text-gray-700'>
                    Date of Birth
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {moment(
                      form?.values?.existingServicePoint?.customer
                        ?.representative?.dateOfBirth ||
                        form?.values?.existingServicePoint?.customer
                          ?.representative?.dateOfBirth
                    ).format(dateFormat)}
                  </div>
                </div>
                <div className=''>
                  <span className='block text-sm font-light text-gray-700'>
                    Gender
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.customer
                      ?.representative?.gender ||
                      form?.values?.existingServicePoint?.customer
                        ?.representative?.gender ||
                      "N/A"}
                  </div>
                </div>
              </div>
              <div className='grid grid-cols-4 gap-x-4 gap-y-6  p-4'>
                <div className=''>
                  <span className='block text-sm font-light text-gray-700'>
                    Account Number
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.account?.code || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Customer Number
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.customer?.code ||
                      "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Property Number
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.property?.code ||
                      "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Service Point Number
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.code || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Geographical Code
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.geoCode || "N/A"}
                  </div>
                </div>
                <div className='col-span-2'>
                  <span className='block text-sm font-light text-gray-700'>
                    Property Address
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.property
                      ?.ghanaPostAddress || "N/A"}
                  </div>
                </div>
              </div>
              <div className='grid grid-cols-4 gap-x-4 gap-y-6  p-4'>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Meter Number
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.meter?.code || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Meter Model
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.meter?.modelMeta
                      ?.modelCode || "N/A"}{" "}
                    -{" "}
                    {form?.values?.existingServicePoint?.meter?.modelMeta
                      ?.modelName || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Meter Make
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.meter?.modelMeta
                      ?.brandCode || "N/A"}{" "}
                    -{" "}
                    {form?.values?.existingServicePoint?.meter?.modelMeta
                      ?.brandName || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Meter Type
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.meter?.modelType ||
                      "N/A"}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='flex mt-2 w-full items-center justify-center'>
              <div className='relative  block w-full rounded-lg border-2 border-dashed border-gray-300 p-6 py-24 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'>
                <UserPlusIcon
                  className='mx-auto h-10 w-10 text-gray-400'
                  stroke='currentColor'
                  strokeWidth={1}
                  aria-hidden='true'
                />
                <h3 className='mt-2 text-sm font-medium text-gray-900'>
                  No Service Point Selected
                </h3>
                <p className='mt-1 text-sm text-gray-500'>
                  Get started by searching for a service point above.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200'>
        <button
          type='button'
          onClick={wrapClick(form.handleSubmit)}
          disabled={!form.isValid}
          className={classNames(
            form.isValid ? "cursor-pointer" : "cursor-not-allowed",
            "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
          )}
        >
          Next
        </button>
        <button
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CustomerInfoForm;
