import { gql, useMutation } from "@apollo/client";
import { BillRegularizationForm, Modal } from "components";
import { useFormik } from "formik";
import toast from "react-hot-toast";

const GENERATE_FRAUD_BILL = gql`
  mutation GenerateFraudBill(
    $servicePoint: ID!
    $consumptionPeriod: PositiveInt
    $consumption: PositiveInt
    $extraItems: [FraudBillExtraItemsInput]
    $informant: ContactPersonInput
    $customer: ContactPersonInput
    $detectionDate: DateTime
    $illegalType: String
  ) {
    generateFraudBill(
      servicePoint: $servicePoint
      consumptionPeriod: $consumptionPeriod
      consumption: $consumption
      extraItems: $extraItems
      informant: $informant
      customer: $customer
      consumptionEndDate: $detectionDate
      illegalType: $illegalType
    ) {
      _id
    }
  }
`;

export default function BillRegularizationContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  const [generateFraudBill] = useMutation(GENERATE_FRAUD_BILL);

  const form = useFormik({
    initialValues: {
      customerInfo: {
        existingServicePoint: null as any,
      },
      readingInfo: {
        currentReadingDate: "",
        currentReadingValue: 0
      },
    },
    onSubmit: async (values) => {
      // await generateFraudBill({
      //   variables: {
      //     ...values.readingInfo,
      //     servicePoint: values.customerInfo.existingServicePoint?._id,
      //     illegalType: values.readingInfo?.illegalType,
      //     informant: {
      //       fullName: values.readingInfo.informantName,
      //       phoneNumber: values.readingInfo.informantContact,
      //     },
      //     customer: {
      //       fullName: values.readingInfo.customerName,
      //       phoneNumber: values.readingInfo.customerPhoneNumber,
      //       emailAddress: values.readingInfo.customerEmail,
      //     }
      //   },
      // }).then(({ data }) => {
      //   if (data.generateFraudBill._id) {
      //     toast(
      //       JSON.stringify({
      //         type: "success",
      //         title: "Fraud bill generated Successfully",
      //       })
      //     );
      //     form.resetForm();
      //   } else {
      //     toast(
      //       JSON.stringify({
      //         type: "error",
      //         title: "Could not generate reading bill",
      //       })
      //     );
      //   }
      // });
    },
    onReset: () => {
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Generate Fraud Bill"
      description="Provide details to generate reading bill"
      size="5xl"
      hideActions={true}
    >
      <BillRegularizationForm form={form} />
    </Modal>
  );
}
