import { gql, useQuery } from "@apollo/client";

const GET_CUSTOMERS = gql`
query GetServicePoints(
  $page: Int
  $pageSize: Int
  $sort: String
  $search: String
  $searchFields: [String!]
  $region: ID
  $district: ID
  $block: ID
  $round: ID
  $plot: ID
  $itinerary: ID
  $property: ID
  $tariffClass: ID
  $customerType: CustomerType
  $meterBrand: ID
  $serviceType: ServiceType
  $status: ServicePointStatus
  $setupType: SetupType
  $fromDate: Date
  $toDate: Date
) {
  rows: getServicePoints(
    page: $page
    pageSize: $pageSize
    sort: $sort
    search: $search
    searchFields: $searchFields
    region: $region
    district: $district
    block: $block
    round: $round
    plot: $plot
    itinerary: $itinerary
    property: $property
    tariffClass: $tariffClass
    customerType: $customerType
    serviceType: $serviceType
    meterBrand: $meterBrand
    status: $status
    setupType: $setupType
    fromDate: $fromDate
    toDate: $toDate
  ) {
    _id
    code
    createdAt
    updatedAt
    accountCode
    customerCode
    meterCode
    geoCode
    propertyCode
    customerOrganization {
    name
    }
    customerRepresentative {
      fullName
      emailAddress
      phoneNumber
      profileImageUrl
    }
    customer {
      _id
      code
      customerType
    }
    status
    tariffClass {
      name
      code
    }
    ghanaPostAddress
    property {
      address
    }
  }
  count: getServicePointsCount(
    search: $search
    searchFields: $searchFields
    region: $region
    district: $district
    block: $block
    round: $round
    plot: $plot
    itinerary: $itinerary
    property: $property
    tariffClass: $tariffClass
    meterBrand: $meterBrand
    customerType: $customerType
    serviceType: $serviceType
    status: $status
    setupType: $setupType
    fromDate: $fromDate
    toDate: $toDate
  )
}
`;

export const useServicePoints = (variables: any) => {
  const { data, ...rest } = useQuery(GET_CUSTOMERS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const servicePoints = data?.rows || [];
  const count = data?.count || 0;
  return { servicePoints, count, ...rest };
};
