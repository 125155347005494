import { useEffect } from "react";
import { classNames, wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { AssignPlotForm, Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_PLOT } from "./view";

const ASSIGN_DISCONNECTOR = gql`
  mutation AssignPlot($id: ID!, $disconnector: ID!) {
    assignPlot(id: $id, disconnector: $disconnector) {
      _id
    }
  }
`;

export default function AssignPlotDisconnectorContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_PLOT, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [assignPlot] = useMutation(ASSIGN_DISCONNECTOR);

  const form = useFormik({
    initialValues: {
      disconnector: null,
    },
    onSubmit: async (values) => {
      await assignPlot({
        variables: {
          id: searchParams.id,
          disconnector: (values.disconnector as any)?._id,
        },
      }).then(({ data }) => {
        if (data.assignPlot._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Disconnector  Assigned Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not assign disconnector",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      disconnector: data?.plot?.disconnector || null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.plot, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title='Assign Plot '
      description='Provide the details assign disconnector to plot'
      size='4xl'
      loading={loading}
      renderActions={() => (
        <>
          <button
            type='button'
            disabled={form.isSubmitting || !form.values.disconnector}
            className={classNames(
              form.isSubmitting || !form.values.disconnector
                ? "cursor-not-allowed"
                : "cursor-pointer",
              "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            )}
            onClick={wrapClick(form.handleSubmit)}
          >
            {form.isSubmitting
              ? "Assigning Disconnector..."
              : "Assign Disconnector"}
          </button>
        </>
      )}
    >
      {data?.plot?._id && <AssignPlotForm form={form} />}
    </Modal>
  );
}
