import { wrapClick } from "utils";
import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { CancelServiceOrderForm, Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";

const CANCEL_SERVICE_ORDER = gql`
  mutation CancelDisconnectionServiceOrder(
    $cancelDisconnectionServiceOrderId: ID!
    $reason: String!
  ) {
    cancelDisconnectionServiceOrder(
      id: $cancelDisconnectionServiceOrderId
      reason: $reason
    ) {
      _id
    }
  }
`;

export default function CancelDisconnectionServiceOrderContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [cancelDisconnectionServiceOrder, { loading }] =
    useMutation(CANCEL_SERVICE_ORDER);

  const form = useFormik({
    initialValues: {
      notes: "",
    },
    onSubmit: async (values) => {
      await cancelDisconnectionServiceOrder({
        variables: {
          cancelDisconnectionServiceOrderId: searchParams.id,
          reason: values.notes,
        },
      }).then(({ data }) => {
        if (data.cancelDisconnectionServiceOrder._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Service Order Cancelled Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not cancel Service Order",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title='Cancel Replacment Service Order'
      description='Provide reason for cancelling service order'
      size="4xl"
      renderActions={() => (
        <>
          <button
            type='button'
            disabled={form.isSubmitting}
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {form.isSubmitting || loading
              ? "Cancelling Service Order..."
              : "Cancel Service Order"}
          </button>
        </>
      )}
    >
      <CancelServiceOrderForm form={form} />
    </Modal>
  );
}
