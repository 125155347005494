import { FC, useRef } from "react";
import { wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, ResolveDisconnectionServiceOrderForm } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_SERVICE_ORDER } from "./view";

interface IResolveDisconnectionServiceOrderProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}

const RESOLVE_SERVICE_ORDER = gql`
  mutation ResolveDisconnectionServiceOrder(
    $id: ID!
    $resolution: DisconnectionServiceOrderResolutionInput!
    $result: DisconnectionServiceOrderResult!
  ) {
    resolveDisconnectionServiceOrder(
      id: $id
      resolution: $resolution
      result: $result
    ) {
      _id
    }
  }
`;

const ResolveDisconnectionServiceOrderContainer: FC<
  IResolveDisconnectionServiceOrderProps
> = ({ open, setOpen, refetch }) => {
  const searchParams = useSearch<LocationGenerics>();
  const { data } = useQuery(GET_SERVICE_ORDER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const cancelButtonRef = useRef(null);
  const [resolveDisconnectionServiceOrder, { loading }] = useMutation(
    RESOLVE_SERVICE_ORDER
  );

  const form = useFormik({
    initialValues: {
      notes: "",
      supportingImageUrls: [] as string[],
      reading: {
        readingDate: "",
        readingValue: 0,
      },
    },
    onSubmit: async (values) => {
      await resolveDisconnectionServiceOrder({
        variables: {
          id: data?.serviceOrder?._id,
          resolution: {
            ...values,
          },
          result: "Passed",
        },
      }).then(({ data }) => {
        if (data.resolveDisconnectionServiceOrder._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Service Order Resolved Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not resolve Service Order",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      hideActions={true}
      title="Resolve Service Order"
      size="4xl"
      description="Provide resolution details for service order"
    >
      {data?.serviceOrder?._id && (
        <div>
          <div className="flex-1 flex flex-col overflow-y-auto  sm:p-6">
            <ResolveDisconnectionServiceOrderForm form={form} />
          </div>
          <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
            <button
              type="button"
              disabled={loading}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(form.handleSubmit)}
            >
              {loading ? "Resolving Service Order..." : "Resolve Service Order"}
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(form.resetForm)}
              ref={cancelButtonRef}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ResolveDisconnectionServiceOrderContainer;
