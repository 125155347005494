import { useEffect } from "react"
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { AssignReconnectionServiceOrderForm, Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_SERVICE_ORDER } from "./view";


const ASSIGN_SERVICE_ORDER = gql`
  mutation AssignReconnectionServiceOrder($id: ID!, $assignee: ID!,   $priority: ReconnectionServiceOrderPriority!, $estimatedResolutionDate: Date!, $notes: String) {
    assignReconnectionServiceOrder(id: $id, assignee: $assignee,  priority: $priority, estimatedResolutionDate: $estimatedResolutionDate, notes: $notes) {
      _id
    }
  }
`

export default function AssignReconnectionServiceOrderContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {

  const searchParams = useSearch<LocationGenerics>();
  const { data } = useQuery(GET_SERVICE_ORDER, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });
  const [assignReconnectionServiceOrder, { loading }] = useMutation(ASSIGN_SERVICE_ORDER)

  const form = useFormik({
    initialValues: {
      assigneeInfo: {
        assignee: null as any
      },
      extraInfo: {
        estimatedResolutionDate: "",
        priority: "Low",
        notes: ""
      }
    },
    onSubmit: async (values) => {
      await assignReconnectionServiceOrder({
        variables: {
          id: searchParams.id,
          assignee: values.assigneeInfo?.assignee?._id,
          ...values.extraInfo,
        }
      }).then(({ data }) => {
        if (data.assignReconnectionServiceOrder._id) {
          toast(JSON.stringify({ type: "success", title: "Service Order Assigned Successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not assign Service Order" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  useEffect(() => {
    form.setValues({
      assigneeInfo: {
        assignee: data?.serviceOrder?.assignee || null as any
      },
      extraInfo: {
        estimatedResolutionDate: data?.serviceOrder?.estimatedResolutionDate || "",
        priority: data?.serviceOrder?.priority || "Low",
        notes: data?.serviceOrder?.notes || ""
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.serviceOrder, searchParams.id, open])

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      hideActions={true}
      title="Assign Service Order"
      description="Provide the details to assign service order"
    >
      {data?.serviceOrder?._id && (
        <AssignReconnectionServiceOrderForm
          form={form}
        />
      )}
    </Modal>
  )
}