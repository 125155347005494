import { useEffect } from 'react'
import { classNames, wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { AssignReadingSetForm, Modal } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';

const GET_READING_SET = gql`
  query GetReadingSet($id: ID!) {
    readingSet: getReadingSet(id: $id) {
      _id
      reader {
        _id
        lastName
        firstName
        profileImageUrl
        phoneNumber
        emailAddress
        ghanaCardNumber
      }
    }
  }
`

const UPDATE_READING_SET = gql`
  mutation AssignReadingSet(
    $id: ID!
    $reader: ID!
  ) {
    assignReadingSet(
      id: $id
      reader: $reader
    ) {
      _id
    }
  }
`

export default function AssignReadingSetContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
 
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_READING_SET, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });
  const [assignReadingSet] = useMutation(UPDATE_READING_SET)

  const form = useFormik({
    initialValues: {
      reader: null,
    },
    onSubmit: async (values) => {
      await assignReadingSet({
        variables: {
          id: searchParams.id,
          reader: (values.reader as any)?._id,
        }
      }).then(({ data }) => {
        if (data.assignReadingSet._id) {
          toast(JSON.stringify({ type: "success", title: "Reading Set Assigned Successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not assign Reading Set" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  useEffect(() => {
    form.setValues({
      reader: data?.readingSet?.reader || null,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.readingSet, searchParams.id, open])

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Assign Reading Set"
      description="Provide the details to assign reading set"
      size="4xl"
      loading={loading}
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={form.isSubmitting || !form.values.reader}
            className={classNames(
              form.isSubmitting || !form.values.reader ? "cursor-not-allowed" : "cursor-pointer",
              "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            )}
            onClick={wrapClick(form.handleSubmit)}
          >
            {form.isSubmitting ? "Assigning Reading Set..." : "Assign Reading Set"}
          </button>
        </>
      )}
    >
      {data?.readingSet?._id && (
        <AssignReadingSetForm
          form={form}
        />
      )}
    </Modal>
  )
}