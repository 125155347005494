import { SelectInput, TextArea, TextInput } from "components/core"
import { useFormik } from "formik"
import moment from "moment";
import { FC } from "react"
import { wrapClick } from "utils";
import { ExtraInfoFormSchema } from "./schema";

interface ExtraInfoFormProps {
  handleNext: (values: any) => void
  handlePrevious: () => void;
  initialValues: any;
  values: any;
  handleCancel: () => void;
}

const ExtraInfoForm: FC<ExtraInfoFormProps> = ({ initialValues, handleNext, handleCancel, handlePrevious }) => {

  const form = useFormik({
    initialValues,
    validationSchema: ExtraInfoFormSchema,
    onSubmit: (values) => {
      handleNext(values)
    },
    onReset: () => {
      handleCancel?.();
    }
  })

  return (
    <form onSubmit={form.handleSubmit} className="flex-1 flex flex-col overflow-hidden">
      <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1">
        <div>
          <span className="text-xs font-light">Extra Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">

            <div className="">
              <SelectInput
                id="priority"
                label="Priority"
                placeholder="G029302"
                required={true}
                options={[
                  { label: "--- Select Priority ---", value: "" },
                  { label: "Low", value: "Low" },
                  { label: "Medium", value: "Medium" },
                  { label: "High", value: "High" },
                  { label: "Critical", value: "Critical" },
                ]}
                {...form}
              />
            </div>

            <div className="">
              <TextInput
                id="estimatedResolutionDate"
                label="Estimated Resolution Date"
                type="date"
                placeholder="e.g. Mensah Enoch Nana Nyankah"
                min={moment().startOf("day").format("YYYY-MM-DD")}
                required={true}
                {...form}
              />
            </div>

            <div className="col-span-3">
              <TextArea
                id="notes"
                label="Extra Notes"
                placeholder="e.g. 0550123292"
                rows={10}
                {...form}
              />
            </div>

          </div>
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="submit"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </form>
  )
}

export default ExtraInfoForm
