import { withPermissions, wrapClick } from 'utils';
import { gql, useQuery } from '@apollo/client';
import { ReaderView, Modal } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';
import { FC } from 'react';

export const GET_READER = gql`
  query GetReader($id: ID!) {
    reader: getUser(id: $id) {
      _id
      code
      lastName
      firstName
      ghanaCardNumber
      phoneNumber
      emailAddress
      gender
      profileImageUrl
      role {
        _id
        code
        name
      }
      region {
        _id
      }
      regionCode
      regionName
      district {
        _id
      }
      districtCode
      districtName
      meta {
        isPasswordSet
        isBlocked
        isDeleted
        lastLoginAt
      }
      createdAt
      updatedAt
    }
  }
`;

interface ViewReaderContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
}

const ViewReaderContainer: FC<ViewReaderContainerProps> = ({ open, setOpen }) => {

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data } = useQuery(GET_READER, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });
  const updateItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "update"
      })
    })
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Reader Information"
      description="Details of reader are shown below"
      renderActions={() => (
        <>
          {withPermissions(["*:*", "readers:*", "readers:update"])(
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(updateItem)}
            >
              Edit
            </button>
          )}
        </>
      )}
    >
      {data?.reader?._id && (
        <ReaderView
          reader={data?.reader}
        />
      )}
    </Modal>
  )
}

export default ViewReaderContainer;