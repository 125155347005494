import { useEffect } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_SERVICE_POINT } from "./view";
import { UpdateCustomerRequest } from "components";
import { withPermissions, wrapClick } from "utils";
import * as Yup from "yup";

const UPDATE_CUSTOMER = gql`
  mutation UpdateServiceRequest(
    $updateServiceRequestId: ID!
    $fullName: String
    $phoneNumber: String
    $serviceType: String
    $serviceClass: String
  ) {
    updateServiceRequest(
      id: $updateServiceRequestId
      fullName: $fullName
      phoneNumber: $phoneNumber
      serviceType: $serviceType
      serviceClass: $serviceClass
    ) {
      _id
    }
  }
`;

export default function UpdateCustomerContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_SERVICE_POINT, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);

  const form = useFormik<{
    phoneNumber: string;
    fullName: String;
    serviceType: string;
    serviceClass: string;
  }>({
    initialValues: {
      phoneNumber: "",
      fullName: "",
      serviceClass: "",
      serviceType: "",
    },
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string().required("Phone Number is Required"),
      fullName: Yup.string().required("Full Name is Required"),
      serviceClass: Yup.string().required("Service Class is Required"),
      serviceType: Yup.string().required("Service Type is Required"),
    }),
    onSubmit: async (values) => {
      await updateCustomer({
        variables: {
          ...values,
          updateServiceRequestId: searchParams.id,
        },
      }).then(({ data }) => {
        if (data.updateServiceRequest._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Customer Edited Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create Customer",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (data?.servicePoint) {
      // alert("Hello" + data?.customer?.customer?.fullName)
      form.setValues({
        fullName:
          data?.servicePoint?.customer?.customerType === "Individual"
            ? data?.servicePoint?.customer?.representative?.fullName
            : data?.servicePoint?.customer?.organization?.name || "N/A",
        phoneNumber:
          data?.servicePoint?.customer?.customerType === "Individual"
            ? data?.servicePoint?.customer?.representative?.phoneNumber || "N/A"
            : data?.servicePoint?.customer?.organization?.phoneNumber || "N/A",
        serviceType: data?.servicePoint?.serviceType,
        serviceClass: data?.servicePoint?.serviceClass,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.servicePoint, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      // hideActions={true}
      loading={loading}
      size='4xl'
      title='Edit Customer Information'
      description='Provide the details to update customer'
      renderActions={() => (
        <>
          {withPermissions([
            "*:*",
            "service-points:*",
            "service-points:update",
          ])(
            <button
              type='button'
              disabled={form.isSubmitting || !form.isValid}
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={wrapClick(form.handleSubmit)}
            >
              {form.isSubmitting ? "Editing ..." : "Edit"}
            </button>
          )}
        </>
      )}
    >
      {data?.servicePoint?._id && (
        <UpdateCustomerRequest form={form} customer={data?.servicePoint} />
      )}
    </Modal>
  );
}
