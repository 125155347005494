import { useRef } from "react";
import { withPermissions, wrapClick } from "utils";
import { gql, useQuery } from "@apollo/client";
import { BillView, Modal } from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { useReactToPrint } from "react-to-print";

const GET_BILL = gql`
  query GetBill($id: ID!) {
    bill: getBill(id: $id) {
      _id
      code
      emailAddress
      phoneNumber
      fullName
      profileImageUrl
      ghanaCardNumber
      payer {
        fullName
        emailAddress
      }
      servicePointNumber
      accountNumber
      geoCode
      contractedDemand
      contractStatus
      meterNumber
      serviceClass
      serviceType
      tariffClassCode
      tariffClassName
      propertyCode
      qrCode
      address
      ghanaPostAddress
      customerCode
      readingSetCode
      readingSetName
      month
      year
      billPeriod
      billStartDate
      billEndDate
      consumptionPeriod
      previousBill {
        readingDate
        readingValue
        readingEstimated
        consumptionValue
        billValue
        totalAmountDue
      }
      currentBill {
        readingDate
        readingValue
        readingEstimated
        consumptionValue
        billValue
        totalAmountDue
      }
      currentBillAmount
      outstandingAmount
      totalAmountDue
      totalAmountDueAt
      bills {
        currentDate
        previousDate
        tariffInstance {
          _id
        }
        numberOfDays
        consumption
        billItems {
          title
          unit
          quantity
          unitCost
          cost
        }
        billAmount
      }
      createdAt
      updatedAt
    }
  }
`;

export default function ViewBillContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data } = useQuery(GET_BILL, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  const billRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => billRef.current,
    documentTitle: data?.bill?.code,
    bodyClass: "w-[1000px]",
  });

  const sendItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "send",
      }),
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title='Bill Information'
      description='Details of bill are shown below'
      size='5xl'
      renderActions={() => (
        <>
          {withPermissions(["bills:*", "bills:send"])(
            <button
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={wrapClick(sendItem)}
            >
              Send
            </button>
          )}
          {withPermissions(["bills:*", "bills:print"])(
            <button
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={wrapClick(handlePrint)}
            >
              Print
            </button>
          )}
        </>
      )}
    >
      <BillView ref={billRef} bill={data?.bill} />
    </Modal>
  );
}
