import { FC, useMemo } from "react";
import {
  AvatarUpload,
  SelectInput,
  TextArea,
  TextInput,
  UploadArea,
} from "components/core";
import { FormikProps } from "formik";
import { classNames } from "utils";
import moment from "moment";
import { Genders, Nationalities, Titles } from "apollo/data";
import lodash from "lodash";
import { Switch } from "@headlessui/react";

type Props = {
  customer: any;
  form: FormikProps<any>;
};

const UpdateCustomerIdentityForm: FC<Props> = ({ customer, form }) => {
  const label = useMemo(
    () => (form.values.customerType === "Individual" ? "" : "Representative's"),
    [form.values.customerType]
  );

  return (
    <div className="space-y-6 divide-y divide-gray-200 ">
      <div className="space-y-6 divide-y divide-gray-200 flex-1 overflow-y-auto">
        <div>
          <span className="text-xs font-light">Customer Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div>
              <SelectInput
                id="customerType"
                label="Customer Type"
                options={[
                  { label: "--- Select Customer Type ---", value: "" },
                  {
                    label: "Organization (Public/Private)",
                    value: "Organization",
                  },
                  { label: "Individual", value: "Individual" },
                ]}
                required={true}
                {...form}
              />
            </div>
          </div>
        </div>
        {form.values.customerType === "Organization" && (
          <div className="pt-6">
            <span className="text-xs font-light">Organization Information</span>
            <div className="grid grid-cols-3 gap-6 mt-2">
              <div className="col-span-2">
                <TextInput
                  id="organization.name"
                  label="Full Name"
                  type="text"
                  required={true}
                  placeholder="e.g. Kofi And Sons Organization Limited"
                  {...form}
                />
              </div>
            </div>
          </div>
        )}
        <div className="pt-6">
          <span className="text-xs font-light">{label} Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div>
              <AvatarUpload
                id="representative.profileImageUrl"
                label="Photo"
                {...form}
              />
            </div>

            <div className="col-start-1">
              <SelectInput
                id="representative.title"
                label={`${label} Title`}
                placeholder={`${label} Title`}
                options={[
                  { label: "--- Select Title ---", value: "" },
                  ...Titles,
                ]}
                required={true}
                {...form}
              />
            </div>

            <div className="col-span-2">
              <TextInput
                id="representative.fullName"
                label={`${label} Full Name`}
                type="text"
                required={true}
                placeholder="e.g. Mensah Enoch Nana Nyankah"
                {...form}
              />
            </div>

            <div>
              <SelectInput
                id="representative.nationality"
                label="Nationality"
                placeholder="Nationality"
                options={[
                  { label: "--- Select Nationality ---", value: "" },
                  ...lodash
                    .map(Nationalities, "nationality")
                    .map((nationality) => ({
                      label: nationality,
                      value: nationality,
                    })),
                ]}
                required={true}
                {...form}
              />
            </div>

            <div>
              <TextInput
                id="representative.dateOfBirth"
                label={`${label} Date of Birth`}
                type="date"
                required={true}
                max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                {...form}
              />
            </div>

            <div>
              <SelectInput
                id="representative.gender"
                label={`${label} Gender`}
                options={[
                  { label: "--- Select Gender ---", value: "" },
                  ...Genders,
                ]}
                required={true}
                {...form}
              />
            </div>
          </div>
        </div>
        <div className="pt-6">
          <span className="text-xs font-light">
            {label} Contact Information
          </span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div>
              <TextInput
                id="representative.phoneNumber"
                label={`${label} Phone Number`}
                type="text"
                placeholder="e.g. 0550123292"
                maxLength={10}
                required={true}
                {...form}
              />
            </div>

            <div className="col-span-2">
              <TextInput
                id="representative.emailAddress"
                label={`${label} Email Address`}
                type="email"
                placeholder="e.g. nyankahmensah@gmail.com"
                {...form}
              />
            </div>
          </div>
        </div>

        {form.values.customerType === "Organization" && (
          <div className="pt-6">
            <span className="text-xs font-light">
              Organization Identity Information
            </span>
            <div className="grid grid-cols-6 gap-6 mt-2">
              <div className="col-span-2">
                <TextInput
                  id="organization.taxIdentificationNumber"
                  label="Tax Identification Number"
                  type="text"
                  placeholder="eg. C0012345678"
                  maxLength={11}
                  required={true}
                  {...form}
                />
              </div>

              <div className="col-span-2">
                <TextInput
                  id="organization.organizationRegistrationNumber"
                  label="Organization Registration Number"
                  type="text"
                  placeholder="eg. CS012345678"
                  maxLength={11}
                  required={true}
                  {...form}
                />
              </div>

              <div className="col-span-2">
                <TextInput
                  id="organization.organizationRegistrationDate"
                  label="Organization Registration Date"
                  type="date"
                  max={moment().format("YYYY-MM-DD")}
                  required={true}
                  {...form}
                />
              </div>

              <div className="col-span-3">
                <UploadArea
                  id="organization.certificateOfIncorporationDocumentUrl"
                  label="Certificate of Incorporation"
                  accept={{
                    "image/*": [".png", ".jpeg", ".jpg"],
                    "application/pdf": [".pdf"],
                  }}
                  required={true}
                  {...form}
                />
              </div>

              <div className="col-span-3">
                <UploadArea
                  id="organization.organizationRegistrationDocumentUrl"
                  label="Business Regsitration Document"
                  accept={{
                    "image/*": [".png", ".jpeg", ".jpg"],
                    "application/pdf": [".pdf"],
                  }}
                  required={true}
                  {...form}
                />
              </div>
            </div>
          </div>
        )}

        <div className="pt-6">
          <span className="text-xs font-light">Identity Type Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="col-span-1">
              <Switch.Group as="div">
                <span className="flex flex-grow flex-col">
                  <Switch.Label
                    as="span"
                    className="text-sm font-medium text-gray-900"
                    passive
                  >
                    Has Ghana Card
                  </Switch.Label>
                </span>
                <div className="flex items-center justify-between mt-1  h-[38px]">
                  <Switch.Description
                    as="span"
                    className="text-sm text-gray-500"
                  >
                    Do you have a Ghana Card?
                  </Switch.Description>
                  <Switch
                    checked={form.values.representative.hasGhanaCard}
                    onChange={(val: boolean) =>
                      form.setFieldValue("representative.hasGhanaCard", val)
                    }
                    className={classNames(
                      form.values.representative.hasGhanaCard
                        ? "bg-primary-600"
                        : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        form.values.representative.hasGhanaCard
                          ? "translate-x-5"
                          : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </div>
              </Switch.Group>
            </div>

            {!form.values.representative.hasGhanaCard && (
              <div>
                <SelectInput
                  id="representative.identityCardType"
                  label="ID Type"
                  options={[
                    { label: "--- Select ID Type ---", value: "" },
                    { label: "Driver's License", value: "DriversLicense" },
                    { label: "Passport", value: "Passport" },
                    {
                      label: "Voters ID",
                      value: "VotersIdentificationCard",
                    },
                  ]}
                  required={true}
                  {...form}
                />
              </div>
            )}
          </div>
        </div>
        {form.values.representative.hasGhanaCard ? (
          <div className="pt-6">
            <span className="text-xs font-light">
              {label} Identity Information
            </span>
            <div className="grid grid-cols-6 gap-6 mt-2">
              <div className="col-span-2">
                <TextInput
                  id="representative.ghanaCardNumber"
                  label="Ghana Card Number"
                  type="text"
                  placeholder="eg. GHA-123456789-0"
                  minLength={15}
                  maxLength={15}
                  required={true}
                  {...form}
                />
              </div>

              <div className="col-span-2">
                <TextInput
                  id="representative.ghanaCardIssueDate"
                  label="Ghana Card Issue Date"
                  type="date"
                  required={true}
                  {...form}
                />
              </div>

              <div className="col-span-2">
                <TextInput
                  id="representative.ghanaCardExpiryDate"
                  label="Ghana Card Expiry Date"
                  type="date"
                  required={true}
                  {...form}
                />
              </div>

              <div className="col-span-3">
                <UploadArea
                  id="representative.ghanaCardFrontImageUrl"
                  label="Ghana Card Front Image"
                  required={true}
                  {...form}
                />
              </div>

              <div className="col-span-3">
                <UploadArea
                  id="representative.ghanaCardBackImageUrl"
                  label="Ghana Card Back Image"
                  required={true}
                  {...form}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="pt-6">
            <span className="text-xs font-light">
              Customer Identity Information
            </span>
            <div className="grid grid-cols-6 gap-6 mt-2">
              <div className="col-span-2">
                <TextInput
                  id="representative.identityCardNumber"
                  label={"ID Number"}
                  type="text"
                  placeholder="eg. GH034034034"
                  required={true}
                  {...form}
                />
              </div>

              <div className="col-span-2">
                <TextInput
                  id="representative.identityCardIssueDate"
                  label="ID Issue Date"
                  type="date"
                  required={true}
                  {...form}
                />
              </div>

              {form.values.representative?.identityCardType !==
                "VotersIdentificationCard" && (
                <div className="col-span-2">
                  <TextInput
                    id="representative.identityCardExpiryDate"
                    label="ID Expiry Date"
                    type="date"
                    required={true}
                    {...form}
                  />
                </div>
              )}

              <div className="col-span-3 col-start-1">
                <UploadArea
                  id="representative.identityCardFrontImageUrl"
                  label="ID Front Image"
                  required={true}
                  {...form}
                />
              </div>

              {form.values.representative?.identityCardType ===
                "DriversLicense" && (
                <div className="col-span-3">
                  <UploadArea
                    id="representative.identityCardBackImageUrl"
                    label="ID Back Image"
                    required={true}
                    {...form}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        <div className="pt-6">
          <span className="text-xs font-light">Update Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="space-y-1 col-span-3">
              <TextArea
                id="reason"
                label="Reason for Update"
                placeholder="e.g. Reason for change"
                {...form}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateCustomerIdentityForm;
