import { TextArea } from "components/core";
import { FormikProps } from "formik";
import { FC } from "react";

interface RejectCustomerReadingFormProps {
  form: FormikProps<{
    reason: string;
  }>;
}

const RejectCustomerReadingForm: FC<RejectCustomerReadingFormProps> = ({
  form,
}) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Rejection Information</span>
        <div className="grid gap-6 mt-2">
          <div>
            <TextArea
              id="reason"
              label="Reason"
              placeholder="e.g. Details captured are conclusive"
              required={true}
              rows={10}
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectCustomerReadingForm;
