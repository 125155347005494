import { SelectInput, TextArea, TextInput, UploadArea } from "components/core"
import { FormikProps } from "formik"
import { ResolveReconnectionServiceOrderInput } from "pages/reconnection-service-orders/resolve";
import { FC } from "react"

interface ResolveServiceOrderFormProps {
  form: FormikProps<ResolveReconnectionServiceOrderInput>;
}

const ResolveServiceOrderForm: FC<ResolveServiceOrderFormProps> = ({ form }) => {

  return (
    <div className="space-y-6 divide-y overflow-y-scroll h-full no-scrollbar divide-gray-200">
      <div>
        <span className="text-xs font-light">Resolution Information</span>
        <div className="grid gap-6 mt-2">
          <div className="">
            <UploadArea
              id="resolution.supportingImageUrls"
              label="Supporting Images"
              className="aspect-w-5 aspect-h-1"
              multiple={true}
              maxFiles={6}
              {...form}
            />
          </div>
          <div>
            <TextArea
              id="resolution.notes"
              label="Notes"
              placeholder="e.g. Details captured are conclusive"
              required={true}
              rows={10}
              {...form}
            />
          </div>

        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Reading Information</span>
        <div className="grid grid-cols-2 gap-6 mt-2">
          <div className="">
            <TextInput
              id="resolution.reading.readingValue"
              label="Reading Value"
              placeholder="e.g. 100"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              id="resolution.reading.readingDate"
              label="Reading Date"
              type="date"
              required={true}
              {...form}
            />
          </div>
          <div className="col-span-2">
            <UploadArea
              id="resolution.reading.readingImageUrl"
              label="Reading Image"
              className="aspect-w-5 aspect-h-1"
              required={true}
              {...form}
            />
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Result Information</span>
        <div className="grid gap-6 mt-2">
          <div>
            <SelectInput
              id="result"
              label="Result"
              options={[
                { label: "-- Select Result --", value: "" },
                { label: "Passed", value: "Passed" },
                { label: "Failed", value: "Failed" }
              ]}
              placeholder="e.g. Passed"
              required={true}
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResolveServiceOrderForm