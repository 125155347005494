import moment from "moment";
import lodash from "lodash";
import numeral from "numeral";
import { BillItem, CalculateChargeInput, CalculateEnergyChargeOutput, CalculateExtraChargeInput, CalculateServiceChargeOutput, GenerateSplitInput, GenerateSplitOutput, GenerateTariffInstanceSplitInput } from "./generate-bill.types";

interface GenerateBillProps {
  previousReadingDate: Date;
  previousReadingValue: number;
  currentReadingDate: Date;
  currentReadingValue: number;
  tariffInstances: {
    code: string;
    name: string;
    startDate: string;
    endDate: string;
    energyCharge: {
      type: string;
      value: number;
      steps: {
        name: string;
        displayName: string;
        minimumConsumption: number;
        maximumConsumption: number;
        value: number;
        exclusive: boolean;
      }[];
    };
    serviceCharge: {
      type: string;
      value: number;
      steps: {
        name: string;
        displayName: string;
        minimumConsumption: number;
        maximumConsumption: number;
        value: number;
        exclusive: boolean;
      }[];
    };
    extraItems: {
      name: string;
      displayName: string;
      category: string;
      type: string;
      appliedTo: string;
      value: number;
    }[];
  }[];
}

const calculateEnergyCharge = ({ tariffInstance, numberOfDays, consumption, daysInYear }: CalculateChargeInput): CalculateEnergyChargeOutput => {
  let allowedConsumption = consumption;
  const energyChargeItems: BillItem[] = [];
  if (tariffInstance.energyCharge.type === "SteppedRate") {
    const exclusiveBands = lodash.chain(tariffInstance.energyCharge.steps || []).filter(["exclusive", true]).sortBy("minimumConsumption").value();
    const nonExclusiveBands = lodash.chain(tariffInstance.energyCharge.steps || []).filter(["exclusive", false]).sortBy("minimumConsumption").reverse().value();

    for (let i = 0; i < exclusiveBands.length; i++) {
      const band = exclusiveBands[i];
      const stepBand = (band.maximumConsumption * 12 / daysInYear) * numberOfDays;
      if (stepBand > allowedConsumption) {
        const stepConsumption = lodash.round(allowedConsumption);
        const unitCost = lodash.round(band.value, 4);
        const cost = lodash.round(unitCost * stepConsumption, 2);
        energyChargeItems.push({
          title: band.name,
          displayTitle: band.displayName,
          unit: `${numeral(stepConsumption).format("0,0")} KWh`,
          quantity: stepConsumption,
          unitCost,
          formattedUnitCost: numeral(unitCost).format("0,0.0000"),
          cost
        });
        return ({
          energyChargeItems,
          energyCharge: lodash.chain(energyChargeItems).sumBy("cost").round(2).value(),
        })
      }
    }

    for (let i = 0; i < nonExclusiveBands.length; i++) {
      const band = nonExclusiveBands[i];
      const stepBand = (lodash.max([(band.minimumConsumption - 1), 0]) as number * 12 / daysInYear) * numberOfDays;
      if (allowedConsumption > stepBand) {
        const stepConsumption = lodash.round(allowedConsumption - stepBand);
        const unitCost = lodash.round(band.value, 4);
        const cost = lodash.round(unitCost * stepConsumption, 2);
        allowedConsumption -= stepConsumption;
        energyChargeItems.push({
          title: band.name,
          displayTitle: band.displayName,
          unit: `${numeral(stepConsumption).format("0,0")} KWh`,
          quantity: stepConsumption,
          unitCost,
          formattedUnitCost: numeral(unitCost).format("0,0.0000"),
          cost,
        });
      }
    }

    energyChargeItems.reverse()
  } else {
    const unitCost = lodash.round(tariffInstance.energyCharge.value, 4);
    const cost = lodash.round(unitCost * numberOfDays, 2);
    energyChargeItems.push({
      title: "Energy Charge",
      displayTitle: "EC",
      unit: `${numeral(allowedConsumption).format("0,0")} KWh`,
      quantity: lodash.round(allowedConsumption),
      unitCost,
      formattedUnitCost: numeral(unitCost).format("0,0.0000"),
      cost
    })
  }

  return ({
    energyChargeItems,
    energyCharge: lodash.chain(energyChargeItems).sumBy("cost").round(2).value(),
  });
}

const calculateServiceCharge = ({ tariffInstance, numberOfDays, consumption, daysInYear }: CalculateChargeInput): CalculateServiceChargeOutput => {
  let allowedConsumption = consumption;
  const serviceChargeItems: BillItem[] = [];
  if (tariffInstance.serviceCharge.type === "SteppedRate") {
    const exclusiveBands = lodash.chain(tariffInstance.serviceCharge.steps || []).sortBy("minimumConsumption").value();

    for (let i = 0; i < exclusiveBands.length; i++) {
      const band = exclusiveBands[i];
      const stepBand = (band.maximumConsumption * 12 / daysInYear) * numberOfDays;
      if (stepBand > allowedConsumption) {
        const unitCost = lodash.round(band.value * 12 / daysInYear, 4);
        const cost = lodash.round(unitCost * numberOfDays, 2);
        serviceChargeItems.push({
          title: band.name || "Service Charge",
          displayTitle: band.displayName || "SC",
          unit: `${numeral(numberOfDays).format("0,0")} Days`,
          quantity: numberOfDays,
          unitCost,
          formattedUnitCost: numeral(unitCost).format("0,0.0000"),
          cost
        });
        return ({
          serviceChargeItems,
          serviceCharge: lodash.chain(serviceChargeItems).sumBy("cost").round(2).value(),
        });
      }
    }

    serviceChargeItems.reverse()
  } else {
    const unitCost = lodash.round(tariffInstance.serviceCharge.value * 12 / daysInYear, 4);
    const cost = lodash.round(unitCost * numberOfDays, 2)
    serviceChargeItems.push({
      title: "Service Charge",
      displayTitle: "SC",
      unit: `${numeral(numberOfDays).format("0,0")} Days`,
      quantity: numberOfDays,
      unitCost,
      formattedUnitCost: numeral(unitCost).format("0,0.0000"),
      cost,
    })
  }

  return ({
    serviceChargeItems,
    serviceCharge: lodash.chain(serviceChargeItems).sumBy("cost").round(2).value(),
  });
}

const calculateExtraCharge = ({ tariffInstance, numberOfDays, consumption, energyCharge, serviceCharge }: CalculateExtraChargeInput): BillItem[] => {
  const items: BillItem[] = []
  const energyPlusServiceCharge = energyCharge + serviceCharge;

  tariffInstance.extraItems.forEach((extraItem: any) => {
    const extraItemQuantity = (({
      "EnergyCharge": energyCharge,
      "ServiceCharge": energyCharge,
      "EnergyPlusServiceCharge": energyPlusServiceCharge,
      "ConsumptionPeriod": numberOfDays,
      "ConsumptionValue": consumption,
    }) as any)[extraItem.appliedTo];

    const extraItemUnit = (({
      "EnergyCharge": `GHS ${numeral(energyCharge).format("0,0.00")}`,
      "ServiceCharge": `GHS ${numeral(energyCharge).format("0,0.00")}`,
      "EnergyPlusServiceCharge": `GHS ${numeral(energyPlusServiceCharge).format("0,0.00")}`,
      "ConsumptionPeriod": `${numberOfDays} days`,
      "ConsumptionValue": `${numeral(consumption).format("0,0")} KWh`,
    }) as any)[extraItem.appliedTo];

    const unitCost = lodash.round(extraItem.value, 4);
    let extraItemCost = 0;
    let formattedUnitCost: string = "";
    switch (extraItem.type) {
      case "FixedPercentage": {
        extraItemCost = (extraItemQuantity as number) * extraItem.value / 100;
        formattedUnitCost = `${numeral(unitCost).format("0,0.00")}%`;
        break;
      }
      case "FixedRate": {
        extraItemCost = (extraItemQuantity as number) * extraItem.value;
        formattedUnitCost = numeral(unitCost).format("0,0.0000");
        break;
      }
      case "FixedValue": {
        extraItemCost = extraItem.value;
        formattedUnitCost = numeral(unitCost).format("0,0.0000");
        break;
      }
    }

    items.push({
      title: extraItem.name,
      displayTitle: extraItem.displayName,
      unit: extraItemUnit,
      quantity: extraItemQuantity,
      unitCost,
      formattedUnitCost,
      cost: lodash.round(extraItemCost, 2),
    })
  })

  return items;
}

const generateSplit = ({ previousDate, currentDate, tariffInstance, billPeriod, totalConsumption }: GenerateSplitInput): GenerateSplitOutput => {
  const numberOfDays = moment(currentDate).diff(previousDate, "days") + 1;
  const consumption = lodash.round((totalConsumption * (numberOfDays / billPeriod)))
  return ({
    currentDate,
    previousDate,
    tariffInstance,
    numberOfDays,
    consumption,
  })
}

const generateTariffInstanceSplits = ({ billPeriod, totalConsumption, billStartDate, billEndDate, tariffInstances }: GenerateTariffInstanceSplitInput): GenerateSplitOutput[] => {
  const tariffInstanceSplits = [];
  const sortedTariffInstances = lodash.sortBy(tariffInstances, "startDate");
  let startDate = billStartDate;
  for (let i = 0; i < sortedTariffInstances.length; i++) {
    const tariffInstance = sortedTariffInstances[i];
    if (tariffInstance.endDate) {
      if (moment(tariffInstance.endDate).isBefore(billEndDate, "day")) {
        tariffInstanceSplits.push(generateSplit({
          previousDate: startDate,
          currentDate: tariffInstance.endDate,
          tariffInstance,
          billPeriod,
          totalConsumption,
        }));
        startDate = moment(tariffInstance.endDate).add(1, "day").startOf("day").toISOString();
      }
      else if (moment(tariffInstance.endDate).isSame(billEndDate, "day")) {
        tariffInstanceSplits.push(generateSplit({
          previousDate: startDate,
          currentDate: tariffInstance.endDate,
          tariffInstance,
          billPeriod,
          totalConsumption,
        }));
        break;
      }
      else {
        tariffInstanceSplits.push(generateSplit({
          previousDate: startDate,
          currentDate: billEndDate,
          tariffInstance,
          billPeriod,
          totalConsumption,
        }));
        break;
      }
    }
    else {
      tariffInstanceSplits.push(generateSplit({
        previousDate: startDate,
        currentDate: billEndDate,
        tariffInstance,
        billPeriod,
        totalConsumption,
      }));
      break;
    }
  }
  return tariffInstanceSplits;
}

export const generateBill = ({
  previousReadingDate,
  previousReadingValue,
  currentReadingDate,
  currentReadingValue,
  tariffInstances
}: GenerateBillProps) => {

  const daysInYear = moment().isLeapYear() ? 366 : 365;

  // Calculate number of days between reading dates
  const consumptionPeriod = moment(currentReadingDate).endOf("day").diff(moment(previousReadingDate).startOf("day"), "days") + 1;
  const billStartDate = moment(previousReadingDate).add(1, "day").startOf("day").toDate();
  const billEndDate = moment(currentReadingDate).endOf("day").toDate();
  const billPeriod = moment(billEndDate).endOf("day").diff(moment(billStartDate).startOf("day"), "days") + 1;

  // Calculate the consumption
  let totalConsumption = currentReadingValue - previousReadingValue;

  // split tariffInstances here
  const tariffInstanceSplits = generateTariffInstanceSplits({
    billPeriod,
    totalConsumption,
    billStartDate: moment(previousReadingDate).add(1, "day").startOf("day"),
    billEndDate: moment(currentReadingDate).endOf("day"),
    tariffInstances: lodash.filter(
      tariffInstances,
      (tariffInstance) =>
        moment(tariffInstance.endDate).isSameOrAfter(
          previousReadingDate
        ) || !tariffInstance.endDate
    )
  });

  const bills = [];
  for (let i = 0; i < tariffInstanceSplits.length; i++) {
    const tariffInstanceSplit = tariffInstanceSplits[i];
    let billItems: BillItem[] = [];
    

        // Calculate the energy charge
        const { energyChargeItems, energyCharge } = calculateEnergyCharge({
          ...tariffInstanceSplit,
          daysInYear,
        });
        billItems = [
          ...billItems,
          ...energyChargeItems
        ];

        // Calculate the service charge
        const { serviceChargeItems, serviceCharge } = calculateServiceCharge({
          ...tariffInstanceSplit,
          daysInYear,
        });
        billItems = [
          ...billItems,
          ...serviceChargeItems
        ];

        // Calculate the extra charge
        const extraChargeItems = calculateExtraCharge({
          ...tariffInstanceSplit,
          daysInYear,
          energyCharge,
          serviceCharge
        })
        billItems = [
          ...billItems,
          ...extraChargeItems
        ];

        bills.push({
          ...tariffInstanceSplit,
          billItems,
          billAmount: lodash.chain(billItems).sumBy("cost").round(2).value(),
        })
      }

  return {
    totalConsumption,
    billPeriod,
    billStartDate,
    billEndDate,
    consumptionPeriod,
    previousReadingDate,
    currentReadingDate,
    previousReadingValue,
    currentReadingValue,
    totalBillAmount: lodash.chain(bills).sumBy("billAmount").round(2).value(),
    bills
  }
}