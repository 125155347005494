import {
  OfficeCalendarHeader,
  OfficeHeader,
  Shimmers,
  TableComponent,
} from "components";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  wrapClick,
  useUrlState,
  classNames,
  withPermissions,
  useTableData,
} from "utils";
import config from "config";
import UpdateCustomerContainer from "./update-contact";
import ViewCustomerContainer from "./view";
import CustomersFilterContainer from "./filter";
import { Avatar, SearchSelectInput, SelectInput } from "components/core";
import ActionButton, { Action } from "components/buttons/action-button";
import { useServicePoints } from "./hooks";
import UpdateCustomerIdentityContainer from "./update-identity";
import UpdateCustomerLocationContainer from "./update-location";
import UpdateCustomerTariffContainer from "./update-tariff";
import SuspendContainer from "./suspend";
import RestoreCustomerContainer from "./restore";
import TerminateCustomerContainer from "./terminate";
import ViewComplaintContainer from "pages/complaints/view";


const searchOptions = [
  {
    label: "Service Point Number",
    value: "code",
    min: 5,
  },
  {
    label: "Customer Number",
    value: "customerCode",
    min: 4,
  },
  {
    label: "Account Number",
    value: "accountCode",
    min: 5,
  },
  {
    label: "Geo Code",
    value: "geoCode",
    min: 17,
  },
  {
    label: "Meter Number",
    value: "meterCode",
    min: 5,
  },
];
const CustomersPage: FC = () => {
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const navigateItem = (id: string) => () => {
    navigate({
      to: `./${id}/readings`,
    });
  };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [status, setStatus] = useUrlState("status");
  const [serviceType, setServiceType] = useUrlState("serviceType");
  const [serviceClass, setServiceClass] = useUrlState("serviceClass");
  const [searchBy, setSearchParameter] = useUrlState("search_field");
  const [searchValue, setSearch] = useUrlState("search");
  const [minSearch, setMinSearch] = useUrlState("minSearch");

  const updateSearchBy = (key: string) => {
    setSearchParameter(key);
    if (key) {
      setMinSearch(searchOptions.find((option) => option.value === key)?.min);
    }
  };

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search:
      searchParams.search && searchBy
        ? (searchParams.search || "").toString()
        : undefined,
    searchFields: searchBy && searchValue ? [searchBy] : undefined,
      sort: searchParams.sort || "",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      block: searchParams.block || undefined,
      round: searchParams.round || undefined,
      plot: searchParams.plot || undefined,
      serviceType: searchParams.serviceType || undefined,
      status: searchParams.status || undefined,
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
    }),
    [searchParams]
  );

  const { servicePoints, count, loading, refetch, networkStatus } =
    useServicePoints(filter);

  const records = useTableData({ rows: servicePoints, count });


  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "" } : {}),
      }),
    });
  }, [navigate]);

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <OfficeCalendarHeader renderActions={() => <></>} />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"customers"}
            refetch={refetch}
            onSearchClicked={setSearch}
            disableSearch={!searchBy}
            hasSearch={true}
            renderSearchOption={() => (
              <SelectInput
                id={"searchBy"}
                label={""}
                values={{ searchBy }}
                handleChange={(e: any) => updateSearchBy(e.target.value)}
                handleBlur={undefined}
                options={[
                  {
                    label: "Search By",
                    value: "",
                  },
                  ...searchOptions,
                ]}
              />
            )}            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            renderHeaderItems={() => (
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex space-x-2">
                <SearchSelectInput
                  id="status"
                  labelHidden={true}
                  options={[
                    { label: { title: "Active" }, value: "Active" },
                    { label: { title: "Suspended" }, value: "Suspended" },
                    {
                      label: { title: "Terminated" },
                      value: "Terminated",
                    },
                  ]}
                  label={""}
                  placeholder="Select Status"
                  setFieldValue={(_: any, value: string) => setStatus(value)}
                  values={{ status }}
                />
                <SearchSelectInput
                  id="serviceType"
                  labelHidden={true}
                  options={[
                    { label: { title: "Prepaid" }, value: "Prepaid" },
                    { label: { title: "Postpaid" }, value: "Postpaid" },
                    {
                      label: { title: "AMR" },
                      value: "AMR",
                    },
                  ]}
                  label={""}
                  placeholder="Select Service Type"
                  setFieldValue={(_: any, value: string) =>
                    setServiceType(value)
                  }
                  values={{ serviceType }}
                />
                {/* <SearchSelectInput
                id="status"
                labelHidden={true}
                options={[
                  { label: { title: "Active" }, value: "Active" },
                  { label: { title: "Suspended" }, value: "Suspended" },
                  {
                    label: { title: "Terminated" },
                    value: "Terminated",
                  },
                ]}
                label={""}
                placeholder="Select Status"
                setFieldValue={(_: any, value: string) => setStatus(value)}
                values={{ status }}
              /> */}
              </div>
            )}
            renderFilter={({ filterOpen, setFilterOpen }) => (
              <CustomersFilterContainer
                open={filterOpen}
                setOpen={setFilterOpen}
              />
            )}
            // renderExport={({ exportOpen, setExportOpen }) => (
            //   <ExportDataContainer
            //     open={exportOpen}
            //     setOpen={setExportOpen}
            //     entityType="Customer"
            //     filter={filter}
            //   />
            // )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Customer
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Contact
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  SPN | Account No
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Geo Code | Meter No
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Address
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Tarrif Class
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.AvatarShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={2} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <Avatar
                        disabled={true}
                        alt={
                          (
                            item?.customerRepresentative?.fullName ||
                            item?.customerRepresentative?.name ||
                            item?.customerRepresentative?.fullName
                          )?.trim() || "N A"
                        }
                        src={
                          item?.customerRepresentative?.profileImageUrl ||
                          item?.customerRepresentative?.profileImageUrl ||
                          ""
                        }
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-gray-900 dark:text-gray-100">
                        {(
                          item?.customerRepresentative?.fullName ||
                          item?.customer?.organization?.name ||
                          item?.customerRepresentative?.fullName
                        )?.trim() || "N/A"}
                      </div>
                      <div className="text-gray-500 dark:text-gray-400">
                        {item?.customerCode || "N/A"}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.customerRepresentative?.phoneNumber ||
                      item?.customerRepresentative?.phoneNumber ||
                      "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.customerRepresentative?.emailAddress ||
                      item?.customerRepresentative?.emailAddress ||
                      "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.code || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.accountCode || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.geoCode || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.meterCode || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.ghanaPostAddress || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400 truncate max-w-md">
                    {item?.property?.address || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.tariffClass?.name || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.tariffClass?.code || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <span
                    className={classNames(
                      "bg-gray-100 text-gray-800",
                      item?.status === "Diisconnected"
                        ? `bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50`
                        : "",
                      item?.status === "Finalized"
                        ? `bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50`
                        : "",
                      item?.status === "Pending"
                        ? `bg-gray-100 text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50`
                        : "",
                      item?.status === "Active"
                        ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                        : "",
                      !item?.status
                        ? "bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-100"
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 10"
                      fill="currentColor"
                      className="w-1.5 h-1.5"
                    >
                      <circle
                        fillRule="evenodd"
                        cx="5"
                        cy="5"
                        r="5"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>{item?.status || "N/A"}</span>
                  </span>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className="space-x-1">
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    {withPermissions([
                      "*:*",
                      "service-points:*",
                      "service-points:update",
                    ])(
                      <ActionButton
                        action="goto"
                        tooltip="view readings"
                        onClick={navigateItem(item?._id)}
                      />
                    )}
                    {/* {withPermissions([
                      "*:*",
                      "service-points:*",
                      "service-points:update",
                    ])(
                      <ActionButton
                        action='update'
                        onClick={dispatchAction(item?._id, "update")}
                      />
                    )} */}
                  </div>
                </td>
              </tr>
            )}
          
          />
        </div>
      </div>

      {!!searchParams.id?.length && (
        <>
          <ViewComplaintContainer
            open={modal === "viewComplaint"}
            setOpen={(val: boolean) =>
              setModal(val ? "viewComplaint" : undefined)
            }
          />
          {withPermissions([
            "*:*",
            "service-points:*",
            "service-points:update",
          ])(
            <UpdateCustomerIdentityContainer
              open={modal === "updateIdentity"}
              setOpen={(val: boolean) =>
                setModal(val ? "updateIdentity" : "view")
              }
              refetch={refetch}
            />
          )}
          {withPermissions([
            "*:*",
            "service-points:*",
            "service-points:update",
          ])(
            <UpdateCustomerContainer
              open={modal === "update"}
              setOpen={(val: boolean) => setModal(val ? "update" : "view")}
              refetch={refetch}
            />
          )}
          {withPermissions([
            "*:*",
            "service-points:*",
            "service-points:update",
          ])(
            <UpdateCustomerLocationContainer
              open={modal === "updateLocation"}
              setOpen={(val: boolean) =>
                setModal(val ? "updateLocation" : "view")
              }
              refetch={refetch}
            />
          )}
          {withPermissions([
            "*:*",
            "service-points:*",
            "service-points:update",
          ])(
            <UpdateCustomerTariffContainer
              open={modal === "updateTariff"}
              setOpen={(val: boolean) =>
                setModal(val ? "updateTariff" : "view")
              }
              refetch={refetch}
            />
          )}
          {withPermissions([
            "*:*",
            "service-points:*",
            "service-points:update",
          ])(
            <SuspendContainer
              open={modal === "suspend"}
              setOpen={(val: boolean) => setModal(val ? "suspend" : "view")}
              refetch={refetch}
            />
          )}
          {withPermissions([
            "*:*",
            "service-points:*",
            "service-points:update",
          ])(
            <RestoreCustomerContainer
              open={modal === "restore"}
              setOpen={(val: boolean) => setModal(val ? "restore" : "view")}
              refetch={refetch}
            />
          )}
          {withPermissions([
            "*:*",
            "service-points:*",
            "service-points:update",
          ])(
            <TerminateCustomerContainer
              open={modal === "terminate"}
              setOpen={(val: boolean) => setModal(val ? "terminate" : "view")}
              refetch={refetch}
            />
          )}
          <ViewCustomerContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default CustomersPage;
