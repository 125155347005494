import { Switch } from "@headlessui/react";
import { TextInput } from "components/core"
import { FormikProps } from "formik"
import { FC } from "react"
import { classNames } from "utils";

interface SendBillFormProps {
  form: FormikProps<{
    fullName: string;
    subject: string;
    emailAddress: string;
    emailAddressEnabled: boolean;
    phoneNumber: string;
    phoneNumberEnabled: boolean;
  }>;
}

const SendBillForm: FC<SendBillFormProps> = ({ form }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Recipient</span>
        <div className="grid grid-cols-2 gap-6 mt-2">
          <div>
            <TextInput
              id="fullName"
              label="Name"
              type="text"
              placeholder="eg. Kwadwo Mensah"
              required={true}
              {...form}
            />
          </div>
          <div className="col-span-2">
            <TextInput
              id="subject"
              label="Subject"
              type="text"
              placeholder="eg. January 2023 Bill"
              {...form}
            />
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">EMAIL</span>
        <div className="grid grid-cols-2 gap-6 mt-2">
          <div>
            <Switch.Group as="div">
              <span className="flex flex-grow flex-col">
                <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                  Email Enabled
                </Switch.Label>
              </span>
              <div className="flex items-center justify-between mt-1  h-[38px]">
                <Switch.Description as="span" className="text-sm text-gray-500">
                  Send copy of bill to an email address?
                </Switch.Description>
                <Switch
                  checked={form.values.emailAddressEnabled}
                  onChange={(val: boolean) => form.setFieldValue("emailAddressEnabled", val)}
                  className={classNames(
                    form.values.emailAddressEnabled ? "bg-primary-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      form.values.emailAddressEnabled ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>

              </div>
            </Switch.Group>
          </div>
          <div>
            <TextInput
              id="emailAddress"
              label="Email Address"
              type="email"
              placeholder="eg. alexagbotsi@gmail.com"
              required={form.values.emailAddressEnabled}
              disabled={!form.values.emailAddressEnabled}
              {...form}
            />
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">SMS ALERT</span>
        <div className="grid grid-cols-2 gap-6 mt-2">
          <div>
            <Switch.Group as="div">
              <span className="flex flex-grow flex-col">
                <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                  SMS Enabled
                </Switch.Label>
              </span>
              <div className="flex items-center justify-between mt-1  h-[38px]">
                <Switch.Description as="span" className="text-sm text-gray-500">
                  Send copy of bill to an phone number via sms?
                </Switch.Description>
                <Switch
                  checked={form.values.phoneNumberEnabled}
                  onChange={(val: boolean) => form.setFieldValue("phoneNumberEnabled", val)}
                  className={classNames(
                    form.values.phoneNumberEnabled ? "bg-primary-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      form.values.phoneNumberEnabled ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>

              </div>
            </Switch.Group>
          </div>
          <div>
            <TextInput
              id="phoneNumber"
              label="Phone Number"
              type="text"
              placeholder="eg. 0550123292"
              required={form.values.phoneNumberEnabled}
              disabled={!form.values.phoneNumberEnabled}
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SendBillForm