import { TextInput } from "components/core";
import { useFormik } from "formik";
import { FC, useMemo } from "react";
import _ from "lodash";
import { wrapClick } from "utils";
import { ReadingInfoFormSchema } from "./schema";
import moment from "moment";

interface ReadingInfoFormProps {
  handleNext: (values: any) => void;
  handlePrevious: () => void;
  initialValues: any;
  values: any;
  handleCancel: () => void;
}

const ReadingInfoForm: FC<ReadingInfoFormProps> = ({
  initialValues,
  handleNext,
  handlePrevious,
  handleCancel,
  values
}) => {
  const form = useFormik({
    initialValues,
    validationSchema: ReadingInfoFormSchema,
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });
  const [previousReadingValue, previousReadingDate] = useMemo(() => [
    _.get(values, "customerInfo.existingServicePoint.meta.lastCmsActualReadingValue"),
    moment(_.get(values, "customerInfo.existingServicePoint.meta.lastCmsActualReadingDate")).format("YYYY-MM-DD")
  ], [values])

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto">
        <div>
          <span className="text-xs font-light">Previous Reading Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="">
              <TextInput
                id="previousReadingValue"
                label="Previous Reading Value"
                type="number"
                step={1}
                min={1}
                placeholder="eg. 3"
                disabled={true}
                postText="KWh"
                {...form}
                values={{previousReadingValue}}
              />
            </div>
            <div className="">
              <TextInput
                id="previousReadingDate"
                label="Previous Reading Date"
                type="date"
                disabled={true}
                {...form}
                values={{previousReadingDate}}
              />
            </div>
          </div>
        </div>
        <div>
          <span className="text-xs font-light">Current Reading Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="">
              <TextInput
                id="currentReadingValue"
                label="Current Reading Value"
                type="number"
                step={1}
                min={previousReadingValue}
                placeholder="eg. 3"
                required={true}
                postText="KWh"
                {...form}
              />
            </div>
            <div className="">
              <TextInput
                id="currentReadingDate"
                label="Current Reading Date"
                type="date"
                required={true}
                min={previousReadingDate}
                max={moment().format("YYYY-MM-DD")}
                {...form}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(form.handleSubmit)}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ReadingInfoForm;
