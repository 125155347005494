import { wrapClick } from "utils";
import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { RejectCustomerReadingForm, Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";

const REJECT_CUSTOMER_READING = gql`
  mutation RejectCustomerReading(
    $rejectCustomerReadingId: ID!
    $reason: String!
  ) {
    rejectCustomerReading(id: $rejectCustomerReadingId, reason: $reason) {
      _id
    }
  }
`;

export default function RejectCustomerReadingContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [approveDebt, { loading }] = useMutation(REJECT_CUSTOMER_READING);

  const form = useFormik({
    initialValues: {
      reason: "",
    },
    onSubmit: async (values) => {
      await approveDebt({
        variables: {
          rejectCustomerReadingId: searchParams.id,
          ...values,
        },
      }).then(({ data }) => {
        if (data.rejectCustomerReading._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Customer Reading Rejected Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not reject Customer Reading",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Reject Customer Reading"
      description="Reason for rejecting Customer Reading"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading
              ? "Rejecting Customer Reading..."
              : "Reject Customer Reading"}
          </button>
        </>
      )}
    >
      <RejectCustomerReadingForm form={form} />
    </Modal>
  );
}
