import {
  Avatar,
  CalendarHeader,
  SearchSelectInput,
  SelectInput,
  Shimmers,
  TableComponent,
} from "components";
import _ from "lodash";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  wrapClick,
  useUrlState,
  classNames,
  useTableData,
  withPermissions,
} from "utils";
import config from "config";
import ViewMeterContainer from "./view";
import { Action } from "components/buttons/action-button";
import { useSubmittedReadings } from "./hooks";
import moment from "moment";
import lodash from "lodash";
import numeral from "numeral";
import CreateCustomerReadingContainer from "./create";
import ApproveCustomerReadingContainer from "./approve";
import RejectCustomerReadingContainer from "./reject";

const DATE_FORMAT = "YYYY-MM-DD";


const searchOptions = [
  {
    label: "Customer Number",
    value: "customerCode",
    min: 4,
  },
  {
    label: "Account Number",
    value: "accountCode",
    min: 5,
  },
  {
    label: "Service Point Number",
    value: "servicePointCode",
    min: 5,
  },
  {
    label: "Geo Code",
    value: "geoCode",
    min: 13,
  },
];
const SubmittedReadingsPage: FC = () => {
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [source, setSource] = useUrlState("source");
  const [searchBy, setSearchParameter] = useUrlState("search_field");
  const [searchValue, setSearch] = useUrlState("search");
  const [minSearch, setMinSearch] = useUrlState("minSearch");

  const updateSearchBy = (key: string) => {
    setSearchParameter(key);
    if (key) {
      setMinSearch(searchOptions.find((option) => option.value === key)?.min);
    }
  };
  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search:
        searchParams.search && searchBy
          ? (searchParams.search || "").toString()
          : undefined,
      searchFields: searchBy && searchValue ? [searchBy] : undefined,
      sort: searchParams.sort || "",
      origin: searchParams.source || undefined,
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      status: searchParams.status || undefined,
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
    }),
    [searchParams]
  );

  const { submittedReadings, count, loading, networkStatus, refetch } =
    useSubmittedReadings(filter);

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "" } : {}),
        ...(!old?.sort ? { sort: "" } : {}),
      }),
    });
  }, [navigate]);

  const records = useTableData({ rows: submittedReadings, count });

  return (
    <main className='flex-1 flex flex-col overflow-hidden bg-gray-50'>
      <CalendarHeader
      // renderActions={() => (
      //   <>
      //     {withPermissions([
      //       "*:*",
      //       "submitted-readings:*",
      //       "submitted-readings:create",
      //     ]) && (
      //       <button
      //         type='button'
      //         onClick={() => setModal("create")}
      //         className='ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
      //       >
      //         <PlusIcon className='-ml-0.5 mr-2 h-4 w-4' aria-hidden='true' />
      //         Record Customer Reading
      //       </button>
      //     )}
      //   </>
      // )}
      />

      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={"submitted readings"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            onSearchClicked={setSearch}
            disableSearch={!searchBy}
            hasSearch={true}
            renderSearchOption={() => (
              <SelectInput
                id={"searchBy"}
                label={""}
                values={{ searchBy }}
                handleChange={(e: any) => updateSearchBy(e.target.value)}
                handleBlur={undefined}
                options={[
                  {
                    label: "Search By",
                    value: "",
                  },
                  ...searchOptions,
                ]}
              />
            )}
            renderHeaderItems={() => (
              <div className='mt-4 sm:mt-0.5 sm:ml-16 sm:flex space-x-2'>
                <SearchSelectInput
                  id='source'
                  labelHidden={true}
                  label={""}
                  placeholder='Select Source'
                  setFieldValue={(_: any, value: string) => {
                    setSource(value);
                  }}
                  values={{ source }}
                  options={[
                    {
                      label: {
                        title: "WhatsApp",
                      },
                      value: "WhatsApp",
                    },
                    {
                      label: {
                        title: "Power App",
                      },
                      value: "PowerApp",
                    },
                    {
                      label: {
                        title: "Zeus Mobile",
                      },
                      value: "ZeusApp",
                    },
                    {
                      label: {
                        title: "Contact Center",
                      },
                      value: "SupportApp",
                    },
                  ]}
                />
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Customer
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  SPN | Account No
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Geo Code | Meter No
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Reading
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Read At
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Status
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Source
                </th>
                {/* <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                ></th> */}
              </tr>
            )}
            renderLoader={() => (
              <>
                <tr>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.SingleShimmer />
                  </td>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.SingleShimmer />
                  </td>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.SingleShimmer />
                  </td>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.AvatarShimmer />
                  </td>
                  {/* <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.ActionsShimmer actionsCount={2} />
                  </td> */}
                </tr>
              </>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='flex items-center'>
                    <div className='h-10 w-10 flex-shrink-0'>
                      <Avatar
                        disabled={true}
                        alt={
                          item?.servicePoint?.customerOrganization?.name?.trim() ||
                          item?.servicePoint?.customerRepresentative?.fullName?.trim() ||
                          "N A"
                        }
                        src={item?.profileImageUrl || ""}
                      />
                    </div>
                    <div className='ml-4'>
                      <div className='text-gray-900 dark:text-gray-100'>
                      {item?.servicePoint?.customerOrganization?.name?.trim() ||
                        item?.servicePoint?.customerRepresentative?.fullName?.trim() ||
                          "N A"}
                      </div>
                      <div className='text-gray-500 dark:text-gray-400'>
                        {item?.servicePoint?.customerCode || "N/A"}
                      </div>
                    </div>
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.servicePoint?.code || "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.servicePoint?.accountCode || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.servicePoint?.geoCode || "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.meter?.code || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {lodash.isNumber(item?.currentReading?.readingValue)
                      ? `${numeral(
                          item.currentReading.readingValue || 0
                        ).format("0,0")} KWh`
                      : "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.currentReading?.readingDate
                      ? moment(item.currentReading?.readingDate).format(DATE_FORMAT)
                      : "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.currentReading?.readingDate
                      ? moment(item.currentReading?.readingDate).format(`hh:mm A`)
                      : "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <span
                    className={classNames(
                      item?.status === "AnomalyDetected"
                        ? `bg-red-100 text-red-800 dark:bg-red-600 dark:text-red-100`
                        : "",
                      item?.status === "ExceptionDetected"
                        ? `bg-amber-100 text-amber-800 dark:bg-amber-600 dark:text-amber-100`
                        : "",
                      item?.status === "Pending"
                        ? `bg-gray-100 text-gray-800 dark:bg-gray-600 dark:text-gray-50`
                        : "",
                      item?.status === "MeterRead"
                        ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-green-100`
                        : "",
                      item?.status === "NoTrace"
                        ? `bg-purple-100 text-purple-800 dark:bg-purple-600 dark:text-purple-100`
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 10 10'
                      fill='currentColor'
                      className='w-1.5 h-1.5'
                    >
                      <circle
                        fillRule='evenodd'
                        cx='5'
                        cy='5'
                        r='5'
                        clipRule='evenodd'
                      />
                    </svg>
                    <span>{lodash.startCase(item?.status) || "Unknown"}</span>
                  </span>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <span
                    className={classNames(
                      item?.origin === "ZeusApp"
                        ? `bg-amber-100 text-amber-800 dark:bg-amber-600 dark:text-amber-100`
                        : "",
                      item?.origin === "PowerApp"
                        ? `bg-blue-100 text-blue-800 dark:bg-blue-600 dark:text-blue-100`
                        : "",
                      item?.origin === "WhatsApp"
                        ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-green-100`
                        : "",
                      item?.origin === "SupportApp"
                        ? `bg-purple-100 text-purple-800 dark:bg-purple-600 dark:text-purple-100`
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 10 10'
                      fill='currentColor'
                      className='w-1.5 h-1.5'
                    >
                      <circle
                        fillRule='evenodd'
                        cx='5'
                        cy='5'
                        r='5'
                        clipRule='evenodd'
                      />
                    </svg>
                    <span>{lodash.startCase(item?.origin) || "Unknown"}</span>
                  </span>
                </td>
                {/* <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    {item?.status === "Pending" && (
                      <>
                        <ActionButton
                          action='approve'
                          onClick={dispatchAction(item?._id, "approve")}
                        />
                        <ActionButton
                          action='reject'
                          onClick={dispatchAction(item?._id, "reject")}
                        />
                      </>
                    )}
                  </div>
                </td> */}
              </tr>
            )}
          />
        </div>
      </div>

      {!!searchParams.id?.length && !!searchParams.modal?.length && (
        <>
          <ViewMeterContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
          {withPermissions(["*:*", "bills:*", "bills:approve"])(
            <ApproveCustomerReadingContainer
              open={modal === "approve"}
              setOpen={(val: boolean) => setModal(val ? "approve" : undefined)}
              refetch={refetch}
            />
          )}{" "}
          {withPermissions(["*:*", "bills:*", "bills:approve"])(
            <RejectCustomerReadingContainer
              open={modal === "reject"}
              setOpen={(val: boolean) => setModal(val ? "reject" : undefined)}
              refetch={refetch}
            />
          )}
        </>
      )}

      <CreateCustomerReadingContainer
        open={modal === "create"}
        setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
        refetch={refetch}
      />
    </main>
  );
};

export default SubmittedReadingsPage;
