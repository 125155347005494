import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Header, Shimmers, TableComponent } from "components";
import _ from "lodash";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick, useUrlState, classNames, withPermissions } from "utils";
import config from "config";
import {
  PlusIcon,
  ArrowUpTrayIcon,
  HomeIcon,
  BuildingStorefrontIcon,
  BuildingOffice2Icon,
  CalculatorIcon,
} from "@heroicons/react/24/outline";
import BillSimulatorContainer from "./simulator";
import { currentConfigVar } from "apollo/cache/config";
import ActionButton, { Action } from "components/buttons/action-button";
import { ExportDataContainer } from "containers";
import BillRegularizationContainer from "./regularization";

const GET_TARIFF_CLASSES = gql`
  query GetTariffClasses(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
  ) {
    getTariffClasses(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
    ) {
        _id
        code
        name
        description
        serviceClass
        currentInstance {
          _id
        }
        createdAt
        updatedAt
    }
  }
`;

const tools = [
  {
    name: "Bill Regularization",
    accessor: "regularization",
    icon: CalculatorIcon,
    iconClass: "bg-primary-600",
  },
  {
    name: "Bill Simulator",
    accessor: "simulator",
    icon: CalculatorIcon,
    iconClass: "bg-primary-600",
  },
] as const;
const Tools = tools.map((item) => item.accessor);

const TariffClassesPage: FC = () => {
  const dispatchAction = (action: (typeof Tools)[number]) => () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: action,
      }),
    });
  };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: (searchParams.search || "").toString(),
      searchFields: ["code", "name", "description"],
      sort: searchParams.sort || "",
    }),
    [searchParams]
  );

  const { data, loading, networkStatus, refetch } = useQuery(
    GET_TARIFF_CLASSES,
    {
      variables: filter,
      notifyOnNetworkStatusChange: false,
    }
  );

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
      }),
    });
  }, [navigate]);

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <Header />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <div className="mt-5 flex-1  overflow-y-auto px-4 sm:px-6">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
              {tools?.map((tool) => (
                <button
                  key={tool.accessor}
                  onClick={dispatchAction(tool.accessor)}
                  className="flex overflow-hidden rounded-lg bg-white text-left shadow px-4 py-4 space-x-3"
                >
                  {tool?.icon && (
                    <div
                      className={classNames(tool?.iconClass, "rounded-md p-3")}
                    >
                      <tool.icon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                  <div>
                    <dt>
                      <p className="truncate text-sm font-medium text-gray-500">
                        {tool?.name}
                      </p>
                    </dt>
                    <dd className="">
                      <p className="font-normal text-gray-900 line-clamp-1">
                        {tool?.name}
                      </p>
                    </dd>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      <BillSimulatorContainer
        open={modal === "simulator"}
        setOpen={(val: boolean) => setModal(val ? "simulator" : undefined)}
      />
      <BillRegularizationContainer
        open={modal === "regularization"}
        setOpen={(val: boolean) => setModal(val ? "regularization" : undefined)}
      />
    </main>
  );
};

export default TariffClassesPage;
