import * as Yup from "yup";

export const CustomerInfoFormSchema = Yup.object().shape({
  existingServicePoint: Yup.object().required(),
});

export const ReadingInfoFormSchema = Yup.object().shape({
  currentReadingValue: Yup.number().required(),
  currentReadingDate: Yup.date().required(),
});

export const ChargesInfoFormSchema = Yup.object().shape({});

export const AssignServiceOrderFormSchema = Yup.object().shape({
  customerInfo: CustomerInfoFormSchema,
  readingInfo: ReadingInfoFormSchema,
});
