import { gql, useQuery } from "@apollo/client";
import { FC } from "react";
import { SearchSelectInput } from "components/core";

interface PropertyPickerContainerProps {
  filter?: {
    region?: string;
    district?: string;
    block?: string;
    round?: string;
    plot?: string;
    itinerary?: string;
  };
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  rawId?: boolean;
}

const GET_PROPERTIES = gql`
  query GetPropertiesSelect(
    $region: ID
    $district: ID
    $block: ID
    $round: ID
    $plot: ID
    $itinerary: ID
  ) {
    properties: getProperties(
      region: $region
      district: $district
      block: $block
      round: $round
      plot: $plot
      itinerary: $itinerary
      sort: "code"
      page: 0
      pageSize: 0
    ) {
      _id
      code
      ghanaPostAddress
      address
    }
  }
`;

const PropertyPickerContainer: FC<PropertyPickerContainerProps> = ({
  filter,
  id,
  label,
  rawId,
  ...form
}) => {
  const { loading, data } = useQuery(GET_PROPERTIES, {
    variables: {
      ...(filter ? filter : {}),
    },
    notifyOnNetworkStatusChange: false,
  });

  return (
    <SearchSelectInput
      id={id ?? "round"}
      label={label ?? "Property"}
      placeholder="Select Property"
      optionsLoading={loading}
      options={(data?.properties ?? [])?.map((property: any) => ({
        label: {
          title: `${property.code} (${property.ghanaPostAddress} - ${property.address})`,
        },
        value: rawId ? property._id : property,
      }))}
      {...form}
    />
  );
};

export default PropertyPickerContainer;
