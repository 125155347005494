import { useReactiveVar } from "@apollo/client";
import {
  Avatar,
  OfficeCalendarHeader,
  OfficeHeader,
  SearchSelectInput,
  SelectInput,
  Shimmers,
  TableComponent,
} from "components";
import _ from "lodash";
import { FC, useEffect, useMemo } from "react";
import { useMatch, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  wrapClick,
  useUrlState,
  withPermissions,
  useTableData,
  classNames,
} from "utils";
import config from "config";
import ActionButton, { Action } from "components/buttons/action-button";
import { PlusIcon } from "@heroicons/react/20/solid";
import GenerateSpotBillingContainer from "./create";
import moment from "moment";
import { useSpotBills } from "./hooks";
import { currentConfigVar } from "apollo/cache/config";
import ViewSpotBillingContainer from "./view";

const DATE_FORMAT = "YYYY-MM-DD";

const searchOptions = [
  {
    label: "Account Number",
    value: "accountCode",
    min: 4,
  },
  {
    label: "Service Point Number",
    value: "servicePointCode",
    min: 5,
  },
];

const SpotBillingPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };
  const navigateItem = (item: any) => () => {
    navigate({
      to: `/itineraries`,
      search: () => ({
        plot: item?._id,
        region: item?.region?._id,
        district: item?.district?._id,
        round: item?.round?._id,
        block: item?.block?._id,
      }),
    });
  };

  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [region, setRegion] = useUrlState("region");
  const [district, setDistrict] = useUrlState("district");
  const [status, setStatus] = useUrlState("status");
  const [searchBy, setSearchParameter] = useUrlState("search_field");
  const [searchValue, setSearch] = useUrlState("search");
  const [minSearch, setMinSearch] = useUrlState("minSearch");

  const updateSearchBy = (key: string) => {
    setSearchParameter(key);
    if (key) {
      setMinSearch(searchOptions.find((option) => option.value === key)?.min);
    }
  };

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search:
        searchParams.search && searchBy
          ? (searchParams.search || "").toString()
          : undefined,
      searchFields: searchBy && searchValue ? [searchBy] : undefined,
      sort: searchParams.sort || "",
      fromDate: searchParams.fromDate || "",
      toDate: searchParams.toDate || moment().endOf("day").format(DATE_FORMAT),
      region: region || "",
      district: district || "",
      status: searchParams.status || undefined,
    }),
    [searchParams, params]
  );

  const onSearch = (searchValue: string, searchField: string) => {
    refetch({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: String(searchValue),
      searchFields: searchField
        ? [searchField]
        : ["servicePointCode", "accountCode"],
      sort: searchParams.sort || "",
      fromDate: searchParams.fromDate || moment().format(DATE_FORMAT),
      toDate: searchParams.toDate || moment().format(DATE_FORMAT),
      region: region || "",
      district: district || "",
      status: searchParams.status || undefined,
    });
  };

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
        ...(!old?.search ? { search: "" } : {}),
      }),
    });
  }, [navigate]);
  const { rows, count, loading, networkStatus, refetch } = useSpotBills(filter);
  const records = useTableData({ rows, count });

  return (
    <main className='flex-1 flex flex-col overflow-hidden h-screen bg-gray-50'>
      <OfficeCalendarHeader
        renderActions={() => (
          <>
            {withPermissions(["*:*", "readings:*", "readings:create"]) && (
              <button
                type='button'
                onClick={() => setModal("create")}
                className='ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
              >
                <PlusIcon className='-ml-0.5 mr-2 h-4 w-4' aria-hidden='true' />
                Generate Spot Billing
              </button>
            )}
          </>
        )}
      />
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={"spot bills"}
            refetch={refetch}
            data={records}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            onSearchClicked={setSearch}
            disableSearch={!searchBy}
            hasSearch={true}
            renderSearchOption={() => (
              <SelectInput
                id={"searchBy"}
                label={""}
                values={{ searchBy }}
                handleChange={(e: any) => updateSearchBy(e.target.value)}
                handleBlur={undefined}
                options={[
                  {
                    label: "Search By",
                    value: "",
                  },
                  ...searchOptions,
                ]}
              />
            )}
            renderHeaderItems={() => (
              <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex space-x-2'>
                <SearchSelectInput
                  id='status'
                  labelHidden={true}
                  options={[
                    { label: { title: "Failed" }, value: "Failed" },
                    { label: { title: "Success" }, value: "Success" },
                  ]}
                  label={""}
                  placeholder='Select Status'
                  setFieldValue={(_: any, value: string) => setStatus(value)}
                  values={{ status }}
                />
              </div>
            )}
            // renderFilter={({ filterOpen, setFilterOpen }) => (
            //   <ReadingsFilterContainer
            //     open={filterOpen}
            //     setOpen={setFilterOpen}
            //   />
            // )}
            // renderExport={({ exportOpen, setExportOpen }) => (
            //   <ExportDataContainer
            //     open={exportOpen}
            //     setOpen={setExportOpen}
            //     entityType="Reading"
            //     filter={filter}
            //   />
            // )}

            renderColumns={() => (
              <tr>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Date
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Customer
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  SPN | Account No
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Service Class | Meter No
                </th>
                {!region && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Region
                  </th>
                )}
                {!district && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    District
                  </th>
                )}
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Status
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <>
                <tr>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.AvatarShimmer />
                  </td>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                  {!region && (
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.SingleShimmer />
                    </td>
                  )}
                  {!district && (
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.SingleShimmer />
                    </td>
                  )}
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.SingleShimmer />
                  </td>

                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.ActionsShimmer actionsCount={2} />
                  </td>
                </tr>
              </>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  {/* <div className='text-gray-900 dark:text-gray-100'>
                    {item?.code || "N/A"}
                  </div> */}
                  <div className='text-gray-900 dark:text-gray-400'>
                    {moment(item?.createdAt).format(dateFormat)}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='flex items-center'>
                    <div className='h-10 w-10 flex-shrink-0'>
                      <Avatar
                        disabled={true}
                        alt={
                          item?.servicePoint?.customerRepresentative?.fullName?.trim() ||
                          item?.servicePoint?.customerOrganization?.name?.trim() ||
                          "N A"
                        }
                        src={
                          item?.servicePoint?.customerRepresentative
                            ?.profileImageUrl ||
                          item?.servicePoint?.customerRepresentative
                            ?.profileImageUrl ||
                          ""
                        }
                      />
                    </div>
                    <div className='ml-4'>
                      <div className='text-gray-900 dark:text-gray-100'>
                        {item?.servicePoint?.customerRepresentative?.fullName?.trim() ||
                          item?.servicePoint?.customerOrganization?.name?.trim() ||
                          "N A"}
                      </div>
                      <div className='text-gray-500 dark:text-gray-400'>
                        {item?.customerCode || "N/A"}
                      </div>
                    </div>
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.servicePointCode || "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.accountCode || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-500 dark:text-gray-400'>
                    <div className='text-gray-500 dark:text-gray-400'>
                      {_.startCase(item?.tariffClass?.serviceClass) || "N/A"}
                    </div>
                  </div>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.meterCode || "N/A"}
                  </div>
                </td>
                {!region && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                    <div className='font-medium text-gray-900 dark:text-gray-100'>
                      {item?.region?.name || "N/A"}
                    </div>
                    <div className='text-gray-500 dark:text-gray-400'>
                      {item?.region?.code || "N/A"}
                    </div>
                  </td>
                )}
                {!district && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                    <div className='font-medium text-gray-900 dark:text-gray-100'>
                      {item?.district?.name || "N/A"}
                    </div>
                    <div className='text-gray-500 dark:text-gray-400'>
                      {item?.district?.code || "N/A"}
                    </div>
                  </td>
                )}
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <span
                    className={classNames(
                      "bg-gray-100 text-gray-800",
                      item?.status === "Failed"
                        ? `bg-red-100 text-red-800`
                        : "",
                      item?.status === "Success"
                        ? `bg-green-100 text-green-800`
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 10 10'
                      fill='currentColor'
                      className='w-1.5 h-1.5'
                    >
                      <circle
                        fillRule='evenodd'
                        cx='5'
                        cy='5'
                        r='5'
                        clipRule='evenodd'
                      />
                    </svg>
                    <span>{item?.status || "Unknown"}</span>
                  </span>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>
      <GenerateSpotBillingContainer
        open={modal === "create"}
        setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
        refetch={() => {}}
      />
      {!!searchParams.id?.length && (
        <>
          <ViewSpotBillingContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default SpotBillingPage;
