import { QueryResult, gql, useQuery } from "@apollo/client";

const GET_CUSTOMER_READINGS = gql`
  query GetCustomerReadings(
    $region: ID
    $district: ID
    $status: CustomerReadingStatus
    $statuses: [CustomerReadingStatus]
    $origin: CustomerReadingOrigin
    $origins: [CustomerReadingOrigin]
    $page: Int
    $pageSize: Int
    $searchFields: [String!]
    $search: String
    $sort: String
    $fromDate: Date
    $toDate: Date
  ) {
    count: getCustomerReadingsCount(
      region: $region
      district: $district
      status: $status
      statuses: $statuses
      origin: $origin
      origins: $origins
      searchFields: $searchFields
      search: $search
      fromDate: $fromDate
      toDate: $toDate
    )
    rows: getCustomerReadings(
      region: $region
      district: $district
      status: $status
      statuses: $statuses
      origin: $origin
      origins: $origins
      page: $page
      pageSize: $pageSize
      searchFields: $searchFields
      search: $search
      sort: $sort
      fromDate: $fromDate
      toDate: $toDate
    ) {
      _id
      code
      servicePoint {
        _id
        code
        customerRepresentative {
          fullName
          profileImageUrl
        }
        customerOrganization {
          name
        }
        customerCode
        accountCode
        geoCode
        status
      }
      status
      origin
      createdAt
      updatedAt
      currentReading {
        consumptionPeriod
        consumptionValue
        readingDate
        readingValue
      }
      meter {
        _id
        code
      }
    }
  }
`;


export const useSubmittedReadings = (
  variables?: any
): Omit<QueryResult<any, any>, "data"> & {
  submittedReadings: any[];
  count: number;
} => {
  const { data, ...rest } = useQuery(GET_CUSTOMER_READINGS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

  return {
    submittedReadings: data?.rows || [],
    count: data?.count || 0,
    ...rest,
  };
};
