import { SelectInput, TextInput } from "components/core";
import { useFormik } from "formik";
import moment from "moment";
import { FC } from "react";
import { classNames, wrapClick } from "utils";
import { CustomerInfoFormSchema } from "./schema";
import { gql, useLazyQuery, useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";

const SEARCH_SERVICE_POINT = gql`
  query SearchServicePoints(
    $search: String!
    $searchField: String!
    $limit: PositiveInt
  ) {
    servicePoints: searchServicePoints(
      search: $search
      searchField: $searchField
      limit: $limit
    ) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      property {
        _id
        code
        qrCode
        address
        ghanaPostAddress
        streetName
        houseNumber
        community
        owner {
          fullName
          phoneNumber
        }
        region {
          _id
          code
          name
        }
        district {
          _id
          code
          name
        }
      }
      propertyCode
      customer {
        _id
        code
        customerType
        organization {
          name
        }
        representative {
          title
          fullName
          nationality
          dateOfBirth
          gender
          phoneNumber
          emailAddress
        }
      }
      customerCode
      account {
        _id
        code
        status
      }
      accountCode
      meter {
        _id
        code
        model {
          _id
          code
          name
          brandCode
          brandName
          type
          phase
          digits
        }
        status
      }
      meterCode
      serviceType
      serviceClass
      tariffClass {
        _id
        code
        name
        description
        serviceClass
      }
      geoCode
      deliveryPointNumber
      contractedDemand
      status
      meta {
        lastCmsActualReadingValue
        lastCmsActualReadingDate
      }
      createdAt
      updatedAt
    }
  }
`;

interface CustomerInfoFormProps {
  handleNext: (values: any) => void;
  handlePrevious: () => void;
  initialValues: any;
  values: any;
  handleCancel: () => void;
}

function Owner({
  owner,
  onClick,
  isActive,
}: {
  owner: any;
  onClick: any;
  isActive: boolean;
}) {
  return (
    <button
      type='button'
      onClick={onClick}
      className={classNames(
        isActive
          ? "border-primary-500 dark:border-primary-600 bg-white dark:bg-primary-800 hover:border-primary-700 dark:hover:border-primary-500"
          : "border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 hover:border-gray-400 dark:hover:border-gray-500",
        "focus:outline-none relative rounded-lg border px-3 py-3 shadow-sm flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
      )}
    >
      <div className='flex-1 min-w-0 text-left'>
        <p className='text-sm font-medium text-gray-900 dark:text-white truncate'>
          {owner?.customer?.representative?.fullName ||
            owner?.customer?.orgarnization?.name ||
            "N A"}
        </p>
        <div className='flex justify-between text-sm text-gray-700'>
          <span className='text-gray-500'>Account Number</span>
          <span>{owner?.accountCode || "N/A"}</span>
        </div>
        <div className='flex justify-between text-sm text-gray-700'>
          <span className='text-gray-500'>Geo Code</span>
          <span>{owner?.geoCode || "N/A"}</span>
        </div>
        <div className='flex justify-between text-sm text-gray-700'>
          <span className='text-gray-500'>Meter Number</span>
          <span>{owner?.meterCode || "N/A"}</span>
        </div>
        <div className='flex justify-between text-sm text-gray-700'>
          <span className='text-gray-500'>Service Point</span>
          <span>{owner?.code || "N/A"}</span>
        </div>
        <div className='flex justify-between text-sm text-gray-700'>
          <span className='text-gray-500'>Ghana Post Address</span>
          <span>{owner?.property?.ghanaPostAddress || "N/A"}</span>
        </div>
      </div>
    </button>
  );
}

const CustomerInfoForm: FC<CustomerInfoFormProps> = ({
  initialValues,
  handleNext,
  handleCancel,
  handlePrevious,
}) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);
  const form = useFormik({
    initialValues,
    validationSchema: CustomerInfoFormSchema,
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  const [searchServicePoints, { loading, data }] =
    useLazyQuery(SEARCH_SERVICE_POINT);

  const servicePointSerachForm = useFormik({
    initialValues: {
      customerIdentifier: "customerCode",
      customerCode: "",
      accountCode: "",
      geoCode: "",
      meterCode: "",
      code: "",
    },
    onSubmit: (values) => {
      searchServicePoints({
        variables: {
          search: (values as any)[values.customerIdentifier],
          searchField: values.customerIdentifier,
          limit: 3,
        },
      });
    },
  });

  const selectServicePoint = (servicePoint: any) => async () => {
    if (servicePoint) {
      form.setFieldValue("existingServicePoint", servicePoint);
    }
  };

  return (
    <div className='flex-1 flex flex-col overflow-hidden'>
      <div className='space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto'>
        <div className='mt-6'>
          <span className='text-xs font-light'>Search for Account</span>
          <form
            onSubmit={servicePointSerachForm.handleSubmit}
            className='grid grid-cols-7 gap-6 mt-2'
          >
            <div className='col-span-3 sm:col-span-2'>
              <SelectInput
                id='customerIdentifier'
                label='Find Customer By'
                options={[
                  { label: "Account Number", value: "accountCode" },
                  { label: "Customer Number", value: "customerCode" },
                  { label: "Geo Code", value: "geoCode" },
                  { label: "Service Point Number", value: "code" },
                  { label: "Meter Number", value: "meterCode" },
                ]}
                placeholder='e.g. Mensah'
                {...servicePointSerachForm}
              />
            </div>

            {servicePointSerachForm.values?.customerIdentifier ===
              "accountCode" && (
              <div className='col-span-6 sm:col-span-3'>
                <TextInput
                  id='accountCode'
                  label='Account Number'
                  type='text'
                  placeholder='e.g. GD34934839893'
                  {...servicePointSerachForm}
                />
              </div>
            )}

            {servicePointSerachForm.values?.customerIdentifier ===
              "customerCode" && (
              <div className='col-span-6 sm:col-span-3'>
                <TextInput
                  id='customerCode'
                  label='Customer Code'
                  type='text'
                  placeholder='e.g. GD34934839893'
                  {...servicePointSerachForm}
                />
              </div>
            )}

            {servicePointSerachForm.values?.customerIdentifier ===
              "geoCode" && (
              <div className='col-span-6 sm:col-span-3'>
                <TextInput
                  id='geoCode'
                  label='Geo Code'
                  type='text'
                  placeholder='e.g. 01-04-001-0001-0001'
                  {...servicePointSerachForm}
                />
              </div>
            )}

            {servicePointSerachForm.values?.customerIdentifier === "code" && (
              <div className='col-span-6 sm:col-span-3'>
                <TextInput
                  id='code'
                  label='Service Point Number'
                  type='text'
                  placeholder='e.g. 2007877698'
                  {...servicePointSerachForm}
                />
              </div>
            )}

            {servicePointSerachForm.values?.customerIdentifier ===
              "meterCode" && (
              <div className='col-span-6 sm:col-span-3'>
                <TextInput
                  id='meterCode'
                  label='Meter Number'
                  type='text'
                  placeholder='e.g. MR12345678'
                  {...servicePointSerachForm}
                />
              </div>
            )}
            <div className='col-span-6 sm:col-span-2 items-end justify-end flex'>
              <button
                type='submit'
                className='w-full inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
              >
                {loading ? "Searching Customer..." : "Search Customer"}
              </button>
            </div>
          </form>
          <div className='grid grid-cols-3 gap-6 mt-6 pt-6 border-t border-gray-200'>
            {data?.servicePoints?.map?.((servicePoint: any) => (
              <Owner
                key={servicePoint._id}
                isActive={
                  servicePoint._id === form.values?.existingServicePoint?._id
                }
                onClick={selectServicePoint(servicePoint)}
                owner={servicePoint}
              />
            ))}
          </div>
          {form?.values?.existingServicePoint && (
            <div className='mt-6 border border-gray-300 border-dashed bg-gray-100 divide-y divide-dashed divide-gray-300 rounded-md'>
              <div className='grid grid-cols-4 gap-4 p-4'>
                <div className=''>
                  <span className='block text-sm font-light text-gray-700'>
                    Type
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.customer
                      ?.customerType || "N/A"}
                  </div>
                </div>
                <div className=''>
                  <span className='block text-sm font-light text-gray-700'>
                    Title
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.customer
                      ?.representative?.title ||
                      form?.values?.existingServicePoint?.customer
                        ?.representative?.title ||
                      "N/A"}
                  </div>
                </div>
                <div className='col-span-2'>
                  <span className='block text-sm font-light text-gray-700'>
                    Full Name
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.customer
                      ?.representative?.fullName ||
                      form?.values?.existingServicePoint?.customer
                        ?.representative?.fullName ||
                      "N/A"}
                  </div>
                </div>
                <div className=''>
                  <span className='block text-sm font-light text-gray-700'>
                    Nationality
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.customer
                      ?.representative?.nationality ||
                      form?.values?.existingServicePoint?.customer
                        ?.representative?.nationality ||
                      "N/A"}
                  </div>
                </div>
                <div className=''>
                  <span className='block text-sm font-light text-gray-700'>
                    Date of Birth
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {moment(
                      form?.values?.existingServicePoint?.customer
                        ?.representative?.dateOfBirth ||
                        form?.values?.existingServicePoint?.customer
                          ?.representative?.dateOfBirth
                    ).format(dateFormat)}
                  </div>
                </div>
                <div className=''>
                  <span className='block text-sm font-light text-gray-700'>
                    Gender
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.customer
                      ?.representative?.gender ||
                      form?.values?.existingServicePoint?.customer
                        ?.representative?.gender ||
                      "N/A"}
                  </div>
                </div>
              </div>
              <div className='grid grid-cols-4 gap-4 p-4'>
                <div className=''>
                  <span className='block text-sm font-light text-gray-700'>
                    Account Number
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.account?.code || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Customer Number
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.customer?.code ||
                      "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Property Number
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.property?.code ||
                      "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Service Point Number
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.code || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Geographical Code
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.geoCode || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Meter Number
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.meter?.code || "N/A"}
                  </div>
                </div>
                <div className='col-span-2'>
                  <span className='block text-sm font-light text-gray-700'>
                    Property Address
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {form?.values?.existingServicePoint?.meter?.code || "N/A"}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200'>
        <button
          type='button'
          onClick={wrapClick(form.handleSubmit)}
          className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
        >
          Next
        </button>
        <button
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CustomerInfoForm;
