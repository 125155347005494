import { gql, useMutation, useReactiveVar } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { SpotBillingForm, Modal } from "components";
import moment from "moment";
import { currentConfigVar } from "apollo/cache/config";

const SUBMIT_SPOT_BILL = gql`
  mutation SubmitSpotBill(
    $servicePoint: ID!
    $readingValue: NonNegativeInt
    $readingImageUrl: String
    $readingDate: DateTime
  ) {
    submitSpotBill(
      servicePoint: $servicePoint
      readingValue: $readingValue
      readingImageUrl: $readingImageUrl
      readingDate: $readingDate
    ) {
      _id
    }
  }
`;

export default function GenerateSpotBillingContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [submitSpotBill] = useMutation(SUBMIT_SPOT_BILL);
  const { dateFormat } = useReactiveVar(currentConfigVar);


  const form = useFormik<any>({
    initialValues: {
      customerInfo: {
        existingServicePoint: "",
        servicePoint: "",
      },
      readingInfo: {
        readingValue: "",
        readingImageUrl: "",
        readingDate: "",
        readingHistory: null,
      },
    },
    onSubmit: async (values) => {
      await submitSpotBill({
        variables: {
          ...values.readingInfo,
          servicePoint: values.customerInfo.existingServicePoint?._id,
          readingDate: values?.readingInfo?.readingDate,
          readingValue: values.readingInfo.readingValue,
          readingImageUrl: values.readingInfo.readingImageUrl,
        },
      }).then(({ data }) => {
        if (data.createCustomerReading._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Spot Bill Generated Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Failed to generate spot bill",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      hideActions={true}
      size={"4xl"}
      title='Generate Spot Billing'
      description='Provide the details to generate spot billing for a customer'
    >
      <SpotBillingForm form={form} />
    </Modal>
  );
}
