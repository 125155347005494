import { FC } from "react";
import { Avatar } from "components/core";
import { classNames, wrapImage } from "utils";
import moment from "moment";
import parse from 'html-react-parser';


interface ComplaintViewProps {
    complaint: any;
    tab: string
}

const ComplaintView: FC<ComplaintViewProps> = ({ complaint, tab }) => {
    return (
        <div className='space-y-6 divide-y divide-gray-200 p-4 h-[50vh] overflow-y-scroll'>
            {/* {JSON.stringify(complaint, null, 2)} */}
            {tab === "CustomerDetails" && (
                <div>
                    <span className='text-xs font-light'>Customer Information</span>
                    <div className='grid grid-cols-3 gap-6 mt-2'>
                        <div className='row-span-3 flex flex-col space-y-3 items-center justify-center'>
                            <Avatar
                                alt={
                                    complaint?.customer?.representative?.fullName ||
                                    complaint?.customer?.organization?.name ||
                                    "N A"
                                }
                                src={complaint?.customer?.representative?.profileImageUrl || ""}
                                size='xl'
                            />

                            <span
                                className={classNames(
                                    "bg-gray-100 text-gray-800",
                                    complaint?.customer?.status === "SuperAdmin"
                                        ? `bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50`
                                        : "",
                                    complaint?.customer?.status === "Admin"
                                        ? `bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50`
                                        : "",
                                    complaint?.customer?.status === "Supervisor"
                                        ? `bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50`
                                        : "",
                                    complaint?.customer?.status === "Reader"
                                        ? `bg-gray-100 text-[#9ACD32] dark:bg-[#9ACD32] dark:text-gray-50`
                                        : "",
                                    complaint?.customer?.status === "Queued"
                                        ? `bg-gray-100 text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50`
                                        : "",
                                    complaint?.customer?.status === "Success"
                                        ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                                        : "",
                                    complaint?.customer?.status === "Reversed"
                                        ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                                        : "",
                                    !complaint?.customer?.status
                                        ? "bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-100"
                                        : "",
                                    "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                                )}
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 10 10'
                                    fill='currentColor'
                                    className='w-1.5 h-1.5'
                                >
                                    <circle
                                        fillRule='evenodd'
                                        cx='5'
                                        cy='5'
                                        r='5'
                                        clipRule='evenodd'
                                    />
                                </svg>
                                <span>{complaint?.customer?.status || "Active Contract"}</span>
                            </span>
                        </div>
                        <div>
                            <span className='block text-sm font-light text-gray-700'>
                                Customer Code
                            </span>
                            <div className='mt-1 block w-full sm:text-sm'>
                                {complaint?.customer?.code || "N/A"}
                            </div>
                        </div>
                        <div>
                            <span className='block text-sm font-light text-gray-700'>
                                Ghana Card Number
                            </span>
                            <div className='mt-1 block w-full sm:text-sm'>
                                {complaint?.customer?.complaintType === "Individual"
                                    ? complaint?.customer?.representative?.ghanaCardNumber || "N/A"
                                    : complaint?.customer?.organization
                                        ?.organizationRegistrationNumber || "N/A"}
                            </div>
                        </div>
                        <div className='col-span-2'>
                            <span className='block text-sm font-light text-gray-700'>
                                Full Name
                            </span>
                            <div className='mt-1 block w-full sm:text-sm'>
                                {complaint?.contactPerson?.fullName || "N/A"}
                            </div>
                        </div>
                        <div>
                            <span className='block text-sm font-light text-gray-700'>
                                Phone Number
                            </span>
                            <div className='mt-1 block w-full sm:text-sm'>
                                {complaint?.contactPerson?.phoneNumber || "N/A"}
                            </div>
                        </div>
                        <div>
                            <span className='block text-sm font-light text-gray-700'>
                                Email Address
                            </span>
                            <div className='mt-1 block w-full sm:text-sm'>
                                {complaint?.contactPerson?.emailAddress || "N/A"}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {tab === "OrderHistory" && (
                <>
                    <div className=''>
                        <span className='text-xs font-light'>Complaint Details</span>
                        <div className='grid grid-cols-3 gap-6 mt-2'>
                            <div>
                                <span className='block text-sm font-light text-gray-700'>Code</span>
                                <div className='mt-1 block w-full sm:text-sm'>
                                    {complaint?.code || "N/A"}
                                </div>
                            </div>
                            <div>
                                <span className='block text-sm font-light text-gray-700'>Type</span>
                                <div className='mt-1 block w-full sm:text-sm'>
                                    {complaint?.type?.name || "N/A"}
                                </div>
                            </div>
                            <div>
                                <span className='block text-sm font-light text-gray-700'>
                                    Status
                                </span>
                                <div className='mt-1 block w-full sm:text-sm'>
                                    {complaint?.status || "N/A"}
                                </div>
                            </div>
                            <div className='col-span-3'>
                                <span className='block text-sm font-light text-gray-700'>
                                    Description
                                </span>
                                <div className='mt-1 block w-full sm:text-sm prose'>
                                    {parse(complaint?.description || "N/A")}
                                </div>
                            </div>
                            {complaint?.supportingDocumentUrls?.length > 0 && (
                                <div className='col-span-3'>
                                    <span className='block text-sm font-light text-gray-700'>
                                        Supporting Document
                                    </span>
                                    {complaint?.supportingDocumentUrls?.map((el: string) => (
                                        <div className='mt-2'>
                                            {wrapImage(
                                                <img
                                                    src={
                                                        el
                                                    }
                                                    alt={"front"}
                                                    className='w-full h-64 text-xs'
                                                />,
                                                !el
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='pt-6'>
                        <span className='text-xs font-light'>Contact Person Information</span>
                        <div className='grid grid-cols-2 gap-6 mt-2'>
                            <div className='col-span-2'>
                                <span className='block text-sm font-light text-gray-700'>
                                    Full Name
                                </span>
                                <div className='mt-1 block w-full sm:text-sm'>
                                    {complaint?.contactPerson?.fullName || "N/A"}
                                </div>
                            </div>
                            <div>
                                <span className='block text-sm font-light text-gray-700'>
                                    Phone Number
                                </span>
                                <div className='mt-1 block w-full sm:text-sm'>
                                    {complaint?.contactPerson?.phoneNumber || "N/A"}
                                </div>
                            </div>
                            <div>
                                <span className='block text-sm font-light text-gray-700'>
                                    Email Address
                                </span>
                                <div className='mt-1 block w-full sm:text-sm'>
                                    {complaint?.contactPerson?.emailAddress || "N/A"}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {tab === "EscalationDetails" && (
                <div className=''>
                    <span className='text-xs font-light'>Escalation Details</span>
                    <div className='grid grid-cols-2 gap-6 mt-2'>
                        <div className='col-span-2'>
                            <span className='block text-sm font-light text-gray-700'>
                                Escalated At
                            </span>
                            <div className='mt-1 block w-full sm:text-sm'>
                                {moment(complaint?.escalatedAt).format("LL LT") || "N/A"}
                            </div>
                        </div>
                        <div className='col-span-3'>
                            <span className='block text-sm font-light text-gray-700'>
                                escalatedReason
                            </span>
                            <div className='mt-1 block w-full sm:text-sm prose'>
                                {parse(complaint?.escalatedReason || "N/A")}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {tab === "ExtraDetails" && (
                <div className=''>
                    <span className='text-xs font-light'>Created By</span>
                    <div className='grid grid-cols-2 gap-6 mt-2'>
                        <div className='col-span-2'>
                            <span className='block text-sm font-light text-gray-700'>
                                Full Name
                            </span>
                            <div className='mt-1 block w-full sm:text-sm'>
                                {[
                                    complaint?.createdBy?.firstName,
                                    complaint?.createdBy?.lastName,
                                ].join(" ") || "N/A"}
                            </div>
                        </div>
                        <div>
                            <span className='block text-sm font-light text-gray-700'>
                                Phone Number
                            </span>
                            <div className='mt-1 block w-full sm:text-sm'>
                                {complaint?.createdBy?.phoneNumber || "N/A"}
                            </div>
                        </div>
                        <div>
                            <span className='block text-sm font-light text-gray-700'>
                                Email Address
                            </span>
                            <div className='mt-1 block w-full sm:text-sm'>
                                {complaint?.createdBy?.emailAddress || "N/A"}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {tab === "ResolutionDetails" && (
                <div className=''>
                    <span className='text-xs font-light'>Resolution</span>
                    <div className='grid grid-cols-3 gap-6 mt-2'>
                        <div>
                            <span className='block text-sm font-light text-gray-700'>
                                Started At
                            </span>
                            <div className='mt-1 block w-full sm:text-sm'>
                                {moment(complaint?.startedAt).isValid() ? moment(complaint?.startedAt).format("LL LT") : "Resolution not started"}
                            </div>
                        </div>
                        <div>
                            <span className='block text-sm font-light text-gray-700'>
                                Resolved At
                            </span>
                            <div className='mt-1 block w-full sm:text-sm'>
                                {moment(complaint?.closedAt).isValid() ? moment(complaint?.closedAt).format("LL LT") : "Resolution not closed"}
                            </div>
                        </div>
                        <div>
                            <span className='block text-sm font-light text-gray-700'>
                                Investigation Service order
                            </span>
                            <div className='mt-1 block w-full sm:text-sm'>
                                {complaint?.resolution?.investigationServiceOrderCode || "N/A"}
                            </div>
                        </div>
                        <div className='col-span-3'>
                            <span className='block text-sm font-light text-gray-700'>
                                Resolution Notes
                            </span>
                            <div className='mt-1 block w-full sm:text-sm'>
                                {complaint?.resolution?.notes || "N/A"}
                            </div>
                        </div>
                        <div className='col-span-3'>
                            <span className='block text-sm font-light text-gray-700'>
                                Supporting Document
                            </span>
                            {complaint?.resolution?.supportingDocumentUrls.length > 0 ? (
                                <div className='mt-1 grid grid-cols-3 gap-3 w-full sm:text-sm'>
                                    {complaint?.resolution?.supportingDocumentUrls?.map((el: string) => (
                                        <>
                                            {wrapImage(
                                                <img src={el} alt="front" className="w-full h-64 text-xs" />,
                                            )}
                                        </>
                                    ))}
                                </div>
                            ) : (
                                <div className='border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600'>
                                    Dcoument not provided
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )
            }
        </div >
    );
};

export default ComplaintView;
