import { useEffect } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_CUSTOMER } from "./view";
import { withPermissions, wrapClick } from "utils";
import * as Yup from "yup";
import SuspendCustomerForm from "./forms/suspend-customer";

const UPDATE_CUSTOMER = gql`
mutation SuspendServicePoint(
  $suspendServicePointId: ID!
  $type: SuspendServicePointType
  $reason: String
) {
  suspendServicePoint(
    id: $suspendServicePointId
    type: $type
    reason: $reason
  ) {
    _id
  }
}
`;

export default function SuspendContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_CUSTOMER, {
    variables: {
      getServicePointId: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);

  const form = useFormik<any>({
    initialValues: {
      type: "",
      reason: "",
    },
    validationSchema: Yup.object().shape({
      type: Yup.string().required("Type is Required"),
      reason: Yup.string().required("Reason is Required"),
    }),
    onSubmit: async (values) => {
      await updateCustomer({
        variables: {
          suspendServicePointId: data?.customer?._id,
          type: values.type,
          reason: values.reason,
        },
      }).then(({ data }) => {
        if (data.suspendServicePoint._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Customer Suspension Initiated Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not suspend Customer",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (data?.customer) {
      // alert("Hello" + data?.customer?.customer?.fullName)
      form.setValues({
        fullName: data?.customer?.customer?.representative?.fullName,
        primaryPhoneNumber: data?.customer?.customer?.representative?.phoneNumber || "",
        primaryEmailAddress: data?.customer?.customer?.representative?.emailAddress || "",
        reason: "",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.customer, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      // hideActions={true}
      loading={loading}
      size='4xl'
      title='Suspend Customer'
      description='Provide the details to update customer contact information'
      renderActions={() => (
        <>
          {withPermissions(["*:*", "service-points:*", "service-points:update"])(
            <button
              type='button'
              disabled={form.isSubmitting || !form.isValid}
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={wrapClick(form.handleSubmit)}
            >
              {form.isSubmitting ? "Saving Changes ..." : "Save Changes"}
            </button>
          )}
        </>
      )}
    >
      {data?.customer?._id && (
        <SuspendCustomerForm form={form} customer={data?.customer} />
      )}
    </Modal>
  );
}
