import { gql, useQuery, useReactiveVar } from "@apollo/client";
import {
  Header,
  OfficeCalendarHeader,
  Shimmers,
  TableComponent,
} from "components";
import { FC, useEffect, useMemo } from "react";
import { useMatch, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  wrapClick,
  useUrlState,
  classNames,
  useTableData,
  withPermissions,
  wrapOnchange,
} from "utils";
import config from "config";
import { Avatar, SelectInput, TextInput } from "components/core";
import ActionButton, { Action } from "components/buttons/action-button";
import ViewServicePointContainer from "pages/service-points/view";
import numeral from "numeral";
import moment from "moment";
import { currentConfigVar } from "apollo/cache/config";
import InitiateDisconnectionContainer from "./initiate";
import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import ViewDebtCustomerContainer from "./view";

const GET_SERVICE_POINTS = gql`
  query GetServicePoints(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $block: ID
    $round: ID
    $plot: ID
    $minimumDebtAmount: Float
    $maximumDebtAmount: Float
    $minimumDebtAge: Int
    $maximumDebtAge: Int
  ) {
    rows: getServicePoints(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      block: $block
      round: $round
      plot: $plot
      minimumDebtAmount: $minimumDebtAmount
      maximumDebtAmount: $maximumDebtAmount
      minimumDebtAge: $minimumDebtAge
      maximumDebtAge: $maximumDebtAge
    ) {
      _id
      code
      geoCode
      customer {
        _id
        code

        organization {
          name
        }
        representative {
          fullName
          phoneNumber
          ghanaCardNumber
        }
      }
      meterCode
      accountCode
      meterLocation
      contractedDemand
      serviceType
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      block {
        _id
        code
        name
      }
      round {
        _id
        code
        name
      }
      plot {
        _id
        code
        name
      }
      itinerary {
        _id
        code
        description
      }
      qrCode
      ghanaPostAddress
      address
      propertyCode
      qrCode
      accountData {
        lastPaymentDate
        debtAmount
        toBeDisconnected
      }
      meta {
        lastReadingDate
        lastReadingValue
        lastBillDate
        lastBillValue
      }
      status
      createdAt
      updatedAt
    }
    count: getServicePointsCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      block: $block
      round: $round
      plot: $plot
      minimumDebtAmount: $minimumDebtAmount
      maximumDebtAmount: $maximumDebtAmount
      minimumDebtAge: $minimumDebtAge
      maximumDebtAge: $maximumDebtAge
    )
  }
`;

const searchOptions = [
  {
    label: "Service Point Number",
    value: "code",
    min: 5,
  },
  {
    label: "Customer Number",
    value: "customerCode",
    min: 4,
  },
  {
    label: "Account Number",
    value: "accountCode",
    min: 5,
  },
  {
    label: "Geo Code",
    value: "geoCode",
    min: 17,
  },
  {
    label: "Meter Number",
    value: "meterCode",
    min: 5,
  },
];
const DebtCustomersPage: FC = () => {
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();

  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [minDebtAmount, setMinAmount] = useUrlState("minDebtAmount");
  const [maxDebtAmount, setMaxAmount] = useUrlState("maxDebtAmount");
  const [minAge, setMinAge] = useUrlState("minAge");
  const [maxAge, setMaxAge] = useUrlState("maxAge");
  const [searchBy, setSearchParameter] = useUrlState("search_field");
  const [searchValue, setSearch] = useUrlState("search");
  const [minSearch, setMinSearch] = useUrlState("minSearch");
  const { dateFormat } = useReactiveVar(currentConfigVar);

  const updateSearchBy = (key: string) => {
    setSearchParameter(key);
    if (key) {
      setMinSearch(searchOptions.find((option) => option.value === key)?.min);
    }
  };

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search:
        searchParams.search && searchBy
          ? (searchParams.search || "").toString()
          : undefined,
      searchFields: searchBy && searchValue ? [searchBy] : undefined,
      sort: searchParams.sort || "",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      block: searchParams.block || undefined,
      round: searchParams.round || undefined,
      plot: params.plot || undefined,
      itinerary: searchParams.itinerary || undefined,
      property: searchParams.property || undefined,
      minimumDebtAmount: minDebtAmount || 0,
      maximumDebtAmount:
        maxDebtAmount > minDebtAmount ? maxDebtAmount : undefined,
      minimumDebtAge: minAge || undefined,
      maximumDebtAge: minAge > maxAge ? maxAge : undefined,
    }),
    [searchParams, params]
  );

  const { data, loading, networkStatus, refetch } = useQuery(
    GET_SERVICE_POINTS,
    {
      variables: filter,
      notifyOnNetworkStatusChange: false,
    }
  );

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.sort ? { sort: "" } : {}),
      }),
    });
  }, [navigate]);

  const records = useTableData(data || {});

  return (
    <main className='flex-1 flex flex-col overflow-hidden h-screen bg-gray-50'>
      <Header
        renderActions={() => (
          <>
            {withPermissions(["*:*", "disconnection-service-orders:*"]) && (
              <button
                type='button'
                onClick={() => setModal("initiate")}
                className='ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
              >
                <DocumentPlusIcon
                  className='-ml-0.5 mr-2 h-4 w-4'
                  aria-hidden='true'
                />
                Prioritize Disconnections
              </button>
            )}
          </>
        )}
      />
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={"customers "}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            onSearchClicked={setSearch}
            disableSearch={!searchBy}
            hasSearch={true}
            renderSearchOption={() => (
              <SelectInput
                id={"searchBy"}
                label={""}
                values={{ searchBy }}
                handleChange={(e: any) => updateSearchBy(e.target.value)}
                handleBlur={undefined}
                options={[
                  {
                    label: "Search By",
                    value: "",
                  },
                  ...searchOptions,
                ]}
              />
            )}
            renderHeaderItems={() => (
              <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex space-x-2'>
                <TextInput
                  id={"minDebtAmount"}
                  labelHidden={true}
                  values={{ minDebtAmount }}
                  type='number'
                  step={0.01}
                  placeholder='Minimum Debt Amount'
                  postText='GHS'
                  handleChange={wrapOnchange(setMinAmount)}
                  handleBlur={undefined}
                />
                <TextInput
                  id={"maxDebtAmount"}
                  labelHidden={true}
                  values={{ maxDebtAmount }}
                  type='number'
                  step={0.01}
                  min={minDebtAmount}
                  placeholder='Maximum Debt Amount'
                  postText='GHS'
                  handleChange={wrapOnchange(setMaxAmount)}
                  handleBlur={undefined}
                />
                <TextInput
                  id={"minAge"}
                  labelHidden={true}
                  values={{ minAge }}
                  type='number'
                  step={0.01}
                  placeholder='Minimum Age'
                  postText='Days'
                  handleChange={wrapOnchange(setMinAge)}
                  handleBlur={undefined}
                />
                <TextInput
                  id={"maxAge"}
                  labelHidden={true}
                  values={{ maxAge }}
                  type='number'
                  step={0.01}
                  min={minAge}
                  placeholder='Maximum Age'
                  postText='Days'
                  handleChange={wrapOnchange(setMaxAge)}
                  handleBlur={undefined}
                />
              </div>
            )}
            // renderExport={({ exportOpen, setExportOpen }) => (
            //   <ExportDataContainer
            //     open={exportOpen}
            //     setOpen={setExportOpen}
            //     entityType="ServicePoint"
            //     filter={filter}
            //   />
            // )}
            renderColumns={() => (
              <tr>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  SPN | Geo Code
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Account Code | Meter No
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Customer
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Contact Info
                </th>
                {/* <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Status
                </th> */}
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Debt Amount
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Last Payment Date
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-center text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Disconnection Prioritized
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.AvatarShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>
                {/* <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td> */}
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.ActionsShimmer actionsCount={1} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.code || "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.geoCode || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.accountCode || "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.meterCode || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='flex items-center'>
                    <div className='h-10 w-10 flex-shrink-0'>
                      <Avatar
                        disabled={true}
                        alt={
                          item?.customer?.representative?.fullName?.trim() ||
                          item?.customer?.organization?.name?.trim() ||
                          item?.customer?.representative?.fullName?.trim() ||
                          "N A"
                        }
                        src={
                          item?.customer?.representative?.profileImageUrl ||
                          item?.customer?.representative?.profileImageUrl ||
                          ""
                        }
                      />
                    </div>
                    <div className='ml-4'>
                      <div className='text-gray-900 dark:text-gray-100'>
                        {item?.customer?.representative?.fullName?.trim() ||
                          item?.customer?.organization?.name?.trim() ||
                          item?.customer?.representative?.fullName?.trim() ||
                          "N/A"}
                      </div>
                      <div className='text-gray-500 dark:text-gray-400'>
                        {item?.customer?.code || "N/A"}
                      </div>
                    </div>
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.customer?.representative?.phoneNumber ||
                      item?.customer?.representative?.phoneNumber ||
                      "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.customer?.representative?.emailAddress ||
                      item?.customer?.representative?.emailAddress ||
                      "N/A"}
                  </div>
                </td>
                {/* <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <span
                    className={classNames(
                      item?.status === "Acknowledged"
                        ? `bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50`
                        : "",
                      item?.status === "Expired"
                        ? `bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50`
                        : "",
                      item?.status === "Failed"
                        ? `bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50`
                        : "",
                      item?.status === "Posted"
                        ? `bg-gray-100 text-[#9ACD32] dark:bg-[#9ACD32] dark:text-gray-50`
                        : "",
                      item?.status === "Queued"
                        ? `bg-gray-100 text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50`
                        : "",
                      item?.status === "Active"
                        ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                        : "",
                      item?.status === "Reversed"
                        ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                        : "",
                      !item?.status
                        ? "bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-100"
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 10 10'
                      fill='currentColor'
                      className='w-1.5 h-1.5'
                    >
                      <circle
                        fillRule='evenodd'
                        cx='5'
                        cy='5'
                        r='5'
                        clipRule='evenodd'
                      />
                    </svg>
                    <span>{item?.status}</span>
                  </span>
                </td> */}
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    GHS{" "}
                    {numeral(item?.accountData?.debtAmount || 0).format(
                      "0,0.00"
                    )}{" "}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='font-medium text-gray-900 dark:text-gray-100'>
                    { item?.accountData?.lastPaymentDate ? moment(item?.accountData?.lastPaymentDate).format(
                      dateFormat
                    ) : "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='font-medium text-gray-900 dark:text-gray-100 text-center'>
                  {item?.accountData?.toBeDisconnected ? "Yes" : "No"}

                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {!!searchParams.id?.length && (
        <>
          <ViewDebtCustomerContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}

      <InitiateDisconnectionContainer
        open={modal === "initiate"}
        setOpen={(val: boolean) => setModal(val ? "initiate" : undefined)}
        refetch={() => {}}
      />
    </main>
  );
};

export default DebtCustomersPage;
