import { gql, useQuery } from "@apollo/client";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { BillSimulatorForm, BillSimulatorView, Modal } from "components";
import { useState } from "react";
import { classNames, wrapClick } from "utils";
import { useFormik } from "formik";

const GET_TARIFF_INSTANCES = gql`
  query GetTariffInstances($tariffClass: ID!) {
    tariffInstances: getTariffInstances(tariffClass: $tariffClass page: 0 pageSize: 0) {
      _id
      code
      name
      energyCharge {
        type
        value
        steps {
          name
          minimumConsumption
          maximumConsumption
          value
          exclusive
        }
      }
      serviceCharge {
        type
        value
        steps {
          name
          minimumConsumption
          maximumConsumption
          value
          exclusive
        }
      }
      hasExtraItems
      extraItems {
        name
        category
        type
        appliedTo
        value
      }
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;

const orderTabs: any[] = [
  {
    name: "Bill",
    href: "bill",
  },
  {
    name: "Installment Plan",
    href: "installment",
  },
];

export default function CalculateTariffClassContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [orderTab, setOrderTab] = useState("bill");
  const { data, loading } = useQuery(GET_TARIFF_INSTANCES, {
    variables: {
      tariffClass: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };
  const form = useFormik({
    initialValues: {
      initialReadingDate: "",
      initialReadingValue: 0,
      lastActualReadingDate: "",
      lastActualReadingValue: 0,
      bills: [],
    },
    onSubmit: () => {},
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Calculate Bill"
      loading={loading}
      size="5xl"
      description="Provide details to simulate bill calculation"
      hidePadding
      renderActions={() => (
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Reset
        </button>
      )}
    >
      <div className="min-h-[75vh] max-h-[75vh] flex-1 flex flex-col overflow-hidden">
        <div className="block">
          <div className="border-b border-gray-200 bg-white px-6">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {orderTabs.map((_orderTab) => (
                <button
                  key={_orderTab.name}
                  onClick={wrapClick(__setOrderTab(_orderTab.href))}
                  disabled={
                    _orderTab?.href === "installment" &&
                    data?.bill?.status === "Pending"
                  }
                  className={classNames(
                    _orderTab?.href === "installment" &&
                      data?.bill?.status === "Pending"
                      ? "cursor-not-allowed"
                      : "cursor-pointer",
                    orderTab === _orderTab.href
                      ? "border-primary-500 text-primary-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                    "whitespace-nowrap disabled:cursor-not-allowed flex py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={
                    orderTab === _orderTab.href ? "page" : undefined
                  }
                >
                  {_orderTab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
        <div className="flex-1 w-full overflow-hidden flex flex-col">
          {orderTab === "bill" && (
            <BillSimulatorForm
              form={form}
              tariffInstances={data?.tariffInstances}
            />
          )}
          {orderTab === "installment" && (
            <BillSimulatorView bills={form.values.bills} />
          )}
        </div>
      </div>
    </Modal>
  );
}
