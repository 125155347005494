import { withPermissions, wrapClick } from 'utils';
import { gql, useQuery } from '@apollo/client';
import { ItineraryView, Modal } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';

const GET_ITINERARY = gql`
 query GetItinerary($id: ID!) {
  itinerary: getItinerary(id: $id) {
    _id
    code
    description
    region {
      _id
      code
      name
    }
    district {
      _id
      code
      name
    }
    block {
      _id
      code
      name
    }
    round {
      _id
      code
      name
    }
    plot {
      _id
      code
      name
    }
    meta {
      cycleStartDate
      cycleEndDate
      propertiesCount
      customersCount
      defaultReader {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        gender
        profileImageUrl
      }
    }
    createdAt
    updatedAt
    blockCode
    blockName
    community
    districtCode
    districtName
    plotCode
    plotName
    regionCode
    regionName
    roundCode
    roundName
  }
}
`

export default function ViewItineraryContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_ITINERARY, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });
  const dispatchAction = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "configure"
      })
    })
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Itinerary Information"
      description="Details of itinerary are shown below"
      size='4xl'
      loading={loading}
      renderActions={() => (
        <>
          {withPermissions(["*:*", "itineraries:*", "itineraries:configure"])(
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(dispatchAction)}
            >
              Configure
            </button>
          )}
        </>
      )}
    >
      <ItineraryView
        itinerary={data?.itinerary}
      />
    </Modal>
  )
}