import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Shimmers, TableComponent } from "components";
import moment from "moment";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick, useUrlState, useTableData } from "utils";
import config from "config";
import ViewBlockContainer from "./view";
import { ExportDataContainer } from "containers";
import { currentConfigVar } from "apollo/cache/config";
import { Avatar } from "components/core";
import { Action } from "components/buttons/action-button";

const GET_UNCAPTURED_METERS = gql`
  query GetUncapturedMeters(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $fromDate: Date
    $toDate: Date
    $region: ID
    $district: ID
  ) {
    rows: getUncapturedMeters(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      fromDate: $fromDate
      toDate: $toDate
      region: $region
      district: $district
    ) {
      _id
      code
      meterNumber
      customerName
      customerPhoneNumber
      nearestMeterNumber
      imageUrl
      reader {
        _id
        lastName
        firstName
        profileImageUrl
        phoneNumber
      }
      property {
        _id
        code
        address
        ghanaPostAddress
        community
        regionCode
        regionName
        districtCode
        districtName
        blockCode
        blockName
      }
      itinerary {
        _id
        code
        community
        regionCode
        regionName
        districtCode
        districtName
        blockCode
        blockName
      }
      createdAt
      updatedAt
    }
    count: getUncapturedMetersCount(
      search: $search
      searchFields: $searchFields
      fromDate: $fromDate
      toDate: $toDate
      region: $region
      district: $district
    )
  }
`;

const UncapturedMetersPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      // navigate({
      //   search: (old) => ({
      //     ...old,
      //     id,
      //     modal: action
      //   })
      // })
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: (searchParams.search || "").toString(),
      searchFields: ["code"],
      sort: searchParams.sort || "",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      fromDate: searchParams.fromDate ? searchParams.fromDate : undefined,
      toDate: searchParams.toDate ? searchParams.toDate : undefined,
    }),
    [searchParams]
  );

  const { data, loading, networkStatus, refetch } = useQuery(
    GET_UNCAPTURED_METERS,
    {
      variables: filter,
      notifyOnNetworkStatusChange: false,
    }
  );

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
      }),
    });
  }, [navigate]);

  const records = useTableData(data || {});

  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-gray-50">
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"unlisted meters"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            // renderExport={({ exportOpen, setExportOpen }) => (
            //   <ExportDataContainer
            //     open={exportOpen}
            //     setOpen={setExportOpen}
            //     entityType="UncapturedMeter"
            //     filter={filter}
            //   />
            // )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Code | Date
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Meter
                </th>
                {/* {!region && (
                  <th
                    scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Region
                  </th>
                )}
                {!district && (
                  <th
                    scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    District
                  </th>
                )} */}
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Customer
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Property
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Itinerary
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Reported By
                </th>
                {/* <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                </th> */}
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                {/* {!region && (
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                )}
                {!district && (
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                )} */}
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.AvatarShimmer />
                </td>
                {/* <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={1} />
                </td> */}
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100">
                    {item?.code || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {moment(item?.createdAt).format(dateFormat + " hh:mm A")}
                  </div>
                </td>
                <td className=" px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.meterNumber || "N A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.nearestMeterNumber || "N/A"}
                  </div>
                </td>
                {/* {!region && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                    <div className="font-medium text-gray-900 dark:text-gray-100">{item?.regionName || item?.customer?.regionName || "N/A"}</div>
                    <div className="text-gray-500 dark:text-gray-400">{item?.regionCode || item?.customer?.regionCode || "N/A"}</div>
                  </td>
                )}
                {!district && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                    <div className="font-medium text-gray-900 dark:text-gray-100">{item?.districtName || item?.customer?.districtName || "N/A"}</div>
                    <div className="text-gray-500 dark:text-gray-400">{item?.districtCode || item?.customer?.districtCode || "N/A"}</div>
                  </td>
                )} */}
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <Avatar
                        disabled={true}
                        alt={item?.customerName?.trim() || "N A"}
                        src={item?.customerProfileImageUrl || ""}
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-gray-900 dark:text-gray-100">
                        {item?.customerName?.trim() || "N A"}
                      </div>
                      <div className="text-gray-500 dark:text-gray-400">
                        {item?.customerPhoneNumber || "N/A"}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.property?.code ||
                      item?.customer?.propertyCode ||
                      "N A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.property?.ghanaPostAddress ||
                      item?.customer?.ghanaPostAddress ||
                      "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.property?.itineraryCode ||
                      item?.itinerary?.code ||
                      "N A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.property?.community ||
                      item?.itinerary?.community ||
                      "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <Avatar
                        disabled={true}
                        alt={
                          [
                            (item?.reader?.lastName || "")?.trim(),
                            (item?.reader?.firstName || "")?.trim(),
                          ]
                            .join(" ")
                            .trim() || "N A"
                        }
                        src={item?.reader?.profileImageUrl || ""}
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-gray-900 dark:text-gray-100">
                        {[
                          (item?.reader?.lastName || "")?.trim(),
                          (item?.reader?.firstName || "")?.trim(),
                        ]
                          .join(" ")
                          .trim() || "N A"}
                      </div>
                      <div className="text-gray-500 dark:text-gray-400">
                        {item?.reader?.phoneNumber || "N/A"}
                      </div>
                    </div>
                  </div>
                </td>
                {/* <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                  </div>
                </td> */}
              </tr>
            )}
          />
        </div>
      </div>
      {!!searchParams.id?.length && false && (
        <>
          <ViewBlockContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default UncapturedMetersPage;
