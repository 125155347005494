import { gql, useLazyQuery } from "@apollo/client";
import { SelectInput, TextArea, TextInput, UploadArea } from "components/core";
import { useFormik } from "formik";
import { FC, useEffect } from "react";
import { validators, wrapClick } from "utils";
import * as yup from "yup";

const RESOLVE_ADDRESS_INFO = gql`
  query ResolveGhanaPostAddress($address: String!) {
    address: resolveGhanaPostAddress(address: $address) {
      _id
      postCode
      district
      region
      area
      address
      street
      coordinates {
        longitude
        latitude
      }
    }
  }
`;

interface ContractInfoFormProps {
  handleNext: (values: any) => void;
  handlePrevious: () => void;
  initialValues: any;
  values: any;
  handleCancel: () => void;
}

const ContractInfoForm: FC<ContractInfoFormProps> = ({
  initialValues,
  handleNext,
  handlePrevious,
  handleCancel,
}) => {
  const [resolveGhanaPostAddress] = useLazyQuery(RESOLVE_ADDRESS_INFO);

  const form = useFormik({
    initialValues,
    validationSchema: yup.object().shape({
      readingValue: yup.number().required("Reading value is required"),
      readingImageUrl: yup.string().required("Reading image is required"),
      readingLocationName: yup
        .string()
        .required("Reading location is required"),
      readingRecordedAt: yup.date().required("Reading date is required"),
    }),
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  useEffect(() => {
    if (form.values.readingLocationName.match(validators.GhanaPostRegex)) {
      resolveGhanaPostAddress({
        variables: {
          address: form.values.readingLocationName,
        },
      })
        .then(({ data, error }) => {
          if (data?.address?._id) {
            if (data.address?.coordinates) {
              form.setFieldValue("readingLocation", {
                longitude: data.address.coordinates.longitude,
                latitude: data.address.coordinates.latitude,
              });
            }
          } else if (error) {
            form.setFieldTouched("readingLocationName", true);
            form.setFieldError("readingLocationName", error?.message);
          }
        })
        .catch((err) => {
          form.setFieldTouched("readingLocationName", true);
          form.setFieldError(
            "readingLocationName",
            "Invalid Ghana Post Address"
          );
        });
    }
  }, [form.values.readingLocationName]);

  return (
    <form
      onSubmit={form.handleSubmit}
      className="flex-1 flex flex-col overflow-hidden"
    >
      <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto">
        <div>
          <span className="text-xs font-light">Contract Information</span>
          <div className="grid grid-cols-2 gap-6 mt-2">
            <div className="space-y-1">
              <TextInput
                id="readingValue"
                placeholder="e.g. 1000"
                label="Reading Value"
                postText="kwH"
                {...form}
              />
            </div>
            <div className="space-y-1">
              <TextInput
                id="readingRecordedAt"
                placeholder="e.g. 1000"
                type="date"
                label="Reading Recorded At"
                // postText="kwH"
                {...form}
              />
            </div>
            <div className="space-y-1 col-span-2">
              <TextInput
                id="readingLocationName"
                placeholder="e.g. GE-123-1234 or GA-123-1234"
                type="text"
                label="Reading Location"
                // postText="kwH"
                {...form}
              />
            </div>

            <div className="col-span-2 ">
              <UploadArea
                id="readingImageUrl"
                label="Proof of Reading"
                {...form}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="submit"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default ContractInfoForm;
