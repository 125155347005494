import React, { useCallback, useEffect, useState } from "react";
import { useDropzone, Accept, FileRejection } from "react-dropzone";
import axios from "axios";
import config from "config";
import _ from "lodash";
import { classNames } from "utils";
import { DocumentPlusIcon, TrashIcon } from "@heroicons/react/24/outline";

axios.defaults.baseURL = config.asset.uri;

interface UploadBoxProps {
  id: string;
  multiple?: boolean;
  maxFiles?: number;
  maxSize?: number;
  minSize?: number;
  accept?: Accept;
  disabled?: boolean;
  label?: string;
  values: any;
  setFieldValue: any;
  setFieldError: any;
  setFieldTouched: any;
  handleBlur: any;
  errors?: any;
  touched?: any;
  className?: string;
  required?: boolean;
}

interface Upload extends File {
  path: any;
  preview: string;
}

const UploadBox: React.FC<UploadBoxProps> = ({
  multiple = false,
  maxFiles = 1,
  maxSize = 1024 * 1024 * 5,
  minSize = 1,
  accept = {
    "image/*": [".png", ".jpeg", ".jpg"],
  },
  errors,
  values,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  touched,
  id,
  label,
  // className = "aspect-w-3 aspect-h-2 w-full",
  required,
}) => {
  const [files, setFiles] = useState<Upload[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      setFieldTouched(false);
      if (!acceptedFiles.length) {
        setFieldError(
          id,
          _.chain(rejectedFiles)
            .map("errors")
            .flatten()
            .map("code")
            .map(_.startCase)
            .join(", ")
            .value()
        );
        return;
      }

      setFiles((prev) =>
        multiple ? [...prev, ...acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )] : acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setLoading(true);
      setShowLoader(true);
      // upload image to server and return url
      if (multiple) {
        const imageUrls: string[] = _.get(values, id, []);
        console.log(imageUrls, "--imageUrls");
        for (let i = 0; i < acceptedFiles.length; i++) {
          const data = new FormData();
          const file = acceptedFiles[i];
          data.append("file", file);
          await axios
            .post("/upload", data, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(({ data }) => {
              imageUrls.push(data);
            })
            .catch((err) => {
              console.log(err, "--ERROR__");
            })
            .finally(() => {
              setLoading(false);
              setTimeout(() => {
                setShowLoader(false);
              }, 3000);
            });
        }
        console.log(imageUrls, "--imageUrls");
        if (imageUrls.length) setFieldValue?.(id, imageUrls);
      } else {
        const data = new FormData();
        const file = acceptedFiles[0];
        data.append("file", file);
        await axios
          .post("/upload", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => {
            if (data) setFieldValue?.(id, data as string);
          })
          .catch((err) => { })
          .finally(() => {
            setLoading(false);
            setTimeout(() => {
              setShowLoader(false);
            }, 3000);
          });
      }
    },
    [
      // files,
      multiple,
      id,
    ]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles,
    multiple,
    accept,
    maxSize,
    minSize,
    noClick: false,
    disabled: loading,
  });

  useEffect(
    () => () => {
      // Make sure to revoke the Object URL to avoid memory leaks
      // files.forEach((file) => URL.revokeObjectURL(file?.preview));
    },
    [files]
  );

  return (
    <>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label} {required ? <span className="text-red-500">*</span> : ""}
      </label>
      <div className="grid grid-cols-4 gap-3">
        <div
          {...getRootProps()}
          id={id}
          className={classNames(
            // className,
            "border h-56 overflow-hidden mt-1 relative cursor-pointer bg-white p-2 rounded-md shadow-md"
          )}
        >
          {showLoader && (
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <div className="text-white">Uploading...</div>
            </div>
          )}
          {!multiple && files.length ? (
            <div className="relative h-full">
              <img
                className="object-cover object-top h-full w-full z-0"
                src={
                  files?.[0]?.preview ||
                  (multiple ? _.get(values, id)?.[0] : _.get(values, id))
                }
                alt="Hello"
              />
              <div className="absolute inset-0 bg-slate-500/30 flex flex-col items-center justify-center">
                <DocumentPlusIcon className="h-10 w-10 m-auto text-gray-200" />
                <span className="text-gray-200">Click to upload file</span>
              </div>
            </div>
          ) : (
            <div className="h-full space-y-1">
              <div className="border flex-col border-dashed h-2/3 w-full flex rounded-md items-center justify-center">
                <DocumentPlusIcon className="h-10 w-10 mx-auto text-gray-400" />
              </div>
              <div className="h-1/3 bg-gray-100 rounded-md flex items-center justify-center">
                <span className="text-gray-400">Click to upload file</span>
              </div>
            </div>

          )}

          {/* <div className="bg-white p-4"></div> */}
        </div>
        {multiple && files.map((file, index) => (
          <div key={index} className="col-span-1 relative h-56 p-1 rounded-md overflow-hidden">
            <img
              src={file.preview}
              alt="preview"
              className="h-full w-full object-cover"
            />
            <div className="p-1 absolute flex text-white flex-row-reverse space-x-2 text-sm top-0 left-0 cursor-pointer bg-red-500" onClick={() => {
              const newFiles = [...files];
              newFiles.splice(index, 1);
              setFiles(newFiles);
              setFieldValue?.(id, newFiles);
            }}>
              {/* <span>Remove File</span> */}
              <TrashIcon className="h-4 w-4 text-white mr-1" />
            </div>
          </div>
        ))}
      </div>
      {
        _.get(errors, id) && _.get(touched, id) ? (
          <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
            {_.get(errors, id)}
          </p>
        ) : null
      }
    </>
  );
};

export default UploadBox;
