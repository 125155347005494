import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { Modal, ReadingView, ExceptionDetailsView } from "components";
import { useEffect, useState } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { classNames, withPermissions, wrapClick } from "utils";

export const GET_READING_EXCEPTION = gql`
  query GetReadingException($id: ID!) {
    readingException: getReadingException(id: $id) {
      _id
      code
      description
      value
      imageUrl
      type
      reader {
        _id
        lastName
        firstName
        profileImageUrl
        phoneNumber
      }
      reading {
        _id
        code
        customerCode
        emailAddress
        phoneNumber
        servicePointNumber
        meterNumber
        accountNumber
        fullName
        meterLocation
        geoCode
        contractedDemand
        ghanaCardNumber
        profileImageUrl
        serviceType
        customerType
        qrCode
        address
        ghanaPostAddress
        tariffClass {
          _id
          code
          name
        }
        tariffClassCode
        tariffClassName
        regionCode
        regionName
        districtCode
        districtName
        blockCode
        blockName
        roundCode
        roundName
        plotCode
        community
        itineraryCode
        itineraryDescription
        propertyCode
        previousReading {
          readingDate
          readingValue
          readingEstimated
          consumptionValue
          billValue
          totalAmountDue
        }
        currentReading {
          readingDate
          readingValue
          readingEstimated
          consumptionValue
          billValue
          totalAmountDue
        }
        meta {
          readingImageUrl
          readingLocation {
            coordinates
          }
          readingImageLocation {
            coordinates
          }
          anomalyImageUrl
          anomalyLocation {
            coordinates
          }
          anomalyImageLocation {
            coordinates
          }
          exceptionImageUrl
          exceptionLocation {
            coordinates
          }
          exceptionImageLocation {
            coordinates
          }
          noTraceLocation {
            coordinates
          }
          readingRecordedAt
          readingSyncedAt
        }
        status
      }
      meta {
        exceptionLocation {
          coordinates
        }
        exceptionImageLocation {
          coordinates
        }
      }
      status
      createdAt
      updatedAt
    }
  }
`;

const tabs = [
  { name: "Customer Details", href: "Customer" },
  { name: "Exception Details", href: "Exception" },
];

export default function ViewReadingExceptionContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [tab, setTab] = useState("Exception");
  const { data, loading } = useQuery(GET_READING_EXCEPTION, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  const __setTab = (tab: string) => () => {
    setTab(tab);
  };

  useEffect(() => {
    setTab("Exception");
  }, [searchParams.id]);

  const approveItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "approve",
      }),
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      hidePadding={true}
      title="Exception Information"
      description="Details of reading exception are shown below"
      size="4xl"
      loading={loading}
      renderActions={() => (
        <>
          {withPermissions([
            "*:*",
            "reading-exceptions:*",
            "reading-exceptions:approve",
          ])(
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(approveItem)}
            >
              Approve
            </button>
          )}
        </>
      )}
    >
      <>
        <div className="block">
          <div className="border-b border-gray-200 bg-white px-6">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((_tab) => (
                <button
                  key={_tab.name}
                  onClick={wrapClick(__setTab(_tab.href))}
                  className={classNames(
                    tab === _tab.href
                      ? "border-primary-500 text-primary-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                    "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={tab === _tab.href ? "page" : undefined}
                >
                  {_tab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
        <div className="flex-1 w-full max-h-[65vh] overflow-y-auto  sm:p-6">
          {tab === "Exception" && (
            <ExceptionDetailsView
              exception={data?.readingException}
              reading={data?.readingException?.reading}
            />
          )}
          {tab === "Customer" && (
            <ReadingView reading={data?.readingException?.reading} />
          )}
        </div>
      </>
    </Modal>
  );
}
