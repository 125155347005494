import moment from "moment";
import lodash from "lodash";
import numeral from "numeral";
import { currentConfigVar } from "apollo/cache/config";

interface GenerateBillProps {
  previousReadingDate: Date;
  previousReadingValue: number;
  currentReadingDate: Date;
  currentReadingValue: number;
  tariffInstance: {
    code: string;
    name: string;
    startDate: string;
    energyCharge: {
      type: string;
      value: number;
      steps: {
        name: string;
        minimumConsumption: number;
        maximumConsumption: number;
        value: number;
        exclusive: boolean;
      }[];
    };
    serviceCharge: {
      type: string;
      value: number;
      steps: {
        displayName: string;
        name: string;
        minimumConsumption: number;
        maximumConsumption: number;
        value: number;
        exclusive: boolean;
      }[];
    };
    extraItems: {
      name: string;
      category: string;
      type: string;
      appliedTo: string;
      value: number;
    }[];
  }
}

const calculateEnergyCharge = ({ tariffInstance, billPeriod, items, consumption: consumptionX, daysInYear }: {
  tariffInstance: GenerateBillProps["tariffInstance"];
  billPeriod: number;
  daysInYear: number;
  consumption: number;
  items: any[];
}) => {
  let consumption = consumptionX;

  if (tariffInstance.energyCharge.type === "SteppedRate") {
    const exclusiveBands = lodash.chain(tariffInstance.energyCharge.steps || []).filter(["exclusive", true]).sortBy("minimumConsumption").value();
    const nonExclusiveBands = lodash.chain(tariffInstance.energyCharge.steps || []).filter(["exclusive", false]).sortBy("minimumConsumption").reverse().value();
    for (let i = 0; i < exclusiveBands.length; i++) {
      const band = exclusiveBands[i];
      const stepBand = (band.maximumConsumption * 12 / daysInYear) * billPeriod;
      if (stepBand > consumption) {
        items.push({
          title: band.name,
          unit: `${numeral(consumption).format("0,0")} KWh`,
          quantity: consumption,
          unitCost: band.value,
          cost: band.value * consumption,
        });
        return;
      }
    }
    for (let i = 0; i < nonExclusiveBands.length; i++) {
      const band = nonExclusiveBands[i];
      const stepBand = (lodash.max([(band.minimumConsumption - 1), 0]) as number * 12 / daysInYear) * billPeriod;
      if (consumption > stepBand) {
        const stepConsumption = lodash.round(consumption - stepBand);
        const stepEnergyCharge = stepConsumption * band.value;
        consumption -= stepConsumption;
        items.push({
          title: band.name,
          unit: `${numeral(stepConsumption).format("0,0")} KWh`,
          quantity: stepConsumption,
          unitCost: band.value,
          cost: stepEnergyCharge,
        });
      }
    }
    items.reverse()
  } else {
    items.push({
      title: "Energy Charge",
      unit: `${numeral(consumption).format("0,0")} KWh`,
      quantity: consumption,
      unitCost: tariffInstance.energyCharge.value,
      cost: tariffInstance.energyCharge.value * billPeriod,
    })
  }
}

const calculateServiceCharge = ({ tariffInstance, billPeriod, items, consumption, daysInYear }: {
  tariffInstance: GenerateBillProps["tariffInstance"];
  billPeriod: number;
  daysInYear: number;
  consumption: number;
  items: any[];
}) => {
  let allowedConsumption = consumption;
  const serviceChargeItems: any[] = [];
  if (tariffInstance.serviceCharge.type === "SteppedRate") {
    const exclusiveBands = lodash.chain(tariffInstance.serviceCharge.steps || []).sortBy("minimumConsumption").value();

    for (let i = 0; i < exclusiveBands.length; i++) {
      const band = exclusiveBands[i];
      const stepBand = (band.maximumConsumption * 12 / daysInYear) * billPeriod;
      if (stepBand > allowedConsumption) {
        const unitCost = lodash.round(band.value * 12 / daysInYear, 4);
        const cost = lodash.round(unitCost * billPeriod, 2);
        serviceChargeItems.push({
          title: band.name || "Service Charge",
          displayTitle: band.displayName || "SC",
          unit: `${numeral(billPeriod).format("0,0")} Days`,
          quantity: billPeriod,
          unitCost,
          formattedUnitCost: numeral(unitCost).format("0,0.0000"),
          cost
        });
        return ({
          serviceChargeItems,
          serviceCharge: lodash.chain(serviceChargeItems).sumBy("cost").round(2).value(),
        });
      }
    }

    serviceChargeItems.reverse()
  } else {
    const unitCost = lodash.round(tariffInstance.serviceCharge.value * 12 / daysInYear, 4);
    const cost = lodash.round(unitCost * billPeriod, 2)
    serviceChargeItems.push({
      title: "Service Charge",
      displayTitle: "SC",
      unit: `${numeral(billPeriod).format("0,0")} Days`,
      quantity: billPeriod,
      unitCost,
      formattedUnitCost: numeral(unitCost).format("0,0.0000"),
      cost,
    })
  }

  return ({
    serviceChargeItems,
    serviceCharge: lodash.chain(serviceChargeItems).sumBy("cost").round(2).value(),
  });
}

export const generateBill = ({
  previousReadingDate,
  previousReadingValue,
  currentReadingDate,
  currentReadingValue,
  tariffInstance
}: GenerateBillProps) => {

  const daysInYear = moment().isLeapYear() ? 366 : 365;

  // Calculate number of days between reading dates
  const consumptionPeriod = moment(currentReadingDate).endOf("day").diff(moment(previousReadingDate).startOf("day"), "days") + 1;
  const billStartDate = moment(previousReadingDate).add(1, "day").startOf("day").toDate();
  const billEndDate = moment(currentReadingDate).endOf("day").toDate();
  const billPeriod = moment(billEndDate).endOf("day").diff(moment(billStartDate).startOf("day"), "days") + 1;

  // Calculate the consumption
  let totalConsumption = currentReadingValue - previousReadingValue;

  // Find tariif
  let items: any[] = []

  // Daily Proration of Energy Charge
  calculateEnergyCharge({ tariffInstance, billPeriod, items, consumption: totalConsumption, daysInYear })

  // Calculate the energy charge
  const energyCharge = lodash.sumBy(items, "cost");

  // Calculate the service charge
  const {serviceCharge, serviceChargeItems } = calculateServiceCharge({ tariffInstance, billPeriod, items, consumption: totalConsumption, daysInYear })

  items = [
    ...items,
    ...serviceChargeItems
  ];

  const energyPlusServiceCharge = energyCharge + serviceCharge;

  tariffInstance.extraItems.forEach((extraItem) => {

    const extraItemQuantity = ({
      "EnergyCharge": energyCharge,
      "ServiceCharge": energyCharge,
      "EnergyPlusServiceCharge": energyPlusServiceCharge,
      "ConsumptionPeriod": billPeriod,
      "ConsumptionValue": totalConsumption,
    })[extraItem.appliedTo];

    const extraItemUnit = ({
      "EnergyCharge": `GHS ${numeral(energyCharge).format("0,0.00")}`,
      "ServiceCharge": `GHS ${numeral(energyCharge).format("0,0.00")}`,
      "EnergyPlusServiceCharge": `GHS ${numeral(energyPlusServiceCharge).format("0,0.00")}`,
      "ConsumptionPeriod": `${billPeriod} days`,
      "ConsumptionValue": `${numeral(totalConsumption).format("0,0")} KWh`,
    })[extraItem.appliedTo];

    let extraItemCost = 0;
    switch (extraItem.type) {
      case "FixedPercentage": {
        extraItemCost = (extraItemQuantity as number) * extraItem.value / 100;
        break;
      }
      case "FixedRate": {
        extraItemCost = (extraItemQuantity as number) * extraItem.value;
        break;
      }
      case "FixedValue": {
        extraItemCost = extraItem.value;
        break;
      }
    }

    items.push({
      title: extraItem.name,
      unit: extraItemUnit,
      quantity: extraItemQuantity,
      unitCost: extraItem.value,
      cost: extraItemCost,
    })
  })

  return {
    items,
    total: lodash.sumBy(items, "cost"),
    consumption: totalConsumption,
    numberOfDays: billPeriod,
  }
}