import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Header, Shimmers, TableComponent } from "components";
import moment from "moment";
import lodash from "lodash";
import { FC, useEffect, useMemo } from "react";
import { useMatch, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick, useUrlState, classNames, useTableData } from "utils";
import config from "config";
import ViewReadingContainer from "./view";
import { Avatar, SearchSelectInput } from "components/core";
import numeral from "numeral";
import { currentConfigVar } from "apollo/cache/config";
import ActionButton, { Action } from "components/buttons/action-button";

const GET_READINGS = gql`
query GetReadings(
  $page: Int
  $pageSize: Int
  $sort: String
  $search: String
  $searchFields: [String!]
  $fromDate: Date
  $toDate: Date
  $month: PositiveInt
  $year: PositiveInt
  $region: ID
  $district: ID
  $block: ID
  $round: ID
  $plot: ID
  $servicePoint: ID
  $itinerary: ID
  $status: ReadingStatus
) {
  rows: getReadings(
    page: $page
    pageSize: $pageSize
    sort: $sort
    search: $search
    searchFields: $searchFields
    fromDate: $fromDate
    toDate: $toDate
    dateField: "meta.readingRecordedAt"
    month: $month
    year: $year
    servicePoint: $servicePoint
    region: $region
    district: $district
    block: $block
    round: $round
    plot: $plot
    itinerary: $itinerary
    status: $status
  ) {
    _id
    qrCode
    address
    ghanaPostAddress
    propertyCode
    customerCode
    emailAddress
    phoneNumber
    geoCode
    servicePointNumber
    meterNumber
    accountNumber
    fullName
    meterLocation
    contractedDemand
    serviceType
    customerType
    tariffClass {
      _id
    }
    tariffClassName
    tariffClassCode
    previousReading {
      readingDate
      readingValue
      readingEstimated
      consumptionValue
      billValue
      totalAmountDue
    }
    currentReading {
      readingDate
      readingValue
      readingEstimated
      consumptionValue
      billValue
      totalAmountDue
    }
    status
    meta {
      readingRecordedAt
      readingSyncedAt
    }
  }
  count: getReadingsCount(
    search: $search
    searchFields: $searchFields
    fromDate: $fromDate
    toDate: $toDate
    dateField: "meta.readingRecordedAt"
    month: $month
    year: $year
    region: $region
    district: $district
    servicePoint: $servicePoint
    block: $block
    round: $round
    plot: $plot
    itinerary: $itinerary
    status: $status
  )
}

`;

const ServicePointReadingsPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };

  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [status, setStatus] = useUrlState("status");

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: (searchParams.search || "").toString(),
      searchFields: [
        "geoCode",
        "propertyCode",
        "ghanaPostAddress",
        "address",
        "servicePointNumber",
      ],
      sort: searchParams.sort || "meta.readingRecordedAt",
      servicePoint: params.servicePoint || "",
      status: searchParams.status || undefined,
      ...(searchParams.view !== "month"
        ? {
          fromDate: searchParams.fromDate ? searchParams.fromDate : undefined,
          toDate: searchParams.toDate ? searchParams.toDate : undefined,
        }
        : {
          month: moment(searchParams.fromDate).month() + 1,
          year: moment(searchParams.fromDate).year(),
        }),
    }),
    [searchParams]
  );

  const { data, loading, refetch } = useQuery(GET_READINGS, {
    variables: filter,
    notifyOnNetworkStatusChange: false,
  });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.sort ? { sort: "-meta.readingRecordedAt" } : {}),
      }),
    });
  }, [navigate]);

  const records = useTableData(data || {})

  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-gray-50">
      <Header />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"readings"}
            refetch={refetch}
            isRefetching={loading}
            loading={loading}
            data={records}
            hasSearch={true}
            renderHeaderItems={() => (
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex space-x-2">
                <SearchSelectInput
                  id="status"
                  labelHidden={true}
                  options={[
                    { label: { title: "Pending" }, value: "Pending" },
                    { label: { title: "Meter Read" }, value: "MeterRead" },
                    {
                      label: { title: "Anomaly Detected" },
                      value: "AnomalyDetected",
                    },
                    {
                      label: { title: "Exception Detected" },
                      value: "ExceptionDetected",
                    },
                    { label: { title: "No Trace" }, value: "NoTrace" },
                  ]}
                  label={""}
                  placeholder="Select Status"
                  setFieldValue={(_: any, value: string) => setStatus(value)}
                  values={{ status }}
                />
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Customer
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  SPN | Account No
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Geo Code | Meter No
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Consumption | Bill
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Read At
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <>
                <tr>
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.SingleShimmer />
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.SingleShimmer />
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.AvatarShimmer />
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.ActionsShimmer actionsCount={2} />
                  </td>
                </tr>
              </>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <Avatar
                        disabled={true}
                        alt={item?.fullName?.trim() || "N A"}
                        src={item?.profileImageUrl || ""}
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-gray-900 dark:text-gray-100">
                        {item?.fullName?.trim() || "N A"}
                      </div>
                      <div className="text-gray-500 dark:text-gray-400">
                        {item?.customerCode || "N/A"}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.servicePointNumber || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.accountNumber || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.geoCode || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.meterNumber || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {lodash.isNumber(item?.currentReading?.consumptionValue)
                      ? `${numeral(
                        item.currentReading.consumptionValue || 0
                      ).format("0,0")} KWh`
                      : "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.currentReading?.billValue
                      ? `GHS ${numeral(
                        item?.currentReading?.billValue || 0
                      ).format("0,0.00")}`
                      : "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.meta?.readingRecordedAt
                      ? moment(item.meta.readingRecordedAt).format(dateFormat)
                      : "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.meta?.readingRecordedAt
                      ? moment(item.meta.readingRecordedAt).format(`hh:mm A`)
                      : "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <span
                    className={classNames(
                      item?.status === "AnomalyDetected"
                        ? `bg-red-100 text-red-800 dark:bg-red-600 dark:text-red-100`
                        : "",
                      item?.status === "ExceptionDetected"
                        ? `bg-amber-100 text-amber-800 dark:bg-amber-600 dark:text-amber-100`
                        : "",
                      item?.status === "Pending"
                        ? `bg-gray-100 text-gray-800 dark:bg-gray-600 dark:text-gray-50`
                        : "",
                      item?.status === "MeterRead"
                        ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-green-100`
                        : "",
                      item?.status === "NoTrace"
                        ? `bg-purple-100 text-purple-800 dark:bg-purple-600 dark:text-purple-100`
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 10"
                      fill="currentColor"
                      className="w-1.5 h-1.5"
                    >
                      <circle
                        fillRule="evenodd"
                        cx="5"
                        cy="5"
                        r="5"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>{lodash.startCase(item?.status) || "Unknown"}</span>
                  </span>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className="space-x-1">
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(item?._id, "view")}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {!!searchParams.id?.length && (
        <>
          <ViewReadingContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default ServicePointReadingsPage;
