import { gql, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { Modal } from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import ComplaintView from "./cards/complaint-view";
import toast from "react-hot-toast";
import { Action } from "components/buttons/action-button";
import { classNames, wrapClick } from "utils";
import { useState } from "react";
import { currentUserVar } from "apollo/cache/auth";



const orderTabs = [
  {
    name: "Customer Details",
    href: "CustomerDetails",
    activeStatus: ["Pending", "InProgress", "Closed", "Open", "Escalated"],
    showWhen: ["Pending", "InProgress", "Closed", "Open", "Escalated"]
  },
  {
    name: "Complaint Details",
    href: "OrderHistory",
    activeStatus: ["Pending", "InProgress", "Closed", "Open", "Escalated"],
    showWhen: ["Pending", "InProgress", "Closed", "Open", "Escalated"]
  },
  {
    name: "Extra Details",
    href: "ExtraDetails",
    activeStatus: ["Pending", "InProgress", "Closed", "Open", "Escalated"],
    showWhen: ["Pending", "InProgress", "Closed", "Open", "Escalated"]
  },
  {
    name: "Escalation Details",
    href: "EscalationDetails",
    activeStatus: ["Escalated"],
    showWhen: ["Escalated"]
  },
  {
    name: "Resolution Details",
    href: "ResolutionDetails",
    activeStatus: ["Closed"],
    showWhen: ["Pending", "InProgress", "Closed", "Open", "Escalated"]
  },
];

const START_RESOLUTION = gql`
mutation StartComplaint($startComplaintId: ID!) {
  startComplaint(id: $startComplaintId) {
    _id
  }
}
`;

export const GET_CUSTOMER = gql`
query GetComplaint($getComplaintId: ID!) {
  complaint: getComplaint(id: $getComplaintId) {
    _id
    code
    type {
      code
      name
    }
    description
    supportingDocumentUrls
    servicePointCode
    customerCode
    accountCode
    geoCode
    contractStatus
    meterCode
    month
    year
    status
    createdAt
    updatedAt
    createdBy {
      code
      firstName
      lastName
      profileImageUrl
      emailAddress
      phoneNumber
    }
    customer {
      code
      customerType

      organization {
        name
      }
      representative {
        phoneNumber
        phoneNumber
        emailAddress
        ghanaCardNumber
      }
    }
    contactPerson {
      emailAddress
      fullName
      phoneNumber
    }
    notes
    startedAt
    resolution {
      notes
      supportingDocumentUrls
      investigationServiceOrderCode
    }
    closedAt
    userRole {
      _id
    }
    escalatedReason
    escalatedAt
  }
}

`;

export default function ViewComplaintContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const currentUser = useReactiveVar(currentUserVar);
  const [orderTab, setOrderTab] = useState("OrderHistory");
  const { data, loading, refetch: refetchCustomer } = useQuery(GET_CUSTOMER, {
    variables: {
      getComplaintId: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };

  const [startResolution, { loading: starting }] = useMutation(START_RESOLUTION, {
    onCompleted: () => {
      refetch?.();
      refetchCustomer?.();
      // setOpen(false);
      toast(
        JSON.stringify({
          type: "success",
          title: "Ticket Resolution Started Successfully",
        })
      );
    },
  });

  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };

  return (
    <Modal
      open={open}
      loading={loading}
      setOpen={setOpen}
      hidePadding
      size="5xl"
      title='Ticket Information'
      description='Details of ticket are shown below'
      renderActions={() => (
        <>
          {data?.complaint?.status === "Open" && orderTab === "OrderHistory" && data?.complaint?.userRole?._id === currentUser.role._id && (
            <button
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={() => startResolution({ variables: { startComplaintId: searchParams.id } })}
            >
              {starting ? " Starting ..." : " Start Resolution"}
            </button>
          )}
          {data?.complaint?.status === "InProgress" && orderTab === "OrderHistory" && data?.complaint?.userRole?._id === currentUser.role._id && (
            <button
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={dispatchAction(searchParams?.id || "", "resolve")}
            >
              {"Resolve Ticket"}
            </button>
          )}
          {data?.complaint?.status === "InProgress" && orderTab === "OrderHistory" && data?.complaint?.userRole?._id === currentUser.role._id && (
            <button
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={dispatchAction(searchParams?.id || "", "send")}
            >
              {"Escalate Ticket"}
            </button>
          )}
        </>
      )}
    >

      <div className='block'>
        <div className='border-b border-gray-200 bg-white px-6'>
          <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
            {orderTabs.filter(el => el.showWhen.includes(data?.complaint?.status)).map((_orderTab) => (
              <button
                key={_orderTab.name}
                onClick={wrapClick(__setOrderTab(_orderTab.href))}
                disabled={
                  !_orderTab.activeStatus?.includes(
                    data?.complaint?.status
                  )
                }
                className={classNames(
                  orderTab === _orderTab.href
                    ? "border-primary-500 text-primary-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap disabled:cursor-not-allowed flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={
                  orderTab === _orderTab.href ? "page" : undefined
                }
              >
                {_orderTab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>

      <ComplaintView complaint={data?.complaint} tab={orderTab} />
    </Modal>
  );
}
