import { useEffect } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_CUSTOMER } from "./view";
import { withPermissions, wrapClick } from "utils";
import UpdateCustomerIdentityForm from "./forms/update-customer-identity";
import * as Yup from 'yup';
import lodash from "lodash";

const UPDATE_CUSTOMER = gql`
mutation UpdateServicePointIdentity(
  $id: ID!
  $customerType: CustomerType!
  $representative: ServicePointIdentityRepresentativeInput!
  $organization: ServicePointIdentityOrganizationInput
  $reason: String
) {
  updateServicePointIdentity(
    id: $id
    customerType: $customerType
    representative: $representative
    organization: $organization
    reason: $reason
  ) {
    _id
  }
}
`;

const validationSchema = Yup.object({
  customerType: Yup.string().required("Required"),
  reason: Yup.string().required("Required"),
  organization: Yup.object().when("customerType", {
    is: "Individual",
    then: (schema) => schema.shape({
      taxIdentificationNumber: Yup.string().notRequired(),
      organizationRegistrationNumber: Yup.string().notRequired(),
      certificateOfIncorporationDocumentUrl: Yup.string().notRequired(),
      organizationRegistrationDate: Yup.string().notRequired(),
      name: Yup.string().notRequired(),
      organizationRegistrationDocumentUrl: Yup.string().notRequired(),
    }).notRequired(),
    otherwise: (schema) => schema.shape({
      taxIdentificationNumber: Yup.string().required("Required"),
      organizationRegistrationNumber: Yup.string().required("Required"),
      certificateOfIncorporationDocumentUrl: Yup.string().required("Required"),
      organizationRegistrationDate: Yup.string().required("Required"),
      name: Yup.string().required("Required"),
      organizationRegistrationDocumentUrl: Yup.string().required("Required"),
    }).required("Organization"),
  }),
  representative: Yup.object().shape({
    fullName: Yup.string().required("Required"),
    title: Yup.string().required("Required"),
    phoneNumber: Yup.string().required("Required"),
    emailAddress: Yup.string().required("Required"),
    profileImageUrl: Yup.string().notRequired(),
    nationality: Yup.string().required("Required"),
    dateOfBirth: Yup.date().required("Required"),
    gender: Yup.string().required("Required"),
    hasGhanaCard: Yup.string().required("Required"),
    ghanaCardNumber: Yup.string().when(["hasGhanaCard"], {
      is: true,
      then: (schema) => schema.required("Required")
    }),
    ghanaCardBackImageUrl: Yup.string().when(["hasGhanaCard"], {
      is: true,
      then: (schema) => schema.required("Required")
    }),
    ghanaCardIssueDate: Yup.string().when(["hasGhanaCard"], {
      is: true,
      then: (schema) => schema.required("Required")
    }),
    ghanaCardExpiryDate: Yup.string().when(["hasGhanaCard"], {
      is: true,
      then: (schema) => schema.required("Required")
    }),
    ghanaCardFrontImageUrl: Yup.string().when(["hasGhanaCard"], {
      is: true,
      then: (schema) => schema.required("Required")
    }),
    identityCardNumber: Yup.string().when(["hasGhanaCard"], {
      is: false,
      then: (schema) => schema.required("Required")
    }),
    identityCardType: Yup.string().when(["hasGhanaCard"], {
      is: false,
      then: (schema) => schema.required("Required")
    }),
    identityCardExpiryDate: Yup.string().when(["hasGhanaCard"], {
      is: false,
      then: (schema) => schema.required("Required")
    }),
    identityCardIssueDate: Yup.string().when(["hasGhanaCard"], {
      is: false,
      then: (schema) => schema.required("Required")
    }),
    identityCardFrontImageUrl: Yup.string().when(["hasGhanaCard"], {
      is: false,
      then: (schema) => schema.required("Required")
    }),
    identityCardBackImageUrl: Yup.string().when(["hasGhanaCard"], {
      is: false,
      then: (schema) => schema.required("Required")
    }),
  })
})

export default function UpdateCustomerIdentityContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_CUSTOMER, {
    variables: {
      getServicePointId: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);

  const form = useFormik<any>({
    initialValues: {
      customerType: "Individual",
      reason: "",
      organization: {
        taxIdentificationNumber: "",
        organizationRegistrationNumber: "",
        certificateOfIncorporationDocumentUrl: "",
        organizationRegistrationDate: "",
        name: "",
        organizationRegistrationDocumentUrl: "",
      },
      representative: {
        fullName: "",
        title: "",
        phoneNumber: "",
        emailAddress: "",
        profileImageUrl: "",
        nationality: "",
        dateOfBirth: "",
        gender: "",
        hasGhanaCard: true,
        identityCardType: "",
        identityCardNumber: "",
        ghanaCardNumber: "",
        ghanaCardBackImageUrl: "",
        ghanaCardIssueDate: "",
        ghanaCardExpiryDate: "",
        ghanaCardFrontImageUrl: "",
        identityCardExpiryDate: "",
        identityCardIssueDate: "",
        identityCardFrontImageUrl: "",
        identityCardBackImageUrl: "",
      }
    },
    validationSchema,
    onSubmit: async (values) => {
      await updateCustomer({
        variables: {
          id: searchParams.id,
          ...(values?.customerType === "Individual" ? lodash.omit(values, ["organization"]) : values)
        },
      }).then(({ data }) => {
        if (data.updateServicePointIdentity._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Customer Identity Edited Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create Customer Identity",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (data?.customer) {
      // alert("Hello" + data?.customer?.customer?.fullName)
      form.setValues({
        customerType: data?.customer?.customer?.customerType || "",
        fullName: data?.customer?.customer?.representative?.fullName || "",
        primaryPhoneNumber: data?.customer?.customer?.representative?.phoneNumber || "",
        primaryEmailAddress: data?.customer?.customer?.representative?.emailAddress || "",
        reason: "",
        organization: {
          taxIdentificationNumber: data?.customer?.customer?.organization?.taxIdentificationNumber || "",
          organizationRegistrationNumber: data?.customer?.customer?.organization?.organizationRegistrationNumber || "",
          certificateOfIncorporationDocumentUrl: data?.customer?.customer?.organization?.certificateOfIncorporationDocumentUrl || "",
          organizationRegistrationDate: data?.customer?.customer?.organization?.organizationRegistrationDate || "",
          name: data?.customer?.customer?.organization?.name || "",
          organizationRegistrationDocumentUrl: data?.customer?.customer?.organization?.organizationRegistrationDocumentUrl || "",
        },
        representative: {
          fullName: data?.customer?.customer?.representative?.fullName || "",
          title: data?.customer?.customer?.representative?.title || "",
          phoneNumber: data?.customer?.customer?.representative?.phoneNumber || "",
          emailAddress: data?.customer?.customer?.representative?.emailAddress || "",
          profileImageUrl: data?.customer?.customer?.representative?.profileImageUrl || "",
          nationality: data?.customer?.customer?.representative?.nationality || "",
          dateOfBirth: data?.customer?.customer?.representative?.dateOfBirth || "",
          gender: data?.customer?.customer?.representative?.gender || "",
          hasGhanaCard: data?.customer?.customer?.representative?.hasGhanaCard || true,
          identityCardType: data?.customer?.customer?.representative?.identityCardType || "",
          identityCardNumber: data?.customer?.customer?.representative?.identityCardNumber || "",
          ghanaCardNumber: data?.customer?.customer?.representative?.ghanaCardNumber || "",
          ghanaCardBackImageUrl: data?.customer?.customer?.representative?.ghanaCardBackImageUrl || "",
          ghanaCardIssueDate: data?.customer?.customer?.representative?.ghanaCardIssueDate || "",
          ghanaCardExpiryDate: data?.customer?.customer?.representative?.ghanaCardExpiryDate || "",
          ghanaCardFrontImageUrl: data?.customer?.customer?.representative?.ghanaCardFrontImageUrl || "",
          identityCardExpiryDate: data?.customer?.customer?.representative?.identityCardExpiryDate || "",
          identityCardIssueDate: data?.customer?.customer?.representative?.identityCardIssueDate || "",
          identityCardFrontImageUrl: data?.customer?.customer?.representative?.identityCardFrontImageUrl || "",
          identityCardBackImageUrl: data?.customer?.customer?.representative?.identityCardBackImageUrl || "",

        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.customer, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      // hideActions={true}
      loading={loading}
      size='4xl'
      title='Edit Customer Identity Information'
      description='Provide the details to update customer identity information'
      renderActions={() => (
        <>
          {withPermissions(["*:*", "service-points:*", "service-points:update"])(
            <button
              type='button'
              disabled={form.isSubmitting}
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={wrapClick(form.handleSubmit)}
            >
              {form.isSubmitting ? "Saving changes ..." : "Save Changes"}
            </button>
          )}
        </>
      )}
    >
      {data?.customer?._id && (
        <UpdateCustomerIdentityForm form={form} customer={data?.customer} />
      )}
    </Modal>
  );
}
