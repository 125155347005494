import { wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { InvestigateReadingExceptionForm, Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { FC } from "react";
import { GET_READING_EXCEPTION } from "./view";

const APPROVE_SERVICE_ORDER = gql`
  mutation InvestigateReadingException($id: ID!, $notes: String!) {
    investigateReadingException(id: $id, notes: $notes) {
      _id
    }
  }
`;

interface InvestigateReadingExceptionContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}

const InvestigateReadingExceptionContainer: FC<
  InvestigateReadingExceptionContainerProps
> = ({ open, setOpen, refetch }) => {
  const searchParams = useSearch<LocationGenerics>();
  const [investigateReadingException] = useMutation(APPROVE_SERVICE_ORDER);
  const { data, loading } = useQuery(GET_READING_EXCEPTION, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  const form = useFormik({
    initialValues: {
      notes: "",
    },
    onSubmit: async (values) => {
      await investigateReadingException({
        variables: {
          id: searchParams.id,
          ...values,
        },
      }).then(({ data }) => {
        if (data.investigateReadingException._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Reading Exception Investigated Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not investigate Reading Exception",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Investigate Reading Exception"
      description="Provide notes for investigating reading exception"
      loading={loading}
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={form.isSubmitting}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {form.isSubmitting
              ? "Initiating Investigation..."
              : "Initiate Investigation"}
          </button>
        </>
      )}
    >
      {data?.readingException?._id && (
        <InvestigateReadingExceptionForm form={form} />
      )}
    </Modal>
  );
};

export default InvestigateReadingExceptionContainer;
