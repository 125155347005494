import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_CUSTOMER } from "./view";
import { withPermissions, wrapClick } from "utils";
import * as Yup from "yup";
import RestoreServicePointForm from "./forms/restore-customer";

const UPDATE_SERVICEPOINT = gql`
  mutation RestoreServicePoint($restoreServicePointId: ID!, $reason: String) {
    restoreServicePoint(id: $restoreServicePointId, reason: $reason) {
      _id
    }
  }
`;

export default function RestoreServicePointContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_CUSTOMER, {
    variables: {
      getServicePointId: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateCustomer] = useMutation(UPDATE_SERVICEPOINT, {
    refetchQueries: ["GetServicePoints", "GetServicePoint"],
  });

  const form = useFormik<any>({
    initialValues: {
      reason: "",
      //requiresInvoice: 1,
    },
    validationSchema: Yup.object().shape({
      reason: Yup.string().required("Reason is Required"),
      //requiresInvoice: Yup.number().oneOf([0, 1]).required('This field is required')
    }),
    onSubmit: async (values) => {
      await updateCustomer({
        variables: {
          restoreServicePointId: data?.customer?._id,
          reason: values.reason,
          requiresInvoice: values.requiresInvoice == 1,
        },
      }).then(({ data }) => {
        if (data.restoreServicePoint._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Customer Restored Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not restore Customer",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  const inCompleteCustomer =
    !data?.customer?.customerRepresentative?.fullName ||
    !data?.customer?.customerRepresentative?.phoneNumber;

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      // hideActions={true}
      loading={loading}
      size='4xl'
      title='Restore Service Point'
      description='Provide reason for restoring service point'
      renderActions={() => (
        <>
          {withPermissions([
            "*:*",
            "service-points:*",
            "service-points:update",
          ])(
            <button
              type='button'
              disabled={
                form.isSubmitting || !form.isValid || inCompleteCustomer
              }
              className='w-full inline-flex justify-center rounded-md disabled:bg-gray-400 border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={wrapClick(form.handleSubmit)}
            >
              {form.isSubmitting ? "Saving Changes ..." : "Save Changes"}
            </button>
          )}
        </>
      )}
    >
      {data?.customer?._id && (
        <RestoreServicePointForm
          form={form}
          servicePoint={data?.customer}
        />
      )}
    </Modal>
  );
}
