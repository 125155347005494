import { wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { CorrectReadingExceptionForm, Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { FC, useEffect } from "react";
import { GET_READING_EXCEPTION } from "./view";

const CORRECT_SERVICE_ORDER = gql`
  mutation CorrectReadingException($id: ID!, $readingValue: NonNegativeInt!, $notes: String!) {
    correctReadingException(id: $id, readingValue: $readingValue, notes: $notes) {
      _id
    }
  }
`;

interface CorrectReadingExceptionContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}

const CorrectReadingExceptionContainer: FC<
  CorrectReadingExceptionContainerProps
> = ({ open, setOpen, refetch }) => {
  const searchParams = useSearch<LocationGenerics>();
  const [correctReadingException] = useMutation(CORRECT_SERVICE_ORDER);
  const { data, loading } = useQuery(GET_READING_EXCEPTION, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  const form = useFormik({
    initialValues: {
      readingValue: 0,
      notes: "",
    },
    onSubmit: async (values) => {
      await correctReadingException({
        variables: {
          id: searchParams.id,
          ...values,
        },
      }).then(({ data }) => {
        if (data.correctReadingException._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Reading Exception Corrected Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not correct Reading Exception",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (data?.readingException?._id) {
      form.setFieldValue("readingValue", data?.readingException?.value || 0)
    }
  }, [data?.readingException])

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Correct Reading Exception"
      description="Provide notes for correcting reading exception"
      size="4xl"
      loading={loading}
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={form.isSubmitting}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {form.isSubmitting
              ? "Correcting Reading..."
              : "Correct Reading"}
          </button>
        </>
      )}
    >
      {data?.readingException?._id && (
        <CorrectReadingExceptionForm
          readingException={data?.readingException}
          form={form}
        />
      )}
    </Modal>
  );
};

export default CorrectReadingExceptionContainer;
