import _ from "lodash";
import { FC } from "react";
import { classNames } from "utils";

interface TableBodyComponentProps<TData = any> {
  data: {
    rows: TData[];
    total: number;
    page: number;
    pageSize: number;
    totalPages: number;
  };
  renderColumns?: FC;
  renderItem?: FC<TData>;
  renderLoader?: FC;
  loading?: boolean;
  fixed?: boolean
}

const TableBodyComponent: FC<TableBodyComponentProps> = ({ renderColumns, data, renderItem, loading, renderLoader, fixed }) => {

  return (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-0 no-scrollbar flex-1 flex overflow-y-hidden">
        <div className="align-middle min-w-full sm:px-6 lg:px-6 flex-1 flex flex-col overflow-y-auto no-scrollbar">
          <table
          className={classNames(
            fixed ? "table-fixed" : "table-auto",
            "border-separate min-w-full divide-y divide-gray-200 dark:divide-gray-700 border-x border-gray-200 dark:border-gray-700 "
           )}
          style={{ borderSpacing: 0 }}
          >
            <thead className="bg-gray-50 dark:bg-gray-800">
              {renderColumns?.({}) ?? (
                <tr>
                  {Object.keys(data.rows[0]).filter(field => !_.isObject(data.rows[0][field])).map((field) => (
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-600 dark:text-gray-300 uppercase tracking-wider whitespace-nowrap"
                    >
                      {_.startCase(field)}
                    </th>
                  ))}
                </tr>
              )}
            </thead>
            <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-700 overflow-y-auto">
              {loading ? (
                renderLoader?.({}) ?? (
                  <tr>
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200  flex-1">
                      <div className="bg-gray-500 dark:bg-gray-400 h-3 rounded-md w-full animate-pulse" />
                    </td>
                  </tr>
                )
              ) : (
                data.rows?.map((item, idx) => renderItem?.(item, idx) ?? (
                  <tr key={item._id}>
                    {Object.keys(data.rows[0]).filter(field => !_.isObject(data.rows[0][field])).map((field) => (
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                        {_.isBoolean(item[field]) ? item[field] ? "Yes" : "No" : item[field]}
                      </td>
                    ))}
                  </tr>
                ))
              )}
            </tbody>
            <tfoot className=" bg-gray-50 dark:bg-gray-800 sticky bottom-0 hidden">
              {renderColumns?.({}) ?? (
                <tr>
                  {Object.keys(data.rows[0]).filter(field => !_.isObject(data.rows[0][field])).map((field) => (
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-600 dark:text-gray-300 uppercase tracking-wider whitespace-nowrap"
                    >
                      {_.startCase(field)}
                    </th>
                  ))}
                </tr>
              )}
            </tfoot>
          </table>
      </div>
    </div>
  )
}

export default TableBodyComponent;