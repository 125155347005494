import { SelectInput, TextInput } from "components/core";
import { useFormik } from "formik";
import { FC, useMemo } from "react";
import { generateBill, splitRegularizationDate } from "./generate-bill";
import moment from "moment";
import numeral from "numeral";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import lodash from "lodash";
import { wrapClick } from "utils";
import sift from "sift";

interface BillingPreviewFormProps {
  form: any;
  tariffInstances: any[];
}

const ClassBillingPreviewForm: FC<BillingPreviewFormProps> = ({
  tariffInstances,
  form,
}) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  const readingForm = useFormik({
    initialValues: {
      previousReadingDate: "",
      previousReadingValue: 0,
      previousReadingType: "",
      currentReadingDate: "",
      currentReadingValue: 0,
      currentReadingType: "",
    },
    onSubmit: (values) => {
      console.log("before", values);
      if (!form.values.initialReadingDate) {
        form.setValues({
          ...form.values,
          initialReadingDate: values.currentReadingDate,
          initialReadingValue: values.currentReadingValue,
          lastActualReadingDate: values.currentReadingDate,
          lastActualReadingValue: values.currentReadingValue,
          bills: [],
        });
      } else {
        if (
          values.currentReadingType === "Estimated" ||
          (values.currentReadingType === "Actual" &&
            values.previousReadingType === "Actual")
        ) {
          const currentBill = generateBill({
            ...values,
            previousReadingDate: moment(values.previousReadingDate).toDate(),
            currentReadingDate: moment(values.currentReadingDate).toDate(),
            tariffInstances,
          });
          form.setFieldValue("bills", [...form.values.bills, currentBill]);
        } else {
          const estimatedReadings = form.values.bills.filter(
            sift({
              $and: [
                {
                  currentReadingDate: {
                    $gte: moment(form.values.lastActualReadingDate).toDate(),
                  },
                },
                {
                  currentReadingDate: {
                    $lt: moment(values.currentReadingDate).toDate(),
                  },
                },
              ],
            })
          );
          console.log(
            form.values.lastActualReadingDate,
            values.currentReadingDate,
            estimatedReadings
          );
          const estimatedBills = {
            totalConsumption: lodash.sumBy(
              estimatedReadings,
              "totalConsumption"
            ),
            totalBillPeriod: lodash.sumBy(estimatedReadings, "billPeriod"),
            totalBillAmount: lodash.sumBy(estimatedReadings, "totalBillAmount"),
          };
          const regularizationDateSplit = splitRegularizationDate({
            currentReadingDate: values.currentReadingDate,
            currentReadingValue: values.currentReadingValue,
            previousReadingDate: form.values.lastActualReadingDate,
            previousReadingValue: form.values.lastActualReadingValue,
            splitReadingDate: values.previousReadingDate,
          });
          const regularizedBill = generateBill({
            previousReadingDate: moment(
              regularizationDateSplit.previousReadingDate
            ).toDate(),
            previousReadingValue: regularizationDateSplit.previousReadingValue,
            currentReadingDate: moment(
              regularizationDateSplit.splitReadingDate
            ).toDate(),
            currentReadingValue: regularizationDateSplit.splitReadingValue,
            tariffInstances,
          });
          const extraBillItems = [
            {
              title: "Estimated Bills",
              displayTitle: "EB",
              unit: `${numeral(estimatedBills.totalConsumption).format(
                "0,0"
              )} KWh`,
              quantity: lodash.round(estimatedBills.totalConsumption),
              unitCost: estimatedBills.totalBillAmount,
              formattedUnitCost: numeral(estimatedBills.totalBillAmount).format(
                "0,0.0000"
              ),
              cost: -estimatedBills.totalBillAmount,
            },
            {
              title: "Regularization Bill",
              displayTitle: "RB",
              unit: `${numeral(regularizedBill.totalConsumption).format(
                "0,0"
              )} KWh`,
              quantity: lodash.round(regularizedBill.totalConsumption),
              unitCost: regularizedBill.totalBillAmount,
              formattedUnitCost: numeral(
                regularizedBill.totalBillAmount
              ).format("0,0.0000"),
              cost: regularizedBill.totalBillAmount,
            },
          ];
          const currentBill = generateBill({
            previousReadingValue: regularizationDateSplit.splitReadingValue,
            currentReadingValue: regularizationDateSplit.currentReadingValue,
            previousReadingDate: moment(
              regularizationDateSplit.splitReadingDate
            ).toDate(),
            currentReadingDate: moment(
              regularizationDateSplit.currentReadingDate
            ).toDate(),
            tariffInstances,
            extraBills: [
              {
                currentDate: regularizedBill.billEndDate,
                previousDate: regularizedBill.billStartDate,
                tariffInstance: null,
                billPeriod: regularizedBill.totalBillPeriod,
                consumption: regularizedBill.totalConsumption,
                billItems: extraBillItems,
                billAmount: lodash
                  .chain(extraBillItems)
                  .sumBy("cost")
                  .round(2)
                  .value(),
              },
            ],
          });
          form.setFieldValue("bills", [...form.values.bills, currentBill]);
        }
      }
      if (values.currentReadingType === "Actual") {
      form.setFieldValue("lastActualReadingDate", values.currentReadingDate);
        form.setFieldValue(
          "lastActualReadingValue",
          values.currentReadingValue
        );
      }
      readingForm.resetForm();
      readingForm.setValues({
        previousReadingDate: values.currentReadingDate,
        previousReadingValue: values.currentReadingValue,
        previousReadingType: values.currentReadingType || "Actual",
        currentReadingDate: "",
        currentReadingValue: values.currentReadingValue,
        currentReadingType: "",
      });
    },
  });

  const previewBill = useMemo(
    () =>
      generateBill({
        ...readingForm.values,
        previousReadingDate: moment(
          readingForm.values.previousReadingDate
        ).toDate(),
        currentReadingDate: moment(
          readingForm.values.currentReadingDate
        ).toDate(),
        tariffInstances: lodash.filter(
          tariffInstances,
          (tariffInstance) =>
            moment(tariffInstance.endDate).isSameOrAfter(
              readingForm.values.previousReadingDate
            ) || !tariffInstance.endDate
        ),
      }),
    [readingForm.values, tariffInstances]
  );

  return (
    <div className="flex-1 flex flex-col overflow-y-auto p-6">
      <div className="space-y-6 divide-y divide-gray-200">
        {!form.values.initialReadingDate ? (
          <div>
            <span className="text-xs font-light">
              Initial Reading Information
            </span>
            <div className="grid grid-cols-4 gap-6 mt-2">
              <div className="">
                <TextInput
                  id="currentReadingDate"
                  label="Initial Reading Date"
                  type="date"
                  placeholder="User Code"
                  required={true}
                  {...readingForm}
                />
              </div>

              <div>
                <TextInput
                  id="currentReadingValue"
                  label="Initial Reading (KWh)"
                  type="number"
                  step={1}
                  placeholder="eg. 1000"
                  required={true}
                  {...readingForm}
                />
              </div>

              <div className="flex items-end">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:text-sm"
                  onClick={wrapClick(readingForm.handleSubmit)}
                >
                  Submit Reading
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div>
              <span className="text-xs font-light">Reading Information</span>
              <div className="grid grid-cols-4 gap-6 mt-2">
                <div className="">
                  <TextInput
                    id="currentReadingDate"
                    label="Current Reading Date"
                    type="date"
                    placeholder="User Code"
                    required={true}
                    {...readingForm}
                    min={lodash.get(readingForm.values, "previousReadingDate")}
                  />
                </div>

                <div>
                  <TextInput
                    id="currentReadingValue"
                    label="Current Reading (KWh)"
                    type="number"
                    step={1}
                    placeholder="eg. 1000"
                    required={true}
                    {...readingForm}
                  />
                </div>

                <div>
                  <SelectInput
                    id="currentReadingType"
                    label="Current Reading Type"
                    placeholder="eg. 1000"
                    required={true}
                    {...readingForm}
                    options={[
                      { label: "--- Select One ---", value: "" },
                      "Actual",
                      "Estimated",
                    ]}
                  />
                </div>

                <div className="flex items-end">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:text-sm"
                    onClick={wrapClick(readingForm.handleSubmit)}
                  >
                    Submit Reading
                  </button>
                </div>
              </div>
            </div>

            <div className="pt-6">
              <span className="text-xs font-light">Bill Preview</span>
              <div className="mt-2 border-gray-300 border p-4 border-dashed bg-gray-50">
                <div className="grid grid-cols-4 gap-6">
                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Previous Reading Date
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {moment(readingForm?.values?.previousReadingDate).format(
                        dateFormat
                      )}
                    </div>
                  </div>

                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Previous Reading Value
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {numeral(
                        readingForm?.values?.previousReadingValue || 0
                      ).format("0,0")}{" "}
                      KWh
                    </div>
                  </div>

                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Consumption
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {numeral(previewBill?.totalConsumption || 0).format(
                        "0,0"
                      )}{" "}
                      KWh
                    </div>
                  </div>

                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Bill Period
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {previewBill?.totalBillPeriod || 0} Days
                    </div>
                  </div>
                </div>
                <table className="min-w-full divide-y divide-gray-300 mt-4 border-collapse ">
                  <thead>
                    <tr className="bg-primary-600">
                      <th
                        scope="col"
                        colSpan={2}
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-4 w-[40%]"
                      >
                        Items
                      </th>
                      <th
                        scope="col"
                        className="hidden py-3.5 px-3 text-right text-sm font-semibold text-white sm:table-cell  w-[20%]"
                      >
                        Units
                      </th>
                      <th
                        scope="col"
                        className="hidden py-3.5 px-3 text-right text-sm font-semibold text-white sm:table-cell w-[20%]"
                      >
                        Price
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-white sm:pr-4 w-[20%]"
                      >
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {previewBill?.bills?.map?.((billX) => (
                      <>
                        <tr className="border-b border-gray-200 bg-yellow-50">
                          <td
                            colSpan={5}
                            className="py-3 pl-4 pr-3 text-sm text-gray-600 sm:pl-4"
                          >
                            {moment(billX?.previousDate).format(dateFormat)} -{" "}
                            {moment(billX?.currentDate).format(dateFormat)} (
                            {numeral(billX?.billPeriod).format("0,0")} days)
                          </td>
                        </tr>
                        {billX?.billItems?.map((item: any, idx: string) => (
                          <tr key={idx} className="border-b border-gray-200">
                            <td
                              colSpan={2}
                              className="py-4 pl-4 pr-3 text-sm sm:pl-4"
                            >
                              <div className="font-medium text-gray-900">
                                {item.title}
                              </div>
                            </td>
                            <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                              {item.unit}
                            </td>
                            <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                              {numeral(item.unitCost).format("0,0.0000")}
                            </td>
                            <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-4">
                              {numeral(item.cost).format("0,0.00")}
                            </td>
                          </tr>
                        ))}
                      </>
                    ))}
                    <tr className="border-b border-transparent">
                      <td colSpan={5} className="h-6" />
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr className="">
                      <th className="relative" rowSpan={3} colSpan={2}></th>
                      <th className="relative" rowSpan={3}></th>
                      <th
                        scope="row"
                        className="pl-4 pr-3  py-3 text-left text-sm font-medium text-gray-700"
                      >
                        Current Bill
                      </th>
                      <td className="pl-3 pr-4 py-3 text-right text-sm text-gray-500">
                        GHS{" "}
                        {numeral(previewBill?.totalBillAmount).format("0,0.00")}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="bg-primary-600 pl-4 pr-3 py-5 text-left text-sm font-semibold text-white"
                      >
                        Amount Payable
                      </th>
                      <td className="bg-primary-600 pl-3 pr-4 py-5 text-right text-sm font-semibold text-white sm:pr-4">
                        GHS{" "}
                        {numeral(previewBill?.totalBillAmount).format("0,0.00")}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ClassBillingPreviewForm;
