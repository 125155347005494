import { TextArea, TextInput, UploadArea } from "components/core";
import { FormikProps } from "formik";
import { FC } from "react";

interface ResolveServiceOrderFormProps {
  form: FormikProps<{
    reading: {
      readingDate: string;
      readingValue: number;
    };
    supportingImageUrls: string[];
    notes: string;
  }>;
}

const ResolveServiceOrderForm: FC<ResolveServiceOrderFormProps> = ({
  form,
}) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Resolution Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div>
            <TextInput
              id="reading.readingDate"
              label="Disconnection Date"
              placeholder="e.g. 01/10/2023"
              required={true}
              type="date"
              {...form}
            />
          </div>
          <div>
            <TextInput
              id="reading.readingValue"
              label="Disconnection Reading"
              placeholder="e.g. 567"
              required={true}
              type="number"
              step={1}
              postText="KWh"
              {...form}
            />
          </div>
          <div className="col-span-3">
            <UploadArea
              id="supportingImageUrls"
              label="Supporting Images"
              className="aspect-w-5 aspect-h-2"
              multiple={true}
              maxFiles={6}
              {...form}
            />
          </div>
          <div className="col-span-3">
            <TextArea
              id="notes"
              label="Notes"
              placeholder="e.g. Details captured are conclusive"
              required={true}
              rows={10}
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResolveServiceOrderForm;
