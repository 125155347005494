import { classNames, withPermissions, wrapClick } from "utils";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { Modal } from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { Action } from "components/buttons/action-button";
import { Fragment, useState } from "react";
import CustomerView from "./cards/customer-view";
import ServicePointHistoryView from "./cards/service-point-history-view";
import { Menu, Transition } from "@headlessui/react";
import { UserPermission } from "apollo/cache/auth";
import CustomerReadingHistoryView from "./cards/customer-reading-history-view";
import CustomerComplaintsView from "./cards/customer-complaints-view";
import { GET_COMPLAINTS } from "pages/complaints/hooks";

export const GET_CUSTOMER = gql`
  query GetServicePoint($getServicePointId: ID!) {
    customer: getServicePoint(id: $getServicePointId) {
      _id
      code
      qrCode
      propertyCode
      customerCode
      accountCode
      meterCode
      serviceType
      serviceClass
      geoCode
      meterLocation
      transformerNumber
      contractedDemand
      status
      subStatus
      customerRepresentative {
        title
        fullName
        phoneNumber
        emailAddress
        profileImageUrl
        ghanaCardNumber
        identityCardNumber
        identityCardType
        nationality
        hasGhanaCard
        identityCardBackImageUrl
        identityCardExpiryDate
        identityCardFrontImageUrl
        identityCardIssueDate
        ghanaCardBackImageUrl
        ghanaCardExpiryDate
        ghanaCardFrontImageUrl
        ghanaCardIssueDate
        gender
        dateOfBirth
        extraEmailAddresses
        extraPhoneNumbers
      }
      customerOrganization {
        name
        organizationRegistrationNumber
        organizationRegistrationDate
        organizationRegistrationDocumentUrl
        taxIdentificationNumber
      }
      customerType
      customerCode
      meta {
        lastReadingDate
        lastBillValue
        lastBillDate
        lastReadingValue
      }
      tariffClass {
        name
        _id
        serviceClass
      }
      property {
        regionName
        districtName
        blockName
        roundName
        plotCode
        itineraryCode
        code
        _id
        ghanaPostAddress
        streetName
        structureNumber
        closestLandmark
        address
        owner {
          fullName
          phoneNumber
        }
        premiseType {
          code
          name
        }
        premiseCategory {
          code
          name
        }
        sitePlanDocumentUrl
        geoLocation {
          coordinates
          type
        }
      }
      customerType
      meter {
        code
        model {
          name
          code
        }
        brand {
          code
          name
        }
        modelType
        modelMeta {
          phase
        }
      }
      structureNumber
      activity {
        name
      }
      subActivity {
        name
      }
      cartesianLocation {
        easting
        northing
      }
      energyCertificateDocumentUrl
      energyCertificateNumber
      district {
        _id
      }
      address
      payer {
        fullName
        primaryPhoneNumber
        primaryEmailAddress
        phoneNumbers
        emailAddresses
      }
      blockCode
      blockName
      districtCode
      districtName
      plotName
      plotCode
      regionName
      regionCode
      poleNumber
      roundCode
      roundName
    }
  }
`;

export const GET_READING_HISTORY = gql`
  query GetCustomerReadingHistory($servicePoint: ID) {
    readings: getReadings(
      statuses: [MeterRead, ExceptionDetected]
      servicePoint: $servicePoint
      sort: "year month"
    ) {
      _id
      currentReading {
        readingDate
        readingValue
        readingEstimated
        consumptionValue
        billValue
        totalAmountDue
      }
      previousReading {
        readingDate
        readingValue
        readingEstimated
        consumptionValue
        billValue
        totalAmountDue
      }
      status
      meta {
        readingImageUrl
        readingLocation {
          coordinates
          type
        }
      }
    }
  }
`;

const GET_SERVICE_POINT_HISTORY = gql`
  query GetServicePointHistories(
    $servicePoint: ID
    $pageSize: Int
    $page: Int
  ) {
    getServicePointHistories(
      servicePoint: $servicePoint
      pageSize: $pageSize
      page: $page
    ) {
      action
      description
      updateReason
      servicePointCode
      createdAt
      actor {
        code
        lastName
        firstName
        profileImageUrl
        roleName
        roleCode
      }
      data {
        oldMeterCode
        oldMeterBrandCode
        oldMeterBrandName
        oldMeterModelCode
        oldMeterModelName
        oldMeterModelType
        newMeterCode
        newMeterBrandCode
        newMeterBrandName
        newMeterModelCode
        newMeterModelName
        newMeterModelType
        oldTariffCode
        oldTariffName
        newTariffCode
        newTariffName
        newTariffEffectiveDate
        oldRegionName
        oldDistrictName
        oldBlockName
        oldRoundName
        oldPlotCode
        oldPlotName
        oldEasting
        oldNorthing
        oldZone
        oldStructureNumber
        oldGeoCode
        oldAddress
        oldGhanaPostAddress
        newRegionName
        newDistrictName
        newBlockName
        newRoundName
        newPlotCode
        newPlotName
        newEasting
        newNorthing
        newZone
        newStructureNumber
        newGeoCode
        newAddress
        newGhanaPostAddress
        oldCustomerType
        newCustomerType
        oldFullName
        oldPrimaryPhoneNumber
        oldPrimaryEmailAddress
        oldPhoneNumbers
        oldEmailAddresses
        newFullName
        newPrimaryPhoneNumber
        newPrimaryEmailAddress
        newPhoneNumbers
        newEmailAddresses
        oldReadingValue
        oldReadingDate
        newReadingValue
        newReadingDate
        suspendType
      }
    }
  }
`;

const Modifications: {
  name: string;
  action: Action;
  permissions: UserPermission[];
  statuses: string[];
}[] = [
  // {
  //   name: "Update Location",
  //   action: "updateLocation",
  //   permissions: ["*:*", "service-points:*", "service-points:update-location"],
  //   statuses: ["Active Contract", "Active"],
  // },
  // {
  //   name: "Update Contact",
  //   action: "update",
  //   permissions: ["*:*", "service-points:*", "service-points:update-contact"],
  //   statuses: ["Active Contract", "Active"],
  // },
  // {
  //   name: "Update Identity",
  //   action: "updateIdentity",
  //   permissions: ["*:*", "service-points:*", "service-points:update-identity"],
  //   statuses: ["Active Contract", "Active"],
  // },
  // {
  //   name: "Update Tariff",
  //   action: "updateTariff",
  //   permissions: ["*:*", "service-points:*", "service-points:update-tarrif"],
  //   statuses: ["Active Contract", "Active"],
  // },
  // {
  //   name: "Suspend",
  //   action: "suspend",
  //   permissions: ["*:*", "service-points:*", "service-points:suspend"],
  //   statuses: ["Active Contract", "Active"],
  // },
  {
    name: "Restore",
    action: "restore",
    permissions: ["*:*", "service-points:*", "service-points:restore"],
    statuses: ["Suspended"],
  },
  // {
  //   name: "Terminate",
  //   action: "terminate",
  //   permissions: ["*:*", "service-points:*", "service-points:terminate"],
  //   statuses: ["Active Contract", "Active"],
  // },
];

const orderTabs = [
  {
    name: "Customer Details",
    href: "customerDetails",
  },
  {
    name: "History",
    href: "history",
  },
  {
    name: "Reading History",
    href: "readingHistory",
  },
  {
    name: "Tickets",
    href: "customerComplaints",
  },
];

export default function ViewCustomerContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [orderTab, setOrderTab] = useState("customerDetails");
  const [getReadingHistory, { data: dataHistory, loading: loadingHistory }] =
    useLazyQuery(GET_READING_HISTORY, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    });
  const [
    getServicePointHistory,
    { data: serviceHistory, loading: loadingServicePointHistory },
  ] = useLazyQuery(GET_SERVICE_POINT_HISTORY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const { data, loading } = useQuery(GET_CUSTOMER, {
    variables: {
      getServicePointId: searchParams.id,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted(data) {
      if (data?.customer?._id) {
        console.log(data?.customer?._id);
        getReadingHistory({
          variables: {
            servicePoint: data?.customer?._id,
          },
        });
        getServicePointHistory({
          variables: {
            page: 1,
            pageSize: 100,
            servicePoint: data?.customer?._id,
          },
        });
      }
    },
  });
  const { data: complaintsData, loading: complaintsLoading } = useQuery(
    GET_COMPLAINTS,
    {
      variables: {
        servicePoint: searchParams.id,
      },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    }
  );

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };

  return (
    <Modal
      open={open}
      loading={loading || loadingHistory || loadingServicePointHistory}
      setOpen={setOpen}
      hidePadding
      title='Customer Information'
      size='6xl'
      renderActions={() => (
        <>
          {withPermissions([
            "*:*",
            "service-points:*",
            "service-points:restore",
          ])(
            <button
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={dispatchAction(
                data?.customer?._id,
                "restore"
              )}
            >
              Restore{" "}
            </button>
          )}
        </>
      )}
      description='Details of customer are shown below'
      >
      <>
        <div className='block'>
          <div className='border-b border-gray-200 bg-white px-6'>
            <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
              {orderTabs.map((_orderTab) => (
                <button
                key={_orderTab.name}
                onClick={wrapClick(__setOrderTab(_orderTab.href))}
                className={classNames(
                    orderTab === _orderTab.href
                      ? "border-primary-500 text-primary-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                    "whitespace-nowrap disabled:cursor-not-allowed flex py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={
                    orderTab === _orderTab.href ? "page" : undefined
                  }
                >
                  {_orderTab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
        <div className='flex-1 w-full max-h-[85vh] overflow-y-auto  sm:p-6'>
          {orderTab === "customerDetails" && (
            <CustomerView customer={data?.customer} />
          )}
          {orderTab === "history" && (
            <ServicePointHistoryView
              history={serviceHistory?.getServicePointHistories || []}
            />
          )}
          {orderTab === "readingHistory" && (
            <CustomerReadingHistoryView
              readings={dataHistory?.readings}
              loading={loadingHistory}
            />
          )}
          {orderTab === "customerComplaints" && (
            <CustomerComplaintsView
              complaints={complaintsData?.rows}
              loading={complaintsLoading}
            />
          )}
        </div>
      </>
    </Modal>
  );
}
