import { gql, useQuery } from "@apollo/client";

const GET_SPOT_BILLS = gql`
 query GetBills(
  $page: Int
  $pageSize: Int
  $sort: String
  $search: String
  $searchFields: [String!]
  $fromDate: Date
  $toDate: Date
  $month: PositiveInt
  $year: PositiveInt
  $region: ID
  $district: ID
) {
  rows: getSpotBills(
    page: $page
    pageSize: $pageSize
    sort: $sort
    search: $search
    searchFields: $searchFields
    fromDate: $fromDate
    toDate: $toDate
    month: $month
    year: $year
    region: $region
    district: $district
  ) {
    _id
    code
    servicePointCode
    accountCode
    tariffClass {
      name
      serviceClass
    }
    tariffClassCode
    createdAt
    updatedAt
    meterCode
    status
    region {
      name
      code
    }
    district {
      code
      name
    }
    servicePoint {
    _id
      customerRepresentative {
        fullName
        profileImageUrl
      }
      customerOrganization {
        name
      }
    }
  }
  count: getSpotBillsCount(
    search: $search
    searchFields: $searchFields
    fromDate: $fromDate
    toDate: $toDate
    month: $month
    year: $year
    region: $region
    district: $district
  )
}
`;

export const useSpotBills = (variables?: any) => {
  const { data, ...rest } = useQuery(GET_SPOT_BILLS, {
    variables: {
      ...variables,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
  });

  return {
    rows: data?.rows,
    count: data?.count,
    ...rest,
  };
};