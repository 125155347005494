import { FC } from "react";
import numeral from "numeral";
import moment from "moment";
import { ICurrentConfig } from "apollo/cache/config";
import { wrapImage } from "utils";

import { MapProvider, Marker } from "react-map-gl";
import config from "config";

import Map from "react-map-gl";

const ReadingInfoSummary: FC<{ data: any; config: ICurrentConfig }> = ({
  data,
  config: { dateFormat },
}) => (
  <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
    <div className=''>
      <span className='text-xs font-light'>Reading Information</span>
      <div className='grid grid-cols-3 gap-6 mt-2'>
        <div className=''>
          <span className='block text-sm font-light text-gray-700'>
            Initial Reading Date
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.readingDate
              ? moment(data?.readingDate).format(dateFormat)
              : "N/A"}
          </div>
        </div>
        <div className=''>
          <span className='block text-sm font-light text-gray-700'>
            Initial Reading Value
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.readingValue
              ? numeral(data?.readingValue).format("0,0")
              : "N/A"}{" "}
            KWh
          </div>
        </div>

        <div className='mt-1  w-full  col-start-1 '>
          <span className='block text-sm font-light text-gray-700'>
          Reconnection Reading Image
          </span>
          <div className='mt-2'>
            {wrapImage(
              <img
                src={data?.readingImageUrl}
                alt={"im"}
                className='h-64 w-full object-cover object-center rounded-md'
              />
            )}
          </div>
        </div>
        <div className='mt-1 w-full self-end'>
          <span className='block text-sm font-light text-gray-700'>
            Meter Image
          </span>
          <div className="mt-2">
            {wrapImage(
              <img
                src={data?.supportingImageUrls?.[0]}
                alt={"im-2"}
                className='h-64 w-full object-cover object-center rounded-md'
              />
            )}
          </div>
        </div>
       
      </div>
    </div>
    <div className='pt-6'>
      <span className='text-xs font-light'>Extra Information</span>
      <div className='grid gap-6 mt-2'>
        <div>
          <span className='block text-sm font-light text-gray-700'>Notes</span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.notes || "N/A"}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ReadingInfoSummary;
