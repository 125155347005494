import { wrapClick } from "utils";
import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { ApproveCustomerReadingForm, Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";

const APPROVE_CUSTOMER_READING = gql`
  mutation ApproveCustomerReading(
    $approveCustomerReadingId: ID!
    $notes: String
  ) {
    approveCustomerReading(id: $approveCustomerReadingId, notes: $notes) {
      _id
    }
  }
`;

export default function ApproveCustomerReadingContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [approveDebt, { loading }] = useMutation(APPROVE_CUSTOMER_READING);

  const form = useFormik({
    initialValues: {
      notes: "",
    },
    onSubmit: async (values) => {
      await approveDebt({
        variables: {
          approveCustomerReadingId: searchParams.id,
          ...values,
        },
      }).then(({ data }) => {
        if (data.approveCustomerReading._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Customer Reading Approved Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not approve Customer Reading",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Approve Customer Reading"
      description="Confirmation for approving Customer Reading"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading
              ? "Approving Customer Reading..."
              : "Approve Customer Reading"}
          </button>
        </>
      )}
    >
      <ApproveCustomerReadingForm form={form} />
    </Modal>
  );
}
